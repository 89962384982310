import React, { useCallback, useEffect, useState } from 'react';
import { InputGroup, Badge } from 'reactstrap';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import ApiService from './../../../../Services/ApiService';
import { addSpinner, removeSpinner } from './../../../../Store/Actions/spinner';
import AlertService from '../../../../Services/AlertService';
import uuid from 'react-uuid';
import { DEFAULT_LANGUAGE_KEY, ERROR_KEY, LANGUAGE_KEY, fieldLengths } from '../../../../Constants/MainKeys';
import { setActiveGroupName, setActiveGroupsName, setPartnerId } from '../../../../Store/Actions/main';
import { setActiveTab } from '../../../../Store/Actions/layout';
import { setGroups, setActiveUserData, setDefaultGroups, changeClientsOnlineStatus, changeCallWaitingStatus, setIsCallInProcess } from '../../../../Store/Actions/chat';
import NoData from '../../../../Components/NoData/NoData';
import TranslationService from '../../../../Services/translationService';
import CustomInput from "./../../../../Components/Inputs/Input"
import CheckBoxInput from '../../../../Components/Inputs/CheckBoxInput';
import * as signalR from '@microsoft/signalr';
import { API_URL_KEY } from '../../../../Constants/ApiKeys';
import { setGlobalConnectionWithRedux, setRtcGlobalConnection } from '../../../../Store/Actions/signalR';
import Auxiliary from '../../../../hoc/auxiliary/auxiliary';

const Groups = (props) => {

    const searchTextMaxLength = 100;
    const dispatch = useDispatch();

    const activeGroupsName = useSelector(state => state.main.activeGroupsName);
    const activeUserData = useSelector(state => state.chat.activeUserData);
    const rtcGlobalConnection = useSelector(state => state.signalR.rtcGlobalConnection);
    const partnerId = useSelector(state => state.main.partnerId);
    const user = useSelector(state => state.user.user);
    const connection = useSelector(state => state.signalR.globalConnectionWithRedux);
    const language = useSelector(state => state.language.language);
    const translations = useSelector(state => state.translation.translations);
    const isShowSettings = useSelector(state => state.main.isShowSettings);
    const groups = useSelector(state => state.chat.groups)
    const defaultGroups = useSelector(state => state.chat.defaultGroups)

    const [translationService, setTranslationService] = useState(null);
    const [searchText, setsearchText] = useState("");
    const [disabledGroupName, setDisabledGroupName] = useState(false);

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    useEffect(() => {
        if (isConnectionOk() && localStorage.getItem("callId") && localStorage.getItem("activeCallId")) {
            connection.invoke("HangupCall", localStorage.getItem("activeCallId"), +localStorage.getItem("callId"), 1, user.id).then(() => {
                rtcGlobalConnection && rtcGlobalConnection.closeRoom();
                dispatch(changeCallWaitingStatus(false));
                dispatch(setIsCallInProcess(false));
                dispatch(setRtcGlobalConnection(null));
                localStorage.removeItem("callId");
                localStorage.removeItem("activeCallId");
            }).catch(error => getFail(error));
        }
        getGroups();
    }, [])

    useEffect(() => {
        if (activeUserData || partnerId) {
            dispatch(setActiveUserData(null));
            dispatch(setPartnerId(null));
        }
    }, [activeUserData, partnerId])

    const getGroups = () => {
        const spinnerId = uuid();
        setSpinner(spinnerId);
        ApiService.getSupporterCompanies().then(response => {
            if (response && response.data) {
                const data = [...response.data];
                if (isConnectionOk()) {
                    connectGroups(connection, data);
                } else {
                    connectToSignalR(data);
                }
                data.forEach(group => {
                    dispatch(setActiveGroupsName(group, group.isChecked));
                })

                dispatch(setGroups(data));
                dispatch(setDefaultGroups(data));
            }
            extractSpinner(spinnerId);
        }).catch(error => getFail(error, spinnerId));
    }

    const isConnectionOk = () => {
        if (connection && connection.state === "Connected") return true;
        else return false;
    }

    const connectToSignalR = async (activeGroups) => {
        if (!user) { return false; }
        let activeGroupsNameItem = activeGroups && activeGroups.length ? activeGroups : activeGroupsName;
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${API_URL_KEY}dashboard`)
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.None)
            .build();

        connection.serverTimeoutInMilliseconds = 1000 * 60 * 10;

        connection.onreconnected((connectionId) => {
            if (!connectionId) { return false; }
            connectGroups(connection, activeGroupsNameItem);
            localStorage.setItem("connectionId", connectionId)
            // console.log("SignalR Reconnected.");
        });

        try {
            await connection.start();
            // console.log("SignalR Connected.");
            dispatch(setGlobalConnectionWithRedux(connection));
            connectGroups(connection, activeGroupsNameItem);
            connection.connectionId && localStorage.setItem("connectionId", connection.connectionId);

            connection.onclose(() => {
                // console.log("SignalR Disconnected.");
                dispatch(changeClientsOnlineStatus());
                if (localStorage.getItem("user") && window.location.pathname.includes("/groups")) {
                    setTimeout(() => connectToSignalR(), 3000);
                } else {
                    return false;
                }
            });
        } catch (error) {
            dispatch(changeClientsOnlineStatus());
            if (localStorage.getItem("user") && window.location.pathname.includes("/groups")) {
                setTimeout(() => connectToSignalR(), 3000);
            } else {
                return false;
            }
        }
    };

    const connectGroups = (connection, groups) => {
        if (connection && connection.connectionId !== localStorage.getItem("connectionId") && groups && groups.length) {
            let currentLanguage = localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY;
            groups.forEach(group => {
                if (group.isChecked) {
                    // console.log("SupporterConnection");
                    connection.invoke("SupporterConnection", group.groupName, user.id, group.partnerId, false, currentLanguage).then(() => {
                        // console.log(`The group where partnerId is ${group.partnerId}, and where name is ${group.groupName} successfully connected`);
                        dispatch(setActiveGroupsName(group, true));
                    });
                }
            })
        }
    }

    const onChangeActiveGroupName = (group) => {
        dispatch(setActiveGroupName(group.groupName));
        dispatch(setPartnerId(group.partnerId));
        dispatch(setActiveTab("chat"));
    }

    const search = (event, cb, maxLength = null) => {
        if (maxLength && maxLength < event.target.value.length) { return; }
        cb(event.target.value);
        if (event.target.value.length && event.target.value.trim().length) {
            dispatch(setGroups(defaultGroups.filter(group => group.companyName.toUpperCase().includes(event.target.value.toUpperCase()))))
        } else {
            dispatch(setGroups(defaultGroups));
        }
    }

    const onCheckboxChange = (event, group) => {
        const groupsCopy = [...groups];
        const data = {
            supporterUserId: user.id,
            partnerId: group.partnerId,
            isChecked: event.target.checked,
        };
        setDisabledGroupName(group.groupName);
        ApiService.changeGroupIsChecked(data).then((response) => {
            if (response && response.data && isConnectionOk()) {
                if (response.data.isChecked) {
                    let currentLanguage = localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY;
                    connection.invoke("SupporterConnection", group.groupName, user.id, group.partnerId, false, currentLanguage).then(() => {
                        groupsCopy.forEach(groupItem => {
                            if (response && response.data && groupItem.groupName === group.groupName) {
                                groupItem.isChecked = response.data.isChecked;
                                dispatch(setActiveGroupsName(group, response.data.isChecked))
                            }
                        })
                    }).catch((err) => {
                        AlertService.alert(ERROR_KEY, err);
                    });
                } else {
                    connection.invoke("SupporterDisconnection", group.groupName, user.id, +group.partnerId).then(() => {
                        groupsCopy.forEach(groupItem => {
                            if (response && response.data && groupItem.groupName === group.groupName) {
                                groupItem.isChecked = response.data.isChecked;
                                dispatch(setActiveGroupsName(group, response.data.isChecked))
                            }
                        })
                    }).catch((err) => {
                        AlertService.alert(ERROR_KEY, err);
                    });
                }
            }
            dispatch(setGroups(groupsCopy));
            setDisabledGroupName(null);
        }).catch(error => getFail(error));
    }

    const setSpinner = useCallback(spinner => {
        dispatch(addSpinner(spinner));
    }, []);

    const extractSpinner = useCallback(spinner => {
        dispatch(removeSpinner(spinner));
    }, []);

    const getFail = (error, spinnerId) => {
        error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
        spinnerId && extractSpinner(spinnerId);
        setDisabledGroupName(null);
    };


    return translationService ?
        <React.Fragment>
            <div className="p-4">
                <h4 className="mb-4 vnd--upp-text-20">{translationService.translate("TR_GROUPS")}</h4>
                <div className={`search-box chat-search-box ${defaultGroups && defaultGroups.length ? "" : "disabled"}`}>
                    <InputGroup>
                        <span className="text-decoration-none text-muted pr-1">
                            <i className="ri-search-line search-icon font-size-18"></i>
                        </span>
                        <CustomInput
                            id="searchText"
                            htmlFor="searchText"
                            type="text"
                            name="searchText"
                            placeholder={`${translationService.translate("TR_SEARCH")} ${translationService.translate("TR_GROUPS")}...`}
                            value={searchText}
                            onChange={(event) => search(event, setsearchText, fieldLengths.maxLength_100)}
                        />
                    </InputGroup>
                </div>
            </div>
            <SimpleBar style={{ maxHeight: "100%" }} className="p-4 chat-message-list chat-group-list">
                <ul className="list-unstyled chat-list">
                    {
                        groups && groups.length ? groups.map((group, index) =>
                            <div key={index} className='d-flex flex-column justify-content-center'>
                                <li
                                    className={`sup-companies ${group.isChecked ? "active" : ""} ${disabledGroupName === group.groupName ? "disabled" : ""}`}
                                >
                                    <Link to={`#`} className="border-bottom cursor-default">
                                        <div className="d-flex align-items-center">
                                            <div className="chat-user-img me-3 ms-0">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-soft-primary vnd--text-primary">
                                                        <i className="ri-briefcase-2-fill"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex-1 overflow-hidden menu-item-wrapper">
                                                <h5 className="text-truncate font-size-16 mb-0 d-flex justify-content-between align-items-center">
                                                    {group.companyName}
                                                    {
                                                        group.inboxMessageCount !== 0
                                                            ? <Badge color="none" pill className="badge-soft-danger float-end">
                                                                {
                                                                    group.inboxMessageCount >= 20 ? group.inboxMessageCount + "+" : group.inboxMessageCount
                                                                }
                                                            </Badge>
                                                            : null
                                                    }
                                                </h5>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className='custom-control-wrapper'>
                                        {
                                            group.isPartnerSubscribed ?
                                                <Auxiliary>
                                                    <CheckBoxInput
                                                        id={`${group.groupName}_${index}`}
                                                        htmlFor={`${group.groupName}_${index}`}
                                                        labelValue={translationService.translate("TR_BECOME_ONLINE")}
                                                        checked={group.isChecked ? true : false}
                                                        onChange={(event) => onCheckboxChange(event, group)}
                                                    />
                                                    <hr />
                                                </Auxiliary>
                                                : null
                                        }
                                        <Link
                                            to={`/${language}/dashboard/chats/inbox`}
                                            className="vnd-btn link-button vnd-primary-btn vnd-small-btn w-100"
                                            onClick={() => onChangeActiveGroupName(group)}
                                        >
                                            {translationService.translate("TR_CHATBOARD")}
                                        </Link>
                                    </div>
                                </li>
                            </div>
                        ) : <NoData />
                    }
                </ul>
            </SimpleBar>

        </React.Fragment>

        : null
}


export default Groups;