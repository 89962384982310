import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import ActionButton from '../../../../Components/Buttons/ActionButton';
import Input from '../../../../Components/Inputs/Input'
import Textarea from '../../../../Components/Inputs/Textarea'
import { ERROR_KEY, SUCCESS_KEY, fieldLengths } from '../../../../Constants/MainKeys';
import AlertService from '../../../../Services/AlertService';
import ApiService from '../../../../Services/ApiService';
import TranslationService from '../../../../Services/translationService';
import { addButtonSpinner, removeButtonSpinner } from '../../../../Store/Actions/spinner';


const spinnerId = uuid();
export default function ContactUs(props) {

  const dispatch = useDispatch()
  const { translations } = useSelector(state => state.translation);
  const { role, user } = useSelector(state => state.user);

  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [translationService, setTranslationService] = useState(null);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (!user) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [])

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
    return () => {
      setName("");
      setSubject("");
      setEmail("");
      setMessage("");
    }
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!name.trim().length || !subject.trim().length || !email.trim().length || !message.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    } else {
      setButtonSpinner(spinnerId);
      const form = {
        name,
        subject,
        email,
        message
      }
      ApiService.sendContactUsFields(form).then(() => {
        setName("");
        setSubject("");
        setEmail("");
        setMessage("");
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_MESSAGE_SENT_SUCCESS"));
        if(props.cb){
          props.cb();
        }
        extractButtonSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId));
    }
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  return translationService ? <form className="row" onSubmit={onSubmit}>
    <div className="col-lg-6">
      <Input
        id="name"
        htmlFor="name"
        type="text"
        name="name"
        value={name}
        className={`${((isInvalidSubmit && !name.trim()) || isInvalidSubmit) ? "error-border" : ""}`}
        labelValue={`${translationService.translate("TR_NAME")} *`}
        placeholder={`${translationService.translate("TR_NAME")}`}
        required={true}
        onChange={(event) => onChange(event, setName, fieldLengths.maxLength_100)}
      />
    </div>
    <div className="col-lg-6">
      <Input
        id="subject"
        type="text"
        name="subject"
        blockClassName="footer-form-group"
        value={subject}
        className={`${isInvalidSubmit && !subject.trim() ? "error-border-bottom" : ""}`}
        labelValue={`${translationService.translate("TR_SUBJECT")} *`}
        placeholder={`${translationService.translate("TR_SUBJECT")}`}
        required={true}
        onChange={(event) => onChange(event, setSubject, fieldLengths.maxLength_200)}
      />
    </div>
    <div className="col-lg-12">
      <Input
        id="email"
        htmlFor="email"
        type="email"
        name="email"
        value={email}
        className={`${((isInvalidSubmit && !email.trim()) || isInvalidSubmit) ? "error-border" : ""}`}
        labelValue={`${translationService.translate("TR_EMAIL")} *`}
        placeholder={`${translationService.translate("TR_EMAIL")}`}
        required={true}
        onChange={(event) => onChange(event, setEmail, fieldLengths.maxLength_120)}
      />
    </div>
    <div className="col-md-12">
      <Textarea
        id="message"
        htmlFor="message"
        value={message}
        className={`resize-none ${((isInvalidSubmit && !message.trim()) || isInvalidSubmit) ? "error-border" : ""}`}
        labelValue={`${translationService.translate("TR_MESSAGE")} *`}
        placeholder={translationService.translate("TR_MESSAGE_PLACEHOLDER")}
        rows="5"
        required={true}
        onChange={(event) => onChange(event, setMessage, fieldLengths.maxLength_2000)}
      />
    </div>
    <div className="col-12">
      <ActionButton
        type="submit"
        name={translationService.translate("TR_SEND")}
        className="vnd-btn vnd-primary-btn ml-auto"
        spinnerId={spinnerId}
      />
    </div>
  </form> : null
}
