import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { setActiveSection } from '../../../Store/Actions/main';
import { ERROR_KEY, SUCCESS_KEY, fieldLengths } from '../../../Constants/MainKeys';
import AlertService from '../../../Services/AlertService';
import uuid from 'react-uuid';
import ApiService from '../../../Services/ApiService';
import { addButtonSpinner, addSpinner, removeButtonSpinner, removeSpinner } from '../../../Store/Actions/spinner';
import MainService from '../../../Services/mainService';
import * as moment from "moment";
import NoData from '../../../Components/NoData/NoData';
import TranslationService from '../../../Services/translationService';
import ActionButtonWithOvalSpinner from '../../../Components/Buttons/ActionButtonWithOvalSpinner';
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import ReactPaginate from 'react-paginate'
import { useRef } from 'react';
import { AiOutlineInfoCircle, AiOutlineLeft } from "react-icons/ai";
import Auxiliary from '../../../hoc/auxiliary/auxiliary';


const buttonSpinnerId = uuid();

const TechnicalAssistants = (props) => {

  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);
  const translations = useSelector(state => state.translation.translations);
  const [key, setKey] = useState(1);
  const [translationService, setTranslationService] = useState(null);
  const [technicalAssistants, setTechnicalAssistants] = useState([]);
  const [partnerSupportersCount, setPartnerSupportersCount] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    setActiveSectionName("");
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getTechnicalAssistants(1, key);
    getTechnicalAssistantsPageData();
  }, []);

  const handlePageClick = (event) => {
    setActivePageNumber(event.selected);
    getTechnicalAssistants(event.selected + 1, key)
  }

  const getTechnicalAssistantsPageData = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getTechnicalAssistantsPageData().then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        setIsSubscribed(data.isSubscribed);
        data.partnerSupportersCount && setPartnerSupportersCount(data.partnerSupportersCount);
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const getTechnicalAssistants = (currentPage, status, cb) => {
    const spinnerId = uuid();
    setSpinner(spinnerId)
    ApiService.getTechnicalAssistants(currentPage, fieldLengths.pageSize_10, status).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        if (data.pagedData && data.pagedData.length) {
          setTechnicalAssistants(data.pagedData);
        } else {
          setTechnicalAssistants([]);
        }
        if (data.pagination) {
          setPagination(data.pagination);
        }
        cb && cb(status);
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const disabledSupporter = (id, bool) => {
    AlertService.alertConfirm(
      `${translationService.translate("TR_ARE_YOU_SURE")} ?`,
      "",
      translationService.translate("TR_YES"),
      translationService.translate("TR_NO")
    ).then(() => {
      let spinnerId = `${id}_${buttonSpinnerId}`
      // setButtonSpinner(spinnerId)
      ApiService.disabledSupporter(id, user.partnerId, bool).then(() => {
        let technicalAssistantsCopy = [...technicalAssistants];
        technicalAssistantsCopy.forEach(technicalAssistant => {
          if (technicalAssistant.id === id) {
            technicalAssistant.disabled = bool
          }
        });
        setTechnicalAssistants(technicalAssistantsCopy);
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_DATA_SAVED"));
        extractButtonSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const onTabChange = (key) => {
    getTechnicalAssistants(1, key, setKey);
  }

  const deleteSupporter = (id) => {
    let partnerSupportersCountCopy = { ...partnerSupportersCount };
    AlertService.alertConfirm(
      `${translationService.translate("TR_ARE_YOU_SURE")} ?`,
      "",
      translationService.translate("TR_YES"),
      translationService.translate("TR_NO")
    ).then(() => {
      let spinnerId = `${id}_${buttonSpinnerId}`;
      // setButtonSpinner(spinnerId);
      ApiService.deleteSupporter(id).then(() => {
        setTechnicalAssistants(technicalAssistants.filter(item => item.id !== id))
        partnerSupportersCountCopy.activeSupportersCount -= 1;
        setPartnerSupportersCount(partnerSupportersCountCopy);
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_USER_DELETED"));
        extractButtonSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const setActiveSectionName = (name) => {
    dispatch(setActiveSection(name))
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
    spinnerId && extractSpinner(spinnerId);
  };

  return user && translationService ?
    <aside className='right-section'>
      <section className="dashboard top-section customer-profile">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="vnd--aside-title-block">
                <div className="backspace">
                  <span
                    className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                    onClick={() => {
                      dispatch(setActiveSection("home"))
                      window.history.back();
                    }}
                  >
                    <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                  </span>
                </div>
                <span className="slash">|</span>
                <p className="vnd--upp-text-22">{translationService.translate("TR_TECHNICAL_ASSISTANTS")}</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-lg-4 col-md-6">
              <div className="vnd--m-block light-brand-background h-100">
                <div className="d-lg-flex align-items-center">
                  <ul className="vnd--inline-info-list">
                    <li className="vnd--inline-info-item">
                      <small>
                        {translationService.translate("TR_BY_SUBSCRIPTION")}:
                      </small>
                      <span>
                        {
                          partnerSupportersCount && partnerSupportersCount.supportersCount ? partnerSupportersCount.supportersCount : 0
                        }
                      </span>
                    </li>
                    <li className="vnd--inline-info-item">
                      <small>
                        {translationService.translate("TR_ACTIVE")}:
                      </small>
                      <span>
                        {
                          partnerSupportersCount && partnerSupportersCount.activeSupportersCount ? partnerSupportersCount.activeSupportersCount : 0
                        }
                      </span>
                    </li>
                    <li className="vnd--inline-info-item">
                      <small>
                        {translationService.translate("TR_INVITED")}:
                      </small>
                      <span>
                        {
                          partnerSupportersCount && partnerSupportersCount.inActiveSupportersCount ? partnerSupportersCount.inActiveSupportersCount : 0
                        }
                      </span>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
            <div className='col-lg-8 col-md-6 mt-md-0 mt-3'>
              <div className="vnd--m-block light-brand-background h-100">
                <div className='d-md-flex flex-wrap align-items-center'>
                  <div className='vnd--info-block'>
                    <AiOutlineInfoCircle />
                    <p>{translationService.translate("TR_S_SPECIAL_CREATE_TEXT")}</p>
                  </div>
                  <button
                    className={`vnd-btn vnd-small-btn vnd-primary-btn create-ts-btn ${!isSubscribed ? "disabled" : ""}`}
                    disabled={!isSubscribed}
                    onClick={() => props.history.push(`/${language}/technical-assistants/form`)}
                  >
                    {translationService.translate("TR_CREATE")} {translationService.translate("TR_TECHNICAL_ASSISTANT")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            {
              !isSubscribed ?
                <div className='col-12 mt-3'>
                  <div className='vnd--m-block light-gray-background h-100'>
                    <span>
                      {translationService.translate("TR_NOT_SUBSCRIBED")}
                      {'\u00A0'}
                      <Link to={`/${language}/subscriptions`} className="underline">{translationService.translate("TR_SUBSCRIBE_NOW")}</Link>
                    </span>
                  </div>
                </div>
                : null
            }
            {
              <div className="col-12 mt-3">

                <Tabs
                  activeKey={key}
                  onSelect={(key) => onTabChange(key)}
                >
                  <Tab eventKey={1} title={`${translationService.translate("TR_ACTIVE")}`} />
                  <Tab eventKey={2} title={`${translationService.translate("TR_INACTIVE")}`} />
                </Tabs>
                {
                  technicalAssistants && technicalAssistants.length ?
                    <Auxiliary>
                      <div className="mt-4">
                        <div className='table-responsive vnd--borderd vnd--m-block'>
                          <table className="table mb-0">
                            <thead className="table-dark">
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">{translationService.translate("TR_NAME")}</th>
                                <th scope="col">{translationService.translate("TR_EMAIL")}</th>
                                <th scope="col">{translationService.translate("TR_JOIN_DATE")}</th>
                                <th scope="col">{translationService.translate("TR_SESSIONS")}</th>
                                <th scope="col">{translationService.translate("TR_LAST")} {translationService.translate("TR_WEEK")}</th>
                                <th scope="col">{translationService.translate("TR_TODAY")}</th>
                                <th scope="col" className='text-center'>{translationService.translate("TR_ACTIONS")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                technicalAssistants.map((technicalAssistant, index) => {
                                  return <tr key={technicalAssistant.id}>
                                    <th scope="row">{index + 1}</th>
                                    <td className='word-break-break-word'>{technicalAssistant.fullName}</td>
                                    <td className='word-break-break-word'>{technicalAssistant.email}</td>
                                    <td>{moment(MainService.convertUTCDateToLocalDate(new Date(technicalAssistant.createDate))).format("LL")}</td>
                                    <td>{technicalAssistant.allSessions ? technicalAssistant.allSessions : 0}</td>
                                    <td>{technicalAssistant.lastWeekSessions ? technicalAssistant.lastWeekSessions : 0}</td>
                                    <td>{technicalAssistant.todaysSessions ? technicalAssistant.todaysSessions : 0}</td>
                                    <td>
                                      <div className='d-flex align-items-center justify-content-center'>
                                        {
                                          +key === 1 ?
                                            <div className='d-flex gap-2'>

                                              <button
                                                onClick={() => props.history.push(`/${language}/technical-assistant/${technicalAssistant.id}`)}
                                                type='button'
                                                className="d-flex vnd-btn vnd-small-btn vnd-outline-primary-btn py-2 px-3"
                                                title={translationService.translate("TR_VIEW")}
                                              >
                                                <i className='ri-eye-line'></i>
                                              </button>
                                              {
                                                technicalAssistant.disabled ?
                                                  <ActionButtonWithOvalSpinner
                                                    title={translationService.translate("TR_ENABLED")}
                                                    iconClass="ri-checkbox-circle-line"
                                                    className="vnd-btn vnd-small-btn vnd-outline-success-btn py-2 px-3"
                                                    onClick={() => disabledSupporter(technicalAssistant.id, !technicalAssistant.disabled)}
                                                    spinnerId={`${technicalAssistant.id}_${buttonSpinnerId}`}
                                                  />
                                                  :
                                                  <ActionButtonWithOvalSpinner
                                                    iconClass="ri-forbid-2-line"
                                                    title={translationService.translate("TR_DISABLE")}
                                                    className="vnd-btn vnd-small-btn vnd-outline-danger-btn py-2 px-3"
                                                    onClick={() => disabledSupporter(technicalAssistant.id, !technicalAssistant.disabled)}
                                                    spinnerId={`${technicalAssistant.id}_${buttonSpinnerId}`}
                                                  />
                                              }
                                            </div>
                                            : <ActionButtonWithOvalSpinner
                                              name={translationService.translate("TR_DELETE")}
                                              className="vnd-btn vnd-small-btn vnd-outline-danger-btn py-2 px-3"
                                              onClick={() => deleteSupporter(technicalAssistant.id)}
                                              spinnerId={`${technicalAssistant.id}_${buttonSpinnerId}`}
                                            />
                                        }
                                      </div>
                                    </td>
                                  </tr>
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {
                        pagination && pagination.totalPages > 1 ?
                          <div className='w-100 d-flex justify-content-end mt-4'>
                            <ReactPaginate
                              nextLabel={translationService.translate("TR_NEXT")}
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={3}
                              marginPagesDisplayed={2}
                              pageCount={pagination.totalPages}
                              previousLabel={translationService.translate("TR_PREVIOUS")}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                              forcePage={activePageNumber}
                            />
                          </div>
                          : null
                      }
                    </Auxiliary>
                    : <NoData />
                }
              </div>
            }
          </div>
        </div>
      </section>
    </aside >
    : null
}

export default withRouter(TechnicalAssistants)