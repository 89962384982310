import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ERROR_KEY, SUCCESS_KEY, fieldLengths } from '../../../../../Constants/MainKeys';
import AlertService from '../../../../../Services/AlertService';
import uuid from 'react-uuid';
import ApiService from '../../../../../Services/ApiService';
import { addSpinner, removeSpinner } from '../../../../../Store/Actions/spinner';
import NoData from '../../../../../Components/NoData/NoData';
import ReactPaginate from 'react-paginate'
import TranslationService from '../../../../../Services/translationService';
import moment from 'moment';
import MainService from '../../../../../Services/mainService';
import { AiOutlineLeft } from "react-icons/ai";

const Scripts = (props) => {

  const { groupId } = props.match.params;
  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);
  const translations = useSelector(state => state.translation.translations);
  const { spinners } = useSelector(state => state.spinner);
  const partnerId = useSelector(state => state.main.partnerId);
  const [translationService, setTranslationService] = useState(null);
  const [name, setName] = useState("");
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [pagination, setPagination] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [currentScript, setCurrentScript] = useState(null);
  const [scripts, setScripts] = useState([]);


  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getGroupById(groupId);
    getScriptsByGroupId(1);
  }, []);

  const handlePageClick = (event) => {
    setActivePageNumber(event.selected);
    getScriptsByGroupId(event.selected + 1);
  }

  const getScriptsByGroupId = (currentPage) => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    let _data = {
      scriptGroupId: +groupId,
      partnerId: user.partnerId ? user.partnerId : partnerId,
      prefix: "",
      pageNumber: currentPage,
      pageSize: fieldLengths.pageSize_20,
    }
    ApiService.getScriptsByGroupId(_data).then(response => {

      if (response && response.data) {
        const data = { ...response.data };
        if (data.pagedData && data.pagedData.length) {
          setScripts(data.pagedData);
        } else {
          setScripts([]);
        }
        if (data.pagination) {
          setPagination(data.pagination);
        }
      }
      extractSpinner(spinnerId);
      onFocus();
    }).catch(error => getFail(error, spinnerId))
  }

  const getGroupById = (groupId) => {
    if (!groupId) { return false; }
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getGroupById(+groupId).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        setCurrentGroup(data);
      }
      extractSpinner(spinnerId);
      onFocus();
    }).catch(error => getFail(error, spinnerId))
  }

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
  }

  const deleteScript = (id) => {
    if (!id) { return false; }
    AlertService.alertConfirm(
      `${translationService.translate("TR_ARE_YOU_SURE")} ?`,
      "",
      translationService.translate("TR_YES"),
      translationService.translate("TR_NO")
    ).then(() => {
      const spinnerId = uuid();
      setSpinner(spinnerId);
      ApiService.deleteScript(id).then(() => {
        setScripts(scripts.filter(script => script.id !== id));
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_GROUP_DELETE_SUCCESS"));
        extractSpinner(spinnerId);
        onFocus();
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!name.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    } else {
      const spinnerId = uuid();
      setSpinner(spinnerId);
      let scriptsCopy = [...scripts];
      let currentScriptCopy = { ...currentScript };
      const form = {
        supportScriptsGroupId: +groupId,
        script: name,
        partnerId: user.partnerId ? +user.partnerId : +partnerId,
      };
      if (currentScript) {
        form.id = currentScript.id;
      }
      (
        currentScript ?
          ApiService.updateScript(form) :
          ApiService.createScript(form)
      ).then(response => {
        if (currentScript) {
          var foundIndex = scriptsCopy.findIndex(x => x.id == currentScriptCopy.id);
          currentScriptCopy.script = name;
          currentScriptCopy.updateDate = new Date();
          currentScriptCopy.isLocalDate = new Date();
          scriptsCopy[foundIndex] = currentScriptCopy;
          setScripts(scriptsCopy);
        } else {
          if (response && response.data) {
            scriptsCopy.unshift(response.data);
            setScripts(scriptsCopy);
          }
        }
        setName("");
        setCurrentScript(null);
        extractSpinner(spinnerId)
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_DATA_SAVED"));
        onFocus();
      }).catch(error => getFail(error, spinnerId))
    }
  }

  const onFocus = () => {
    if (document.getElementById("scriptName")) {
      document.getElementById("scriptName").focus();
    }
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractSpinner(spinnerId);
  };

  return user && translationService ?

    <div className='vnd-list-container'>
      <aside>
        <div className="px-4 pt-4 pb-3 left-bar-header d-flex justify-content-start align-items-center">
          <div className="backspace">
            <span
              className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
              onClick={() => {
                props.history.push(`/${language}/dashboard/script-groups`)
              }}
            >
              <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
            </span>
          </div>
          <span className="slash mx-2">|</span>
          <h4 className="vnd--upp-text-20 word-break-break-word">{currentGroup ? currentGroup.name : ""}</h4>
        </div>
      </aside>
      <hr className='my-2' />
      {
        currentGroup ?

          <div className="px-2 mb-auto">
            <div className="container-fluid">
              <div className='row'>
                {
                  <div className={`col-12 mt-3`}>
                    <div className="vnd-block vnd-p-block vnd-box-shadow mb-5">
                      <div className="row">
                        <div className="col-12">
                          <p className="vnd--upp-text-20 mb-4">{translationService.translate("TR_SCRIPTS")}</p>
                        </div>
                        <div className='col-12'>
                          <div className='form-group'>

                            <div className="vnd-complexe-input d-flex">
                              <input
                                id="scriptName"
                                name="name"
                                type="text"
                                className={isInvalidSubmit && !name.trim().length ? "error-border" : ""}
                                placeholder={`${translationService.translate("TR_TYPE_SOME_SCRIPT")}`}
                                disabled={spinners.length}
                                value={name}
                                onChange={event => onChange(event, setName, fieldLengths.maxLength_250)}
                                onKeyDown={event => {
                                  if (event.key === "Enter") {
                                    onSubmit(event);
                                  }
                                }}
                              />
                              {
                                !currentScript ?
                                  <div
                                    className="vnd-complexe-btn"
                                    title={translationService.translate("TR_ADD_SCRIPT")}
                                    onClick={onSubmit}
                                  >
                                    <i className="ri-add-line text-white font-size-20"></i>
                                  </div>
                                  :
                                  <div>
                                    <div
                                      style={{ right: "55px" }}
                                      className="vnd-complexe-btn"
                                      title={translationService.translate("TR_UPDATE")}
                                      onClick={(event) => {
                                        onSubmit(event);
                                      }}
                                    >
                                      <i className="ri-check-line  text-white font-size-20"></i>
                                    </div>
                                    <div
                                      className="vnd-complexe-btn"
                                      title={translationService.translate("TR_CANCEL")}
                                      onClick={() => {
                                        setName("");
                                        setCurrentScript(null);
                                      }}
                                    >
                                      <i className="ri-close-line  text-white font-size-20"></i>
                                    </div>
                                  </div>
                              }
                            </div>
                            <small className='mt-1 d-block'>{translationService.translate("TR_MAX_LENGHT")}: {fieldLengths.maxLength_250}</small>
                          </div>
                        </div>

                        <div className={`col-12`}>
                          <div className='form-group'>
                            <div className="vnd-added-item-container max-h-initial">
                              {
                                scripts.length ? scripts.map(script => {
                                  return <div
                                    key={script.id}
                                    className='vnd-added-item-wrapper vnd-added-item-inline-wrapper'
                                  >
                                    <div className={`align-items-center d-flex justify-content-between`}>
                                      <p className="font-size-16 max-line-3 word-break-break-all text">{script.script}</p>
                                      <div className='option-icons-wrapper ml-4 d-flex'>
                                        <i
                                          className="ri-edit-2-line font-size-20 cursor-pointer content-icon d-block"
                                          title={translationService.translate("TR_EDIT")}
                                          onClick={() => {
                                            setCurrentScript(script);
                                            setName(script.script)
                                          }}
                                        />
                                        <i
                                          className="ri-delete-bin-line font-size-20 cursor-pointer content-icon d-block"
                                          title={translationService.translate("TR_DELETE")}
                                          onClick={() => {
                                            deleteScript(script.id);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {
                                      script.createDate ?
                                        <small className='font-size-12 m-0'>
                                          {`${translationService.translate("TR_CREATE_DATE")}: ${moment(MainService.convertUTCDateToLocalDate(new Date(script.createDate))).format("LL | HH:mm")}`}
                                        </small>
                                        : null
                                    }
                                    {
                                      script.updateDate ?
                                        <small className='font-size-12 m-0'>
                                          {translationService.translate("TR_UPDATE_DATE")}:&nbsp;
                                          {
                                            script.isLocalDate ?
                                              moment(new Date(script.updateDate)).format("LL | HH:mm") :
                                              moment(MainService.convertUTCDateToLocalDate(new Date(script.updateDate))).format("LL | HH:mm")
                                          }
                                        </small>
                                        : null
                                    }
                                  </div>
                                }) : <div className='d-flex justify-content-center w-100'>
                                  <NoData width={200} height={200} />
                                </div>
                              }
                            </div>
                          </div>
                          {
                            pagination && scripts.length && pagination.totalPages > 1 ?
                              <div className='w-100 d-flex justify-content-center mt-5'>
                                <ReactPaginate
                                  nextLabel={translationService.translate("TR_NEXT")}
                                  onPageChange={handlePageClick}
                                  pageRangeDisplayed={3}
                                  marginPagesDisplayed={2}
                                  pageCount={pagination.totalPages}
                                  previousLabel={translationService.translate("TR_PREVIOUS")}
                                  pageClassName="page-item"
                                  pageLinkClassName="page-link"
                                  previousClassName="page-item"
                                  previousLinkClassName="page-link"
                                  nextClassName="page-item"
                                  nextLinkClassName="page-link"
                                  breakLabel="..."
                                  breakClassName="page-item"
                                  breakLinkClassName="page-link"
                                  containerClassName="pagination"
                                  activeClassName="active"
                                  renderOnZeroPageCount={null}
                                  forcePage={activePageNumber}
                                />
                              </div>
                              : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          : <NoData />
      }
    </div>

    : null
}

export default withRouter(Scripts)