import React from 'react'
import moment from 'moment/moment';

export default function Widget(props) {

  const {
    isActiveCircularMenu,
    setIsActiveCircularMenu,
    setIsShowWidget,
    isShowWidget,
    widget_size,
    color,
    textColor,
    widgetSize,
    widgetFAQs,
    welcomeMessage,
    welcomeSubMessage,
    widgetGradientColor,
    welcomeMessageTitleLibrary,
    welcomeMessageLibDataId,
    welcomeSubMessageLibDataId,
    welcomeSubMessageTitleLibrary,
    translationService,
    useWidgetButton
  } = props;

  return <div className="col-lg-6">
    <hr className='d-lg-none d-block' />
    <div className="vnd-demo-widget-container my-2">
      <div className="vnd--circular-menu-container">
        <div
          className={`vnd--circular-menu ${!useWidgetButton ? "d-none" : ""} ${isActiveCircularMenu ? "active" : ""}`}
          onClick={() => setIsActiveCircularMenu(!isActiveCircularMenu)}
        >
          <div className="vnd--floating-btn vnd--background-image d-flex justify-content-center align-items-center">
            {
              isActiveCircularMenu ?
                <i className="ri-message-3-line" style={{ color: textColor ? textColor : "#ffffff", fontSize: "30px" }}></i>
                :
                <span className='position-relative'>
                  <i
                    className="ri-add-line"
                    style={{
                      color: textColor ? textColor : "#ffffff",
                      fontSize: widgetSize === "3em" ? "40px" : widgetSize === "4em" ? "50px" : widgetSize === "5em" ? "60px" : "50px"
                    }}
                  />
                </span>
            }
          </div>
          {
            isActiveCircularMenu ?
              <i
                className="ri-close-line vnd-widget-close-icon"
                style={{
                  color: textColor ? textColor : "#ffffff",
                  backgroundColor: color,
                  width: widgetSize === "3em" ? "25px" : widgetSize === "4em" ? "35px" : widgetSize === "5em" ? "40px" : "35px",
                  height: widgetSize === "3em" ? "25px" : widgetSize === "4em" ? "35px" : widgetSize === "5em" ? "40px" : "35px",
                  fontSize: widgetSize === "3em" ? "20px" : widgetSize === "4em" ? "27px" : widgetSize === "5em" ? "30px" : "27px",
                }}
              ></i>
              : null
          }
          <div className={`vnd--items-wrapper ${widget_size} ${!isActiveCircularMenu ? "opacity-0" : ""}`} >
            <div className="vnd--menu-item d-flex justify-content-center align-items-center" onClick={() => setIsShowWidget(true)}>
              <i className="ri-attachment-2" style={{ color: textColor ? textColor : "#ffffff" }}></i>
            </div>
            <div className="vnd--menu-item d-flex justify-content-center align-items-center" onClick={() => setIsShowWidget(true)}>
              <i className="ri-vidicon-line" style={{ color: textColor ? textColor : "#ffffff" }}></i>
            </div>
            <div className="vnd--menu-item d-flex justify-content-center align-items-center" onClick={() => setIsShowWidget(true)}>
              <i className="ri-phone-line" style={{ color: textColor ? textColor : "#ffffff" }}></i>
            </div>
            <div className="vnd--menu-item d-flex justify-content-center align-items-center" onClick={() => setIsShowWidget(true)}>
              <i className="ri-cast-line" style={{ color: textColor ? textColor : "#ffffff" }}></i>
            </div>
            <div className="vnd--menu-item d-flex justify-content-center align-items-center" onClick={() => setIsShowWidget(true)}>
              <i className="ri-screenshot-line " style={{ color: textColor ? textColor : "#ffffff" }}></i>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`vnd--chatbox-popup ${!isShowWidget ? "opacity-0" : ""}`}
        style={{ right: !useWidgetButton ? "10px" : widgetSize === "3em" ? "5.3em" : widgetSize === "4em" ? "6.3em" : widgetSize === "5em" ? "7.3em" : "5.3em", }}
      >
        <div style={{ position: "relative", display: "block", width: "100%", height: "100%" }}>
          <div className="vnd--chatbox-header"
            id="vndChatPopupHeader"
            style={{ background: widgetGradientColor ? `linear-gradient(to right, ${widgetGradientColor}, ${color})` : color }}
          >
            <div className="vnd--brand-aside" id="chatboxHeader">
              <div className="vnd--header-btn-block">
                <div>
                  <i className="ri-close-line font-size-24 me-1 cursor-pointer"
                    onClick={() => {
                      setIsShowWidget(false);
                      setIsShowWidget(false);
                    }}
                  />
                  <i className="ri-more-2-line font-size-22 me-1"></i>
                </div>
              </div>
              <div
                className="vnd--header-hi-text word-break-break-word"
                style={{ color: textColor }}>
                {
                  welcomeMessage ? welcomeMessage : welcomeMessageTitleLibrary && welcomeMessageTitleLibrary.length ? welcomeMessageTitleLibrary.map(welcomeMessageTitleLibraryItem => {
                    if (welcomeMessageTitleLibraryItem.id === welcomeMessageLibDataId && welcomeMessageTitleLibraryItem.name !== "WELCOME_CUSTOM") {
                      return welcomeMessageTitleLibraryItem.displayName
                    }
                  }) : translationService.translate("TR_WIDGET_WELCOME_TEXT")
                }
              </div>
              <div className="vnd--header-default-text word-break-break-word" style={{ color: textColor }}>
                {
                  welcomeSubMessage ? welcomeSubMessage : welcomeSubMessageTitleLibrary && welcomeSubMessageTitleLibrary.length ?
                    welcomeSubMessageTitleLibrary.map(welcomeSubMessageTitleLibraryItem => {
                      if (welcomeSubMessageTitleLibraryItem.id === welcomeSubMessageLibDataId && welcomeSubMessageTitleLibraryItem.name !== "WELCOME_SUB_CUSTOM") {
                        return welcomeSubMessageTitleLibraryItem.displayName
                      }
                    }) : translationService.translate("TR_WIDGET_WELCOME_SUB_TEXT")
                }
              </div>
            </div>
          </div>
          <div className="vnd--chatbox-body">
            <div className="vnd--chatbox-body-content">
              <div className="vnd--faq-container">
                {
                  widgetFAQs && widgetFAQs.length ? widgetFAQs.map((widgetFAQ, index) => <div key={index} className="vnd--faq-wrapper word-break-break-word" style={{ borderColor: color }}>
                    <div className="vnd--faq" style={{ color: color }}>{widgetFAQ}</div>
                  </div>) : null
                }
              </div>
              <div className='vnd--chatbox-messages-container vnd--local'>
                <div className="vnd--date-wrapper">
                  <div className="vnd--date">{moment(new Date()).format("LL")} | {moment(new Date()).format("HH:mm")}</div>
                </div>
                <div className="vnd--message-container">
                  <div
                    className="vnd--message-wrapper vnd-local-message"
                    style={{ background: color }}
                  >
                    <div className="vnd--message" style={{ color: textColor }}>{translationService.translate("TR_HELLO")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="vnd--chatbox-footer">
            <div className='d-flex align-content-center position-relative'>
              <div className="vnd--messagearea-wrapper">
                <div className="vnd--emoji text-center cursor-default" id="vnd_emoji">
                  <div className="vnd--background-image vnd-chatbox-footer-icon d-flex align-items-center">
                    <i className="ri-emotion-happy-line text-muted font-size-24"></i>
                  </div>
                </div>
                <textarea placeholder={`${translationService.translate("TR_MESSAGE_PLACEHOLDER")}...`} rows="1" id="vndTextarea" style={{ height: "44" }} disabled />
              </div>
            </div>
            <div className='d-flex align-items-center p-2 pt-0'>
              <div className="vnd--fotter-aside text-center cursor-default py-2" id="file_share">
                <i className="ri-attachment-2 font-size-24 vnd--widget-bottom-icon my-0"></i>
              </div>
              <div className="vnd--fotter-aside text-center cursor-default py-2" id="video_call">
                <i className="ri-vidicon-line font-size-24 vnd--widget-bottom-icon"></i>
              </div>
              <div className="vnd--fotter-aside text-center cursor-default py-2" id="voice_call">
                <i className="ri-phone-line font-size-24 vnd--widget-bottom-icon"></i>
              </div>
              <div className="vnd--fotter-aside text-center cursor-default py-2" id="screen_share">
                <i className="ri-cast-line font-size-24 vnd--widget-bottom-icon"></i>
              </div>
              <div className="vnd--fotter-aside text-center cursor-default py-2" id="screen_share">
                <i className="ri-screenshot-line font-size-24 vnd--widget-bottom-icon"></i>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
}
