import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { DEFAULT_LANGUAGE_KEY } from '../../Constants/MainKeys'
import BecomePartnerStep1 from "../../Pages/AuthUserPages/BecomePartner/BecomePartnerStep1"
import BecomePartnerStep2 from "../../Pages/AuthUserPages/BecomePartner/BecomePartnerStep2"
import BecomePartnerStep3 from "../../Pages/AuthUserPages/BecomePartner/BecomePartnerStep3"
import BecomePartnerStep4 from "../../Pages/AuthUserPages/BecomePartner/BecomePartnerStep4"
import BecomePartnerStep5 from "../../Pages/AuthUserPages/BecomePartner/BecomePartnerStep5"

export default function WithoutHeaderFooterRouting() {
  const language = useSelector(state => state.language.language);
  return <Switch>
    <Route path="/:language/become-partner/step-1" exact component={BecomePartnerStep1} />
    <Route path="/:language/become-partner/step-2" exact component={BecomePartnerStep2} />
    <Route path="/:language/become-partner/step-3" exact component={BecomePartnerStep3} />
    <Route path="/:language/become-partner/step-4" exact component={BecomePartnerStep5} />
    {/* <Route path="/:language/become-partner/step-5" exact component={BecomePartnerStep5} /> */}
    <Redirect to={`/${language ? language : DEFAULT_LANGUAGE_KEY}/become-partner/step-1`} />
  </Switch>
}
