import { SET_GLOBAL_CONNECTION, SET_CLIENT_SESSION_ID, SET_RTC_GLOBAL_CONNECTION } from "../../Constants/ReduxKeys";


export function setGlobalConnectionWithRedux(data) {
  return {
    type: SET_GLOBAL_CONNECTION,
    payload: data
  }
}
export function setClientSessionId(id) {
  return {
    type: SET_CLIENT_SESSION_ID,
    payload: id
  }
}
export function setRtcGlobalConnection(data) {
  return {
    type: SET_RTC_GLOBAL_CONNECTION,
    payload: data
  }
}

