import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { setActiveSection } from '../../../Store/Actions/main';
import TranslationService from '../../../Services/translationService';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import Parser from 'html-react-parser';
import ideaSvg from "./../../../assets/images/3d-images/idea.png";
import userAvatar from './../../../assets/images/user-avatar.jpg'
import feedback from './../../../assets/images/3d-images/feedback.png'
import Carousel from "react-multi-carousel"

const fullScreen = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 0 },
    items: 1
  },
};

const SignIn = (props) => {

  const dispatch = useDispatch();
  const { translations } = useSelector(state => state.translation);

  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // dispatch(setActiveSection("home"));
  }, [])

  return translationService ? <Auxiliary>
    <section className='section' style={{ marginTop: "100px" }}>
      <div className="container">
        <div className="row">
          <div className='col-lg-7'>
            <div className='vnd-block brand-background'>
              <h2 className="vnd--text-50 text-white">{translationService.translate("TR_ABOUT_US_TITLE_A")}</h2>
              <h3 className="vnd--upp-text-20 vnd--opacity-50 text-white">{translationService.translate("TR_ABOUT_US_SUBTITLE_A")}</h3>
              <p className='my-3 text-white'>{Parser(translationService.translate("TR_ABOUT_US_TEXT_A"))}</p>
            </div>
          </div>
          <div className='col-lg-5 d-lg-block d-none'>
            <div className='d-flex justify-content-center align-items-center h-100'>
              <img className="w-100" src={ideaSvg} alt="idea" style={{ maxWidth: "300px" }} />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className='container'>
      <hr />
    </div>
    <section className='section'>
      <div className='container'>
        <div className="row">
          <div className="col-12">
            <div className='vnd-block vnd--borderd'>
              <div className='row'>
                <div className='col-12'>
                  <h2 className="vnd--text-50 mb-5">{translationService.translate("TR_ABOUT_US_SUBTITLE_B")}</h2>
                </div>
                <div className="col-md-6">
                  <p>{Parser(translationService.translate("TR_ABOUT_US_TEXT_B"))}</p>
                </div>
                <div className="col-md-6">
                  <p>{Parser(translationService.translate("TR_ABOUT_US_TEXT_C"))}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <section className='section'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='vnd-block light-gray-background h-100'>
              <h2 className="vnd--text-50">{Parser(translationService.translate("TR_WHAT_PEOPLE_THINK_ABOUT"))}</h2>
              <h3 className="vnd--upp-text-20 vnd--opacity-50">{translationService.translate("TR_PARTNERS_JOURNEY")}</h3>
              <p className="my-3">{Parser(translationService.translate("TR_FEEDBACK_TEXT"))}</p>
            </div>
          </div>
          <div className='col-lg-6 mt-lg-0 mt-3'>
            <div className='vnd-block light-brand-background position-relative h-100'>
              <div className='position-relative z-index-10'>
                <Carousel
                  responsive={fullScreen}
                  showDots={true}
                  autoPlay={true}
                  infinite={true}
                  arrows={false}
                  className='vnd--custom-carousel dots-center vnd--carousel-wrapper'
                >
                  <div className="vnd-partners-feedback-wrapper">
                    <div className="vnd-partner-wrapper">
                      <div className="vnd-partner-image-wrapper">
                        <div className="vnd-partner-image" style={{ backgroundImage: `url(${userAvatar})` }}></div>
                      </div>
                      <div className="vnd-partner-info`">
                        <h5>Mindalay</h5>
                        <h6>Miles Harrington</h6>
                      </div>
                    </div>
                    <p>
                      I just wanted to take a moment to say thank you for your assistance. Your help was greatly appreciated and made a
                      big difference in my experience. Your professionalism, knowledge, and patience were outstanding,
                      and I feel fortunate to have had your support.
                    </p>
                  </div>
                  <div className="vnd-partners-feedback-wrapper">
                    <div className="vnd-partner-wrapper">
                      <div className="vnd-partner-image-wrapper">
                        <div className="vnd-partner-image" style={{ backgroundImage: `url(${userAvatar})` }}></div>
                      </div>
                      <div className="vnd-partner-info`">
                        <h5>Frantixmedia</h5>
                        <h6>Harper Carrington</h6>
                      </div>
                    </div>
                    <p>I am writing to express my gratitude for the outstanding service I
                      received from you and your team. Your level of expertise and dedication was
                      truly impressive, and I was thoroughly impressed with the way you handled my issue.
                    </p>
                  </div>
                  <div className="vnd-partners-feedback-wrapper">
                    <div className="vnd-partner-wrapper">
                      <div className="vnd-partner-image-wrapper">
                        <div className="vnd-partner-image" style={{ backgroundImage: `url(${userAvatar})` }}></div>
                      </div>
                      <div className="vnd-partner-info`">
                        <h5>Get me pet</h5>
                        <h6>Avery Townsend</h6>
                      </div>
                    </div>
                    <p>I recently started using the supporter system platform and I
                      am blown away by its capabilities. The user interface is easy
                      to navigate, and I was able to quickly set up and manage my
                      organization's supporter database.
                    </p>
                  </div>
                </Carousel>
              </div>
              <img src={feedback} className="vnd-decoreation vnd-feedback" alt="vnd-feedback" />
            </div>
          </div>
        </div>
      </div>
    </section> */}
  </Auxiliary> : null
}

export default withRouter(SignIn)
