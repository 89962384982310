import { all, fork } from 'redux-saga/effects';
import translation from "./translation"
import language from "./language"
import user from "./user"
import pageContent from "./pageContent"
import chat from "./chat"

export default function* watchers() {
  yield all([
    translation,
    language,
    user,
    pageContent,
    chat
  ].map(fork))
}