import React from 'react'

export default function MissedVoiceCallSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 256 256">
      <title>miss-call</title>
      <circle style={{ fill: "#cf000f" }} cx="128" cy="128" r="119.5" />
      <rect style={{ fill: "none" }} width="256" height="256" />
      <path style={{ fill: "#fff" }} d="M187.74,150.71,156.69,137.4a10.56,10.56,0,0,0-10.05.93L130,149.39a50.74,50.74,0,0,1-23.33-23.23h0l11-16.84a10.6,10.6,0,0,0,.88-10L105.29,68.26a10.62,10.62,0,0,0-11-6.35A37.17,37.17,0,0,0,61.84,98.73a95.54,95.54,0,0,0,95.43,95.43,37.16,37.16,0,0,0,36.82-32.42A10.62,10.62,0,0,0,187.74,150.71Z" />
      <path style={{ fill: "#fff" }} d="M142.92,113.08a5.29,5.29,0,0,0,7.49,0l12.16-12.15,12.16,12.15a5.3,5.3,0,1,0,7.49-7.49L170.07,93.43l12.15-12.16a5.3,5.3,0,0,0-7.49-7.49L162.57,85.93,150.41,73.78a5.3,5.3,0,0,0-7.49,7.49l12.15,12.16-12.15,12.16A5.29,5.29,0,0,0,142.92,113.08Z" /></svg>
  )
}
