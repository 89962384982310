// @flow
import {
  SET_ACTIVE_TAB,
  OPEN_USER_PROFILE_SIDEBAR,
  CLOSE_USER_PROFILE_SIDEBAR,
  SET_CONVERSATION_NAME_IN_OPEN_CHAT
} from "./../../Constants/ReduxKeys";

const initialState = {
  activeTab: "group",
  userSidebar: true,
  conversationName: "general"
};

const Layout = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload
      };

    case OPEN_USER_PROFILE_SIDEBAR:
      return {
        ...state,
        userSidebar: true
      };

    case CLOSE_USER_PROFILE_SIDEBAR:
      return {
        ...state,
        userSidebar: false
      };

    case SET_CONVERSATION_NAME_IN_OPEN_CHAT:
      return {
        ...state,
        conversationName: action.payload
      };
    default:
      return state;
  }
};

export default Layout;
