import React from 'react'

export default function CompletedVoiceCallSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 256 256"
      className={props.className}
    >
      <circle style={{ fill: "#094" }} cx="128" cy="128" r="119.5" />
      <rect style={{ fill: "none" }} width="256" height="256" />
      <path style={{ fill: "#fff" }} d="M187.74,150.71,156.69,137.4a10.56,10.56,0,0,0-10.05.93L130,149.39a50.74,50.74,0,0,1-23.33-23.23h0l11-16.84a10.6,10.6,0,0,0,.88-10L105.29,68.26a10.62,10.62,0,0,0-11-6.35A37.17,37.17,0,0,0,61.84,98.73a95.54,95.54,0,0,0,95.43,95.43,37.16,37.16,0,0,0,36.82-32.42A10.62,10.62,0,0,0,187.74,150.71Z" />
      <path style={{ fill: "#fff" }} d="M154.56,114.64h0a5,5,0,0,1-3.5-1.45l-12.61-12.61a5,5,0,1,1,7-7l9.1,9.11,29-29a5,5,0,0,1,7,7l-32.5,32.51A5,5,0,0,1,154.56,114.64Z" /></svg>
  )
}
