import React, { useCallback, useEffect, useState } from 'react';
import Input from '../../../../Components/Inputs/Input';
import MainService from '../../../../Services/mainService';
import * as moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { addButtonSpinner, removeButtonSpinner } from '../../../../Store/Actions/spinner';
import AlertService from '../../../../Services/AlertService';
import { ERROR_KEY, SUCCESS_KEY, fieldLengths } from '../../../../Constants/MainKeys';
import uuid from 'react-uuid';
import ActionButton from '../../../../Components/Buttons/ActionButton';
import ApiService from '../../../../Services/ApiService';
import { updateUserData } from '../../../../Store/Actions/user';
import TranslationService from '../../../../Services/translationService';


const spinnerId = uuid();
export default function ProfileDataBlock(props) {

  const { user, isShowCancelButton, setIsShowTabs } = props;
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const translations = useSelector(state => state.translation.translations);
  const [isInvalidFirstName, setIsInvalidFirstName] = useState(false);
  const [isInvalidLastName, setIsInvalidLastName] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidDob, setIsInvalidDob] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [translationService, setTranslationService] = useState(null);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
  }

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const onDateChange = (event) => {
    setDob(event.target.value);
    setIsInvalidSubmit(false);
  }

  const onCheckDates = () => {
    if (!dob) { return; }
    if (MainService.checkDates(dob, moment(MainService.convertUTCDateToLocalDate(new Date()))) && MainService.checkDates("1900-01-01", dob)) {
      setIsInvalidDob(false);
    } else {
      setIsInvalidDob(true);
    }
  }

  const checkFieldsValidation = (field, fieldName) => {
    if (!field.trim().length) {
      return;
    }
    var isValid = MainService.isValidField(field, fieldName);
    switch (fieldName) {
      case "firstName":
        changeIsinvalidFieldName(isValid, setIsInvalidFirstName)
        break;
      case "lastName":
        changeIsinvalidFieldName(isValid, setIsInvalidLastName)
        break;
      case "email":
        changeIsinvalidFieldName(isValid, setIsInvalidEmail)
        break;
      default:
        break;
    }
  }

  const changeIsinvalidFieldName = (isValid, cb) => {
    if (!isValid) cb(true);
    else cb(false);
  }

  const cancel = () => {
    setIsInvalidFirstName(false);
    setIsInvalidLastName(false);
    setIsInvalidEmail(false);
    setIsInvalidDob(false);
    setIsInvalidSubmit(false);
    setIsFormChanged(false);
    setIsShowTabs(false);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (
      isInvalidFirstName ||
      isInvalidLastName ||
      isInvalidEmail ||
      isInvalidDob ||
      !firstName.trim().length ||
      !lastName.trim().length ||
      !email.trim().length
    ) {
      setIsInvalidSubmit(true);
      return;
    } else {
      setButtonSpinner(spinnerId);
      const form = {
        id: user.id,
        firstname: firstName.trim(),
        lastname: lastName.trim(),
        email: email.trim(),
        phoneNumber,
      };
      if (dob && dob.trim().length) { form.dob = dob }

      ApiService.updateUser(form).then(() => {
        dispatch(updateUserData(form));
        AlertService.alert(SUCCESS_KEY, `${translationService.translate("TR_DATA")} ${translationService.translate("TR_SAVED")}`)
        extractButtonSpinner(spinnerId);
        setIsFormChanged(false);
        cancel();
      }).catch(error => getFail(error, spinnerId));
    }
  }

  const getToday = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }
    return today = yyyy + '-' + mm + '-' + dd;
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  useEffect(() => {
    if (user) {
      user.firstname && setFirstName(user.firstname);
      user.lastname && setLastName(user.lastname);
      user.email && setEmail(user.email);
      user.phoneNumber && setPhoneNumber(user.phoneNumber);
      user.dob && setDob(moment(MainService.convertUTCDateToLocalDate(new Date(user.dob))).format("YYYY-MM-DD"));
    }
  }, [user]);

  return translationService ? <div className='p-3'>
    <form onSubmit={onSubmit} onChange={() => setIsFormChanged(true)}>
      <div className='row'>
        <div className="col-lg-6 col-12">
          <Input
            id="firstName"
            htmlFor="firstName"
            type="text"
            name="firstName"
            value={firstName}
            labelValue={`${translationService.translate("TR_FIRST_NAME")} *`}
            placeholder={translationService.translate("TR_FIRST_NAME")}
            required={true}
            inputClassName={`${((isInvalidSubmit && !firstName.trim()) || isInvalidFirstName) ? "error-border" : ""}`}
            onChange={(event) => onChange(event, setFirstName, fieldLengths.maxLength_100)}
            onBlur={() => checkFieldsValidation(firstName, "firstName")}
          />
        </div>
        <div className="col-lg-6 col-12">
          <Input
            id="lastName"
            htmlFor="lastName"
            type="text"
            name="lastName"
            value={lastName}
            labelValue={`${translationService.translate("TR_LAST_NAME")} *`}
            placeholder={translationService.translate("TR_LAST_NAME")}
            required={true}
            inputClassName={`${((isInvalidSubmit && !lastName.trim()) || isInvalidLastName) ? "error-border" : ""}`}
            onChange={(event) => onChange(event, setLastName, fieldLengths.maxLength_100)}
            onBlur={() => checkFieldsValidation(lastName, "lastName")}
          />
        </div>
        <div className="col-lg-6 col-12">
          <Input
            id="phoneNumber"
            htmlFor="phoneNumber"
            type="tel"
            name="phoneNumber"
            placeholder="+123456789"
            value={phoneNumber}
            labelValue={translationService.translate("TR_PHONE_NUMBER")}
            onChange={(event) => onChange(event, setPhoneNumber, fieldLengths.maxLength_100)}
            onBlur={() => checkFieldsValidation(phoneNumber, "phoneNumber")}
          />
        </div>
        {/* <div className="col-lg-6 col-12">
          <Input
            id="email"
            htmlFor="email"
            type="email"
            name="email"
            value={email}
            labelValue={translationService.translate("TR_EMAIL")}
            placeholder={translationService.translate("TR_EMAIL")}
            disabled={true}
            required={false}
            inputClassName={`${((isInvalidSubmit && !email.trim()) || isInvalidEmail) ? "error-border" : ""}`}
            onChange={() => { }}
            onBlur={() => checkFieldsValidation(email, "email")}
          />
        </div> */}
        <div className="col-lg-6 col-12">
          <Input
            id="dob"
            htmlFor="dob"
            type="date"
            name="dob"
            min="1900-01-01"
            max={getToday()}
            labelValue={translationService.translate("TR_DOB")}
            placeholder={translationService.translate("TR_DOB")}
            required={false}
            value={dob}
            inputClassName={`${isInvalidDob ? "error-border" : ""}`}
            onChange={onDateChange}
            onBlur={onCheckDates}
          />
        </div>
      </div>

      <div className='d-flex justify-content-end'>
        <ActionButton
          type="submit"
          name={translationService.translate("TR_SAVE_CHANGES")}
          className={`vnd-btn vnd-primary-btn my-3 ${isInvalidFirstName || isInvalidLastName || isInvalidEmail || isInvalidDob || !isFormChanged ? "disabled" : ""}`}
          disabled={isInvalidFirstName || isInvalidLastName || isInvalidEmail || isInvalidDob || !isFormChanged}
          spinnerId={spinnerId}
        />
        {
          isShowCancelButton ?
            <button className='vnd-btn vnd-primary-outline-btn my-3 ms-2' type='button' onClick={cancel}>
              {translationService.translate("TR_CANCEL")}
            </button>
            : null
        }
      </div>
    </form>
  </div> : null
}
