import React from 'react'

export default function TransactionModal({
  translationService,
  transactionStatus,
  closeModal
}) {

  return <div className="info-alert-container">
    <div className="layer" onClick={closeModal} />
    <div className="info-alert-block">
      <div className="position-relative">
        <div className="close-icon-block" onClick={closeModal}  >
          <i className="ri-close-line"></i>
        </div>
      </div>
      {
        transactionStatus ?
          <div className="info-description">
            <div className="payment-modal-status-icon-block">
              <i className="ri-checkbox-circle-line transaction-successful"></i>
            </div>
            <h2 className="title text-center my-4 capitalize">
              {translationService.translate("TR_TRANSACTION")} {translationService.translate("TR_SUCCESSFUL")}
            </h2>
            <p className="text-center">{translationService.translate("TR_TRANSACTION_SC_INFO")}</p>
          </div>
          : <div className="info-description">
            <div className="payment-modal-status-icon-block">
              <i className="ri-close-circle-line transaction-unsuccessful"></i>
            </div>
            <h2 className="title text-center my-4 capitalize">
              {translationService.translate("TR_TRANSACTION")} {translationService.translate("TR_UNSUCCESSFUL")}
            </h2>
            <p className="text-center">{translationService.translate("TR_TRANSACTION_UNSC_INFO")}</p>
          </div>
      }
    </div>
  </div>
}
