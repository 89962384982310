import React, { useState } from 'react';
import { DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { VALID_FILE_TYPES_KEY, VALID_IMAGE_TYPES_KEY } from '../../../../Constants/MainKeys';
import FileList from './FileList';

function FileMessageComponent(props) {
    const [isOpen, setisOpen] = useState(false);
    const [currentImage, setcurrentImage] = useState(null);
    const [files] = useState(props.files);

    /* intilize t variable for multi language implementation */

    const toggleLightbox = (currentImage) => {
        setisOpen(!isOpen);
        setcurrentImage(currentImage);
    }

    return (
        <React.Fragment>
            <ul className="list-inline message-img mb-0">
                {
                    files.map((file, key) =>
                        <li key={key} className={`list-inline-item message-img-list ${files.length === 1 ? "message-img-single" : ""}`}>
                            {
                                file.uploadedFile ?
                                    VALID_IMAGE_TYPES_KEY.includes(file.fileName.split('.').pop()) ?
                                        <Link
                                            to={"#"}
                                            onClick={() => toggleLightbox(file.uploadedFile)} className="popup-img d-inline-block m-1" title="Project 1"
                                        >
                                            <img src={file.uploadedFile} alt="/" className="rounded" />
                                        </Link>
                                        : VALID_FILE_TYPES_KEY.includes(file.fileName.split('.').pop()) ?
                                            <FileList file={file} controls={false} />
                                            : null
                                    : null
                            }
                            {
                                file.fileRealPath || file.isExternal ?
                                    VALID_IMAGE_TYPES_KEY.includes(file.filePath.split('.').pop()) || file.isExternal ?
                                        <Link
                                            to={"#"}
                                            onClick={() => toggleLightbox(file.fileRealPath)} className="popup-img d-inline-block m-1" title="Project 1"
                                        >
                                            <img src={file.fileRealPath} alt="/" className="rounded" />
                                        </Link>
                                        : VALID_FILE_TYPES_KEY.includes(file.filePath.split('.').pop()) ?
                                            <FileList file={file} controls={true} />
                                            : null
                                    : null
                            }
                            {
                                props.isShowTools ?
                                    <div className="message-img-link">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item">
                                                <a href={`${!file.downloadPath ? file.downloadPath : file.fileRealPath}/download`} target="_blank" className="float-right brand-color">
                                                    <i className="fas fa-download" />
                                                </a>
                                            </li>
                                            <UncontrolledDropdown tag="li" className="list-inline-item">
                                                <DropdownToggle tag="a">
                                                    <i className="ri-more-fill"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {/* <DropdownItem>Copy <i className="ri-file-copy-line float-end text-muted"></i></DropdownItem>
                                                    <DropdownItem>Save <i className="ri-save-line float-end text-muted"></i></DropdownItem>
                                                    <DropdownItem>Forward <i className="ri-chat-forward-line float-end text-muted"></i></DropdownItem> */}
                                                    <DropdownItem>Delete <i className="ri-delete-bin-line float-end text-muted"></i></DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </ul>
                                    </div>
                                    : null
                            }
                        </li>
                    )
                }

                {isOpen && (
                    <Lightbox
                        mainSrc={currentImage}
                        onCloseRequest={toggleLightbox}
                        imageTitle="Project 1"
                    />
                )}

            </ul>
            {
                props.textMessage ?
                    <p className="mb-0 word-break-break-word text-left message">
                        {props.textMessage}
                    </p>
                    : null
            }
            {
                props.textMessageAfterCall ?
                    <p className="mb-0 word-break-break-word text-left message">
                        {props.textMessageAfterCall}
                    </p>
                    : null
            }
        </React.Fragment>
    );
}

export default withRouter(FileMessageComponent);