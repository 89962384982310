import React, { useEffect , useState} from 'react'
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom"
import TranslationService from '../../../Services/translationService';
import { PROJECT_NAME } from '../../../Constants/MainKeys';

export default function PartnerFooter() {

  const { language } = useSelector(state => state.language);
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  return translationService ? <section className='right-section'>
    <div className='vnd--poor-footer-contaiber'>
      <div className='vnd--poor-footer-wrapper'>
        <div className='vnd--poor-footer-item'>
          <p>{new Date().getFullYear()} | {PROJECT_NAME}</p>
        </div>
        <div className='vnd--poor-footer-item'>
          <ul>
            <li><Link to={`/${language}/help`}>{translationService.translate("TR_HELP")}</Link></li>
            <li>|</li>
            <li><Link to={`/${language}/terms-and-conditions`}>{translationService.translate("TR_TERMS")}</Link></li>
            <li>|</li>
            <li><Link to={`/${language}/privacy-policy`}>{translationService.translate("TR_PRIVACY_POLICY")}</Link></li>
            <li>|</li>
            <li><Link to={`/${language}/cookies-policy`}>{translationService.translate("TR_COOKIES")}</Link></li>
          </ul>
        </div>
      </div>
    </div>
  </section> : null
}
