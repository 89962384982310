import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_KEY } from '../../../Constants/MainKeys';
import AlertService from '../../../Services/AlertService';
import uuid from 'react-uuid';
import { addButtonSpinner, removeButtonSpinner } from '../../../Store/Actions/spinner';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import TranslationService from '../../../Services/translationService';
import { FaViber, FaWhatsapp, FaTelegramPlane } from "react-icons/fa";
import { RiMessengerLine } from "react-icons/ri";
import ActionButton from './../../../Components/Buttons/ActionButton';
import Input from './../../../Components/Inputs/Input';
import Textarea from '../../../Components/Inputs/Textarea';
import { AiOutlineLeft } from "react-icons/ai";


const buttonSpinnerId = uuid();
const Channels = (props) => {

  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);

  const [translationService, setTranslationService] = useState(null);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(null);
  const [values, setValues] = useState({
    source: "",
    accessToken: "",
    accountId: ""
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const onNumberChange = (event, name, maxValue = null) => {
    if (event.target.value.includes("e") || event.target.value.includes(".")) { return false };
    if (event.target.value === '' || (typeof +event.target.value === "number" && +event.target.value >= 0 && +event.target.value <= maxValue)) {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: event.target.value
      }));
    }
  }

  const onChange = (event, name, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((prevValues) => ({
      ...prevValues,
      [name]: event.target.value
    }));
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };


  return translationService ?
    <Auxiliary>

      <aside className='right-section'>
        <section className="dashboard top-section customer-profile">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="vnd--aside-title-block">
                  <div className="backspace">
                    <span
                      className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                    </span>
                  </div>
                  <span className="slash">|</span>
                  <div className="">
                    <p className="vnd--upp-text-20">Channels</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 my-2">
                <div className="vnd-block vnd-p-block vnd-box-shadow">
                  <div className="d-flex align-items-center customer-sucscription mb-3">
                    <p className="vnd--upp-text-20">Messengers</p>
                  </div>

                  <div className='d-flex flex-wrap'>

                    <div className='form-group mb-0'>
                      <div className="vnd-added-item-container max-h-initial">
                        <div
                          className='vnd-added-item-wrapper cursor-pointer'
                        >
                          <div className={`align-items-center d-flex`}>
                            <FaViber color='#7360F2' size={22} className='me-2' />
                            <p className="font-size-16 max-line-3 word-break-break-all">Viber</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='form-group mb-0'>
                      <div className="vnd-added-item-container max-h-initial">
                        <div
                          className='vnd-added-item-wrapper cursor-pointer'
                        >
                          <div className={`align-items-center d-flex`}>
                            <FaWhatsapp color='#47C357' size={22} className='me-2' />
                            <p className="font-size-16 max-line-3 word-break-break-all">WhatsApp</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='form-group mb-0'>
                      <div className="vnd-added-item-container max-h-initial">
                        <div
                          className='vnd-added-item-wrapper cursor-pointer'
                        >
                          <div className={`align-items-center d-flex`}>
                            <FaTelegramPlane color='#229ED9' size={22} className='me-2' />
                            <p className="font-size-16 max-line-3 word-break-break-all">Telegram</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='form-group mb-0'>
                      <div className="vnd-added-item-container max-h-initial">
                        <div
                          className='vnd-added-item-wrapper cursor-pointer'
                        >
                          <div className={`align-items-center d-flex`}>
                            <RiMessengerLine color='#0077FF' size={22} className='me-2' />
                            <p className="font-size-16 max-line-3 word-break-break-all">Messenger</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              <div className="col-lg-7 my-2">
                <div className="vnd-block vnd-p-block vnd-box-shadow">
                  <div className="d-flex align-items-center customer-sucscription mb-3">
                    <p className="vnd--upp-text-20">Form</p>
                  </div>
                  <form onSubmit={onSubmit}>

                    <div className="form-group">
                      <Input
                        id="source"
                        htmlFor="source"
                        type="number"
                        name="source"
                        labelValue="Source"
                        value={values.source}
                        autoComplete="new-password"
                        inputClassName={`${(isInvalidSubmit && !values.source.trim()) ? "error-border" : ""}`}
                        onChange={(event) => {
                          onNumberChange(event, "source", 1000000000000);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <Textarea
                        id="accessToken"
                        htmlFor="accessToken"
                        name="accessToken"
                        className="resize-none"
                        labelValue="Access Token"
                        value={values.accessToken}
                        autoComplete="new-password"
                        rows={2}
                        inputClassName={`${(isInvalidSubmit && !values.accessToken.trim()) ? "error-border" : ""}`}
                        onChange={(event) => {
                          onChange(event, "accessToken", 2000);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <Textarea
                        id="accountId"
                        htmlFor="accountId"
                        name="accountId"
                        className="resize-none"
                        labelValue="Account Id"
                        value={values.accountId}
                        autoComplete="new-password"
                        inputClassName={`${(isInvalidSubmit && !values.accountId.trim()) ? "error-border" : ""}`}
                        onChange={(event) => {
                          onChange(event, "accountId", 2000);
                        }}
                      />
                    </div>

                    <ActionButton
                      type="submit"
                      name={`Save`}
                      className="vnd-btn vnd-primary-btn my-4"
                      spinnerId={buttonSpinnerId}
                      onClick={onSubmit}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </aside >
    </Auxiliary>
    : null
}

export default Channels