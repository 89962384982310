import { TOKEN_KEY, USER_KEY, OBJECT_KEY, ACTIVE_GROUP_NAME_KEY, REFRESH_TOKEN_KEY } from "../../Constants/MainKeys";
import MainService from "../../Services/mainService";
import { setActiveGroupName } from "../Actions/main";
import {
  AUTH_SUCCESS,
  CURRENT_USER_SUCCESS,
  UPDATE_USER_DATA,
  AUTH_FAIL,
  REMOVE_LOGIN_ERROR_MESSAGE_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  REMOVE_USER_FIELD,
  SET_USER_DATA,
  SET_TOKEN,
} from '../../Constants/ReduxKeys';

const initialState = {
  token: localStorage.getItem(TOKEN_KEY),
  user: localStorage.getItem(USER_KEY) && MainService.isJson(localStorage.getItem(USER_KEY)) ? JSON.parse(localStorage.getItem(USER_KEY)) : null,
  // role: localStorage.getItem(USER_KEY) && MainService.isJson(localStorage.getItem(USER_KEY)) ? window.atob(JSON.parse(localStorage.getItem(USER_KEY))?.roles) : '',
  refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
  loginErrorMessage: ""
};

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case AUTH_SUCCESS:
      localStorage.setItem(TOKEN_KEY, action.payload.token.data.accessToken);
      action.payload.refreshToken && localStorage.setItem(REFRESH_TOKEN_KEY, action.payload.refreshToken);

      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken
      };


    case SET_TOKEN:
      action.payload.token && localStorage.setItem(TOKEN_KEY, action.payload.token);
      action.payload.refreshToken && localStorage.setItem(REFRESH_TOKEN_KEY, action.payload.refreshToken);

      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken
      };

    case CURRENT_USER_SUCCESS:
    case SET_USER_DATA:
      localStorage.setItem(USER_KEY, JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
        // role: action.payload.roles ? atob(action.payload.roles) : ""
      };
    case AUTH_LOGOUT_SUCCESS:
      setActiveGroupName(null);
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(USER_KEY);
      localStorage.removeItem("activeUserData");
      localStorage.removeItem("activeGroupName");
      localStorage.removeItem("activeSessionName");
      localStorage.removeItem("activeGroupsName");
      localStorage.removeItem("partnerId");
      localStorage.removeItem("isCallInProcess");
      localStorage.removeItem("clientActiveCallId");
      localStorage.removeItem("isMuted");
      localStorage.removeItem("groupsByCountry");
      localStorage.removeItem("selectedCountryId");
      localStorage.removeItem("attachedSessionIds");

      localStorage.removeItem("activeCallId");
      localStorage.removeItem("connectionId");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("callId");

      return {
        ...state,
        token: null,
        refreshToken: null,
        user: null,
        hasUser: false,
        role: ''
      };
    case AUTH_FAIL:
      return {
        ...state,
        loginErrorMessage: action.payload,
      };
    case REMOVE_LOGIN_ERROR_MESSAGE_REQUEST:
      return {
        ...state,
        loginErrorMessage: ''
      };
    case UPDATE_USER_DATA:
      var currentUserData = localStorage.getItem(USER_KEY);
      currentUserData = currentUserData ? JSON.parse(currentUserData) : {};
      const updatedFields = action.payload;
      var updatedUserData = Object.assign({}, currentUserData, updatedFields);
      if (updatedUserData.groupName) {
        localStorage.setItem(ACTIVE_GROUP_NAME_KEY, updatedUserData.groupName);
      }
      localStorage.setItem(USER_KEY, JSON.stringify(updatedUserData));
      return {
        ...state,
        user: updatedUserData,
      };

    case REMOVE_USER_FIELD:
      let userDatacopy = { ...state.user };
      let fieldName = action.payload;
      userDatacopy[fieldName] = null;
      localStorage.setItem(USER_KEY, JSON.stringify(userDatacopy));
      return {
        ...state,
        user: userDatacopy,
      };
    default:
      break;
  }
  return state;
};

export default reducer;