import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import DraggableComponent from "../../Components/DraggableComponent/DraggableComponent";
import LeftSidebarMenu from "../../Pages/AgentPages/dashboard/LeftSidebarMenu";
import UserChat from "../../Pages/AgentPages/dashboard/UserChat/UserChat";
import SupporterRouting from "../../Routing/SupporterRouting/SupporterRouting";
import { useSelector } from "react-redux";
import Parser from 'html-react-parser'
import Carousel from "react-multi-carousel";
import TranslationService from "../../Services/translationService";
import { AiOutlineCheck } from "react-icons/ai";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import chatboardSvg from "./../../assets/images/resources/chatboard-2.svg"
import star from '../../assets/images/3d-images/massage-emojies.png'

const SupporterLayout = props => {
  const { pathname } = useLocation();
  const { spinners } = useSelector(state => state.spinner);
  const { language } = useSelector(state => state.language);
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const fullScreen = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1
    },
  };

  const returnLeftMenuWidth = () => {
    switch (true) {
      case pathname.includes("/dashboard/groups"):
        return "group-side-width";
      case pathname.includes("/dashboard/settings"):
        return "group-side-width";
      default:
        return "";
    }
  }

  return (
    <div id='agent'>
      <DraggableComponent />
      <div className="layout-wrapper d-lg-flex">
        <LeftSidebarMenu />
        <div className={`chat-leftsidebar position-fixed ${returnLeftMenuWidth()} ${spinners.length ? "disabled" : ""}`}>
          <SupporterRouting />
        </div>
        {/* <UserChat /> */}
        {
          pathname.includes("/dashboard/groups") || pathname.includes("/dashboard/settings") ?
            <div className="vnd--dashboard-rigth-side">
              {
                translationService ?
                  <Auxiliary>
                    <section className='section'>
                      <div className="container">
                        <div className="light-brand-opacity-background vnd-block">
                          <Carousel
                            showDots={true}
                            arrows={false}
                            autoPlay={true}
                            responsive={fullScreen}
                            infinite={true}
                            transitionDuration={4000}
                            className='vnd--custom-carousel dots-end'
                          >
                            <div className='row'>
                              <div className='col-lg-10'>
                                <h2 className="vnd--text-50">
                                  {Parser(translationService.translate("TR_WELCOME_TO_RELAMY"))}
                                </h2>
                                <p>
                                  {translationService.translate("TR_WELCOME_TO_RELAMY_TEXT")}
                                </p>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-lg-10'>
                                <h2 className="vnd--text-50">
                                  {Parser(translationService.translate("TR_MANAGE_SEP_COMPANIES"))}
                                </h2>
                                <p>
                                  {translationService.translate("TR_MANAGE_SEP_COMPANIES_TEXT")}
                                </p>
                              </div>
                            </div>
                          </Carousel>
                        </div>
                        <img src={star} className="vnd-decoreation vnd-decoration-right vnd-star" alt="planet-blue" />
                      </div>
                    </section>
                    <section className='section'>
                      <div className='container'>
                        <div className='row align-items-center'>
                          <div className='col-xl-6'>
                            <div className='vnd-block'>
                              <h2 className="vnd--text-50">{translationService.translate("TR_DASHBOARD")}</h2>
                              <h3 className="vnd--upp-text-20 vnd--opacity-50">
                                {translationService.translate("TR_KNOW_ALL_ABOUT_DASHBOARD")}
                              </h3>
                              <div className='my-3'>
                                <p>
                                  {translationService.translate("TR_KNOW_ALL_ABOUT_DASHBOARD_TEXT")}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='col-xl-6'>
                            <div className="vnd-side-image-wrapper">
                              <img src={chatboardSvg} alt="chatboard" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </Auxiliary>
                  : null
              }
            </div >
            : null
        }
      </div>
    </div >
  );
}

export default withRouter(SupporterLayout);

