import React from "react";
import useElementDimensionsByClassName from "../../Hooks/useElementDimensionsByClassName";

function SpinnerComponent(props) {

  const header = new useElementDimensionsByClassName("vnd-header");
  const getSize = () => {
    let sizeObj = {
      top: 0,
      left: 0,
      width: "100%"
    }
    if (header && header.height) sizeObj.top = header.height;
    if (header && header.width) {
      sizeObj.width = header.width;
      sizeObj.right = 0;
      delete sizeObj.left;
    }
    return sizeObj;
  }

  return (
    props.spinner && props.spinner.length ?
      <div className="loader-line" style={getSize()}></div>
      : null
  );

}

export default SpinnerComponent;