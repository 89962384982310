import { ACTIVE_GROUP_NAME_KEY, ACTIVE_SECTION_KEY, PARTNER_ID_KEY, ACTIVE_GROUPS_NAME_KEY } from "../../Constants/MainKeys";
import {
  SET_ACTIVE_SECTION,
  SET_ACTIVE_GROUP_NAME,
  SET_PARTNER_ID,
  SET_IS_SHOW_SETTINGS,
  SET_IS_CONNECTION_ALLOW,
  SET_TICKET_STATUS_RESPONSE,
  SET_TICKET_URGENT_RESPONSE,
  SET_ACTIVE_GROUPS_NAME,
  SET_IS_SHOW_ATTACH_TO_SESSION_NOTIFICATION,
  SET_IS_HIDE_ATTACH_TO_SESSION_NOTIFICATION,
  SET_IS_MUTED,
  SET_SUBSCRIPTION_SERVICE
} from '../../Constants/ReduxKeys';

let initialState = {
  activeSection: localStorage.getItem(ACTIVE_SECTION_KEY),
  activeGroupName: localStorage.getItem(ACTIVE_GROUP_NAME_KEY) ? localStorage.getItem(ACTIVE_GROUP_NAME_KEY) : null,
  activeGroupsName: localStorage.getItem(ACTIVE_GROUPS_NAME_KEY) ? JSON.parse(localStorage.getItem(ACTIVE_GROUPS_NAME_KEY)) : [],
  partnerId: localStorage.getItem(PARTNER_ID_KEY) ? localStorage.getItem(PARTNER_ID_KEY) : null,
  isShowSettings: false,
  isConnectionAllow: true, //false //TODO
  ticketStatusResponse: null,
  ticketUrgentResponse: null,
  showAttacheToSessionNotification: null,
  isMuted: localStorage.getItem("isMuted") ? JSON.parse(localStorage.getItem("isMuted")) : false,
  subscriptionService: null,
};

const reducer = (state = initialState, action) => {
  let activeGroupsNameCopy = [...state.activeGroupsName];

  switch (action.type) {
    case SET_SUBSCRIPTION_SERVICE:
      return {
        ...state,
        subscriptionService: action.payload,
      };
    case SET_ACTIVE_SECTION:
      localStorage.setItem(ACTIVE_SECTION_KEY, action.payload)
      return {
        ...state,
        activeSection: action.payload,
      };

    case SET_ACTIVE_GROUP_NAME:
      action.payload ? localStorage.setItem(ACTIVE_GROUP_NAME_KEY, action.payload) : localStorage.removeItem(ACTIVE_GROUP_NAME_KEY);
      return {
        ...state,
        activeGroupName: action.payload,
      };

    case SET_ACTIVE_GROUPS_NAME:
      const { group, isChecked } = action.payload;
      if (isChecked) {
        if (!activeGroupsNameCopy.length) {
          activeGroupsNameCopy.push(group);
        } else {
          const isExist = activeGroupsNameCopy.find(groupNameItem => groupNameItem.groupName === group.groupName);
          if (!isExist) {
            activeGroupsNameCopy.push(group);
          }
        }
      } else {
        activeGroupsNameCopy = activeGroupsNameCopy.filter(groupNameItem => groupNameItem.groupName !== group.groupName);
      }
      if (activeGroupsNameCopy.length) {
        localStorage.setItem(ACTIVE_GROUPS_NAME_KEY, JSON.stringify(activeGroupsNameCopy));
      } else {
        localStorage.removeItem(ACTIVE_GROUPS_NAME_KEY);
      }
      return {
        ...state,
        activeGroupsName: activeGroupsNameCopy
      };

    case SET_PARTNER_ID:
      action.payload ? localStorage.setItem(PARTNER_ID_KEY, action.payload) : localStorage.removeItem(PARTNER_ID_KEY);
      return {
        ...state,
        partnerId: action.payload,
      };
    case SET_IS_SHOW_SETTINGS:
      return {
        ...state,
        isShowSettings: action.payload,
      };
    case SET_IS_CONNECTION_ALLOW:
      return {
        ...state,
        isConnectionAllow: action.payload,
      };
    case SET_TICKET_STATUS_RESPONSE:
      return {
        ...state,
        ticketStatusResponse: action.payload,
      };
    case SET_TICKET_URGENT_RESPONSE:
      return {
        ...state,
        ticketUrgentResponse: action.payload,
      };
    case SET_IS_SHOW_ATTACH_TO_SESSION_NOTIFICATION:
      const { supporterName, sessionName } = { ...action.payload }
      return {
        ...state,
        showAttacheToSessionNotification: { supporterName, sessionName },
      };
    case SET_IS_HIDE_ATTACH_TO_SESSION_NOTIFICATION:
      return {
        ...state,
        showAttacheToSessionNotification: null,
      };
    case SET_IS_MUTED:
      localStorage.setItem("isMuted", action.payload)
      return {
        ...state,
        isMuted: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default reducer;