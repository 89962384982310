import React from 'react'

export default function CompletedVideoCallSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 256 256"
      className={props.className}
    >
      <circle style={{ fill: "#094" }} cx="128" cy="128" r="119.5" />
      <rect style={{ fill: "none" }} width="256" height="256" />
      <path style={{ fill: "#fff" }} d="M142.16,91H73.35a10.58,10.58,0,0,0-10.58,10.58v52.94a10.58,10.58,0,0,0,10.58,10.58h68.81a10.59,10.59,0,0,0,10.59-10.58V101.53A10.59,10.59,0,0,0,142.16,91Zm-8.34,24.3-32.51,32.51a5,5,0,0,1-7,0L81.69,135.15a5,5,0,1,1,7-7l9.11,9.11,29-29a5,5,0,0,1,7,7Z" />
      <path style={{ fill: "#fff" }} d="M161.48,112.12v31.76L185,159.57a5.3,5.3,0,0,0,8.23-4.4V100.83a5.3,5.3,0,0,0-8.23-4.4Zm0,0" /></svg>
  )
}
