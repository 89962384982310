import React from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import ActionButton from '../../../../Components/Buttons/ActionButton'
import { Link } from "react-router-dom"

export default function StepFooter({
  translationService,
  setpNumber,
  backUrl,
  actionButtonName,
  spinnerId,
  onSubmit
}) {
  return (
    <div className='vnd--steps-footer'>
      <div className='container'>
        <div className='row align-items-center justify-content-between flex-wrap'>
          <div className='col-md-6 order-md-1 order-6'>
            <hr className='d-md-none' />
            <div className='d-flex align-items-center'>
              <AiOutlineInfoCircle className='vnd--steps-footer-logo me-2 m-0' fontSize={22} />
              <p className='vnd--steps-footer-description m-0'>{translationService.translate("TR_GUARANTEE_INFO")}</p>
            </div>
          </div>
          <div className='col-md-6 order-md-6'>
            <div className='d-sm-flex justify-content-sm-end justify-content-between align-items-center flex-1'>
              <p className='vnd-block-subtitle me-3 mb-sm-0 mb-2 text-sm-start text-end'>{translationService.translate("TR_STEP")} {setpNumber}/4</p>
              <div className='d-flex align-items-center'>
                {
                  backUrl ?
                    <Link to={backUrl} className="font-bold vnd-small-btn me-3 flex-1">{translationService.translate("TR_BACK")}</Link>
                    : null
                }
                <ActionButton
                  type="button"
                  name={actionButtonName}
                  className="vnd-btn vnd-primary-btn vnd-small-btn"
                  spinnerId={spinnerId}
                  onClick={onSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
