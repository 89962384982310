import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

function useElementDimensionsByClassName(className) {

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { spinners } = useSelector(state => state.spinner);

  useEffect(() => {
    const element = document.querySelector(`.${className}`);
    function updateDimensions() {
      if (element) {
        const width = element.offsetWidth;
        const height = element.offsetHeight;
        setDimensions({ width, height });
      } else {
        setDimensions({ width: 0, height: 0 });
      }
    }

    // Initial dimensions update
    updateDimensions();

    // Update dimensions when the window resizes
    window.addEventListener('resize', updateDimensions);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [className, spinners]);

  return dimensions;
}

export default useElementDimensionsByClassName;
