import { SET_PAGE_CONTENT, GET_PAGE_CONTENT_BY_ID, SET_PAGE_CONTENT_ID, SET_IS_SHOW_PAGE_CONTENT_SPINNER } from "../../Constants/ReduxKeys";

export function setHelpPageContent(language) {
  return {
    type: SET_PAGE_CONTENT,
    payload: language,
  };
};

export function getPageContentById(id) {
  return {
    type: GET_PAGE_CONTENT_BY_ID,
    payload: id,
  };
};

export function setPageContentId(id) {
  return {
    type: SET_PAGE_CONTENT_ID,
    payload: id,
  };
};

export function setIsShowPageContentSpinner(bool) {
  return {
    type: SET_IS_SHOW_PAGE_CONTENT_SPINNER,
    payload: bool,
  };
};
