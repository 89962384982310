import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, Card } from "reactstrap";
import { InputGroup } from "reactstrap";
import SimpleBar from "simplebar-react";
import AttachedFiles from "./AttachedFiles";
import CustomCollapse from "./CustomCollapse";
import { closeUserSidebar } from "./../../../Store/Actions/layout";
import TranslationService from '../../../Services/translationService';
import { ERROR_KEY, PARTNER_USER_TYPE_ID_KEY, SUCCESS_KEY, SUPERVISOR_USER_TYPE_ID_KEY, SUPPORTER_USER_TYPE_ID_KEY, fieldLengths } from '../../../Constants/MainKeys';
import ActionButton from '../../../Components/Buttons/ActionButton';
import { Link, useLocation, withRouter } from 'react-router-dom';
import TicketModal from '../../../Components/Modals/TicketModal';
import * as moment from "moment";
import CallWeitingRingtone from "./../../../assets/audio/duooutgoing_b9801c9f5127101.mp3";
import { RtcConnection } from "mindalayvideostream";
import CloseSvg from "./../../../assets/icons/close.svg";
import CloseWhiteSvg from "./../../../assets/icons/close-white.svg";
import VoiceCallSvg from "./../../../assets/icons/voice-call.svg";
import VideoCallSvg from "./../../../assets/icons/video-call.svg";
import ScreenCastSvg from "./../../../assets/icons/screencast.svg";
import { setRtcGlobalConnection } from '../../../Store/Actions/signalR';
import AlertService from '../../../Services/AlertService';
import ApiService from '../../../Services/ApiService';
import { addButtonSpinner, addSpinner, removeButtonSpinner, removeSpinner } from '../../../Store/Actions/spinner';
import uuid from 'react-uuid';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import CustomInput from "./../../../Components/Inputs/Input"
import {
    addFieldsToActiveUserDataFromSession,
    addMessagesToUser,
    changeCallWaitingStatus,
    getCountries,
    getScriptGroups,
    setActiveUserData,
    setClientIsInActiveCallException,
    setCountries,
    setDefaultTechnicalAssistants,
    setIsCallInProcess,
    setScriptGroups,
    setScripts,
    setSelectedCountry,
    setSelectedGroup,
    setTechnicalAssistants,
    setUsers,
    toggleGroupsByCountry,
    updateActiveUserData
} from '../../../Store/Actions/chat';
import MainService from '../../../Services/mainService';
import Scripts from './Scripts';
import Note from './Note';
import { setTicketStatusResponse, setTicketUrgentResponse } from '../../../Store/Actions/main';
import { AiOutlineUser } from "react-icons/ai";

const buttonSpinnerId = uuid();
const leaveChatSpinnerId = uuid();

function setScriptAttributeData(obj) {
    if (!obj) { return false };
    return (
        <>
            {Object.entries(obj).map(([key, value]) => {
                if (typeof value === "object" && !Array.isArray(value)) {
                    return (
                        <>
                            <hr />
                            <div key={key} className="my-1 aaa">
                                <span>{key}:{" "}</span>
                                <div className='ml-5'> {setScriptAttributeData(value)}</div>
                            </div>
                        </>
                    );
                } else if (Array.isArray(value)) {
                    return (
                        <div key={key}>
                            <hr />
                            <div className="my-1 bbb ">
                                <span>{key}:{" "}</span>
                                {value.map((item, index) => (
                                    <span key={index} className="xxx">
                                        <br />
                                        {typeof item === "object" ? setScriptAttributeData(item) : item}
                                    </span>
                                ))}
                            </div>
                        </div>
                    );
                } else {
                    return <div key={key}>
                        <div className="my-1 ccc d-flex justify-content-between">
                            <span>{key}:{" "}</span>
                            <span>{value}</span>
                        </div>
                    </div>
                }
            })}
        </>
    );
}

function UserProfileSidebar(props) {

    const dispatch = useDispatch();

    var supporterId = null;
    const { pathname } = useLocation();
    if (pathname.includes("/technical-assistant")) {
        supporterId = pathname.split("/")[pathname.split("/").length - 1]
    }
    const translations = useSelector(state => state.translation.translations);
    const { user } = useSelector(state => state.user);
    const { userSidebar } = useSelector(state => state.layout);
    const connection = useSelector(state => state.signalR.globalConnectionWithRedux);
    const { rtcGlobalConnection } = useSelector(state => state.signalR);

    const {
        activeUserData,
        activeSessionName,
        users,
        isCallInProcess,
        isCallwaiting,
        clientActiveCallId,
        technicalAssistants,
        defaultTechnicalAssistants,
        isClientIsInActiveCallException,
    } = useSelector(state => state.chat);
    const {
        ticketStatusResponse,
        ticketUrgentResponse,
        activeGroupName,
        activeGroupsName,
        partnerId,
        subscriptionService,
    } = useSelector(state => state.main);

    const [translationService, setTranslationService] = useState(null);
    const [isShowTicketModal, setIsShowTicketModal] = useState(false);
    const [files, setFiles] = useState([]);
    const [currentTicket, setCurrentTicket] = useState(null);
    const [callId, setCallId] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [selectedTechnicalAssistant, setSelectedTechnicalAssistant] = useState(null);
    const [isDisabledFfields, setIsDisabledFfields] = useState(false);
    const [isShowCopiedMessage, setIsShowCopiedMessage] = useState(false);
    const [activeCollapseId, setActiveCollapseId] = useState(null);
    const [scrollBarHeight, setScrollBarHeight] = useState(500);

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    useEffect(() => {
        if (document.getElementById("user-profie-top-menu")) {
            setScrollBarHeight(window.innerHeight - document.getElementById("user-profie-top-menu").offsetHeight)
        }
    }, [document.getElementById("user-profie-top-menu"), document.querySelectorAll(".vnd-added-item-container")])

    useEffect(() => {
        if (isClientIsInActiveCallException && rtcGlobalConnection && callId !== 3) {
            setCallId(null);
            localStorage.removeItem("callId");
            localStorage.removeItem("activeCallId");
            dispatch(changeCallWaitingStatus(false));
            dispatch(setIsCallInProcess(false));
            rtcGlobalConnection.closeRoom();
            dispatch(setRtcGlobalConnection(null));
            dispatch(setClientIsInActiveCallException(false));
        }
        if (callId === 3 && isClientIsInActiveCallException) {
            setCallId(null);
            localStorage.removeItem("callId");
            localStorage.removeItem("activeCallId");
            dispatch(changeCallWaitingStatus(false));
            dispatch(setIsCallInProcess(false));
            dispatch(setClientIsInActiveCallException(false));
        }
    }, [isClientIsInActiveCallException, rtcGlobalConnection]);

    useEffect(() => {
        let files = [];
        if (activeUserData) {
            if (activeUserData.messages && activeUserData.messages.length) {
                activeUserData.messages.forEach(message => {
                    if (message.fileMessages && message.fileMessages.length) {
                        files = [...files, ...message.fileMessages]
                    }
                })
            }
        }
        setFiles(files);
    }, [activeUserData]);

    useEffect(() => {
        if (activeUserData && defaultTechnicalAssistants.length) {
            if (activeUserData.attachedUserId) {
                setSelectedTechnicalAssistant(defaultTechnicalAssistants.find(technicalAssistant => +technicalAssistant.id === +activeUserData.attachedUserId));
            } else {
                setSelectedTechnicalAssistant(null);
            }
        }
        //todo
    }, [activeUserData, defaultTechnicalAssistants]);

    useEffect(() => {
        if (isCallwaiting) {
            audioStart();
        } else {
            audioStop();
            setCallId(null);
        }
    }, [isCallwaiting]);

    const startCall = (callId) => {
        if (!callId || isCallwaiting) { return false; }
        switch (callId) {
            case 1:
                startVideoCall();
                break;
            case 2:
                startAudioCall();
                break;
            case 3:
                startScreenShare();
                break;

            default:
                break;
        }
    }

    const audioStart = () => {
        var audioElement = document.getElementById("call-waiting-ringtone");
        audioElement.src = CallWeitingRingtone;
        audioElement.autoplay = true;
        audioElement.loop = true;
    }

    const audioStop = () => {
        var audioElement = document.getElementById("call-waiting-ringtone");
        audioElement.src = "";
        audioElement.autoplay = false;
        audioElement.loop = false;
    }

    const isConnectionOk = () => {
        if (connection && connection.state === "Connected") return true;
        else return false;
    }

    const startAudioCall = () => {
        dispatch(changeCallWaitingStatus(true));
        if (connection) {
            connection.invoke("CallToClient", activeUserData.clientSessionKey, user.id, 2, new Date().getTime().toString(), 1).then(() => {
                // rtcConnection = null;
                dispatch(setRtcGlobalConnection(null));
                var rtcGlobalConnectionCopy = new RtcConnection({
                    roomId: activeUserData.clientSessionKey,
                    mediaContainer: document.getElementById('videoContainer'),
                    isAudio: true,
                    isVideo: false,
                    videoWidth: 1920,
                    videoHeight: 1080,
                    frameRate: 30,
                    width: 250,
                    isScreenShare: false,
                    videoControls: [
                        'mute-audio', 'stop'
                    ]
                });
                rtcGlobalConnectionCopy.openRoom();
                dispatch(setIsCallInProcess(true));
                dispatch(setRtcGlobalConnection(rtcGlobalConnectionCopy));
            }).catch(error => getFail(error))
        }
    }

    const startVideoCall = () => {
        dispatch(changeCallWaitingStatus(true));
        if (connection) {
            connection.invoke("CallToClient", activeUserData.clientSessionKey, user.id, 1, new Date().getTime().toString(), 1).then(() => {
                dispatch(setRtcGlobalConnection(null));
                var rtcGlobalConnectionCopy = new RtcConnection({
                    roomId: activeUserData.clientSessionKey,
                    mediaContainer: document.getElementById('videoContainer'),
                    isAudio: true,
                    isVideo: true,
                    videoWidth: 1920,
                    videoHeight: 1080,
                    frameRate: 30,
                    width: 250,
                    isScreenShare: false,
                    videoControls: [
                        'mute-audio', 'mute-video', 'full-screen', 'stop'
                    ]
                });
                rtcGlobalConnectionCopy.openRoom();
                dispatch(setIsCallInProcess(true));
                dispatch(setRtcGlobalConnection(rtcGlobalConnectionCopy));
            }).catch(error => getFail(error))
        }
    }

    const startScreenShare = () => {
        dispatch(changeCallWaitingStatus(true));
        if (connection) {
            connection.invoke("CallToClient", activeUserData.clientSessionKey, user.id, 3, new Date().getTime().toString(), 1).then(() => {
                dispatch(setIsCallInProcess(true));
            }).catch(error => getFail(error))
        }
    }

    const endCall = (isCallwaiting) => {
        if (!callId) { return false; }
        setCallId(null);
        localStorage.removeItem("callId");
        localStorage.removeItem("activeCallId");
        if (connection && isCallwaiting) {
            connection.invoke("HangupCall", activeUserData.clientSessionKey, callId, 1, user.id).then(() => {
                rtcGlobalConnection && rtcGlobalConnection.closeRoom();
                dispatch(changeCallWaitingStatus(false));
                dispatch(setIsCallInProcess(false));
                dispatch(setRtcGlobalConnection(null));
            }).catch(error => getFail(error));
        }
    }

    const closeSession = () => {
        AlertService.alertConfirm(
            `${translationService.translate("TR_ARE_YOU_SURE")} ?`,
            "",
            translationService.translate("TR_YES"),
            translationService.translate("TR_NO")
        ).then(() => {
            setButtonSpinner(buttonSpinnerId);
            // ApiService.closeSession(activeUserData.clientSessionKey).then(() => {
            connection.invoke("CloseClientSession", activeUserData.clientSessionKey, user.id).then(() => {
                if (activeSessionName !== "all") {
                    const usersCopy = [...users];
                    const usersCopyWithOutCurrentUser = usersCopy.filter(user => user.clientSessionKey !== activeUserData.clientSessionKey);
                    dispatch(setUsers(usersCopyWithOutCurrentUser));
                    if (usersCopyWithOutCurrentUser.length) {
                        getCurrentUserChat(usersCopyWithOutCurrentUser[0]);
                    } else {
                        dispatch(setActiveUserData(null));
                    }
                } else {
                    let today = new Date();
                    const activeUserDataCopy = { ...activeUserData };
                    activeUserDataCopy.endTime = moment(today.toUTCString()).format("L HH:mm");
                    dispatch(updateActiveUserData(activeUserDataCopy));
                }
                extractButtonSpinner(buttonSpinnerId);
                closeUserSidebar();
                translationService && AlertService.alert(SUCCESS_KEY, translationService.translate("TR_CLOSED_SUCCESSFULLY"));
            }).catch(error => getFail(error, buttonSpinnerId));
            // }).catch(error => getFail(error, buttonSpinnerId));
        })
    }

    const getCurrentUserChat = (currentUser) => {
        var spinnerId = uuid();
        if (!currentUser) { return false; }

        if (currentUser.supportSessionId) {
            setSpinner(spinnerId);
            ApiService.getCurrentUserChat(currentUser.supportSessionId, 1, 10).then(response => {
                if (response && response.data) {
                    const data = { ...response.data };
                    checkSessionIsAttached(data?.sessionId);
                    let messageResponseModelsItem = [];
                    const messageResponseModels = [...data.messageResponseModels];
                    if (messageResponseModels && messageResponseModels.length) {
                        messageResponseModelsItem = messageResponseModels.filter(message => {
                            if (!message.mediaMessage || (message.mediaMessage && message.mediaMessage.status)) {
                                return message;
                            }
                        })
                    }
                    dispatch(setActiveUserData(currentUser.clientSessionKey));
                    dispatch(addMessagesToUser(currentUser.clientSessionKey, messageResponseModelsItem));
                    dispatch(
                        addFieldsToActiveUserDataFromSession(
                            data.tickets || [],
                            data?.isSessionAttachedMe,
                            data?.attachedUserId,
                            data?.sessionId,
                            data?.feedbackResponse,
                            data?.note,
                            data.sessionSource,
                        )
                    );
                    if (pathname.includes("/dashboard/chats/my-clients") || pathname.includes("/dashboard/chats/all")) {
                        resetScripts();
                        dispatch(getCountries(user.partnerId ? +user.partnerId : +partnerId));
                        _getScriptGroupsWithoutCountry(1);
                    }
                    if (isConnectionOk() && activeGroupName) {
                        if (supporterId) {
                            if (activeUserData && data.isSessionAttachedMe) {
                                if (currentUser && !currentUser.isOnline) {
                                    connection.invoke("ToggleOfflineSessionConnection", currentUser.clientSessionKey,
                                        !activeUserData ? null : activeUserData.isOnline ? null : activeUserData.clientSessionKey)
                                } else {
                                    connection.invoke("ToggleSupervisorSessionConnection", currentUser.clientSessionKey, activeUserData ? activeUserData.clientSessionKey : null)
                                }
                            }
                        } else {
                            if (currentUser && !currentUser.isOnline) {
                                connection.invoke("ToggleOfflineSessionConnection", currentUser.clientSessionKey,
                                    !activeUserData ? null : activeUserData.isOnline ? null : activeUserData.clientSessionKey).catch(error => getFail(error))
                            }
                        }
                    }
                    ApiService.getTicketFields().then(res => {
                        if (res && res.data) {
                            dispatch(setTicketStatusResponse(res.data.ticketStatusResponse));
                            dispatch(setTicketUrgentResponse(res.data.ticketUrgentResponse));
                        }
                    }).catch(error => getFail(error));
                }
                if (user.partnerId || partnerId) {
                    getTechnicalAssistantsList(user.partnerId ? +user.partnerId : +partnerId);
                }
                var userChat = document.getElementsByClassName("user-chat");
                if (userChat) {
                    userChat[0]?.classList.add("user-chat-show");
                }
                document.getElementById("chatInput")?.focus();
                extractSpinner(spinnerId);
            }).catch(error => getFail(error, spinnerId));
        }
    }

    const resetScripts = () => {
        dispatch(setCountries([]));
        dispatch(setSelectedCountry(null));
        dispatch(setScriptGroups([]));
        dispatch(setSelectedGroup(null));
        dispatch(setScripts([]));
        dispatch(toggleGroupsByCountry(false));
    }

    const _getScriptGroupsWithoutCountry = (currentPage) => {
        let data = {
            partnerId: user.partnerId ? +user.partnerId : +partnerId,
            prefix: "",
            pageNumber: currentPage,
            pageSize: fieldLengths.pageSize_10,
        }
        dispatch(getScriptGroups(data));
    }

    const getTechnicalAssistantsList = (partnerId) => {
        ApiService.getTechnicalAssistantsList(partnerId).then(response => {
            if (response && response.data) {
                const data = [...response.data];
                let sortedData = data.sort(function (a, b) { return b.isOnline - a.isOnline });
                dispatch(setTechnicalAssistants(sortedData));
                dispatch(setDefaultTechnicalAssistants(sortedData));
            }
        }).catch(error => getFail(error))
    }


    const checkSessionIsAttached = (sessionId) => {
        if (!sessionId) { return false; }
        if (localStorage.getItem("attachedSessionIds") && MainService.isJson(localStorage.getItem("attachedSessionIds"))) {
            let attachedSessionIds = JSON.parse(localStorage.getItem("attachedSessionIds"));
            attachedSessionIds = attachedSessionIds.filter(item => item !== sessionId);
            localStorage.setItem("attachedSessionIds", JSON.stringify(attachedSessionIds));
        }
    }

    const leaveChat = () => {
        if (!activeUserData) { return false; }
        const activeUserDataCopy = { ...activeUserData };
        setButtonSpinner(leaveChatSpinnerId);

        if (isConnectionOk()) {
            connection.invoke("LeaveFromExistedSession", user.id, activeUserData.clientSessionKey).then(() => {
                activeUserDataCopy.isSessionAttachedMe = false;
                dispatch(updateActiveUserData(activeUserDataCopy));
                AlertService.alert(SUCCESS_KEY, translationService.translate("TR_YOU_LEFT_THE_CHAT"));
                extractButtonSpinner(leaveChatSpinnerId);
                setCallId(null);
                localStorage.removeItem("callId");
                localStorage.removeItem("activeCallId");
                dispatch(changeCallWaitingStatus(false));
                dispatch(setIsCallInProcess(false));
            }).catch(error => getFail(error, leaveChatSpinnerId));
        } else {
            AlertService.alert(ERROR_KEY, translationService.translate("TR_CONNECTION_LOST"));
            extractButtonSpinner(leaveChatSpinnerId)
        }
    }

    const disablesUserProfileSidebar = () => {
        if (user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY || user.userTypeId === PARTNER_USER_TYPE_ID_KEY) {
            if (supporterId && activeUserData && !activeUserData.isSessionAttachedMe) {
                return ""
            }
        }
        return "";
    }

    const search = (event, cb, maxLength = null) => {
        const defaultTechnicalAssistantsCopy = [...defaultTechnicalAssistants];
        if (maxLength && maxLength < event.target.value.length) { return; }
        cb(event.target.value);
        if (event.target.value.length && event.target.value.trim().length) {
            if (defaultTechnicalAssistantsCopy && defaultTechnicalAssistantsCopy.length) {
                dispatch(setTechnicalAssistants([...defaultTechnicalAssistantsCopy].filter(technicalAssistant => technicalAssistant.fullName.toUpperCase().includes(event.target.value.toUpperCase()))))
            }
        } else {
            dispatch(setTechnicalAssistants(defaultTechnicalAssistantsCopy))
        }
    }

    const onSelectTechnicalAssistant = (technicalAssistant) => {
        if (!technicalAssistant || !activeUserData || (activeUserData && activeUserData.endTime)) { return false; }
        if (isConnectionOk()) {
            AlertService.alertConfirm(
                `${translationService.translate("TR_ARE_YOU_SURE")} ?`,
                // `You want to attach the ${technicalAssistant.fullName} to the current session ?`,
                `${translationService.translate("TR_LINK_SESSION_SS").replace('{0}', `"${technicalAssistant.fullName}"`)}.`,
                translationService.translate("TR_YES"),
                translationService.translate("TR_NO")
            ).then(() => {
                setIsDisabledFfields(true);
                connection.invoke("attacheSupporterToSession", technicalAssistant.id, activeUserData.supportSessionId, activeUserData.clientSessionKey, user.partnerId ? +user.partnerId : +partnerId, 1).then(() => {
                    setSelectedTechnicalAssistant(technicalAssistant);
                    setActiveCollapseId(null);
                    setIsDisabledFfields(false);
                    AlertService.alert(SUCCESS_KEY, `${translationService.translate("TR_ATTACHED_SUCCESS")}.`)
                }).catch(error => getFail(error))
            })
        }
    }

    const copyScript = () => {
        const copiedText = document.querySelector(".script-tag");
        navigator.clipboard.writeText(copiedText.innerText);
        setIsShowCopiedMessage(true);
        setTimeout(() => {
            setIsShowCopiedMessage(false)
        }, 1000);
    }

    const setSpinner = useCallback(spinner => {
        dispatch(addSpinner(spinner));
    }, []);

    const extractSpinner = useCallback(spinner => {
        dispatch(removeSpinner(spinner));
    }, []);

    const setButtonSpinner = useCallback(spinner => {
        dispatch(addButtonSpinner(spinner));
    }, []);

    const extractButtonSpinner = useCallback(spinner => {
        dispatch(removeButtonSpinner(spinner));
    }, []);

    const getFail = (error, spinnerId) => {
        error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
        spinnerId && extractButtonSpinner(spinnerId);
        spinnerId && extractSpinner(spinnerId);
        setIsDisabledFfields(false);
        rtcGlobalConnection && rtcGlobalConnection.closeRoom();
        dispatch(changeCallWaitingStatus(false));
        dispatch(setIsCallInProcess(false));
        dispatch(setRtcGlobalConnection(null));
    };

    // closes sidebar
    const onCloseUserSidebar = () => {
        dispatch(closeUserSidebar())
    }

    const cancel = () => {
        setIsShowTicketModal(false);
        setCurrentTicket(null)
    }

    let isDisabledFields = false;
    if (user && (user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY)) {
        if (activeGroupsName && activeGroupsName.length && activeGroupName) {
            let isExist = activeGroupsName.find(group => group.groupName === activeGroupName);
            if (!isExist) isDisabledFields = true;
            else isDisabledFields = false;
        } else {
            isDisabledFields = true;
        }
    }

    return translationService && activeUserData ? <Auxiliary>
        {
            callId ?
                <Modal
                    tabIndex="-1"
                    isOpen={true}
                    centered
                >
                    <ModalBody>
                        <div className='modal-close-block'>
                            <img
                                src={CloseSvg}
                                alt="/"
                                onClick={() => endCall(isCallwaiting)} />
                        </div>

                        <div className="text-center p-4">
                            <div className={`mb-4 ${isCallwaiting ? "call-animation" : ""}`}>
                                {
                                    activeUserData ?
                                        activeUserData.profilePicture ?
                                            <div className="d-flex justify-content-center">
                                                <img src={activeUserData.profilePicture} className="rounded-circle avatar-lg" alt="/" />
                                            </div>
                                            : <div className="d-flex justify-content-center">
                                                <div className="avatar-lg">
                                                    <span style={{ fontSize: "30px" }} className="avatar-title rounded-circle bg-soft-primary vnd--text-primary">
                                                        <i className="ri-user-3-line"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        : null
                                }
                            </div>
                            {
                                activeUserData && activeUserData.clientName ?
                                    <h5 className="text-truncate">{activeUserData.clientName}</h5>
                                    : null
                            }
                            <p className="text-muted">
                                {translationService.translate("TR_START")} {callId === 1 ? translationService.translate("TR_VIDEO_CALL") : callId === 2 ? translationService.translate("TR_VOICE_CALL") : callId === 3 ? translationService.translate("TR_SCREEN_SHARE") : ""}
                            </p>

                            <div className="mt-4">
                                <ul className="list-inline mb-1">
                                    <li className="list-inline-item px-2 me-2 ms-0">
                                        <button type="button" className="btn vnd-danger-btn avatar-sm rounded-circle" onClick={() => endCall(isCallwaiting)}>
                                            <span className="avatar-title bg-transparent font-size-20">
                                                <img src={CloseWhiteSvg} alt="/" />
                                            </span>
                                        </button>
                                    </li>
                                    <li className="list-inline-item px-2">
                                        <button
                                            type="button"
                                            className="btn btn-success avatar-sm rounded-circle"
                                            disabled={isCallwaiting || (clientActiveCallId && clientActiveCallId === activeUserData.clientSessionKey) ? true : false}
                                            onClick={() => startCall(callId)}
                                        >
                                            <span className="avatar-title bg-transparent font-size-20">
                                                {
                                                    callId === 1 ?
                                                        <img src={VideoCallSvg} alt="/" /> :
                                                        callId === 2 ?
                                                            <img src={VoiceCallSvg} alt="/" /> :
                                                            callId === 3 ?
                                                                <img src={ScreenCastSvg} alt="/" /> : null
                                                }
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                : null
        }
        <div
            style={{ display: (userSidebar === true) ? "block" : "none" }}
            className={`user-profile-sidebar ${disablesUserProfileSidebar()}`}
        >
            {
                isShowTicketModal && ticketStatusResponse && ticketUrgentResponse ?
                    <TicketModal
                        ticketStatusResponse={ticketStatusResponse}
                        ticketUrgentResponse={ticketUrgentResponse}
                        currentTicket={currentTicket}
                        cancel={cancel}
                    />
                    : null
            }
            <div id='user-profie-top-menu' className='user-profie-top-menu'>


                <div className='d-flex justify-content-between align-items-center p-2'>
                    {
                        activeUserData && !activeUserData.endTime && !supporterId && !pathname.includes("inbox") ?
                            <div className='mt-2'>
                                <ActionButton
                                    type="button"
                                    name={translationService.translate("TR_CLOSE_SESSION")}
                                    className={`
                                vnd-btn vnd-primary-btn vnd-small-btn m-1 w-auto  
                                ${isCallInProcess || isCallwaiting || !activeGroupName || isDisabledFields || (clientActiveCallId && clientActiveCallId === activeUserData.clientSessionKey) ? "disabled" : ""}`
                                    }
                                    disabled={isCallInProcess || isCallwaiting || !activeGroupName || isDisabledFields ? true : false}
                                    spinnerId={buttonSpinnerId}
                                    onClick={closeSession}
                                />
                            </div>
                            : null
                    }

                    <div
                        className="session-hide-btn"
                        onClick={onCloseUserSidebar}
                    >
                        <Link to="#" className="user-chat-remove text-muted font-size-16 py-1 px-2" >
                            <i className="ri-close-line"></i>
                        </Link>
                    </div>
                </div>


                <div className="p-3 border">
                    <div className='d-flex align-items-center'>
                        <div>
                            {
                                activeUserData ?
                                    !activeUserData.profilePicture ?
                                        <div className="avatar-sm mr-4">
                                            <span style={{ fontSize: "20px" }} className="avatar-title rounded-circle bg-soft-primary vnd--text-primary">
                                                <i className="ri-user-3-line"></i>
                                            </span>
                                        </div>
                                        : <img src={activeUserData.profilePicture} className="rounded-circle avatar-lg img-thumbnail" alt="/" />
                                    : null
                            }
                        </div>
                        <div>
                            {
                                activeUserData ?
                                    <Auxiliary>
                                        <h5 className="font-size-16 mb-1 text-truncate">{activeUserData.clientName ? activeUserData.clientName : activeUserData.clientSessionResponse ? activeUserData.clientSessionResponse.clientName : ""}</h5>
                                        {
                                            activeUserData.email ?
                                                <small className='d-block mt-2 word-break-break-word'>{activeUserData.email}</small>
                                                : null
                                        }
                                    </Auxiliary>
                                    : null
                            }

                        </div>
                    </div>
                </div>

                <div className={`${supporterId && !activeUserData.isSessionAttachedMe ? "disabled_04" : ""}`}>
                    {
                        activeUserData &&
                            activeUserData.isOnline && !activeUserData.endTime &&
                            (!supporterId || (supporterId && (user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY || user.userTypeId === PARTNER_USER_TYPE_ID_KEY)) && activeUserData.isSessionAttachedMe) &&
                            !pathname.includes("/inbox") ?
                            <div className={`border-bottom p-3 ${!activeGroupName || isDisabledFields ? "disabled" : ""}`}>
                                <ul className='list-inline user-chat-nav text-end mb-0 text-center'>
                                    {
                                        MainService.checkServicePermission(subscriptionService, "audio_call_service") ?
                                            <li className="list-inline-item" title='audio call'>
                                                {/* audio_call_service */}
                                                <button
                                                    type="button"
                                                    title='audio call'
                                                    className="btn nav-btn"
                                                    disabled={isCallInProcess ? true : false}
                                                    onClick={() => {
                                                        setCallId(2);
                                                        localStorage.setItem("callId", 2);
                                                        localStorage.setItem("activeCallId", activeUserData.clientSessionKey);
                                                    }}
                                                >
                                                    <i className="ri-phone-line font-size-22"></i>
                                                </button>
                                            </li>
                                            : null
                                    }
                                    {
                                        MainService.checkServicePermission(subscriptionService, "video_call_service") ?
                                            <li className="list-inline-item" title='video call'>
                                                {/*  */}
                                                <button
                                                    type="button"
                                                    title='video call'
                                                    className="btn nav-btn"
                                                    disabled={isCallInProcess ? true : false}
                                                    onClick={() => {
                                                        setCallId(1);
                                                        localStorage.setItem("callId", 1);
                                                        localStorage.setItem("activeCallId", activeUserData.clientSessionKey);
                                                    }}
                                                >
                                                    <i className="ri-vidicon-line font-size-22"></i>
                                                </button>
                                            </li>
                                            : null
                                    }
                                    {
                                        MainService.checkServicePermission(subscriptionService, "screen_share_service") ?
                                            <li className="list-inline-item">
                                                {/* screen_capture_service */}
                                                <button
                                                    type="button"
                                                    title='screen share'
                                                    className="btn nav-btn"
                                                    disabled={isCallInProcess ? true : false}
                                                    onClick={() => {
                                                        setCallId(3);
                                                        localStorage.setItem("callId", 3);
                                                        localStorage.setItem("activeCallId", activeUserData.clientSessionKey);
                                                    }}
                                                >
                                                    <i className="ri-share-box-line font-size-22"></i>
                                                </button>
                                            </li>
                                            : null
                                    }
                                </ul>
                            </div>
                            : null
                    }

                    {
                        supporterId && activeUserData.isSessionAttachedMe ?
                            <div className={`border-bottom p-3 ${!activeGroupName || localStorage.getItem("isCallInProcess") ? "disabled" : ""}`}>
                                <ActionButton
                                    type="button"
                                    name={translationService.translate("TR_LEAVE_CHAT")}
                                    className="vnd-btn vnd-danger-btn vnd-small-btn w-100"
                                    spinnerId={leaveChatSpinnerId}
                                    onClick={leaveChat}
                                />
                            </div>
                            : null
                    }
                </div>

            </div>


            <SimpleBar
                id='rightMenuScrollBar'
                className={`p-3 user-profile-desc mb-3 ${supporterId && !activeUserData.isSessionAttachedMe ? "disabled_04" : ""}`}
                style={{ maxHeight: scrollBarHeight }}
            >
                <div id="profile-user-accordion" className="custom-accordion">
                    {
                        activeUserData.messageUrl ?
                            <>
                                <label className="mb-1 form-label capitalize">{translationService.translate("TR_MESSAGE_URL")}</label>
                                <div className="vnd-input-button mb-2">
                                    <span className="vnd-text-input script-tag">{activeUserData.messageUrl}</span>
                                    <button
                                        className={`vnd-btn vnd-outline-secondary-btn vnd-small-btn ${isShowCopiedMessage ? "copied" : ""}`}
                                        onClick={copyScript}
                                        disabled={isShowCopiedMessage ? true : false}
                                    >
                                        {`${isShowCopiedMessage ? `${translationService.translate("TR_COPIED")} !` : ''}`}
                                        <i className="ri-file-copy-line"></i>
                                    </button>
                                </div>
                            </>
                            : null
                    }
                    <Note />
                    {
                        pathname.includes("/dashboard/chats/my-clients") ?
                            <Scripts
                                activeCollapseId={activeCollapseId}
                                setActiveCollapseId={setActiveCollapseId}
                            />
                            : null
                    }
                    {
                        MainService.checkServicePermission(subscriptionService, "chat_transfer_service") && !supporterId ?
                            defaultTechnicalAssistants && defaultTechnicalAssistants.length && activeSessionName !== "inbox" ?
                                !activeGroupName || activeUserData.endTime ? null :
                                    <Card style={{ maxHeight: "400px", overflow: "hidden" }} className={`mb-0 ${localStorage.getItem("isCallInProcess") ? "disabled" : ""}`}>
                                        <CustomCollapse
                                            title={selectedTechnicalAssistant ? selectedTechnicalAssistant.fullName : translationService.translate("TR_TECHNICAL_ASSISTANTS")}
                                            name={selectedTechnicalAssistant && selectedTechnicalAssistant.fullName ? selectedTechnicalAssistant.fullName : null}
                                            avatarPath={selectedTechnicalAssistant && selectedTechnicalAssistant.avatarPath ? selectedTechnicalAssistant.avatarPath : null}
                                            iconClass="ri-attachment-line"
                                            isOpen={activeCollapseId === 1}
                                            toggleCollapse={() => setActiveCollapseId(activeCollapseId === 1 ? null : 1)}
                                        >
                                            <ul className="list-unstyled chat-list chat-user-list" id="chat-list">
                                                <InputGroup size="lg" className=" rounded-lg">
                                                    <CustomInput
                                                        id="searchText"
                                                        htmlFor="searchText"
                                                        type="text"
                                                        name="searchText"
                                                        blockClassName="w-100 mb-0"
                                                        placeholder={`${translationService.translate("TR_SEARCH")} ...`}
                                                        value={searchText}
                                                        onChange={(e) => search(e, setSearchText, fieldLengths.maxLength_100)}
                                                    />
                                                </InputGroup>
                                                {
                                                    technicalAssistants.map((technicalAssistant, key) =>
                                                        <li
                                                            key={key}
                                                            id={"conversation_" + key}
                                                            className={`cl-chat-wrappers border mt-2`}
                                                        >
                                                            <Link
                                                                to={`#`}
                                                                onClick={() => onSelectTechnicalAssistant(technicalAssistant)}
                                                                className={`cl-chat-wrapper-item ${isDisabledFfields || isDisabledFields ? "disabled" : ""}`}
                                                            >
                                                                <div className="d-flex align-items-center">
                                                                    <div className={"chat-user-img " + `${technicalAssistant.isOnline ? "online" : "away"}` + " align-self-center me-3 ms-0"}>
                                                                        <div className="avatar-xs">
                                                                            {
                                                                                technicalAssistant.avatarPath ?
                                                                                    <img src={technicalAssistant.avatarPath} className="rounded-circle avatar-xs" alt="/" />
                                                                                    : <span className="avatar-title rounded-circle bg-soft-primary vnd--text-primary">
                                                                                        <i className="ri-user-3-line"></i>
                                                                                    </span>
                                                                            }

                                                                        </div>
                                                                        <span className="user-status"></span>
                                                                    </div>

                                                                    <div className="flex-1 overflow-hidden menu-item-wrapper">
                                                                        <h5 className="text-truncate font-size-15 m-0">{technicalAssistant.fullName}</h5>
                                                                        <small>{translationService.translate(technicalAssistant.userTypeName) ? translationService.translate(technicalAssistant.userTypeName) : technicalAssistant.userTypeName}</small>
                                                                    </div>
                                                                    {
                                                                        technicalAssistant.activeSessionsCount ?
                                                                            <div className="unread-message" title='Active sessions count'>
                                                                                <span className="badge badge-soft-danger rounded-pill">{technicalAssistant.activeSessionsCount}</span>
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </Link>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </CustomCollapse>
                                    </Card>
                                : null
                            : null
                    }

                    {
                        activeUserData && activeUserData.scriptAttributeData && MainService.isJson(activeUserData.scriptAttributeData) ?
                            <>
                                <Card style={{ maxHeight: "600px", overflowY: "hidden", overflowX: "auto" }} className="mb-0">
                                    <CustomCollapse
                                        title={translationService.translate("TR_ADDITIONAL_INFO")}
                                        iconClass="ri-attachment-line"
                                        isOpen={activeCollapseId === 2}
                                        toggleCollapse={() => setActiveCollapseId(activeCollapseId === 2 ? null : 2)}
                                    >
                                        {setScriptAttributeData(JSON.parse(activeUserData.scriptAttributeData))}
                                    </CustomCollapse>
                                </Card>
                            </>
                            : null
                    }

                    {
                        files && files.length ?
                            <Card className="mb-0 shadow-none border">
                                {/* attached files */}
                                <CustomCollapse
                                    title={translationService.translate("TR_ATTACHED_FILES")}
                                    iconClass="ri-attachment-line"
                                    isOpen={activeCollapseId === 3}
                                    toggleCollapse={() => setActiveCollapseId(activeCollapseId === 3 ? null : 3)}
                                >
                                    <AttachedFiles files={files} />
                                </CustomCollapse>
                            </Card>
                            : null
                    }
                    {
                        MainService.checkServicePermission(subscriptionService, "ticket_managment_service") ?
                            activeUserData.tickets && activeUserData.tickets.length ?
                                <Card className="shadow-none border mb-0">
                                    <CustomCollapse
                                        tomCollapse
                                        title={translationService.translate("TR_TICKETS")}
                                        iconClass="ri-user-2-line"
                                        isOpen={activeCollapseId === 4}
                                        toggleCollapse={() => setActiveCollapseId(activeCollapseId === 4 ? null : 4)}
                                    >
                                        {
                                            activeUserData.tickets.map(ticket => {
                                                return <div key={ticket.id} className='p-2 border mb-0 card cursor-pointer' onClick={() => {
                                                    setCurrentTicket(ticket);
                                                    setIsShowTicketModal(true);
                                                }}>
                                                    <h5 className="font-size-16 mb-1 max-line-1" title={ticket.title}>{ticket.title}</h5>
                                                    <small className='text-muted mb-0 max-line-2' title={ticket.description}>{ticket.description}</small>
                                                    <small className="font-size-12 my-1 max-line-1 text-right">{moment(ticket.createDate).format("L")}</small>
                                                </div>
                                            })
                                        }
                                    </CustomCollapse>
                                </Card>
                                : null
                            : null
                    }

                    {
                        MainService.checkServicePermission(subscriptionService, "ticket_managment_service") ?
                            !supporterId || (supporterId && user.userTypeId !== SUPPORTER_USER_TYPE_ID_KEY) ?
                                ticketStatusResponse &&
                                    ticketStatusResponse.statuses &&
                                    ticketStatusResponse.statuses.length &&
                                    ticketUrgentResponse &&
                                    ticketUrgentResponse.urgents &&
                                    ticketUrgentResponse.urgents.length &&
                                    activeUserData && !activeUserData.endTime &&
                                    !isDisabledFields ?
                                    <div className={`${!activeGroupName ? "disabled" : ""}`}>
                                        <button
                                            color="primary"
                                            className='vnd-primary-btn vnd-btn vnd-small-btn d-flex align-items-center justify-content-center w-100 mt-3'
                                            onClick={() => setIsShowTicketModal(true)}
                                        >
                                            <i className="ri-add-line mr-2"></i>
                                            {translationService.translate("TR_ADD_TICKET")}
                                        </button>
                                    </div>
                                    : null
                                : null
                            : null
                    }

                </div>
            </SimpleBar>

        </div>
    </Auxiliary> : null
}

export default withRouter(UserProfileSidebar)

