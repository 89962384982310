import { ERROR_KEY, INFO_KEY, OBJECT_KEY, SUCCESS_KEY, WARNING_KEY } from "../Constants/MainKeys";
import { confirmAlert } from 'react-confirm-alert';
import warningSvg from "../assets/icons/warning.svg";
import ApiService from './ApiService';
import { toast } from 'react-toastify';


const getAlertType = (type, cb, error) => {
  if (!type) { cb.error(error) };
  switch (type) {
    case 'info':
      return cb.info(error, { position: toast.POSITION.TOP_RIGHT });
    case 'success':
      return cb.success(error, { position: toast.POSITION.TOP_RIGHT });
    case 'warning':
      return cb.warning(error, { position: toast.POSITION.TOP_RIGHT });
    case 'error':
      return cb.error(error, { position: toast.POSITION.TOP_RIGHT });
    default:
      break;
  }
}

export default class AlertService {

  static checkMessageType = (respcode) => {
    switch (respcode) {
      case 0:
        return SUCCESS_KEY
      case 1:
        return ERROR_KEY
      case 2:
        return WARNING_KEY
      case 3:
        return INFO_KEY
      default:
        return ERROR_KEY
    }
  };

  static getTransaltionByKey = (key, error) => {
    ApiService.getTransaltionByKey(key).then(response => {
      let errorMessage = null;
      if (response && response.data && response.data.translation1) {
        errorMessage = response.data.translation1;
      } else {
        errorMessage = "error";
      }
      this.alert(this.checkMessageType(error.respcode), errorMessage);
    }).catch(error => this.alert(ERROR_KEY, error));
  }

  static alert = (type, error) => {
    const respMessage = typeof error === OBJECT_KEY ? error.message || error.respmess : error;
    if (!respMessage) { return false; }
    if (respMessage && respMessage.slice(0, 3) === "TR_") {
      this.getTransaltionByKey(respMessage, error);
      return false;
    }
    getAlertType(type, toast, respMessage);
  }

  // static alert = (type, error) => {
  //   const respMessage = typeof error === OBJECT_KEY ? error.message || error.respmess : error;
  //   if (!respMessage) { return false; }
  //   if (respMessage && respMessage.slice(0, 3) === "TR_") {
  //     this.getTransaltionByKey(respMessage, error);
  //     return false;
  //   }
  //   var alertBlockWithCloseButton = document.createElement("div");
  //   alertBlockWithCloseButton.className = "vnd-alert-block";
  //   var containerElement = document.createElement("div");
  //   containerElement.className = "mx-3 alert-block-wrapper";
  //   var icon = document.createElement("img");
  //   var closeIcon = document.createElement("img")
  //   closeIcon.className = 'close-icon';
  //   closeIcon.src = closeSvg;
  //   var alertMessage = document.createElement("p");
  //   alertMessage.className = "alertText";
  //   closeIcon.onclick = () => {
  //     AlertService.removeAlertBlock(alertBlockWithCloseButton);
  //   }
  //   if (type) {
  //     switch (type) {
  //       case 'info':
  //         alertBlockWithCloseButton.className = "vnd-alert-block";
  //         icon.src = infoSvg;
  //         break;
  //       case 'success':
  //         alertBlockWithCloseButton.className = "vnd-alert-block";
  //         icon.src = successSvg;
  //         break;
  //       case 'warning':
  //         alertBlockWithCloseButton.className = "vnd-alert-block";
  //         icon.src = warningSvg;
  //         break;
  //       case 'error':
  //         alertBlockWithCloseButton.className = "vnd-alert-block";
  //         icon.src = errorSvg;
  //         break;
  //       default:
  //         break
  //     }
  //   } else {
  //     alertBlockWithCloseButton.className = "vnd-alert-block";
  //     icon.src = errorSvg;
  //   }
  //   alertMessage.innerHTML = respMessage;
  //   containerElement.append(icon);
  //   containerElement.append(alertMessage);
  //   containerElement.append(closeIcon);
  //   alertBlockWithCloseButton.append(containerElement);
  //   document.getElementById("vnd-alert-container") && document.getElementById("vnd-alert-container").append(alertBlockWithCloseButton);

  //   setTimeout(() => {
  //     AlertService.removeAlertBlock(alertBlockWithCloseButton)
  //   }, 5000);
  // }

  static removeAlertBlock = (element) => {
    element && element.remove();
  }

  static alertConfirm = (title, message, yes, no) => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="vnd-modal-window">
              <div className="vnd-modal-window-wrapper">
                <div className="vnd-modal">
                  <div className="d-flex align-items-start">
                    <img src={warningSvg} alt="/" />
                    <div className="d-block">
                      {
                        title ?
                          <strong className="vnd--upp-text-20">{title}</strong>
                          : null
                      }
                      {
                        message ?
                          <p className="mt-3">{message}</p>
                          : null
                      }
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <button className="vnd-outline-btn vnd-small-btn m-1 flex-1 vnd-danger-outline-btn" onClick={() => { reject(); onClose(); }}>{no}</button>
                    <button className="vnd-btn mr-2 vnd-small-btn m-1 flex-1 vnd-success-btn" onClick={() => { resolve(); onClose(); }}>{yes}</button>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })
    })
  }

}