import React from 'react';
import { useSelector } from 'react-redux';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import DefaultButtonSpinner from '../Spinners/DefaultButtonSpinner';

const ActionButton = (props) => {
  const buttonSpinners = useSelector(state => state.spinner.buttonSpinners);
  const currentSpinner = buttonSpinners.find(spinner => spinner === props.spinnerId);
  return <button
    type={props.type}
    color={props.color}
    disabled={currentSpinner || props.disabled}
    className={`d-flex justify-content-center ${props.className}`}
    onClick={props.onClick}
  >
    {
      currentSpinner ?
        <Auxiliary>
          <DefaultButtonSpinner
            spinner={currentSpinner}
            spinnerColor={props.spinnerColor ? props.spinnerColor : "#fff"}
          />
        </Auxiliary> :
        props.name ? props.name : props.children
    }
  </button>
};

export default ActionButton;  