import { SET_GLOBAL_CONNECTION, SET_CLIENT_SESSION_ID, SET_RTC_GLOBAL_CONNECTION } from "../../Constants/ReduxKeys";

let initialState = {
  globalConnectionWithRedux: null,
  clientSessionKey: null,
  rtcGlobalConnection: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENT_SESSION_ID:
      return {
        ...state,
        clientSessionKey: action.payload,
      };
    case SET_GLOBAL_CONNECTION:
      return {
        ...state,
        globalConnectionWithRedux: action.payload,
      };
    case SET_RTC_GLOBAL_CONNECTION:
      return {
        ...state,
        rtcGlobalConnection: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default reducer;