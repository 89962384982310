import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Chats from '../../Pages/AgentPages/dashboard/Tabs/Chats';
import Groups from '../../Pages/AgentPages/dashboard/Tabs/Groups';
import TechnicalAssistants from '../../Pages/AgentPages/dashboard/Tabs/TechnicalAssistants';
import {
  DEFAULT_LANGUAGE_KEY,
  PARTNER_USER_TYPE_ID_KEY,
  SUPERVISOR_USER_TYPE_ID_KEY,
  SUPPORTER_USER_TYPE_ID_KEY
} from '../../Constants/MainKeys';
import Tickets from '../../Pages/AgentPages/dashboard/Tabs/Tickets';
import Settings from '../../Pages/AgentPages/dashboard/Tabs/Settings';
import ScriptGroups from '../../Pages/AgentPages/dashboard/Tabs/Scripts/Groups';
import Scripts from '../../Pages/AgentPages/dashboard/Tabs/Scripts/Scripts';
import Help from '../../Pages/GeneralPages/Help/Help';

export default function SupporterRouting() {

  const user = useSelector(state => state.user.user);
  const language = useSelector(state => state.language.language);

  return <Switch>
    {
      user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY ?
        <Route path="/:language/dashboard/groups" exact component={Groups} />
        : null
    }
    {
      user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY ?
        <Route path="/:language/dashboard/settings" exact component={Settings} />
        : null
    }
    <Route path="/:language/dashboard/technical-assistants" exact component={TechnicalAssistants} />
    <Route path="/:language/dashboard/tickets" exact component={Tickets} />
    <Route path="/:language/dashboard/chats/:session" exact component={Chats} />
    <Route path="/:language/dashboard/chats/:session/:clientSessionKey" exact component={Chats} />
    <Route path="/:language/dashboard/chats/:session/technical-assistant/:supporterId" exact component={Chats} />

    <Route path="/:language/dashboard/script-groups" exact component={ScriptGroups} />
    <Route path="/:language/dashboard/script-groups/:groupId" exact component={Scripts} />
    <Route path="/:language/dashboard/help" exact component={Help} />
    <Redirect
      to={
        `${user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY ?
          `/${language ? language : DEFAULT_LANGUAGE_KEY}/dashboard/groups` :
          user.userTypeId === PARTNER_USER_TYPE_ID_KEY ?
            `/${language ? language : DEFAULT_LANGUAGE_KEY}/dashboard/chats/inbox` :
            `/${language ? language : DEFAULT_LANGUAGE_KEY}`
        }`
      }
    />
  </Switch>
}
