import React from 'react';
import { Collapse, CardBody, CardHeader } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

function CustomCollapse(props) {

    const { isOpen, toggleCollapse } = props;

    {/* {technicalAssistant.fullName ? technicalAssistant.fullName.charAt(0).toUpperCase() : ""} */ }
    return (
        <React.Fragment>
            <Link to={"#"} onClick={toggleCollapse} className="text-dark" >
                <CardHeader id="profile-user-headingOne">
                    <div className={props.name || props.avatarPath ? "d-flex align-items-center" : ""}>
                        {
                            props.avatarPath ?
                                <img src={props.avatarPath} className="rounded-circle avatar-xs mr-2" alt="/" />
                                : props.name ?
                                    <span className="avatar-title rounded-circle bg-soft-primary vnd--text-primary mr-2" style={{ width: "40px", height: "35px" }}>
                                        {props.name.charAt(0).toUpperCase()}
                                    </span> : null
                        }
                        <small className="font-size-14 m-0 w-100 d-flex align-items-center justify-content-between word-break-break-word max-line-1" title={props.title}>
                            {/* {
                                props.iconClass && <i className={props.iconClass + " me-2 align-middle d-inline-block"}></i>
                            } */}
                            {props.title}
                            <i className={isOpen ? "mdi mdi-chevron-up float-end accor-plus-icon" : "mdi mdi-chevron-right float-end accor-plus-icon"}></i>
                        </small>

                    </div>
                </CardHeader>
            </Link>

            <Collapse isOpen={isOpen}>
                <CardBody
                    className={`border ${props.collapseClassName ? props.collapseClassName : ""} p-3`}
                >
                    {props.children}
                </CardBody>
            </Collapse>
        </React.Fragment>
    );
}

export default withRouter(CustomCollapse);