import React from 'react'

export default function MissedScreenShareSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 256 256">
      <title>miss-call</title>
      <circle style={{ fill: "#cf000f" }} cx="128" cy="128" r="119.5" />
      <rect style={{ fill: "none" }} width="256" height="256" />
      <path style={{ fill: "#fff" }} d="M188.33,160.91H171.87a11,11,0,0,0,11-11V95.09a11,11,0,0,0-11-11H84.13a11,11,0,0,0-11,11v54.85a11,11,0,0,0,11,11H67.67a5.48,5.48,0,0,0,0,11H188.33a5.48,5.48,0,0,0,0-11Zm-40.67-25a5.3,5.3,0,0,1-7.5,7.49L128,131.25,115.84,143.4a5.3,5.3,0,0,1-7.49-7.49l12.15-12.16-12.15-12.16a5.3,5.3,0,0,1,7.49-7.5L128,116.25l12.16-12.16a5.3,5.3,0,0,1,7.5,7.5L135.5,123.75Z" /></svg>
  )
}
