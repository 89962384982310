import React, { useState } from 'react'
import AlertService from '../../../../Services/AlertService'
import { ERROR_KEY, SUCCESS_KEY, VALID_IMAGE_TYPES_KEY, WARNING_KEY } from '../../../../Constants/MainKeys'
import ApiService from '../../../../Services/ApiService'
import { useDispatch, useSelector } from 'react-redux'
import MainService from '../../../../Services/mainService'
import { AiOutlineClose } from "react-icons/ai"
import { addButtonSpinner, removeButtonSpinner } from '../../../../Store/Actions/spinner'
import { useCallback } from 'react'
import uuid from 'react-uuid'
import ActionButton from '../../../../Components/Buttons/ActionButton'

const buttonSpinnerId = uuid();
export default function CompanyLogo({
  id,
  companyLogoPath,
  setCompanyLogoPath,
  translationService
}) {

  const maxFilesSize = 3; //MB
  const mainService = new MainService();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [uploadedCompanyLogo, setUploadedCompanyLogo] = useState(null);
  const [isUploadedSuccessfully, setIsUploadedSuccessfully] = useState(false);

  const uploadFile = event => {
    if (!event.target.files[0]) { return; }
    const file = event.target.files[0];
    if (file) {
      if (file.size >= maxFilesSize * 1024 * 1024) {
        AlertService.alert(WARNING_KEY, translationService.translate("TR_WIDGET_IMAGE_SIZE_VALIDATION"));
        return false;
      }
      mainService.readFile(file, VALID_IMAGE_TYPES_KEY).then(uploadedImage => {
        setUploadedCompanyLogo(uploadedImage);
        setCompanyLogo(file);
      }).catch(error => {
        error && AlertService.alert(ERROR_KEY, translationService.translate("TR_INVALID_FILE_EXC"));
        setUploadedCompanyLogo(null);
      });
    };
  }

  const updateCompanyLogo = () => {
    if (!companyLogo) return false;
    const formData = new FormData();
    formData.append("id", id);
    formData.append("companyLogo", companyLogo);
    setButtonSpinner(buttonSpinnerId);
    ApiService.updateCompanyLogo(formData).then(response => {
      setIsUploadedSuccessfully(true);
      extractButtonSpinner(buttonSpinnerId);
      AlertService.alert(SUCCESS_KEY, `${translationService.translate("TR_DATA")} ${translationService.translate("TR_SAVED")}`)
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const removeUploadedLogo = () => {
    if (uploadedCompanyLogo) {
      setUploadedCompanyLogo(null);
      setCompanyLogo(null);
    }
  }

  const removeLogo = () => {
    if (!companyLogoPath && !isUploadedSuccessfully) return false;
    AlertService.alertConfirm(
      `${translationService.translate("TR_ARE_YOU_SURE")} ?`,
      "",
      translationService.translate("TR_YES"),
      translationService.translate("TR_NO")
    ).then(() => {
      setButtonSpinner(buttonSpinnerId);
      ApiService.removeCompanyLogo(id).then(() => {
        setCompanyLogoPath(null);
        setUploadedCompanyLogo(null);
        setIsUploadedSuccessfully(false);
        AlertService.alert(SUCCESS_KEY, `${translationService.translate("TR_DATA")} ${translationService.translate("TR_SAVED")}`)
        extractButtonSpinner(buttonSpinnerId);
      }).catch(error => getFail(error, buttonSpinnerId))
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);


  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  return (
    <div>
      <div className='d-flex justify-content-between align-items-end'>
        {
          uploadedCompanyLogo && !isUploadedSuccessfully ?
            <div>
              <label className="form-control-label capitalize font-weight-bold mb-2">{translationService.translate("TR_COMPANY_LOGO")}</label>
              <ActionButton
                type="button"
                name={translationService.translate("TR_SAVE")}
                className="vnd-primary-btn vnd-btn vnd-small-btn"
                spinnerId={buttonSpinnerId}
                onClick={updateCompanyLogo}
              />
            </div>
            : null
        }
        {
          !uploadedCompanyLogo && !companyLogoPath && !isUploadedSuccessfully ?
            <div>
              <label className="form-control-label capitalize font-weight-bold mb-2">{translationService.translate("TR_COMPANY_LOGO")}</label>
              <div className="vnd--upload-image-btn">
                <button type='button' className="vnd-primary-btn vnd-btn vnd-small-btn" onClick={MainService.triggerUploadClick}>
                  {translationService.translate("TR_UPLOAD_LOGO")}
                </button>
                <input type="file" id='avatarImage' className="d-none" hidden onChange={uploadFile} />
              </div>
            </div>
            : null
        }
        {
          companyLogoPath || isUploadedSuccessfully ?
            <div className="vnd--upload-image-btn flex-column align-items-start">
              <label className="form-control-label capitalize font-weight-bold mb-2">{translationService.translate("TR_COMPANY_LOGO")}</label>
              <ActionButton
                type="button"
                name={translationService.translate("TR_REMOVE_LOGO")}
                className="vnd-danger-btn vnd-btn vnd-small-btn"
                spinnerId={buttonSpinnerId}
                onClick={removeLogo}
              />
            </div>
            : null
        }
        {
          uploadedCompanyLogo || companyLogoPath ?
            <div className=' avatar-lg position-relative bg-white p-1 border rounded-3'>
              <div
                className="vnd-bkg-contain-img h-100 w-100 rounded-1"
                style={{ backgroundImage: `url(${uploadedCompanyLogo ? uploadedCompanyLogo : companyLogoPath ? companyLogoPath : null})` }}
              />
              {
                uploadedCompanyLogo && !isUploadedSuccessfully ?
                  <div className='position-absolute cursor-pointer' style={{ top: 2, left: 4 }} onClick={removeUploadedLogo}>
                    <AiOutlineClose fontSize={16} />
                  </div>
                  : null
              }
            </div>
            : null
        }
      </div>
    </div>
  )
}
