import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { DEFAULT_LANGUAGE_KEY } from '../../Constants/MainKeys';
import Profile from '../../Pages/AuthUserPages/Profile/Profile';
import WidgetSettings from '../../Pages/AuthUserPages/WidgetSettings/WidgetSettings';
import TechnicalAssistants from '../../Pages/AuthUserPages/TechnicalAssistants/TechnicalAssistants';
import Invitations from '../../Pages/AuthUserPages/Invitations/Invitations';
import Finances from '../../Pages/AuthUserPages/Finances/Finances';
import Subscriptions from '../../Pages/AuthUserPages/Subscriptions/Subscriptions';
import Settings from '../../Pages/AuthUserPages/Settings/Settings';
import Channels from '../../Pages/AuthUserPages/Channels/Channels';
import TechnicalAssistantForm from '../../Pages/AuthUserPages/TechnicalAssistants/TechnicalAssistantForm';
import TechnicalAssistantView from '../../Pages/AuthUserPages/TechnicalAssistants/TechnicalAssistantView';
import Help from '../../Pages/GeneralPages/Help/Help';
import PrivacyPolicy from '../../Pages/UnAuthUserPages/Resources/PrivacyPolicy';
import TermsAndConditions from '../../Pages/UnAuthUserPages/Resources/TermsAndConditions';
import CookiesPolicy from '../../Pages/UnAuthUserPages/Resources/CookiesPolicy';

export default function PartnerRouting() {
  const language = useSelector(state => state.language.language);

  return <Switch>
    <Route path="/:language/profile" exact component={Profile} />
    <Route path="/:language/widget-settings" exact component={WidgetSettings} />
    <Route path="/:language/technical-assistant/:suppoterId" exact component={TechnicalAssistantView} />
    <Route path="/:language/technical-assistants" exact component={TechnicalAssistants} />
    <Route path="/:language/technical-assistants/form" exact component={TechnicalAssistantForm} />
    <Route path="/:language/invitations" exact component={Invitations} />
    <Route path="/:language/finances" exact component={Finances} />
    <Route path="/:language/subscriptions" exact component={Subscriptions} />
    <Route path="/:language/subscriptions/stripesuccess/returnurl" exact component={Subscriptions} />
    <Route path="/:language/subscriptions/stripecancel/returnurl" exact component={Subscriptions} />
    <Route path="/:language/subscriptions/paypalsuccess/returnurl" exact component={Subscriptions} />
    <Route path="/:language/subscriptions/paypalcancel/returnurl" exact component={Subscriptions} />
    <Route path="/:language/settings" exact component={Settings} />
    <Route path="/:language/channels" exact component={Channels} />
    <Route path="/:language/help" exact component={Help} />
    <Route path="/:language/privacy-policy" exact component={PrivacyPolicy} />
    <Route path="/:language/cookies-policy" exact component={CookiesPolicy} />
    <Route path="/:language/terms-and-conditions" exact component={TermsAndConditions} />

    <Redirect to={`/${language ? language : DEFAULT_LANGUAGE_KEY}/widget-settings`} />
  </Switch>
}
