import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import uuid from 'react-uuid'
import { addButtonSpinner, removeButtonSpinner, addSpinner, removeSpinner } from '../../../Store/Actions/spinner'
import ApiService from '../../../Services/ApiService'
import { updateUserData } from '../../../Store/Actions/user'
import AlertService from '../../../Services/AlertService'
import { ERROR_KEY } from '../../../Constants/MainKeys'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'
import TranslationService from '../../../Services/translationService'
import Carousel from "react-multi-carousel"
import StepFooter from './Components/StepFooter'
import cryllex from '../../../assets/icons/site/partners/crillex.svg'
import frantixmedia from '../../../assets/icons/site/partners/frantixmedia.svg'
import mindalay from '../../../assets/icons/site/partners/mindalay.svg'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const spinnerId = uuid();
const BecomeApartnerStep5 = (props) => {

  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);

  const [translationService, setTranslationService] = useState(null);
  const [isShowCopiedMessage, setIsShowCopiedMessage] = useState(false);
  const [script, setScript] = useState(null);

  useEffect(() => {
    getWidgetData();
  }, []);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const getWidgetData = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getWidgetDataByPageStepNumber(5).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        data.script && setScript(data.script);
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const copyScript = () => {
    const copiedText = document.querySelector(".script-tag");
    navigator.clipboard.writeText(copiedText.innerText);
    setIsShowCopiedMessage(true);
    setTimeout(() => {
      setIsShowCopiedMessage(false)
    }, 1000);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    setButtonSpinner(spinnerId);
    ApiService.becomePartnerComplete().then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        dispatch(updateUserData(data));
      }
      extractButtonSpinner(spinnerId);
      props.history.push(language);
    }).catch(error => getFail(error, spinnerId))
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
    spinnerId && extractSpinner(spinnerId);
  };

  return translationService ? <section className="top-section-steps">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className='vnd--section-title-wrapper'>
            <p className="vnd--upp-text-20">{translationService.translate("TR_INSTALLATION")}</p>
            <span className="font-size-14">{translationService.translate("TR_SCRIPT_MESSAGE")}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className='col-12'>
          <div className="vnd--m-block light-gray-background my-2">
            {
              script ?
                <Auxiliary>
                  <div className="vnd-input-button">
                    <span className="vnd-text-input script-tag">{script}</span>
                    <button
                      className={`vnd-btn vnd-secondary-btn vnd-small-btn ${isShowCopiedMessage ? "copied" : ""}`}
                      onClick={copyScript}
                      disabled={isShowCopiedMessage ? true : false}
                    >
                      {`${isShowCopiedMessage ? `${translationService.translate("TR_COPIED")} !` : ''}`}
                      <i className="ri-file-copy-line"></i>
                    </button>
                  </div>
                  <p className='font-size-14 mt-3 mb-0'>{translationService.translate("TR_WG_SCRIPT_TEXT")}</p>
                </Auxiliary>
                : null
            }
          </div>
        </div>
        <div className='col-12'>
          <hr />
          <div className='vnd--section-title-wrapper'>
            <p className="vnd--upp-text-20">{translationService.translate("TR_WE_ARE_TRUSTED")}</p>
          </div>
          <div className='vnd--m-block vnd--borderd my-2'>
            <Carousel
              showDots={true}
              arrows={false}
              autoPlay={true}
              responsive={responsive}
              infinite={true}
              className='vnd--custom-carousel dots-center vnd--carousel-wrapper'
            >
              <div className="vnd--carousel-partner-logo">
                <a href="https://cryllex.biz/" target='_blank'>
                  <img src={cryllex} alt="" />
                </a>
              </div>
              <div className="vnd--carousel-partner-logo">
                <a href="https://frantixmedia.com/" target='_blank'>
                  <img src={frantixmedia} alt="" />
                </a>
              </div>
              <div className="vnd--carousel-partner-logo" target='_blank'>
                <a href="https://mindalay.com/">
                  <img src={mindalay} alt="" />
                </a>
              </div>

            </Carousel>
          </div>
        </div>
      </div>
    </div>
    <StepFooter
      translationService={translationService}
      setpNumber="4"
      backUrl={`/${language}/become-partner/step-3`}
      actionButtonName={translationService.translate("TR_COMPLETE")}
      spinnerId={spinnerId}
      onSubmit={onSubmit}
    />
  </section> : null
}

export default withRouter(BecomeApartnerStep5)
