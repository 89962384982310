import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import Auxiliary from '../../hoc/auxiliary/auxiliary'
import TranslationService from '../../Services/translationService'

const GlobalNotificationAlert = (props) => {
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);
  const [isShowNotification, setIsShowNotification] = useState(false);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    setIsShowNotification(true);
  }, []);

  return translationService ? <Auxiliary>
    {
      isShowNotification ?
        <div className='not-connected-supoprter-block scale-up-ver-top'>
          <div>
            <p>
              {props.text ? props.text : null}
              &nbsp;
              {
                props.path && props.linkName ?
                  <Link
                    to={props.path}
                    className='text-decoration-underline'
                  >
                    {props.linkName}
                  </Link>
                  : null
              }
            </p>
          </div>
          <div onClick={() => {
            setIsShowNotification(false)
          }}>
            <i className="ri-close-line"></i>
          </div>
        </div>
        : null
    }
  </Auxiliary> : null
}

export default withRouter(GlobalNotificationAlert)