import React, { useState, useEffect } from 'react'
import TranslationService from '../../../Services/translationService';
import ApiService from '../../../Services/ApiService';
import AlertService from '../../../Services/AlertService';
import { ERROR_KEY, SUCCESS_KEY, fieldLengths } from '../../../Constants/MainKeys';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { changeCurrentUserNote } from '../../../Store/Actions/chat';
import Textarea from '../../../Components/Inputs/Textarea';

export default function Note() {

  const dispatch = useDispatch();
  const {
    activeUserData,
  } = useSelector(state => state.chat);
  const [translationService, setTranslationService] = useState(null);
  const { translations } = useSelector(state => state.translation);
  const [note, setNote] = useState("");
  const [isDisabledNote, setIsDisabledNote] = useState(false);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    setNote(activeUserData.note ? activeUserData.note : "");
  }, [activeUserData])

  const saveNote = () => {
    if (note !== activeUserData.note) {
      setIsDisabledNote(true);
      const form = {
        id: activeUserData.supportSessionId,
        note: note,
      }
      ApiService.saveNote(form).then(() => {
        setIsDisabledNote(false);
        dispatch(changeCurrentUserNote(note));
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_DATA_SAVED"))
      }).catch(error => getFail(error))
    }
  }

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    setIsDisabledNote(false);
  };

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
  }

  return (translationService ?
    activeUserData.supporterUserId ?
      <>
        <div className={`${isDisabledNote ? "disabled" : ""}`}>
          <label htmlFor='note' className="mb-1 form-label capitalize">{translationService.translate("TR_NOTE")}</label>
          <div className="vnd-input-button mb-2">
            <Textarea
              id="note"
              rows="2"
              value={note}
              blockClassName="mb-0"
              className="resize-none pr-5 note"
              placeholder={`${translationService.translate("TR_ENTER_NOTE")}...`}
              onChange={(event) => onChange(event, setNote, fieldLengths.maxLength_1000)}
            />
            <button
              className={`vnd-btn vnd-dark-btn vnd-small-btn`}
              onClick={saveNote}
            >
              <i className="ri-add-line"></i>
            </button>
          </div>
        </div>
      </>
      : null
    : null
  )
}
