import React from 'react';
import { Card } from "reactstrap";
import { API_URL_KEY } from '../../../../Constants/ApiKeys';


function FileList(props) {

    const { file, controls } = props;

    const downloadFile = (path) => {
        console.log(path, "path");
        if (!path) return false;

        window.open(path, '_blank').focus();
    }

    return (
        file ? <React.Fragment>
            <Card className="p-2 mb-0">
                <div className="d-flex align-items-center">
                    <a href={file.fileRealPath} className='d-flex align-items-center cursor-pointer' target="_blank">
                        <div className="avatar-sm me-3 ms-0">
                            <div className="avatar-title bg-soft-primary vnd--text-primary rounded font-size-20">
                                <i className="ri-file-text-fill"></i>
                            </div>
                        </div>
                        <div className="flex-1">
                            <div className="text-start">
                                {
                                    file.fileName ?
                                        <h5 className="font-size-14 mb-1">{file.fileName}</h5>
                                        : null
                                }
                                {
                                    file.fileSize ?
                                        <p className="text-muted font-size-13 mb-0">{file.fileSize}</p>
                                        : null
                                }
                            </div>
                        </div>
                    </a>
                    {
                        controls ?
                            <div className="ms-2">
                                <ul className="list-inline mb-0 font-size-20">
                                    {
                                        file.fileRealPath ?
                                            <span
                                                className={`text-muted px-1 cursor-pointer`} title="Download"
                                                onClick={() => downloadFile(file.fileRealPath)}
                                            >
                                                <i className="ri-download-2-line"></i>
                                            </span>
                                            : null
                                    }
                                    {/* <UncontrolledDropdown tag="li" className="list-inline-item">
                                        <DropdownToggle tag="a" className="dropdown-toggle text-muted">
                                            <i className="ri-more-fill"></i>
                                        </DropdownToggle>
                                        <DropdownMenu end>
                                            <DropdownItem>Share <i className="ri-share-line float-end text-muted"></i></DropdownItem>
                                            <DropdownItem>Delete <i className="ri-delete-bin-line float-end text-muted"></i></DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown> */}
                                </ul>
                            </div>
                            : null
                    }
                </div>
            </Card>
        </React.Fragment> : null
    );
}

export default FileList;