import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import uuid from 'react-uuid'
import ActionButton from '../../../Components/Buttons/ActionButton'
import ReactSelectOption from '../../../Components/SelectOption/ReactSelectOption'
import { addButtonSpinner, addSpinner, removeButtonSpinner, removeSpinner } from '../../../Store/Actions/spinner'
import AlertService from '../../../Services/AlertService'
import { ERROR_KEY } from '../../../Constants/MainKeys'
import ApiService from '../../../Services/ApiService'
import TranslationService from '../../../Services/translationService'
import CheckBoxInput from '../../../Components/Inputs/CheckBoxInput'
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import { AiOutlineInfoCircle } from 'react-icons/ai'
import StepFooter from './Components/StepFooter'

const spinnerId = uuid();
export default function BecomeApartnerStep4(props) {

  const startTime = 0;
  const endTime = 1439;

  const workingGraphic = [
    {
      id: 1,
      name: "TR_CUSTOM"
    },
    {
      id: 2,
      name: "24/7"
    },
  ];

  const dispatch = useDispatch();

  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);
  const [id, setId] = useState(null);
  const [languageId, setLanguageId] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [form, setForm] = useState({});
  const [timeZones, setTimeZones] = useState([]);
  const [timeZoneId, setTimeZoneId] = useState("");
  const [weekDaysModel, setWeekDaysModel] = useState([])
  const [workingGraphicId, setWorkingGraphicId] = useState(1);

  const changeWeekDayChecked = (event, name) => {
    const weekDaysModelCopy = [...weekDaysModel];
    const filteredWeekDaysModel = weekDaysModelCopy.filter(item => item.checked);
    if (filteredWeekDaysModel.length <= 1 && !event.target.checked) return false;
    weekDaysModelCopy.forEach(weekDaysModelCopyItem => {
      if (weekDaysModelCopyItem.name === name) {
        weekDaysModelCopyItem.checked = event.target.checked;
      }
    })
    setWeekDaysModel(weekDaysModelCopy);
  }

  useEffect(() => {
    getWidgetData();
  }, []);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const getWidgetData = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getWidgetDataByPageStepNumber(4).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        data.id && setId(data.id);
        setForm(data);
        setWorkingGraphicId(data.isFullWeek ? 2 : 1);

        if (data.languageResponseModels && data.languageResponseModels.length) {
          setLanguages(data.languageResponseModels);
          setLanguageId(data.languageId ? data.languageId : data.languageResponseModels[0].id);
        }

        if (data.timeZones && data.timeZones.length) {
          setTimeZones(data.timeZones);
          data.timeZoneId ? setTimeZoneId(data.timeZoneId) : setTimeZoneId(data.timeZones[0].id);
        }

        if (
          data.weekDays &&
          data.weekDays.widgetLibraryDatas &&
          data.weekDays.widgetLibraryDatas.length &&
          (!data.widgetScheduleResponseModels || (data.widgetScheduleResponseModels && !data.widgetScheduleResponseModels.length))
        ) {
          data.weekDays.widgetLibraryDatas.forEach(widgetLibraryData => {
            if (widgetLibraryData.name === "SATURDAY" || widgetLibraryData.name === "SUNDAY") {
              widgetLibraryData.checked = false;
              widgetLibraryData.startTime = startTime;
              widgetLibraryData.endTime = endTime;
            } else {
              widgetLibraryData.checked = true;
              widgetLibraryData.startTime = 540; //"09:00"
              widgetLibraryData.endTime = 1080; //"18:00"
            }
          })
        }

        if (
          data.widgetScheduleResponseModels &&
          data.widgetScheduleResponseModels.length &&
          data.weekDays &&
          data.weekDays.widgetLibraryDatas &&
          data.weekDays.widgetLibraryDatas.length
        ) {
          data.widgetScheduleResponseModels.forEach(item => {
            data.weekDays.widgetLibraryDatas.forEach(el => {
              if (item.weekDay.trim() === el.name.trim()) {
                el.checked = true;
                el.startTime = convertTimeToMinutes(item.startTime);
                el.endTime = convertTimeToMinutes(item.endTime);
              } else {
                el.startTime = 540; //"09:00"
                el.endTime = 1080; //"18:00"
              }
            })
          })
        }
        if (data.weekDays && data.weekDays.widgetLibraryDatas) {
          setWeekDaysModel(data.weekDays.widgetLibraryDatas);
        }
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const convertMinutesToTime = (minutes) => {
    var m = minutes % 60;
    var h = (minutes - m) / 60;
    var hhmm = (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
    return hhmm;
  }

  const convertTimeToMinutes = (hms) => {
    var time = hms.split(':');
    var minutes = (+time[0]) * 60 + (+time[1]);
    return minutes;
  }

  const changeWeekDayHours = (event, name) => {
    if (!event || !name) { return false; }
    const weekDaysModelCopy = [...weekDaysModel];
    weekDaysModelCopy.forEach(weekDaysModelCopyItem => {
      if (weekDaysModelCopyItem.name === name) {
        weekDaysModelCopyItem.startTime = event[0];
        weekDaysModelCopyItem.endTime = event[1];
      }
    })
    setWeekDaysModel(weekDaysModelCopy);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    setButtonSpinner(spinnerId);
    let widgetSchedules = [];
    weekDaysModel.forEach(weekDaysModelItem => {
      if (weekDaysModelItem.checked) {
        widgetSchedules.push({
          weekDay: weekDaysModelItem.name,
          startTime: convertMinutesToTime(weekDaysModelItem.startTime),
          endTime: convertMinutesToTime(weekDaysModelItem.endTime),
        })
      }
    })
    form.id = id;
    form.stepNumber = 4;
    form.languageId = languageId;
    form.isFullWeek = workingGraphicId === 1 ? false : true;
    form.timeZoneId = timeZoneId;
    if (widgetSchedules.length && workingGraphicId === 1) { form.widgetSchedules = widgetSchedules };

    delete form.timeZones;
    delete form.weekDays;
    delete form.languageResponseModels;
    delete form.widgetScheduleResponseModels;

    ApiService.becomePartner(form).then(() => {
      props.history.push(`/${language}/become-partner/step-5`);
      extractButtonSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId));
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
    spinnerId && extractSpinner(spinnerId);
  };

  return translationService ? <section className="top-section-steps">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="vnd--section-title-wrapper">
            <p className="vnd--upp-text-20">{translationService.translate("TR_SCHEDULE")}</p>
            <span className="font-size-14">{translationService.translate("TR_SCHEDULE_TEXT")}</span>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className="h-100">
            <div className='d-flex mb-3'>
              {
                workingGraphic.map(workingGraphic => {
                  return <div key={workingGraphic.id} className="custom-control custom-radio mx-2">
                    <input
                      id={`workingGraphic_${workingGraphic.id}`}
                      name="working_hours"
                      type="radio"
                      className="custom-control-input"
                      checked={workingGraphicId === workingGraphic.id ? true : false}
                      onChange={() => setWorkingGraphicId(workingGraphic.id)}
                    />
                    <label className="custom-control-label" htmlFor={`workingGraphic_${workingGraphic.id}`}>
                      {translationService.translate(workingGraphic.name) ? translationService.translate(workingGraphic.name) : workingGraphic.name}
                    </label>
                  </div>
                })
              }
            </div>
            {
              weekDaysModel && weekDaysModel.length && workingGraphicId === 1 ?
                <div className='week-days-block mt-2'>
                  {
                    weekDaysModel.map(weekDaysModelItem => {
                      return <div key={weekDaysModelItem.id} className='week-days-block-item border-bottom'>
                        <div>
                          <div className='row'>
                            <div className='col-md-4 order-12 order-md-0 d-flex align-items-center'>
                              <CheckBoxInput
                                id={`working_hour_graphic_${weekDaysModelItem.name}`}
                                htmlFor={`working_hour_graphic_${weekDaysModelItem.name}`}
                                labelValue={
                                  translationService.translate(`TR_${(weekDaysModelItem.displayName).toUpperCase()}`) ?
                                    translationService.translate(`TR_${(weekDaysModelItem.displayName).toUpperCase()}`) :
                                    weekDaysModelItem.displayName
                                }
                                checked={weekDaysModelItem.checked}
                                onChange={(event) => {
                                  changeWeekDayChecked(event, weekDaysModelItem.name)
                                }}
                              />
                            </div>
                            <div className={`col-md-8 order-0 order-md-12 2 d-flex align-items-end ${!weekDaysModelItem.checked ? "disabled" : ""}`}>
                              <div className='w-100 px-2'>
                                <p className='mb-1 font-default'>{convertMinutesToTime(weekDaysModelItem.startTime)} - {convertMinutesToTime(weekDaysModelItem.endTime)}</p>
                                <Slider
                                  range
                                  step={30}
                                  min={0}
                                  max={1439}
                                  allowCross={false}
                                  defaultValue={[weekDaysModelItem.startTime, weekDaysModelItem.endTime]}
                                  tipProps={{ visible: true }}
                                  onChange={(event) => changeWeekDayHours(event, weekDaysModelItem.name)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    })
                  }
                </div>
                : null
            }
          </div>
        </div>
      </div>
      <div className="row mt-4">
        {
          languages && languages.length ?
            <div className='col-12 col-md-6 my-1'>
              <ReactSelectOption
                value={languageId}
                isSearchable={true}
                selectedValue={(() => {
                  const selectedValue = { ...languages.find(data => data.id === languageId) };
                  if (selectedValue) {
                    selectedValue.label = selectedValue.name;
                    selectedValue.value = selectedValue.id;
                  }
                  return selectedValue;
                })()}
                items={languages.map(data => ({ label: data.name, value: data.id }))}
                onChange={(item) => setLanguageId(item.value)}
              />
            </div>
            : null
        }
        {
          timeZones && timeZones.length ?
            <div className='col-12 col-md-6 my-1'>
              <ReactSelectOption
                value={timeZoneId}
                selectedValue={(() => {
                  const selectedValue = { ...timeZones.find(data => data.id === timeZoneId) };
                  if (selectedValue) {
                    selectedValue.label = selectedValue.displayName;
                    selectedValue.value = selectedValue.id;
                  }
                  return selectedValue;
                })()}
                items={timeZones.map(data => ({ label: data.displayName, value: data.id }))}
                onChange={(item) => setTimeZoneId(item.value)}
              />
            </div>
            : null
        }
      </div>
    </div>
    <StepFooter
      translationService={translationService}
      setpNumber="4"
      backUrl={`/${language}/become-partner/step-3`}
      actionButtonName={translationService.translate("TR_CONTINUE")}
      spinnerId={spinnerId}
      onSubmit={onSubmit}
    />
  </section> : null
}
