import React from "react";
import WithoutHeaderFooterRouting from "../../Routing/WithoutHeaderFooterRouting/WithoutHeaderFooterRouting"
import { useSelector } from "react-redux";
import SemiPartnerHeader from "../LayoutSections/Headers/SemiPartnerHeader";

const SemiPartnerLayout = props => {

  const { spinners } = useSelector(state => state.spinner);

  return (
    <div id="main">
      <div className={`layout-wrapper ${spinners.length ? "disabled" : ""}`}>
        <SemiPartnerHeader />
        <WithoutHeaderFooterRouting />
      </div>
    </div>

  );
}

export default SemiPartnerLayout;

