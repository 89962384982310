import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ERROR_KEY, SUCCESS_KEY, fieldLengths } from '../../../../../Constants/MainKeys';
import AlertService from '../../../../../Services/AlertService';
import uuid from 'react-uuid';
import ApiService from '../../../../../Services/ApiService';
import { addSpinner, removeSpinner } from '../../../../../Store/Actions/spinner';
import NoData from '../../../../../Components/NoData/NoData';
import ReactPaginate from 'react-paginate'
import { useRef } from 'react';
import TranslationService from '../../../../../Services/translationService';
import ReactSelectOption from '../../../../../Components/SelectOption/ReactSelectOption';
import ReactCountryFlag from 'react-country-flag';
import CheckBoxInput from '../../../../../Components/Inputs/CheckBoxInput';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import Auxiliary from '../../../../../hoc/auxiliary/auxiliary';

const Groups = (props) => {

  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);
  const translations = useSelector(state => state.translation.translations);
  const partnerId = useSelector(state => state.main.partnerId);
  const { spinners } = useSelector(state => state.spinner);
  const [translationService, setTranslationService] = useState(null);
  const [name, setName] = useState("");
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [pagination, setPagination] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [groups, setGroups] = useState([]);
  const [countries, setCountries] = useState([]);
  const [existingCountries, setExistingCountries] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState(localStorage.getItem("selectedCountryId") ? +localStorage.getItem("selectedCountryId") : null);
  const [groupsByCountry, setGroupsByCountry] = useState(localStorage.getItem("groupsByCountry") ? true : false);


  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (selectedCountryId) {
      getCountries();
      getExistingCountries();
    }

    getGroups(1);

    // return () => {
    //   localStorage.removeItem("selectedCountryId");
    //   localStorage.removeItem("groupsByCountry");
    //   setSelectedCountryId(null);
    //   setGroupsByCountry(false);
    // }
  }, []);

  const handlePageClick = (event) => {
    setActivePageNumber(event.selected);
    getGroups(event.selected + 1, selectedCountryId ? selectedCountryId : null);
  }

  const getGroups = (currentPage, countryId = null) => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    let _data = {
      partnerId: user.partnerId ? user.partnerId : partnerId,
      countryId,
      prefix: "",
      pageNumber: currentPage,
      pageSize: fieldLengths.pageSize_10
    }
    ApiService.getScriptGroups(_data).then(response => {

      if (response && response.data) {
        const data = { ...response.data };
        if (data.pagedData && data.pagedData.length) {
          setGroups(data.pagedData);
        } else {
          setGroups([]);
        }
        if (data.pagination) {
          setPagination(data.pagination);
        }
      }
      extractSpinner(spinnerId)
      onFocus();
    }).catch(error => getFail(error, spinnerId));
  }

  const getCountries = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getCountries().then(response => {
      if (response && response.data && response.data.length) {
        const data = [...response.data];
        setCountries(data);
        if (!selectedCountryId) {
          setSelectedCountryId(data[0].id);
          localStorage.setItem("selectedCountryId", data[0].id);
        }
      }
      extractSpinner(spinnerId);
      onFocus();
    }).catch(error => getFail(error))
  }

  const getExistingCountries = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getExistingCountries(user.partnerId ? +user.partnerId : partnerId).then(response => {
      if (response && response.data && response.data.length) {
        const data = [...response.data];
        setExistingCountries(data);
        if (!selectedCountryId) {
          setSelectedCountryId(data[0].id);
          localStorage.setItem("selectedCountryId", data[0].id);
          getGroups(1, data[0].id);
        } else {
          getGroups(1, selectedCountryId);
        }
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error))
  }

  const onSelectCountry = (selectedItemId) => {
    if (!selectedItemId) { return false };
    setSelectedCountryId(selectedItemId);
    localStorage.setItem("selectedCountryId", selectedItemId);
    setIsInvalidSubmit(false);
    setActivePageNumber(0);
    getGroups(1, selectedItemId);
  }

  const onCheckBoxChange = (event) => {
    if (event.target.checked) {
      localStorage.setItem("groupsByCountry", 1);
      getCountries();
      getExistingCountries();
    } else {
      localStorage.removeItem("groupsByCountry");
      localStorage.removeItem("selectedCountryId");
      setSelectedCountryId(null);
      setCountries([]);
      setExistingCountries([]);
      // getGroupsWithoutCountry(1);
      getGroups(1);
      setName("");
      setCurrentGroup(null);
    }
    onFocus();
    setGroupsByCountry(event.target.checked);
  }

  const deleteGroup = (id) => {
    if (!id) { return false; }
    AlertService.alertConfirm(
      `${translationService.translate("TR_ARE_YOU_SURE")} ?`,
      "",
      translationService.translate("TR_YES"),
      translationService.translate("TR_NO")
    ).then(() => {
      const spinnerId = uuid();
      setSpinner(spinnerId);
      ApiService.deleteGroup(id).then(() => {
        if (groups.length === 1) {
          setExistingCountries(existingCountries.filter(country => country.id !== selectedCountryId))
        }
        setGroups(groups.filter(group => group.id !== id));
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_GROUP_DELETE_SUCCESS"));
        currentGroup && setCurrentGroup(null);
        extractSpinner(spinnerId);
        onFocus();
      }).catch(error => getFail(error, spinnerId));
    })
  }

  const deleteCountry = (event, id) => {
    if (!id) { return false; }
    event && event.stopPropagation();
    AlertService.alertConfirm(
      `${translationService.translate("TR_ARE_YOU_SURE")} ?`,
      "",
      translationService.translate("TR_YES"),
      translationService.translate("TR_NO")
    ).then(() => {
      const spinnerId = uuid();
      setSpinner(spinnerId);
      ApiService.deleteCountry(user.partnerId ? +user.partnerId : partnerId, id).then(() => {
        setExistingCountries(existingCountries.filter(country => country.id !== id));
        AlertService.alert(SUCCESS_KEY, "Country deleted successfully");
        if (selectedCountryId === id) {
          setGroups([]);
        }
        extractSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId));
    })
  }

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
  }

  const onFocus = () => {
    if (document.getElementById("groupName")) {
      document.getElementById("groupName").focus();
    }
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!name.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    } else {
      const spinnerId = uuid();
      setSpinner(spinnerId);
      let groupsCopy = [...groups];
      let currentGroupCopy = { ...currentGroup };
      const form = {
        name,
        partnerId: user.partnerId ? +user.partnerId : +partnerId,
      };
      if (selectedCountryId) {
        form.countryId = selectedCountryId;
      }
      if (currentGroup) {
        form.id = currentGroup.id;
      }
      (
        currentGroup ?
          ApiService.updateGroup(form) :
          ApiService.createGroup(form)
      ).then(response => {
        if (currentGroup) {
          var foundIndex = groupsCopy.findIndex(x => x.id == currentGroupCopy.id);
          currentGroupCopy.name = name;
          groupsCopy[foundIndex] = currentGroupCopy;
          setGroups(groupsCopy);
        } else {
          if (response && response.data) {
            groupsCopy.unshift(response.data);
            setGroups(groupsCopy);
          }
          if (selectedCountryId && !existingCountries.find(country => country.id === selectedCountryId) && !currentGroup) {
            getExistingCountries();
          }
        }
        setName("");
        setCurrentGroup(null);
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_DATA_SAVED"));
        extractSpinner(spinnerId);
        onFocus();
      }).catch(error => getFail(error, spinnerId))
    }
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractSpinner(spinnerId);

  };

  return user && translationService ?

    <div className='vnd-list-container'>
      <aside>
        <div className="p-4 left-bar-header">
          <h4 className="vnd--upp-text-20">{translationService.translate("TR_SCRIPT_GROUP")}</h4>
        </div>
        <hr className='m-0' />
      </aside>
      <div className="px-2 vnd--body-container">
        <div className="container-fluid">
          <div className='row'>
            <div className='col-lg-4'>
              <div className='row'>
                <div className={`col-12 mt-3`}>
                  <div className={`vnd--m-block vnd--borderd`}>
                    <div className='form-group flex-1 mx-1'>
                      <p className="vnd--upp-text-20 mb-4">{translationService.translate("TR_COUNTRIES")}</p>
                      <CheckBoxInput
                        id="groupsByCountry"
                        htmlFor="groupsByCountry"
                        blockClassName="mb-3"
                        labelValue={translationService.translate("TR_GROUPS_BY_COUNTRY")}
                        checked={groupsByCountry}
                        onChange={onCheckBoxChange}
                      />
                      {
                        countries && countries.length ?
                          <ReactSelectOption
                            value={selectedCountryId}
                            className={`vnd--select mt-1`}
                            selectedValue={(() => {
                              const selectedValue = { ...countries.find(data => data.id === selectedCountryId) };
                              if (selectedValue) {
                                selectedValue.label = selectedValue.name;
                                selectedValue.value = selectedValue.id;
                              }
                              return selectedValue;
                            })()}
                            items={countries.map(data => ({ label: data.name, value: data.id }))}
                            onChange={(item) => onSelectCountry(item.value)}
                          />
                          : null
                      }
                    </div>

                    {
                      existingCountries && existingCountries.length ?
                        <div>
                          <hr />
                          <div className='d-flex flex-wrap gap-2'>
                            {
                              existingCountries.map(country => {
                                return <div
                                  key={country.id}
                                  className="vnd-added-item-container max-h-initial cursor-pointer"
                                  onClick={() => {
                                    if (selectedCountryId && selectedCountryId === country.id) { return false; }
                                    setActivePageNumber(0);
                                    getGroups(1, country.id);
                                    setSelectedCountryId(country.id);
                                    localStorage.setItem("selectedCountryId", country.id);
                                  }}
                                >
                                  <div className={`vnd--primary-bordered-block word-break-break-word align-items-center d-flex justify-content-between px-3 ${selectedCountryId === country.id ? "active" : ""}`} >
                                    <div className='d-flex align-items-center'>
                                      <ReactCountryFlag className='mr-3' countryCode={country.isoCode2} svg />
                                      <span className="font-size-16">{country.name}</span>
                                    </div>
                                    <div className='ml-4 d-flex'>
                                      <i
                                        className="ri-delete-bin-line font-size-20 cursor-pointer content-icon d-block"
                                        onClick={(event) => deleteCountry(event, country.id)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              })
                            }

                          </div>
                        </div>
                        : null
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='row'>
                {
                  <Auxiliary>
                    <div className='col-12 mt-3'>
                      <div className='vnd--m-block light-brand-background h-100'>
                        <div className='vnd--info-block'>
                          <AiOutlineInfoCircle />
                          {
                            groupsByCountry ?
                              <p>{translationService.translate("TR_SCRIPT_GROUP_BY_COUNTRY")}</p>
                              : <p>{translationService.translate("TR_SCRIPT_INFO_TEXT")}</p>

                          }
                        </div>
                      </div>
                    </div>
                    <div className={`col-12 my-3`}>
                      <div className="vnd--m-block light-brand-background">
                        <div className="row">
                          <div className="col-12">
                            {
                              groupsByCountry ?
                                <p className="vnd--upp-text-20 mb-4">{translationService.translate("TR_GROUPS_BY_COUNTRY")}</p>
                                : <p className="vnd--upp-text-20 mb-4">{translationService.translate("TR_GROUPS")}</p>

                            }
                          </div>
                          <div className='col-12'>
                            <div className='form-group'>

                              <div className="vnd-complexe-input d-flex">
                                <input
                                  id='groupName'
                                  name="name"
                                  type="text"
                                  className={isInvalidSubmit && !name.trim().length ? "error-border" : ""}
                                  placeholder={`${translationService.translate("TR_GROUP_NAME")} *`}
                                  disabled={spinners.length}
                                  value={name}
                                  onChange={event => onChange(event, setName, fieldLengths.maxLength_250)}
                                  onKeyDown={event => {
                                    if (event.key === "Enter") {
                                      onSubmit(event);
                                    }
                                  }}
                                />
                                {
                                  !currentGroup ?
                                    <div
                                      className="vnd-complexe-btn"
                                      title={translationService.translate("TR_ADD_GROUP")}
                                      onClick={onSubmit}
                                    >
                                      <i className="ri-add-line text-white font-size-20"></i>
                                    </div>
                                    :
                                    <div>
                                      <div
                                        style={{ right: "55px" }}
                                        className="vnd-complexe-btn"
                                        title={translationService.translate("TR_UPDATE")}
                                        onClick={(event) => {
                                          onSubmit(event);
                                        }}
                                      >
                                        <i className="ri-check-line  text-white font-size-20"></i>
                                      </div>
                                      <div
                                        className="vnd-complexe-btn"
                                        title={translationService.translate("TR_CANCEL")}
                                        onClick={() => {
                                          setName("");
                                          setCurrentGroup(null);
                                        }}
                                      >
                                        <i className="ri-close-line  text-white font-size-20"></i>
                                      </div>
                                    </div>
                                }
                              </div>
                              <small className='mt-1 d-block'>{translationService.translate("TR_MAX_LENGHT")}: {fieldLengths.maxLength_250}</small>
                            </div>
                          </div>

                          <div className={`col-12`}>
                            <div className='form-group'>
                              <div className="vnd-added-item-container max-h-initial">
                                {
                                  groups.length ? groups.map(group => {
                                    return <div
                                      key={group.id}
                                      className='vnd-added-item-wrapper'
                                    >
                                      <div className={`align-items-center justify-content-between d-flex`}>
                                        <p className="font-size-16 max-line-3 word-break-break-all group-name">{group.name}</p>
                                        <div className='option-icons-wrapper ml-4 d-flex'>
                                          <i
                                            className="ri-edit-2-line font-size-20 cursor-pointer content-icon d-block"
                                            title={translationService.translate("TR_EDIT")}
                                            onClick={() => {
                                              setCurrentGroup(group);
                                              setName(group.name)
                                            }}
                                          />
                                          <i
                                            className="ri-delete-bin-line font-size-20 cursor-pointer content-icon d-block"
                                            title={translationService.translate("TR_DELETE")}
                                            onClick={() => {
                                              deleteGroup(group.id);
                                            }}
                                          />
                                          <i
                                            className="ri-add-line font-size-20 vnd--styled-icon vnd--styled-secondary-icon cursor-pointer d-block ml-2"
                                            title="Add script"
                                            onClick={() => {
                                              props.history.push(`/${language}/dashboard/script-groups/${group.id}`)
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  }) : <div className='d-flex justify-content-center w-100'>
                                    <NoData width={200} height={200} />
                                  </div>
                                }
                              </div>
                            </div>
                            {
                              pagination && groups.length && pagination.totalPages > 1 ?
                                <div className='w-100 d-flex justify-content-center mt-5'>
                                  <ReactPaginate
                                    nextLabel={translationService.translate("TR_NEXT")}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={pagination.totalPages}
                                    previousLabel={translationService.translate("TR_PREVIOUS")}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                    forcePage={activePageNumber}
                                  />
                                </div>
                                : null
                            }
                          </div>


                        </div>
                      </div>

                    </div>
                  </Auxiliary>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    : null
}

export default withRouter(Groups)