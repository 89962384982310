
import { takeLatest, call, put } from 'redux-saga/effects';
import uuid from 'react-uuid';
import ApiService from '../../Services/ApiService';
import AlertService from "../../Services/AlertService";
import { addSpinner, removeSpinner } from '../Actions/spinner';
import { ERROR_KEY } from "../../Constants/MainKeys";
import { GET_LANGUAGES_SUCCESS, GET_LANGUAGES_REQUEST } from "../../Constants/ReduxKeys";

export default function* watcher() {
  yield takeLatest(GET_LANGUAGES_REQUEST, getLanguages);
};

function* getLanguages() {
  const spinnerId = uuid();
  try {
    yield put(addSpinner(spinnerId));
    const languages = yield call(ApiService.getLanguages);
    yield put({
      type: GET_LANGUAGES_SUCCESS,
      payload: languages && languages.data,
    });
    yield put(removeSpinner(spinnerId));
  } catch (e) {
    yield put(removeSpinner(spinnerId));
    AlertService.alert(e, ERROR_KEY);
  };
};


