import React from 'react'

export default function MissedVideoCallSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 256 256">
      {/* <defs><style>.cls-1{fill:#cf000f;}.cls-2{fill:none;}.cls-3{fill:#fff;}</style></defs> */}
      <title>miss-call</title>
      <circle style={{ fill: "#cf000f" }} cx="128" cy="128" r="119.5" />
      <rect style={{ fill: "none" }} width="256" height="256" />
      <path style={{ fill: "#fff" }} d="M141.58,91H72.77a10.58,10.58,0,0,0-10.58,10.58v52.94a10.58,10.58,0,0,0,10.58,10.58h68.81a10.59,10.59,0,0,0,10.59-10.58V101.53A10.59,10.59,0,0,0,141.58,91Zm-14.74,49.21a5.3,5.3,0,0,1-7.5,7.49L107.18,135.5,95,147.65a5.3,5.3,0,0,1-7.49-7.49L99.68,128,87.53,115.84a5.3,5.3,0,0,1,7.49-7.5l12.16,12.16,12.16-12.16a5.3,5.3,0,0,1,7.5,7.5L114.68,128Z" />
      <path style={{ fill: "#fff" }} d="M162.05,112.12v31.76l23.53,15.69a5.3,5.3,0,0,0,8.23-4.4V100.83a5.3,5.3,0,0,0-8.23-4.4Zm0,0" /></svg>
  )
}
