export const GET_TRANSLATIONS_REQUEST = 'GET_TRANSLATIONS_REQUEST';
export const GET_TRANSLATIONS_SUCCESS = "GET_TRANSLATIONS_SUCCESS";
export const CURRENT_USER_UPDATE_REQUEST = 'CURRENT_USER_UPDATE_REQUEST';
export const CURRENT_USER_UPDATE_SUCCESS = 'CURRENT_USER_UPDATE_SUCCESS';
export const ADD_SPINNER = 'ADD_SPINNER';
export const REMOVE_SPINNER = 'REMOVE_SPINNER';
export const GET_LANGUAGES_REQUEST = 'GET_LANGUAGES_REQUEST';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export const LANGUAGE_SUCCESS = 'LANGUAGE_SUCCESS';
export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION';
export const ADD_BUTTON_SPINNER = 'ADD_BUTTON_SPINNER';
export const REMOVE_BUTTON_SPINNER = 'REMOVE_BUTTON_SPINNER';
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS';
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_REGISTRATION_REQUEST = 'USER_REGISTRATION_REQUEST';
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const REGISTRATION_FAIL = 'REGISTRATION_FAIL';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const CHAT_USER = "CHAT_USER";
export const ACTIVE_USER = "ACTIVE_USER";
export const SET_ACTIVE_USER_DATA = "SET_ACTIVE_USER_DATA";
export const FULL_USER = "FULL_USER";
export const ADD_LOGGED_USER = "ADD_LOGGED_USER";
export const CREATE_GROUP = "CREATE_GROUP";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const OPEN_USER_PROFILE_SIDEBAR = 'OPEN_USER_PROFILE_SIDEBAR';
export const CLOSE_USER_PROFILE_SIDEBAR = 'CLOSE_USER_PROFILE_SIDEBAR';
export const SET_CONVERSATION_NAME_IN_OPEN_CHAT = 'SET_CONVERSATION_NAME_IN_OPEN_CHAT';
export const SET_GLOBAL_CONNECTION = 'SET_GLOBAL_CONNECTION';
export const SET_RTC_GLOBAL_CONNECTION = 'SET_RTC_GLOBAL_CONNECTION';
export const SET_CLIENT_SESSION_ID = 'SET_CLIENT_SESSION_ID';
export const SET_USERS = 'SET_USERS';
export const REMOVE_LOGIN_ERROR_MESSAGE_REQUEST = 'REMOVE_LOGIN_ERROR_MESSAGE_REQUEST';
export const ADD_MESSAGES_TO_USER = 'ADD_MESSAGES_TO_USER';
export const ADD_FIELDS_TO_ACTIVE_USER_DATA_FROM_SESSION = 'ADD_FIELDS_TO_ACTIVE_USER_DATA_FROM_SESSION';
export const UPDATE_ACTIVE_USER_DATA = 'UPDATE_ACTIVE_USER_DATA';
export const ADD_MESSAGE_TO_ACTIVE_USER_MESSAGES = 'ADD_MESSAGE_TO_ACTIVE_USER_MESSAGES';
export const CHANGE_CURRENT_USER_UNREAD_MESSAGES_COUNT = 'CHANGE_CURRENT_USER_UNREAD_MESSAGES_COUNT';
export const RESET_CURRENT_USER_UNREAD_MESSAGES_COUNT = 'RESET_CURRENT_USER_UNREAD_MESSAGES_COUNT';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const ADD_NEW_USER_TO_MY_CLIENTS = 'ADD_NEW_USER_TO_MY_CLIENTS';
export const CHANGE_CURRENT_USER_LAST_MESSAGE = 'CHANGE_CURRENT_USER_LAST_MESSAGE';
export const CHANGE_CURRENT_USER_DATA = 'CHANGE_CURRENT_USER_DATA';
export const CHANGE_USERS_ONLINE_STATUS = 'CHANGE_USERS_ONLINE_STATUS';
export const CHANGE_USER_ONLINE_STATUS = 'CHANGE_USER_ONLINE_STATUS';
export const CHANGE_INBOX_TITLE_NOTIFICATION = 'CHANGE_INBOX_TITLE_NOTIFICATION';
export const CHANGE_MY_CLIENTS_TITLE_NOTIFICATION = 'CHANGE_MY_CLIENTS_TITLE_NOTIFICATION';
export const SET_ACTIVE_GROUP_NAME = 'SET_ACTIVE_GROUP_NAME';
export const SET_ACTIVE_GROUPS_NAME = 'SET_ACTIVE_GROUPS_NAME';
export const SET_PARTNER_ID = 'SET_PARTNER_ID';
export const SET_DEFAULT_USERS = 'SET_DEFAULT_USERS';
export const CHANGE_CURRENT_MESSAGE_RECEIVED_FIELD = 'CHANGE_CURRENT_MESSAGE_RECEIVED_FIELD';
export const CHANGE_CLIENT_ISTYPING = 'CHANGE_CLIENT_ISTYPING';
export const CHANGE_CALL_WAITING_STATUS = 'CHANGE_CALL_WAITING_STATUS';
export const CHANGE_GROUP_UNREAD_MESSAGES_COUNT = 'CHANGE_GROUP_UNREAD_MESSAGES_COUNT';
export const SET_ACTIVE_SESSION_NAME = 'SET_ACTIVE_SESSION_NAME';
export const SET_IS_SHOW_SETTINGS = 'SET_IS_SHOW_SETTINGS';
export const SET_IS_CONNECTION_ALLOW = 'SET_IS_CONNECTION_ALLOW';
export const SET_IS_CALL_IN_PROCESS = 'SET_IS_CALL_IN_PROCESS';
export const SET_NEW_MESSAGE_OBJ = 'SET_NEW_MESSAGE_OBJ';
export const SET_TICKET_STATUS_RESPONSE = 'SET_TICKET_STATUS_RESPONSE';
export const SET_TICKET_URGENT_RESPONSE = 'SET_TICKET_URGENT_RESPONSE';
export const CHANGE_ACTIVE_USER_TICKETS = 'CHANGE_ACTIVE_USER_TICKETS';
export const SET_TECHNICAL_ASSISTANTS = 'SET_TECHNICAL_ASSISTANTS';
export const SET_DEFAULT_TECHNICAL_ASSISTANTS = 'SET_DEFAULT_TECHNICAL_ASSISTANTS';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_DEFAULT_GROUPS = 'SET_DEFAULT_GROUPS';
export const CHANGE_TECHNICAL_ASSISTANT_ONLINE_STATUS = 'CHANGE_TECHNICAL_ASSISTANT_ONLINE_STATUS';
export const CHANGE_USER_ISTYPING = 'CHANGE_USER_ISTYPING';
export const SET_CLIENT_ACTIVE_CALL_ID = 'SET_CLIENT_ACTIVE_CALL_ID';
export const REMOVE_CLIENT_FROM_INBOX = 'REMOVE_CLIENT_FROM_INBOX';
export const REMOVE_CLIENT_FROM_CHAT_BOARD = 'REMOVE_CLIENT_FROM_CHAT_BOARD';
export const SET_CLIENT_IS_IN_ACTIVE_CALL_EXCEPTION = 'SET_CLIENT_IS_IN_ACTIVE_CALL_EXCEPTION';
export const SET_IS_SHOW_ATTACH_TO_SESSION_NOTIFICATION = 'SET_IS_SHOW_ATTACH_TO_SESSION_NOTIFICATION';
export const SET_IS_HIDE_ATTACH_TO_SESSION_NOTIFICATION = 'SET_IS_HIDE_ATTACH_TO_SESSION_NOTIFICATION';
export const CHANE_CLIENTS_ONLINE_STATUS = 'CHANE_CLIENTS_ONLINE_STATUS';
export const SET_IS_MUTED = 'SET_IS_MUTED';
export const SET_FLAG_SPINNER = 'SET_FLAG_SPINNER';
export const SET_PAGE_CONTENT = 'SET_PAGE_CONTENT';
export const GET_PAGE_CONTENT_BY_ID = 'GET_PAGE_CONTENT_BY_ID';
export const SET_PAGE_CONTENT_ID = 'SET_PAGE_CONTENT_ID';
export const SET_IS_SHOW_PAGE_CONTENT_SPINNER = 'SET_IS_SHOW_PAGE_CONTENT_SPINNER';
export const SET_SUBSCRIPTION_SERVICE = 'SET_SUBSCRIPTION_SERVICE';
export const CHANGE_CURRENT_USER_NOTE = 'CHANGE_CURRENT_USER_NOTE';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY';
export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';
export const SET_SELECTED_SCRIPT = 'SET_SELECTED_SCRIPT';
export const GET_SCRIPTS = 'GET_SCRIPTS';
export const SET_SCRIPTS = 'SET_SCRIPTS';
export const SET_SCRIPT_GROUPS = 'SET_SCRIPT_GROUPS';
export const SET_IS_DISABLED_SCRIPTS = 'SET_IS_DISABLED_SCRIPTS';
export const SET_SCRIPT_GROUPS_PAGINATION = 'SET_SCRIPT_GROUPS_PAGINATION';
export const ADD_SCRIPT_GROUPS = 'ADD_SCRIPT_GROUPS';
export const SET_SCRIPTS_PAGINATION = 'SET_SCRIPTS_PAGINATION';
export const ADD_SCRIPTS = 'ADD_SCRIPTS';
export const TOGGLE_GROUPS_BY_COUNTRY = 'TOGGLE_GROUPS_BY_COUNTRY';
export const REMOVE_USER_FIELD = 'REMOVE_USER_FIELD';
export const SORT_CLIENTS = 'SORT_CLIENTS';
export const REMOVE_CURRNET_CHAT_ANIMATION = 'REMOVE_CURRNET_CHAT_ANIMATION';
export const GET_SCRIPT_GROUPS = 'GET_SCRIPT_GROUPS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_TOKEN = 'SET_TOKEN';
