import React, { useState } from 'react';
import Auxiliary from './../../hoc/auxiliary/auxiliary';
import { BsFill0CircleFill, BsFill1CircleFill } from "react-icons/bs";
import { BiShowAlt } from "react-icons/bi";
import { GrFormViewHide } from "react-icons/gr";

export default function Input({
  id,
  type,
  blockClassName,
  labelValue,
  htmlFor,
  labelClassName,
  readOnly,
  name,
  min,
  max,
  defaultValue,
  value,
  autoComplete,
  disabled,
  required,
  onBlur,
  style,
  pattern,
  onFocus,
  placeholder,
  onKeyDown,
  className,
  inputClassName,
  onChange
}) {

  const [isShowPassword, setIsShowPassword] = useState(false);

  return (
    <Auxiliary>
      <div className={`form-group ${blockClassName ? blockClassName : ""}`}>
        {
          labelValue ?
            <label
              className={`form-control-label capitalize ${labelClassName ? labelClassName : ""}`}
              htmlFor={htmlFor ? htmlFor : ""}
            >
              {labelValue ? labelValue : ""}
            </label>
            : null
        }
        <div className='position-relative'>
          <input
            readOnly={readOnly}
            id={id}
            type={isShowPassword ? "text" : type}
            name={name}
            min={min}
            max={max}
            defaultValue={defaultValue}
            value={value}
            disabled={disabled}
            required={required}
            autoComplete={autoComplete ? autoComplete : "off"}
            onBlur={onBlur}
            style={style}
            pattern={pattern}
            onFocus={onFocus}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            className={`${type === "password" ? "pe-5" : ""} ${className ? className : inputClassName ? inputClassName : ""}`}
            onChange={onChange}
          />
          {
            type === "password" ?
              <div className='input-icon' style={{opacity :"0.5"}}>
                {
                  !isShowPassword ?
                    <span className='cursor-pointer' onClick={() => setIsShowPassword(!isShowPassword)}><GrFormViewHide fontSize={24} /></span>
                    : <span className='cursor-pointer' onClick={() => setIsShowPassword(!isShowPassword)}><BiShowAlt fontSize={24} /></span>
                }
              </div>
              : null
          }
        </div>
      </div>
    </Auxiliary>
  )
}
