import React from 'react'
import Auxiliary from '../../hoc/auxiliary/auxiliary'

export default function Textarea(props) {
  return (
    <Auxiliary>
      <div className={`form-group ${props.blockClassName ? props.blockClassName : ""}`}>
        <Auxiliary>
          {
            props.labelValue ?
              <label
                className={`form-control-label capitalize ${props.labelClassName ? props.labelClassName : ""}`}
                htmlFor={props.htmlFor ? props.htmlFor : ""}
              >
                {props.labelValue ? props.labelValue : ""}
              </label>
            :null
          }
          <textarea
            id={props.id}
            type="textarea"
            name={props.name}
            value={props.value}
            rows={props.rows}
            min={props.min}
            max={props.max}
            disabled={props.disabled}
            required={props.required}
            placeholder={props.placeholder}
            onFocus={props.onFocus}
            className={`
            ${props.className ? props.className : ""}
            ${props.isInvalidField ? "error-border" : ""}`}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            onKeyPress={props.onKeyPress}
          />
        </Auxiliary>
      </div>
    </Auxiliary>
  )
}
