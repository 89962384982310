import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveSection } from '../../../Store/Actions/main';
import { ERROR_KEY, SUCCESS_KEY, fieldLengths } from '../../../Constants/MainKeys';
import AlertService from '../../../Services/AlertService';
import uuid from 'react-uuid';
import { addButtonSpinner, removeButtonSpinner } from '../../../Store/Actions/spinner';
import Input from '../../../Components/Inputs/Input';
import MainService from '../../../Services/mainService';
import ActionButton from '../../../Components/Buttons/ActionButton';
import CloseAccountModal from '../../../Components/Modals/CloseAccountModal';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import ApiService from '../../../Services/ApiService';
import TranslationService from '../../../Services/translationService';
import { AiOutlineLeft } from "react-icons/ai";


const buttonSpinnerId = uuid();
const Subscriptions = (props) => {

  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const {translations} = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);
  const [password, setpassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [mismatchPasswords, setMismatchPasswords] = useState(false);
  const [isInvalidConfirmPassword, setIsInvalidConfirmPassword] = useState(false);
  const [isInvalidNewPassword, setIsInvalidNewPassword] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isShowCloseAccountModal, setIsShowCloseAccountModal] = useState(false);
  const [isSimilarPasswords, setIsSimilarPasswords] = useState(false);


  useEffect(() => {
    setActiveSectionName("");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const setActiveSectionName = (name) => {
    dispatch(setActiveSection(name))
  }

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
    setMismatchPasswords(false);
    setIsSimilarPasswords(false);
  }

  const checkFieldsValidation = (field, fieldName) => {
    var isValid = MainService.isValidField(field, fieldName);

    if (fieldName === "newPassword") {
      changeIsinvalidFieldName(isValid, setIsInvalidNewPassword);
    }
    if (fieldName === "confirmPassword") {
      changeIsinvalidFieldName(isValid, setIsInvalidConfirmPassword);
    }
    if (fieldName === "newPassword" || fieldName === "confirmPassword") {
      if (newPassword.trim().length && confirmPassword.trim().length && (newPassword !== confirmPassword)) {
        changeIsinvalidFieldName(false, setMismatchPasswords);
      } else {
        changeIsinvalidFieldName(true, setMismatchPasswords);
      }
    }
    switch (fieldName) {
      case "newPassword":
        changeIsinvalidFieldName(isValid, setIsInvalidNewPassword)
        break;
      case "confirmPassword":
        changeIsinvalidFieldName(isValid, setIsInvalidConfirmPassword)
        break;
      default:
        break;
    }
  }

  const showOrHideCloseAccountModal = (bool) => {
    setIsShowCloseAccountModal(bool);
  }

  const changeIsinvalidFieldName = (isValid, cb) => {
    if (!isValid) cb(true);
    else cb(false);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (!password.trim().length || !newPassword.trim().length || !confirmPassword.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    } else {
      if (password.trim() === newPassword.trim()) {
        setIsSimilarPasswords(true);
        return false;
      } else {
        if (newPassword !== confirmPassword) {
          setMismatchPasswords(true);
          return false;
        } else {
          const form = {
            oldPassword: password,
            password: btoa(newPassword),
            confirmPassword: btoa(confirmPassword),
          }
          setButtonSpinner(buttonSpinnerId);
          ApiService.changeOldPassword(form).then(() => {
            extractButtonSpinner(buttonSpinnerId);
            setpassword("");
            setnewPassword("");
            setconfirmPassword("")
            AlertService.alert(SUCCESS_KEY, translationService.translate("TR_PASSWORD_CHANGED"));
          }).catch(error => getFail(error, buttonSpinnerId))
        }
      }
    }
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };


  return user && translationService ?
    <Auxiliary>
      {
        isShowCloseAccountModal ?
          <CloseAccountModal closeModal={() => showOrHideCloseAccountModal(false)} />
          : null
      }
      <aside className='right-section'>
        <section className="dashboard top-section customer-profile">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="vnd--aside-title-block">
                  <div className="backspace">
                    <span
                      className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                      onClick={() => {
                        dispatch(setActiveSection("home"))
                        window.history.back();
                      }}
                    >
                      <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                    </span>
                  </div>
                  <span className="slash">|</span>
                  <div className="">
                    <p className="vnd--upp-text-20">{translationService.translate("TR_SETTINGS")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 my-2">
                <div className="vnd-block vnd-p-block vnd-box-shadow">
                  <div className="d-flex align-items-center customer-sucscription mb-3">
                    <p className="vnd--upp-text-20">{translationService.translate("TR_CHANGE")} {translationService.translate("TR_PASSWORD")}</p>
                  </div>
                  <form onSubmit={onSubmit}>
                    {/* <div className="form-group">
                    <label htmlFor="">old password</label>
                    <input type="email" className="flex-1" name="" placeholder="Old password" id="" />
                  </div> */}
                    <div className="form-group">
                      <Input
                        id="password"
                        htmlFor="password"
                        type="password"
                        name="password"
                        labelValue={`${translationService.translate("TR_OLD")} ${translationService.translate("TR_PASSWORD")} *`}
                        value={password}
                        autoComplete="new-password"
                        inputClassName={`${(isInvalidSubmit && !password.trim()) ? "error-border" : ""}`}
                        onChange={(event) => {
                          onChange(event, setpassword, fieldLengths.maxLength_100);
                          setIsInvalidSubmit(false);
                        }}
                        onBlur={() => checkFieldsValidation(password, "password")}
                      />
                    </div>
                    {/* <div className="form-group">
                    <label htmlFor="">new password</label>
                    <input type="email" className="flex-1" name="" placeholder="New password" id="" />
                  </div> */}
                    <div className="form-group">
                      <Input
                        id="newPassword"
                        htmlFor="newPassword"
                        type="password"
                        name="newPassword"
                        labelValue={`${translationService.translate("TR_NEW")} ${translationService.translate("TR_PASSWORD")} *`}
                        value={newPassword}
                        autoComplete="new-password"
                        inputClassName={`${isInvalidSubmit && (!newPassword.trim() || mismatchPasswords || isInvalidNewPassword) ? "error-border" : ""}`}
                        onChange={(event) => {
                          onChange(event, setnewPassword, fieldLengths.maxLength_100);
                          setIsInvalidSubmit(false);
                        }}
                        onBlur={() => checkFieldsValidation(newPassword, "newPassword")}
                      />
                    </div>
                    {/* <div className="form-group">
                    <label htmlFor="">conform password</label>
                    <input type="email" className="flex-1" name="" placeholder="Confirm new password" id="" />
                  </div> */}
                    <div className="form-group">
                      <Input
                        id="confirmPassword"
                        htmlFor="confirmPassword"
                        type="password"
                        name="confirmPassword"
                        labelValue={`${translationService.translate("TR_CONFIRM")} ${translationService.translate("TR_PASSWORD")} *`}
                        value={confirmPassword}
                        autoComplete="new-password"
                        inputClassName={`${isInvalidSubmit && (!confirmPassword.trim() || mismatchPasswords || isInvalidConfirmPassword) ? "error-border" : ""}`}
                        onChange={(event) => {
                          onChange(event, setconfirmPassword, fieldLengths.maxLength_100);
                          setIsInvalidSubmit(false);
                        }}
                        onBlur={() => checkFieldsValidation(confirmPassword, "confirmPassword")}
                      />
                    </div>
                    {
                      mismatchPasswords ?
                        <div className='my-4'><small className="red-color">{translationService.translate("TR_PASSWORD_MISMATCH")}</small></div>
                        : null
                    }
                    {
                      isInvalidNewPassword || isInvalidConfirmPassword ?
                        <small className="red-color">{translationService.translate("TR_PASSWORD_LENGTH_VAL").replace('{0}', "6").replace('{1}', "100")}</small>
                        : null
                    }
                    {
                      isSimilarPasswords ?
                        <small className="red-color">{translationService.translate("TR_PASSWORD_NEW_AND_OLD")}</small>
                        : null
                    }
                    <ActionButton
                      type="submit"
                      name={`${translationService.translate("TR_CHANGE")} ${translationService.translate("TR_PASSWORD")}`}
                      className="vnd-btn vnd-primary-btn my-3"
                      spinnerId={buttonSpinnerId}
                      onClick={onSubmit}
                    />
                  </form>
                </div>
              </div>
              <div className="col-lg-7 my-2">
                <div className="vnd-block vnd-p-block vnd-box-shadow">
                  <div className="d-flex align-items-center customer-sucscription mb-3">
                    {/* <p className="vnd--upp-text-20">{`${translationService.translate({"TR_CLOSE"})} ${translationService.translate("TR_PASSWORD")}`}</p> */}
                  </div>
                  <div>
                    <p>{translationService.translate("TR_CLOSE_ACCOUNT_INFO")}</p>
                    <button type='button' className="vnd-btn vnd-danger-btn my-3" onClick={() => showOrHideCloseAccountModal(true)}>{translationService.translate("TR_CLOSE_ACCOUNT")}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </aside >
    </Auxiliary>
    : null
}

export default Subscriptions