import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'react-uuid'
import { withRouter } from "react-router-dom"
import ActionButton from './../../../Components/Buttons/ActionButton'
import Input from './../../../Components/Inputs/Input'
import { ERROR_KEY, INVALID_USERNAME_OR_PASSWORD, SUCCESS_KEY, fieldLengths } from '../../../Constants/MainKeys'
import AlertService from '../../../Services/AlertService'
import { addButtonSpinner, addSpinner, removeButtonSpinner, removeSpinner } from './../../../Store/Actions/spinner'
import { setActiveSection } from '../../../Store/Actions/main'
import ApiService from './../../../Services/ApiService'
import MainService from '../../../Services/mainService'
import { removeLoginErrorMessage, userLogin } from '../../../Store/Actions/user'
import TranslationService from '../../../Services/translationService'

import hiring from '../../../assets/images/3d-images/hireing.png'

// email-200
// name-100
// password- 6-100

const buttonSpinnerId = uuid();
const BecomeSupporter = (props) => {

  // Via invitation link

  const query = new URLSearchParams(props.location.search);
  const key = query.get('key');
  const dispatch = useDispatch();
  const loginErrorMessage = useSelector(state => state.user.loginErrorMessage);
  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);
  const [partnerName, setPartnerName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [partnerId, setPartnerId] = useState(null);
  const [isSupporter, setIsSupporter] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isInvalidFirstName, setIsInvalidFirstName] = useState(false);
  const [isInvalidLastName, setIsInvalidLastName] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [isInvalidConfirmPassword, setIsInvalidConfirmPassword] = useState(false);


  useEffect(() => {
    dispatch(setActiveSection("home"));
    if (!key) {
      props.history.push(`/${language}`);
    } else {
      getPageDataByPartnerKey(key);
    }
  }, []);

  const getPageDataByPartnerKey = (key) => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getBecomeSupporterPageDataByPartnerKey(key).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        if (data.userModel) {
          setFirstName(data.userModel.firstname);
          setLastName(data.userModel.lastname);
          setCurrentUserEmail(data.userModel.email);
        }
        data.companyName && setCompanyName(data.companyName);
        data.partnerName && setPartnerName(data.partnerName);
        data.email && setCurrentUserEmail(data.email);
        data.partnerId && setPartnerId(data.partnerId);
        data.isSupporter && setIsSupporter(data.isSupporter);
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
  }

  const checkFieldsValidation = (field, fieldName) => {
    var isValid = MainService.isValidField(field, fieldName);
    if (fieldName === "password") {
      changeIsinvalidFieldName(isValid, setIsInvalidPassword);
    }
    if (fieldName === "confirmPassword") {
      changeIsinvalidFieldName(isValid, setIsInvalidConfirmPassword);
    }
    switch (fieldName) {
      case "firstName":
        changeIsinvalidFieldName(isValid, setIsInvalidFirstName)
        break;
      case "lastName":
        changeIsinvalidFieldName(isValid, setIsInvalidLastName)
        break;
      default:
        break;
    }
  }

  const changeIsinvalidFieldName = (isValid, cb) => {
    if (!isValid) cb(true);
    else cb(false);
  }

  const supporterSelfRegistration = (event) => {
    event.preventDefault();
    if (!firstName.trim() || !lastName.trim() || !currentUserEmail.trim().length || !password.trim()) {
      setIsInvalidSubmit(true);
      return;
    } else {
      const form = {
        firstName,
        lastName,
        email: currentUserEmail,
        password: btoa(password),
        confirmPassword: btoa(password),
        partnerId,
        key
      }
      setButtonSpinner(buttonSpinnerId);

      ApiService.supporterSelfRegistration(form).then(() => {
        const data = {
          username: currentUserEmail.trim(),
          password: password.trim(),
        }
        AlertService.alert(SUCCESS_KEY, `${translationService.translate("TR_DATA")} ${translationService.translate("TR_SAVED")}`)
        // extractButtonSpinner(buttonSpinnerId);
        dispatch(userLogin(data, buttonSpinnerId, props.history));
      }).catch(error => getFail(error, buttonSpinnerId))
    }
  }

  const attachSupporterToNewPartner = (event) => {
    event.preventDefault();

    if (!password.trim()) {
      setIsInvalidSubmit(true);
      return;
    } else {
      const data = {
        partnerId,
        password: btoa(password),
        email: currentUserEmail.trim()
      };

      setButtonSpinner(buttonSpinnerId);
      ApiService.attachSupporterToNewPartner(data).then(() => {

        const form = {
          username: currentUserEmail.trim(),
          password: password.trim(),
        }
        // extractButtonSpinner(buttonSpinnerId);
        dispatch(userLogin(form, buttonSpinnerId, props.history));
      }).catch(error => getFail(error, buttonSpinnerId))
    }
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
    spinnerId && extractSpinner(spinnerId);
    props.history.push(`/${language}`);
  };

  return translationService ? <section className="top-section">
    <div className="container">
      <div className='row'>
        <div className='col-lg-6'>
          <div className="vnd-block white-background vnd-border">
            <div className="mb-4">
              <h5 className="vnd--text-50">{translationService.translate("TR_BECOME_SUPPORTER")}</h5>
            </div>
            {
              !isSupporter ?
                <form onSubmit={supporterSelfRegistration}>
                  <div className="form-group">
                    <Input
                      id="firstName"
                      htmlFor="firstName"
                      type="text"
                      name="firstName"
                      labelValue={`${translationService.translate("TR_FIRST_NAME")} *`}
                      placeholder={`${translationService.translate("TR_FIRST_NAME")}`}
                      value={firstName}
                      autoComplete="new-password"
                      inputClassName={`${isInvalidSubmit && (!firstName.trim() || isInvalidFirstName) ? "error-border" : ""}`}
                      onChange={(event) => {
                        onChange(event, setFirstName, fieldLengths.maxLength_100);
                        setIsInvalidSubmit(false);
                      }}
                      onBlur={() => checkFieldsValidation(firstName, "firstName")}
                    />
                  </div>
                  <div className="form-group">
                    <Input
                      id="lastName"
                      htmlFor="lastName"
                      type="text"
                      name="lastName"
                      labelValue={`${translationService.translate("TR_LAST")} ${translationService.translate("TR_NAME")} *`}
                      placeholder={`${translationService.translate("TR_LAST")} ${translationService.translate("TR_NAME")}`}
                      value={lastName}
                      autoComplete="new-password"
                      inputClassName={`${isInvalidSubmit && (!lastName.trim() || isInvalidLastName) ? "error-border" : ""}`}
                      onChange={(event) => {
                        onChange(event, setLastName, fieldLengths.maxLength_100);
                        setIsInvalidSubmit(false);
                      }}
                      onBlur={() => checkFieldsValidation(lastName, "lastName")}
                    />
                  </div>
                  <div className="form-group">
                    <Input
                      id="currentUserEmail"
                      htmlFor="currentUserEmail"
                      type="email"
                      name="currentUserEmail"
                      labelValue={`${translationService.translate("TR_EMAIL")} *`}
                      placeholder={`${translationService.translate("TR_EMAIL")}`}
                      disabled={true}
                      value={currentUserEmail}
                      autoComplete="new-password"
                      onChange={() => { }}
                    />
                  </div>
                  <div className="form-group">
                    <Input
                      id="password"
                      htmlFor="password"
                      type="password"
                      name="password"
                      labelValue={`${translationService.translate("TR_PASSWORD")} *`}
                      placeholder={`${translationService.translate("TR_PASSWORD")}`}
                      value={password}
                      autoComplete="new-password"
                      inputClassName={`${isInvalidSubmit && (!password.trim() || isInvalidPassword) ? "error-border" : ""}`}
                      onChange={(event) => {
                        onChange(event, setPassword, fieldLengths.maxLength_100);
                        setIsInvalidSubmit(false);
                      }}
                      onBlur={() => checkFieldsValidation(password, "password")}
                    />
                  </div>
                  {
                    isInvalidConfirmPassword ?
                      <small className="red-color">{translationService.translate("TR_PASSWORD_LENGTH_VAL").replace('{0}', "6").replace('{1}', "100")}</small>
                      : null
                  }
                  <ActionButton
                    type="submit"
                    name={translationService.translate("TR_BECOME_SUPPORTER")}
                    className="vnd-btn vnd-primary-btn my-4 w-100"
                    spinnerId={buttonSpinnerId}
                  />
                </form>
                :
                <form onSubmit={attachSupporterToNewPartner}>
                  <div className="form-group">
                    <Input
                      id="currentUserEmail"
                      htmlFor="currentUserEmail"
                      type="email"
                      name="currentUserEmail"
                      labelValue={`${translationService.translate("TR_EMAIL")} *`}
                      placeholder={`${translationService.translate("TR_EMAIL")}`}
                      value={currentUserEmail}
                      disabled={true}
                      autoComplete="new-password"
                      inputClassName={`${(isInvalidSubmit && !currentUserEmail.trim()) ? "error-border" : ""}`}
                      onChange={(event) => {
                        onChange(event, setCurrentUserEmail, fieldLengths.maxLength_100);
                        setIsInvalidSubmit(false);
                      }}
                      onBlur={() => checkFieldsValidation(currentUserEmail, "email")}
                    />
                  </div>
                  <div className="form-group">
                    <Input
                      id="password"
                      htmlFor="password"
                      type="password"
                      name="password"
                      labelValue={`${translationService.translate("TR_PASSWORD")} *`}
                      placeholder={`${translationService.translate("TR_PASSWORD")}`}
                      value={password}
                      autoComplete="new-password"
                      inputClassName={`${(isInvalidSubmit && !password.trim()) ? "error-border" : ""}`}
                      onChange={(event) => {
                        onChange(event, setPassword, fieldLengths.maxLength_100);
                        setIsInvalidSubmit(false);
                        dispatch(removeLoginErrorMessage());
                      }}
                    />
                  </div>

                  {
                    loginErrorMessage === INVALID_USERNAME_OR_PASSWORD ?
                      <small className="red-color mb-2 d-block mt-2">{translationService.translate("TR_INVALID_EMAIL_PASSWORD")}</small>
                      : null
                  }

                  <ActionButton
                    type="submit"
                    name={translationService.translate("TR_BECOME_SUPPORTER")}
                    className="vnd-btn vnd-primary-btn my-4 w-100"
                    spinnerId={buttonSpinnerId}
                  />
                </form>
            }
          </div>
        </div>
        <div className='col-lg-6 d-lg-block d-none'>
          <div className="vnd-block light-gray-background h-100 position-relative position-relative overflow-hidden">
            <div className='position-relative z-index-10'>
              <p className='vnd--upp-text-22'>{translationService.translate("TR_HI_THERE")} <q className='vnd--text-secondary'>{partnerName}</q> {translationService.translate("TR_THE_OWNER")} <q className='vnd--text-secondary'>{companyName}</q></p>
              <hr />
              <q className='position-relative font-italic z-index-10 font-size-18'>{translationService.translate("TR_BECOME_SUPPORTER_INFO")}</q>
            </div>
            <img src={hiring} style={{ maxWidth: "350px" }} className="vnd-decoreation vnd-hiring-bottom-right" alt="vnd-hiring-bottom-right" />
          </div>
        </div>
      </div>
    </div>
  </section> : null
}

export default withRouter(BecomeSupporter)