import { useEffect } from 'react';
import { createElement, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { setActiveSection } from '../../Store/Actions/main';
import { useDispatch } from 'react-redux';
import ScrollTopView from './scrollTopView';

const ScrollTop = () => {

	const history = useHistory();
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const [isInProfilePage, setIsInProfilePage] = useState(false)

	useEffect(() => {
		if (history.location.pathname.includes("profile")) {
			setIsInProfilePage(true);
		} else {
			setIsInProfilePage(false);
		}
	}, [history.location.pathname])

	const onVisbile = () => {
		const scrolled = document.documentElement.scrollTop
		if (scrolled > 200) setVisible(true)
		else if (scrolled <= 300) setVisible(false)
	}
	
	const onScroll = () => {
		if (typeof window === 'object') {
			setActiveSectionName();
			window.scrollTo({ top: 0, behavior: 'smooth' })
		} 
	}
	
	const setActiveSectionName = () => {
		dispatch(setActiveSection(null));
		localStorage.removeItem("active-section")
	}

	return createElement(ScrollTopView, {
		onScroll,
		onVisbile,
		visible,
		isInProfilePage
	})
}

export default withRouter(ScrollTop);