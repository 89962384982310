import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { BrowserRouter } from "react-router-dom";
import watchers from './Store/Sagas';
import reducer from './Store/Reducers';
import "./Css/vnd.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/scss/themes.scss";
import "./sass/styles.css";
import 'emoji-mart/css/emoji-mart.css';
import "react-multi-carousel/lib/styles.css";

const saga = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(saga))
);

saga.run(watchers);
window.Storage = store;

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>, document.getElementById('root'));

export default store;