import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import uuid from 'react-uuid';
import ApiService from './Services/ApiService';
import { addSpinner, removeSpinner } from './Store/Actions/spinner';
import { useDispatch } from 'react-redux';

const ErrorHandling = ({ history }) => {
  let isPending = false;
  const dispatch = useDispatch();

  useEffect(() => {
    window.onerror = (msg, url, lineNo, columnNo, error) => {
      console.error(
        msg,
        url,
        lineNo,
        columnNo,
        error
      )
      // if (!error || isPending) { return false; }
      // isPending = true;
      // sendError(msg, url, lineNo, columnNo, error);
    };
  }, []);

  const sendError = (msg, url, lineNo, columnNo, error) => {
    const spinnerId = uuid();
    const form = {
      column: columnNo,
      line: lineNo,
      url: url,
      message: msg,
      error: error,
    };
    dispatch(addSpinner(spinnerId));
    ApiService.sendError(form).then(() => {
      history.push(`/`);
      window.location.reload();
      dispatch(removeSpinner(spinnerId));
    }).catch(() => {
      history.push(`/`);
      window.location.reload();
      dispatch(removeSpinner(spinnerId));
    })
  };

  return <></>;
};

export default withRouter(ErrorHandling);