import $ from 'jquery';

class MainService {

  static triggerUploadClick(event) {
    let inputElem = $($(event.target).find('input:file'))[0];
    if (!inputElem) {
      inputElem = $($(event.target.parentElement).find('input:file'))[0];
    }
    $(inputElem).trigger('click');
  }

  static isValidField(fieldValue, fieldName) {
    if (fieldName === "firstName" || fieldName === "lastName" || fieldName === "firstname" || fieldName === "lastname" || fieldName === "name") {
      const idValid = /^[A-Za-z\u0400-\u04FF]{1,100}(\-?)[A-Za-z\u0400-\u04FF]{1,100}$/;
      return idValid.test(fieldValue);
    }
    if (fieldName === "email" || fieldName === "organizationEmail") {
      // const idValid = /^[A-z,0-9,]{1,30}\d*@[A-z,0-9,]*.[A-z]*$/;
      const idValid = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
      return idValid.test(fieldValue);
    }
    if (fieldName === "password" || fieldName === "confirmPassword" || fieldName === "newPassword") {
      // const idValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/;
      const idValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]([^\s@]+){6,}$/;
      return idValid.test(fieldValue);
    }
  }

  static convertUTCDateToLocalDate(date) {
    let offset = date.getTimezoneOffset() / 60;
    let hours = date.getHours();
    date.setHours(hours - offset);
    return date;
  }

  static convertUtcDayTimeToLocal(time) {
    if (!time) { return false; }
    var d = new Date();
    var date = new Date(d.setHours(time.split(":")[0], time.split(":")[1]))
    const localDate = this.convertUTCDateToLocalDate(date);
    let hours = localDate.getHours();
    let minutes = localDate.getMinutes();
    if (hours.toString().length === 1) {
      hours = "0" + hours
    }
    if (minutes.toString().length === 1) {
      minutes = "0" + minutes
    }
    return `${hours}:${minutes}`
  }

  readFile(file, validFileTypes = null) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
      const isValid = validFileTypes.find(data => data === fileExtention);
      !isValid && reject(true);
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result);
      }
    });
  }

  static isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static isEqualDates(firstDate, secondDate) {
    if (!firstDate || !secondDate) { return; }
    if (new Date(firstDate).getTime() === new Date(secondDate).getTime()) {
      return true;
    } else return false;
  }

  static checkDates(firstDate, secondDate) {
    if (!firstDate || !secondDate) { return; }
    if (new Date(firstDate).getTime() < new Date(secondDate).getTime()) {
      return true;
    } else return false;
  }

  static checkServicePermission(subscriptionService, name) {
    if (!subscriptionService || (subscriptionService && !subscriptionService.length)) {
      return false;
    }
    if (subscriptionService.find(item => item.nameKey === name)) {
      return true;
    } else {
      return false;
    }

  }


}

export default MainService;