import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setActiveSection } from '../../../Store/Actions/main';
import { ERROR_KEY, SUCCESS_KEY, fieldLengths } from '../../../Constants/MainKeys';
import AlertService from '../../../Services/AlertService';
import uuid from 'react-uuid';
import { addButtonSpinner, addSpinner, removeButtonSpinner, removeSpinner } from '../../../Store/Actions/spinner';
import ApiService from '../../../Services/ApiService';
import MainService from '../../../Services/mainService';
import * as moment from "moment";
import ActionButton from '../../../Components/Buttons/ActionButton';
import Input from '../../../Components/Inputs/Input';
import NoData from '../../../Components/NoData/NoData';
import TranslationService from '../../../Services/translationService';
import ReactPaginate from 'react-paginate';
import { AiOutlineInfoCircle, AiOutlineLeft } from "react-icons/ai";

const buttonSpinnerId = uuid();
const Invitations = (props) => {

  const dispatch = useDispatch();
  const { language } = useSelector(state => state.language);
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);
  const { user } = useSelector(state => state.user);
  const [invitations, setInvitations] = useState([]);
  const [email, setEmail] = useState("");
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [pagination, setPagination] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    setActiveSectionName("");
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getInvitations(1);
  }, [])

  const handlePageClick = (event) => {
    setActivePageNumber(event.selected);
    getInvitations(event.selected + 1)
  }

  const getInvitations = (currentPage) => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getInvitations(currentPage, fieldLengths.pageSize_10).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        if (data.pagedList && data.pagedList.pagedData) {
          setInvitations(data.pagedList.pagedData);
        }
        if (data.pagedList && data.pagedList.pagination) {
          setPagination(data.pagedList.pagination);
        }
        data.isSubscribed && setIsSubscribed(data.isSubscribed);
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const setActiveSectionName = (name) => {
    dispatch(setActiveSection(name))
  };

  const deleteInvitation = (id) => {
    if (!id) { return false; }
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `${translationService.translate("TR_ARE_YOU_SURE")} ?`,
      "",
      translationService.translate("TR_YES"),
      translationService.translate("TR_NO")
    ).then(() => {
      setSpinner(spinnerId);
      ApiService.deleteInvitation(id).then(() => {
        setInvitations(invitations.filter(invitation => invitation.id !== id));
        AlertService.alert(SUCCESS_KEY, `${translationService.translate("TR_INVITATIONS")} ${translationService.translate("TR_DELETED_SUCCESSFULLY")}`);
        extractSpinner(spinnerId)
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
  }

  const copyLink = (link) => {
    if (!link) { return; }
    navigator.clipboard.writeText(link);
    AlertService.alert(SUCCESS_KEY, translationService.translate("TR_COPIED"));
  }

  const checkFieldsValidation = (field, fieldName) => {
    var isValid = MainService.isValidField(field, fieldName);
    switch (fieldName) {
      case "email":
        changeIsinvalidFieldName(isValid, setIsInvalidEmail)
        break;
      default:
        break;
    }
  }

  const changeIsinvalidFieldName = (isValid, cb) => {
    if (!isValid) cb(true);
    else cb(false);
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!email.trim().length || isInvalidEmail) {
      setIsInvalidSubmit(true);
    } else {
      setButtonSpinner(buttonSpinnerId);
      ApiService.createInvitation({ email }).then(response => {
        if (response && response.data) {
          const data = { ...response.data }
          const invitationCopy = [...invitations];
          invitationCopy.unshift(data);
          setInvitations(invitationCopy);
          extractButtonSpinner(buttonSpinnerId);
          setEmail("");
        }
      }).catch(error => getFail(error, buttonSpinnerId))
    }
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
    spinnerId && extractSpinner(spinnerId);
    setEmail("");
  };

  return user && translationService ?
    <aside className='right-section'>
      <section className="dashboard top-section customer-profile">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="vnd--aside-title-block">
                <div className="backspace">
                  <span
                    className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                    onClick={() => {
                      window.history.back();
                    }}
                  >
                    <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                  </span>
                </div>
                <span className="slash">|</span>
                <div className="">
                  <p className="vnd--upp-text-22">{translationService.translate("TR_INVITATIONS")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <form className='vnd--m-block light-brand-background h-100' onSubmit={onSubmit}>
                <div className="d-sm-flex align-items-end">
                  <div className='flex-1'>
                    <Input
                      id="email"
                      htmlFor="email"
                      type="email"
                      name="email"
                      labelValue={translationService.translate("TR_INVITE_TS")}
                      value={email}
                      disabled={!isSubscribed}
                      autoComplete="new-password"
                      inputClassName={` mt-1 ${((isInvalidSubmit && !email.trim()) || isInvalidEmail) ? "error-border" : ""} ${!isSubscribed ? "disabled" : ""}`}
                      blockClassName="w-100"
                      onChange={(event) => {
                        onChange(event, setEmail, fieldLengths.maxLength_120);
                        setIsInvalidSubmit(false);
                        setIsInvalidEmail(false)
                      }}
                      onBlur={() => checkFieldsValidation(email, "email")}
                    />
                  </div>
                  <ActionButton
                    type="submit"
                    name={translationService.translate("TR_INVITE")}
                    className={`vnd-btn vnd-primary-btn ms-sm-3 mb-sm-3 mb-0 ${!isSubscribed ? "disabled" : ""}`}
                    spinnerId={buttonSpinnerId}
                    disabled={!isSubscribed}
                    onClick={onSubmit}
                  />
                </div>
              </form>
            </div>
            <div className='col-lg-6 mt-lg-0 mt-3'>
              <div className='vnd--m-block light-brand-background h-100'>
                <div className='vnd--info-block'>
                  <AiOutlineInfoCircle />
                  <p className='m-0'>{translationService.translate("TR_INVITE_TEXT")}</p>
                </div>
              </div>
            </div>
            {
              !isSubscribed ?
                <div className='col-12 my-2'>
                  <div className='vnd--m-block light-gray-background h-100'>
                    <span>
                      {translationService.translate("TR_NOT_SUBSCRIBED")}
                      {'\u00A0'}
                      <Link to={`/${language}/subscriptions`} className="underline">{translationService.translate("TR_SUBSCRIBE_NOW")}</Link>
                    </span>
                  </div>
                </div>
                : null
            }
            {
              invitations && invitations.length ?
                <div className="col-12 mt-4">
                  <div className='table-responsive vnd--borderd vnd--m-block'>
                    <table className="table mb-0">
                      <thead className="table-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">{translationService.translate("TR_EMAIL")}</th>
                          <th scope="col">{translationService.translate("TR_URL")}</th>
                          <th scope="col">{translationService.translate("TR_CREATE_DATE")}</th>
                          <th scope="col">{translationService.translate("TR_EXPIRE_DATE")}</th>
                          <th scope="col">{translationService.translate("TR_STATUS")}</th>
                          <th scope="col">{translationService.translate("TR_ACTION")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          invitations.map((invitation, index) => {
                            return <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{invitation.email}</td>
                              {
                                invitation.isExpired ?
                                  <td className='word-break-break-word'>{invitation.invitationUrl}</td>
                                  : <td title={translationService.translate("TR_COPY")} className='cursor-pointer' onClick={() => copyLink(invitation.invitationUrl)}>{invitation.invitationUrl}</td>
                              }
                              <td>{moment(MainService.convertUTCDateToLocalDate(new Date(invitation.createDate))).format("LL")}</td>
                              <td>{moment(MainService.convertUTCDateToLocalDate(new Date(invitation.expireDate))).format("LL")}</td>
                              {
                                invitation.isExpired ?
                                  <td className="trnasaction-faild">{translationService.translate("TR_EXPIRED")}</td> :
                                  <td className="trnasaction-completed">{translationService.translate("TR_INPROCESS")}</td>
                              }
                              <td>
                                <button
                                  type='button'
                                  className="vnd-btn vnd-small-btn vnd-outline-danger-btn py-2 px-3"
                                  title={translationService.translate("TR_DELETE")}
                                  onClick={() => deleteInvitation(invitation.id)}
                                >
                                  <i className="ri-delete-bin-line"></i>
                                </button>
                              </td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                  {
                    pagination && pagination.totalPages > 1 ?
                      <div className='w-100 d-flex justify-content-end mt-4'>
                        <ReactPaginate
                          nextLabel={translationService.translate("TR_NEXT")}
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          pageCount={pagination.totalPages}
                          previousLabel={translationService.translate("TR_PREVIOUS")}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                          forcePage={activePageNumber}
                        />
                      </div>
                      : null
                  }
                </div>
                : <NoData />
            }
          </div>

        </div>
      </section>
    </aside >
    : null
}

export default Invitations