import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'react-uuid'
import Input from '../../../Components/Inputs/Input'
import { ERROR_KEY, fieldLengths } from '../../../Constants/MainKeys'
import AlertService from '../../../Services/AlertService'
import { addButtonSpinner, addSpinner, removeButtonSpinner, removeSpinner } from '../../../Store/Actions/spinner'
import Laptop from "./../../../assets/images/laptop.png"
import ApiService from '../../../Services/ApiService'
import TranslationService from '../../../Services/translationService'
import StepFooter from './Components/StepFooter'
import ReactSelectOption from '../../../Components/SelectOption/ReactSelectOption'

const spinnerId = uuid();
export default function BecomeApartnerStep1(props) {

  const widgetPositionArr = [
    {
      name: "TR_TOP_LEFT",
      dataPosition: "top-left",
      className: "position-button top-left"
    },
    {
      name: "TR_TOP_CENTER",
      dataPosition: "top-center",
      className: "position-button top-center"
    },
    {
      name: "TR_TOP_RIGHT",
      dataPosition: "top-right",
      className: "position-button top-right"
    },
    {
      name: "TR_BOTTOM_LEFT",
      dataPosition: "bottom-left",
      className: "position-button bottom-left"
    },
    {
      name: "TR_BOTTOM_CENTER",
      dataPosition: "bottom-center",
      className: "position-button bottom-center"
    },
    {
      name: "TR_BOTTOM_RIGHT",
      dataPosition: "bottom-right",
      className: "position-button bottom-right"
    },
  ];

  const dispatch = useDispatch()

  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);

  const [translationService, setTranslationService] = useState(null);
  const [id, setId] = useState(null);
  const [position, setPosition] = useState("bottom-right");
  const [domainName, setDomainName] = useState("");
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [form, setForm] = useState({});
  const [languageId, setLanguageId] = useState(null);
  const [languages, setLanguages] = useState([]);


  useEffect(() => {
    getWidgetData();
  }, []);

  const onWidgetPositionChange = (position) => {
    setPosition(position)
  }

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
  }

  const getWidgetData = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getWidgetDataByPageStepNumber(1).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        data.id && setId(data.id);
        data.domainName && setDomainName(data.domainName);
        data.companyName && setCompanyName(data.companyName);
        data.position && setPosition(data.position);
        setForm(data);
        if (data.languageResponseModels && data.languageResponseModels.length) {
          setLanguages(data.languageResponseModels);
          setLanguageId(data.languageId ? data.languageId : data.languageResponseModels[0].id);
        }
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (!companyName.trim().length || !domainName.trim().length) {
      setIsInvalidSubmit(true);
    } else {
      setButtonSpinner(spinnerId);
      form.id = id;
      form.stepNumber = 1;
      form.companyName = companyName;
      form.domainName = domainName;
      form.position = position;
      form.languageId = languageId;
      ApiService.becomePartner(form).then(() => {
        props.history.push(`/${language}/become-partner/step-2`);
        extractButtonSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId));
    }
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
    spinnerId && extractSpinner(spinnerId);
  };

  return translationService ? <section className="top-section-steps">
    <div className="container">
      <div className='row'>
        <div className='col-12'>
          <div className="vnd--section-title-wrapper">
            <p className="vnd--upp-text-20">{translationService.translate("TR_COMPANY_DETAILS")}</p>
            <span className="font-size-14">{translationService.translate("TR_COMPANY_DETAILS_TEXT")}</span>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='light-brand-background vnd--m-block mb-3'>
            <form>
              <div className="form-group">
                <Input
                  id="companyName"
                  htmlFor="companyName"
                  type="text"
                  name="companyName"
                  labelValue={`${translationService.translate("TR_COMPANY_NAME")} *`}
                  placeholder={`${translationService.translate("TR_EXAMPLE_COMPANY")}`}
                  labelClassName="cursor-pointer font-weight-bold"
                  value={companyName}
                  className={`${isInvalidSubmit && !companyName.trim().length ? "error-border" : ""}`}
                  onChange={(event) => {
                    onChange(event, setCompanyName, fieldLengths.maxLength_50);
                    setIsInvalidSubmit(false);
                  }}
                />
              </div>
              <Input
                id="domainName"
                htmlFor="domainName"
                type="text"
                name="domainName"
                labelValue={`${translationService.translate("TR_WEBSITE_URLS")} *`}
                labelClassName="cursor-pointer font-weight-bold"
                placeholder="www.sample.com"
                value={domainName}
                inputClassName={`${isInvalidSubmit && !domainName.trim().length ? "error-border" : ""}`}
                blockClassName="mt-2"
                onChange={(event) => {
                  onChange(event, setDomainName, fieldLengths.maxLength_200);
                  setIsInvalidSubmit(false);
                }}
              />
              {
                languages && languages.length ?
                  <div>
                    <label className='form-control-label capitalize font-weight-bold'>{translationService.translate("TR_WIDGET_LANGUAGE")} *</label>
                    <ReactSelectOption
                      value={languageId}
                      isSearchable={true}
                      selectedValue={(() => {
                        const selectedValue = { ...languages.find(data => data.id === languageId) };
                        if (selectedValue) {
                          selectedValue.label = selectedValue.name;
                          selectedValue.value = selectedValue.id;
                        }
                        return selectedValue;
                      })()}
                      items={languages.map(data => ({ label: data.name, value: data.id }))}
                      onChange={(item) => setLanguageId(item.value)}
                    />
                  </div>
                  : null
              }
            </form>
          </div>
          <div className='vnd--m-block light-gray-background'>
            <h4 className="vnd-block-subtitle">{translationService.translate("TR_WIDGET_POSITION")}</h4>
            <span className="font-italic">{translationService.translate("TR_WIDGET_POSITION_EXAMPLE")}</span>
            <p className="font-bold m-0 mt-3">{translationService.translate("TR_SELECTED_POSITION")}:&nbsp;
              {
                widgetPositionArr.map((widgetPositionItem, index) => {
                  if (widgetPositionItem.dataPosition === position) {
                    return <span key={index} className="vnd--text-primary">
                      {translationService.translate(widgetPositionItem.name) ? translationService.translate(widgetPositionItem.name) : widgetPositionItem.name}
                    </span>
                  }
                })
              }
            </p>
          </div>
        </div>
        <div className="col-lg-6">
          <hr className='d-lg-none d-block' />
          <div className="vnd-laptop-container">
            <div className="vnd-laptop-wrapper">
              <img src={Laptop} alt="" />
              {
                widgetPositionArr.map((widgetPositionItem, index) => {
                  return <span
                    key={index}
                    data-position={widgetPositionItem.dataPosition}
                    className={`${widgetPositionItem.className} ${widgetPositionItem.dataPosition === position ? "active" : ""}`}
                    onClick={() => onWidgetPositionChange(widgetPositionItem.dataPosition)}
                  />
                })
              }
            </div>
            <strong className="vnd-block-subtitle">{translationService.translate("TR_WIDGET")} {translationService.translate("TR_POSITIONS")}</strong>
          </div>
        </div>
      </div>
    </div>
    <StepFooter
      translationService={translationService}
      setpNumber="1"
      backUrl={null}
      actionButtonName={translationService.translate("TR_CONTINUE")}
      spinnerId={spinnerId}
      onSubmit={onSubmit}
    />
  </section> : null
}
