import React from "react";
import UnauthUsersRouting from "../../Routing/UnauthUsersRouting/UnauthUsersRouting";
import Footer from "../LayoutSections/Footers/Footer";
import Header from "../LayoutSections/Headers/Header";
import { useSelector } from "react-redux";

const UnAuthUsersLayout = () => {

  const { spinners } = useSelector(state => state.spinner);

  return (
    <div id="main">
      <Header />
      <div className={`${spinners.length ? "disabled" : ""}`}>
        <UnauthUsersRouting />
      </div>
      <Footer />
    </div>
  );
}

export default UnAuthUsersLayout;

