import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, withRouter } from "react-router-dom";
import TranslationService from '../../Services/translationService';
import { setHideAttacheToSessionNotification } from '../../Store/Actions/main';

const AttachToSessionModal = (props) => {
  const { pathname } = useLocation();
  var supporterId = null;

  if (pathname.includes("/technical-assistant")) {
    supporterId = pathname.split("/")[pathname.split("/").length - 1]
  }

  const dispatch = useDispatch()
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const closeModal = () => {
    dispatch(setHideAttacheToSessionNotification(null));
  }

  return (translationService ? <div className="vnd-modal-window">
    <div className="vnd-modal-window-wrapper">
      <div className="vnd-modal">
        <p className="vnd--upp-text-20">
          {translationService.translate("TR_NOTIFICATION")}
        </p>
        <hr />
        <p>{translationService.translate(props.message) ? translationService.translate(props.message) : props.message}</p>
        <div className='d-flex justify-content-end mb-3 mt-4'>
          {
            !pathname.includes("my-clients") || (pathname.includes("my-clients") && supporterId) ?
              <button
                className='vnd-btn vnd-primary-btn vnd-small-btn mx-1'
                type='button'
                onClick={() => {
                  props.history.push(`/${language}/dashboard/chats/my-clients`);
                  closeModal();
                }}>
                {translationService.translate("TR_VIEW")}
              </button>
              : null
          }
          <button className='vnd-btn vnd-danger-btn  vnd-small-btn mx-1' type='button' onClick={closeModal}>
            {translationService.translate("TR_CLOSE")}
          </button>
        </div>
      </div>
    </div>
  </div> : null
  )
}

export default withRouter(AttachToSessionModal)