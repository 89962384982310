import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, withRouter } from "react-router-dom";
import Auxiliary from './hoc/auxiliary/auxiliary';
import * as signalR from '@microsoft/signalr';
import AlertService from './Services/AlertService';
import MessageRingtone from "./assets/audio/iphone_msg_sent.mp3"
import RolesService from './Services/rolesService';
import { setActiveGroupName, setActiveGroupsName, setShowAttacheToSessionNotification, setSubscriptionService } from './Store/Actions/main';
import { RtcConnection } from 'mindalayvideostream';
import { setGlobalConnectionWithRedux, setRtcGlobalConnection } from './Store/Actions/signalR';
import { API_URL_KEY } from './Constants/ApiKeys';
import {
  SUPPORTER_USER_TYPE_ID_KEY,
  ERROR_KEY,
  PARTNER_USER_TYPE_ID_KEY,
  LANGUAGE_KEY,
  SUPERVISOR_USER_TYPE_ID_KEY,
  DEFAULT_LANGUAGE_KEY,
} from './Constants/MainKeys';
import {
  addMessageToActiveUserMessages,
  addNewUser,
  changeCurrentUserLastMessage,
  changeCurrentUserUnReadMessagesCount,
  changeCurrentUserData,
  resetCurrentUserUnReadMessagesCount,
  changeUsersOnlineStatus,
  showOrHideMyClientsTitleNotification,
  showOrHideInboxTitleNotification,
  changeCurrentMessageReceivedField,
  changeUserOnlineStatus,
  changeClientIsTyping,
  changeGroupUnreadMessagesCount,
  changeCallWaitingStatus,
  setIsCallInProcess,
  setNewMessageObj,
  changeTechnicalAssistantOnlineStatus,
  changeUserIsTyping,
  setClientActiveCallId,
  removeClientFromInbox,
  setClientIsInActiveCallException,
  removeClientFromChatBoard,
  addNewUserToMyClients,
  changeClientsOnlineStatus,
  sortClients
} from './Store/Actions/chat';
import UnAvailableConnectionModal from './Components/Modals/UnAvailableConnectionModal';
import AttachToSessionModal from './Components/Modals/AttachToSessionModal';
import TranslationService from './Services/translationService';
import GlobalNotificationAlert from './Components/GlobalNotificationAlert/GlobalNotificationAlert';
import Reconnect from './Components/Reconnect/Reconnect';
import ApiService from './Services/ApiService';
import MainService from './Services/mainService';

const Listeners = (props) => {

  const { pathname } = useLocation();
  var supporterId = null;
  if (pathname.includes("/technical-assistant")) {
    supporterId = pathname.split("/")[pathname.split("/").length - 1]
  }

  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const role = useSelector(state => state.user.role)
  const showAttacheToSessionNotification = useSelector(state => state.main.showAttacheToSessionNotification);
  const translations = useSelector(state => state.translation.translations);
  const activeGroupsName = useSelector(state => state.main.activeGroupsName);
  const user = useSelector(state => state.user.user);
  const globalConnection = useSelector(state => state.signalR.globalConnectionWithRedux);
  const rtcGlobalConnection = useSelector(state => state.signalR.rtcGlobalConnection);
  const activeGroupName = useSelector(state => state.main.activeGroupName);
  const partnerId = useSelector(state => state.main.partnerId);
  const isConnectionAllow = useSelector(state => state.main.isConnectionAllow);
  const [roleService, setRoleService] = useState(null);
  const [connectionUnAvailableMessage, setConnectionUnAvailableMessage] = useState("");
  const [translationService, setTranslationService] = useState(null);
  const [isShowConnectionModal, setIsShowConnectionModal] = useState(false);

  const isLog = false;

  const isConnectionOk = () => {
    if (globalConnection && globalConnection.state === "Connected") return true;
    else return false;
  }

  useEffect(() => {
    isLog && console.log("useEffect");
    window.addEventListener("beforeunload", disconnect);
    return () => {
      window.removeEventListener("beforeunload", disconnect);
    };
  }, []);

  useEffect(() => {
    isLog && console.log("useEffect");
    if (user && (user.partnerId || +partnerId)) {
      ApiService.getSubscriptionService(user.partnerId ? +user.partnerId : +partnerId).then(response => {
        if (response && response.data) {
          dispatch(setSubscriptionService(response.data));
        }
      }).catch(error => getFail(error))
    }
  }, [user.partnerId, partnerId]);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    MainService.isJson(role) && setRoleService(new RolesService(JSON.parse(role)))
  }, [role]);

  useEffect(() => {
    isLog && console.log("useEffect");
    if (user) {
      ////heto piti hanenq henc ngrock-y poxecinq
      if (user.userTypeId === PARTNER_USER_TYPE_ID_KEY) {
        dispatch(setActiveGroupName("YmIzOGMzNzItYw=="))
      }
    }
  }, [])

  useEffect(() => {
    isLog && console.log("useEffect");
    if (activeGroupName && pathname.includes("/dashboard") && isConnectionAllow) {
      let connectionId = localStorage.getItem("connectionId");
      if (user && (!isConnectionOk() || (isConnectionOk() && globalConnection.connectionId !== connectionId))) {
        if (user.userTypeId === PARTNER_USER_TYPE_ID_KEY) {
          connectToSignalR();
        }
        if (user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY) {
          if (!pathname.includes("/groups")) {
            connectToSignalR();
          }
        }
      }
    }
  }, [globalConnection && globalConnection.state, user, activeGroupName, partnerId, pathname.includes("/dashboard"), rtcGlobalConnection]);


  const sleep = (msec) => {
    return new Promise(resolve => setTimeout(resolve, msec));
  }

  const connect = async (conn) => {
    conn.start().then(() => {
      // if(conn && conn.state === "Connected" && localStorage.getItem("callHangUp")){ hangupCall(conn) } 
      if (!conn.connectionId) { return false; }
      if (user.partnerId) {
        supporterConnection(conn);
      }
      connectGroups(conn);
      localStorage.setItem("connectionId", conn.connectionId);
      setIsShowConnectionModal(false);
    }).catch(() => {
      dispatch(changeClientsOnlineStatus());
      if (localStorage.getItem("user") && !window.location.pathname.includes("/groups")) {
        sleep(5000);
        connect(conn);
      }
      isLog && console.log("Reconnecting Socket");
    })
  }

  const connectToSignalR = async () => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${API_URL_KEY}dashboard`)
      .withAutomaticReconnect([0, 1000, 2000, 3000])
      .configureLogging(signalR.LogLevel.None)
      .build();

    connection.serverTimeoutInMilliseconds = 1000 * 60 * 10;

    await connection.start();

    connection && connection.connectionId && localStorage.setItem("connectionId", connection.connectionId);

    await connectGroups(connection);
    dispatch(setGlobalConnectionWithRedux(connection));

    if (user.partnerId) {
      await supporterConnection(connection);
    }

    connection.onclose(() => {
      isLog && console.log('onclose');
      dispatch(changeClientsOnlineStatus());
      if (window.location.pathname.includes("/dashboard") && localStorage.getItem("user") && !window.location.pathname.includes("/groups")) {
        connect(connection);
      }
    });

    connection.onreconnecting(() => {
      if (user.userTypeId === PARTNER_USER_TYPE_ID_KEY && pathname.includes("/dashboard")) {
        setTimeout(() => {
          showConnectionModal(connection);
        }, 3000);
      }
      if ((user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY) && activeGroupsName.length) {
        setTimeout(() => {
          showConnectionModal(connection);
        }, 3000);
      }
    });

    const showConnectionModal = (connection) => {
      if (!connection || connection && connection.state !== "Connected") {
        return false;
      } else {
        setIsShowConnectionModal(true);
        dispatch(changeClientsOnlineStatus());
      }
    }

    connection.onreconnected((connectionId) => {
      // if(connection && connection.state === "Connected" && localStorage.getItem("callHangUp")){ hangupCall(connection) } 
      if (!connectionId) { return false; }
      if (user.partnerId) {
        supporterConnection(connection);
      }
      connectGroups(connection);
      localStorage.setItem("connectionId", connectionId);
      setIsShowConnectionModal(false);
      isLog && console.log("SignalR Reconnected.");
    });

    // try {
    //   await connection.start();
    //   isLog && console.log("SignalR Connected.");
    //   connection && connection.connectionId && localStorage.setItem("connectionId", connection.connectionId);
    //   dispatch(setGlobalConnectionWithRedux(connection));
    //   isLog && console.log(user.partnerId, "user.partnerId");
    //   if (user.partnerId) {
    //     await supporterConnection(connection);
    //   }
    //   await connectGroups(connection);
    //   connection.onclose(() => {
    //     isLog && console.log("SignalR Disconnected.");
    //     dispatch(changeClientsOnlineStatus());
    //     if (window.location.pathname.includes("/dashboard") && localStorage.getItem("user") && !window.location.pathname.includes("/groups")) {
    //       setTimeout(() => connectToSignalR(), 3000);
    //     }
    //   });
    // } catch (error) {
    //   isLog && console.log("SignalR Disconnected.");
    //   dispatch(changeClientsOnlineStatus());
    //   if (localStorage.getItem("user") && !window.location.pathname.includes("/groups")) {
    //     setTimeout(() => connectToSignalR(), 3000);
    //   }
    // }
  }

  const hangupCall = (newConnection) => {
    if (!newConnection || !localStorage.getItem("callId") || !localStorage.getItem("activeCallId") || !user) { return false; }
    isLog && console.log("hangupCall method was called");
    newConnection.invoke("HangupCall", localStorage.getItem("activeCallId"), +localStorage.getItem("callId"), 1, user.id).then(() => {
      rtcGlobalConnection && rtcGlobalConnection.closeRoom();
      dispatch(changeCallWaitingStatus(false));
      dispatch(setIsCallInProcess(false));
      dispatch(setRtcGlobalConnection(null));
      localStorage.removeItem("callId");
      localStorage.removeItem("activeCallId");
      localStorage.removeItem("callHangUp");
    }).catch(error => getFail(error));
  }

  const supporterConnection = async (connection) => {
    try {
      isLog && console.log("SupporterConnection");
      if (window.location.pathname.includes("/dashboard")) {
        let currentLanguage = localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY;
        await connection.invoke("SupporterConnection", activeGroupName, user.id, user.partnerId, true, currentLanguage);
      }
    } catch (err) {
      isLog && console.log(err)
      setTimeout(() => { supporterConnection(connection); }, 1000);
    }
  }

  useEffect(() => {
    isLog && console.log("useEffect");
    // Listeners
    if (isConnectionOk()) {

      globalConnection.on('messageReceived', (timeStampId) => {
        if (timeStampId) {
          dispatch(changeCurrentMessageReceivedField(timeStampId))
        }
      });

      globalConnection.on('shareInitialMessageToInbox', (messageResponse, clientSessionResponse) => {
        isLog && console.log(messageResponse, clientSessionResponse);
        //new user listener

        if (localStorage.getItem("activeSessionName") && localStorage.getItem("activeSessionName") !== "inbox") {
          dispatch(showOrHideInboxTitleNotification(true));
        }
        audioStart();

        const newClient = { ...clientSessionResponse };
        newClient.messages = [messageResponse];
        newClient.lastMessageResponse = { ...messageResponse };
        newClient.unreadMessagesCount = 1;
        newClient.isOnline = true;
        isLog && console.log(localStorage.getItem("activeSessionName"));
        if (localStorage.getItem("activeSessionName") && localStorage.getItem("activeSessionName") === "inbox") {
          dispatch(addNewUser(newClient));
          clientSessionResponse && clientSessionResponse.clientSessionKey && dispatch(changeClientIsTyping(clientSessionResponse.clientSessionKey, false));
        }
      });

      globalConnection.on('shareMessageToInbox', (messageResponse, clientSessionKey) => {
        isLog && console.log(messageResponse, clientSessionKey);
        // inbox messages listener

        if (localStorage.getItem("activeSessionName") && localStorage.getItem("activeSessionName") !== "inbox") {
          dispatch(showOrHideInboxTitleNotification(true));
        }
        audioStart();

        let activeUserDataItem = null;
        if (localStorage.getItem("activeUserData") && MainService.isJson(localStorage.getItem("activeUserData"))) {
          activeUserDataItem = JSON.parse(localStorage.getItem("activeUserData"))
        }
        if (messageResponse && activeUserDataItem && messageResponse.supportSessionId === activeUserDataItem.supportSessionId) {
          getChatBoardPosition(messageResponse, !checkPositionIsBottom());
          dispatch(resetCurrentUserUnReadMessagesCount(clientSessionKey));
          dispatch(addMessageToActiveUserMessages(messageResponse));
        }
        if (messageResponse && activeUserDataItem && messageResponse.supportSessionId !== activeUserDataItem.supportSessionId) {
          dispatch(changeCurrentUserUnReadMessagesCount(clientSessionKey));
        }

        dispatch(sortClients(clientSessionKey));
        dispatch(changeCurrentUserLastMessage(clientSessionKey, messageResponse));
        dispatch(changeClientIsTyping(clientSessionKey, false));
      });

      globalConnection.on('shareMessageSupporter', (messageResponse, clientSessionKey) => {
        isLog && console.log(messageResponse, clientSessionKey);
        // my clients listener
        // petq e vor nor grox usery haytnvi verevum
        if (
          localStorage.getItem("activeSessionName") &&
          (localStorage.getItem("activeSessionName") !== "my-clients" || (localStorage.getItem("activeSessionName") === "my-clients" && supporterId))) {
          dispatch(showOrHideMyClientsTitleNotification(true));
        }
        audioStart();

        let activeUserDataItem = null;
        if (localStorage.getItem("activeUserData") && MainService.isJson(localStorage.getItem("activeUserData"))) {
          activeUserDataItem = JSON.parse(localStorage.getItem("activeUserData"))
        }
        if (messageResponse && activeUserDataItem && messageResponse.supportSessionId === activeUserDataItem.supportSessionId) {
          getChatBoardPosition(messageResponse, !checkPositionIsBottom());
          dispatch(resetCurrentUserUnReadMessagesCount(clientSessionKey));
          dispatch(addMessageToActiveUserMessages(messageResponse));
        }
        if (messageResponse && activeUserDataItem && messageResponse.supportSessionId !== activeUserDataItem.supportSessionId) {
          dispatch(changeCurrentUserUnReadMessagesCount(clientSessionKey));
        }
        dispatch(sortClients(clientSessionKey));
        dispatch(changeCurrentUserLastMessage(clientSessionKey, messageResponse));
        dispatch(changeClientIsTyping(clientSessionKey, false));
      });

      globalConnection.on('getSupportMessage', (messageResponse, clientSessionKey) => {
        isLog && console.log(messageResponse, clientSessionKey, "messageResponse");
        // other supporter message listener
        if (!messageResponse || !clientSessionKey) { return false; }
        audioStart();
        let activeUserDataItem = null;
        dispatch(changeUserIsTyping(false));
        if (localStorage.getItem("activeUserData") && MainService.isJson(localStorage.getItem("activeUserData"))) {
          activeUserDataItem = JSON.parse(localStorage.getItem("activeUserData"))
        }
        if (messageResponse && activeUserDataItem && messageResponse.supportSessionId === activeUserDataItem.supportSessionId) {
          getChatBoardPosition(messageResponse, !checkPositionIsBottom());
          dispatch(addMessageToActiveUserMessages(messageResponse));
        }
        dispatch(changeCurrentUserLastMessage(clientSessionKey, messageResponse));
        dispatch(changeClientIsTyping(clientSessionKey, false));

      });

      globalConnection.on('clientRegistered', (clentDataResponse) => {
        isLog && console.log(clentDataResponse);
        if (!clentDataResponse) { return false; }
        dispatch(changeCurrentUserData(clentDataResponse));
      });

      globalConnection.on('receiveOnlineClientsIds', (userIds) => {
        isLog && console.log(userIds, "userIds");
        if (userIds && userIds.length) {
          dispatch(changeUsersOnlineStatus(userIds));
        }
      });

      globalConnection.on('isOnline', (clientSessionKey, bool) => {
        isLog && console.log(clientSessionKey, bool);
        if (!clientSessionKey) { return false; }
        dispatch(changeUserOnlineStatus(clientSessionKey, bool));
      });

      globalConnection.on('messageTyping', (clientSessionKey, bool, typerUserName) => {
        isLog && console.log(clientSessionKey, bool, typerUserName);

        if (!typerUserName) {
          dispatch(changeClientIsTyping(clientSessionKey, bool));
        } else {
          let activeUserDataItem = null;
          if (localStorage.getItem("activeUserData") && MainService.isJson(localStorage.getItem("activeUserData"))) {
            activeUserDataItem = JSON.parse(localStorage.getItem("activeUserData"))
          }
          if (activeUserDataItem && activeUserDataItem.clientSessionKey === clientSessionKey) {
            dispatch(changeUserIsTyping(bool, typerUserName));
          }
        }
      });

      globalConnection.on('inboxMessageTyping', (clientSessionKey, bool, typerUserName) => {
        isLog && console.log(clientSessionKey, bool, typerUserName);
        if (!typerUserName) {
          dispatch(changeClientIsTyping(clientSessionKey, bool));
        } else {
          dispatch(changeUserIsTyping(bool, typerUserName));
        }
      });

      globalConnection.on('supporterConnected', (userId, bool) => {
        isLog && console.log(userId, bool);
        if (!userId) { return false; }
        dispatch(changeTechnicalAssistantOnlineStatus(userId, bool));
      });

      globalConnection.on('removeClientFromInbox', (clientSessionKey) => {
        isLog && console.log(clientSessionKey, "clientSessionKey");
        if (!clientSessionKey) { return false; }
        dispatch(removeClientFromInbox(clientSessionKey));
      });

      globalConnection.on('clientCloseSession', (clientSessionKey) => {
        isLog && console.log(clientSessionKey, "clientCloseSession");
        if (!clientSessionKey) { return false; }
        dispatch(removeClientFromChatBoard(clientSessionKey));
      });

      globalConnection.on('unattacheSupporter', (clientSessionKey) => {
        isLog && console.log(clientSessionKey, "unattacheSupporter");
        if (!clientSessionKey) { return false; }
        dispatch(removeClientFromChatBoard(clientSessionKey));
      });

      globalConnection.on('closeSession', (clientSessionKey) => {
        isLog && console.log(clientSessionKey, "closeSession");
        if (!clientSessionKey) { return false; }
        dispatch(removeClientFromChatBoard(clientSessionKey));
        if (localStorage.getItem("clientActiveCallId")) {
          globalConnection.invoke("HangupCall", localStorage.getItem("activeCallId"), +localStorage.getItem("callId"), 1, user.id).then(() => {
            rtcGlobalConnection && rtcGlobalConnection.closeRoom();
            dispatch(changeCallWaitingStatus(false));
            dispatch(setIsCallInProcess(false));
            dispatch(setRtcGlobalConnection(null));
            localStorage.removeItem("callId");
            localStorage.removeItem("activeCallId");
          }).catch(error => getFail(error));
        }
      });

      globalConnection.on('clientAcceptedCall', (clientSessionKey) => {
        isLog && console.log(clientSessionKey, "clientAcceptedCall");
        dispatch(changeCallWaitingStatus(false));
      });

      globalConnection.on('clientAccpetedScreenShare', (clientSessionKey) => {
        isLog && console.log(clientSessionKey, "clientAccpetedScreenShare");
        dispatch(changeCallWaitingStatus(false));
      });

      globalConnection.on('getUnreadMessageCount', (groupName) => {
        isLog && console.log(groupName, "groupName");
        dispatch(changeGroupUnreadMessagesCount(groupName));
        audioStart();
      });

      globalConnection.on('clientAccpetedScreenShare', (roomId) => {
        if (!roomId) { return false; }
        var rtcGlobalConnectionCopy = new RtcConnection({
          roomId: roomId,
          mediaContainer: document.getElementById('videoContainer'),
          isAudio: true,
          isVideo: false,
          width: 250,
          isScreenShare: true,
          videoWidth: 1280,
          videoHeight: 720,
          frameRate: 30,
          signalR: globalConnection,
          mediaControls: [
            'full-screen', 'stop'
          ]
        });
        rtcGlobalConnectionCopy.joinRoom();
        dispatch(setRtcGlobalConnection(rtcGlobalConnectionCopy));
      });

      globalConnection.on('outOfRangeSupporters', connectionUnAvailableMessage => {
        isLog && console.log(connectionUnAvailableMessage, "connectionUnAvailableMessage");
        connectionUnAvailableMessage && setConnectionUnAvailableMessage(connectionUnAvailableMessage);
      });

      globalConnection.on('clientIsInActiveCall', clientSessionKey => {
        isLog && console.log(clientSessionKey, "clientSessionKey");
        if (!clientSessionKey) { return false; }
        dispatch(setClientActiveCallId(clientSessionKey));
      });

      globalConnection.on('removeConnection', (connectionId, userId) => {
        if (!connectionId) { return false; }
        if (globalConnection.connection && globalConnection.connection.connectionId === connectionId) {
          redirectToDashboard()
        }
      });

      globalConnection.on('attacheToSession', (clientSession, supporterName) => {
        if (!clientSession) { return false; }
        isLog && console.log(clientSession, supporterName);
        if (localStorage.getItem("activeSessionName") &&
          (localStorage.getItem("activeSessionName") === "my-clients")
          && !supporterId
        ) {
          dispatch(addNewUserToMyClients(clientSession));
        }
        attachToSession(clientSession.supportSessionId);

        dispatch(setShowAttacheToSessionNotification(supporterName, clientSession.clientName));
        audioStart();
      });

      globalConnection.on('getHangupCall', (messageResponse, clientSessionKey) => {
        isLog && console.log(messageResponse, clientSessionKey);
        let messageResponseCopy = { ...messageResponse };
        getChatBoardPosition(messageResponse, !checkPositionIsBottom());
        dispatch(changeCallWaitingStatus(false));
        dispatch(setIsCallInProcess(false));
        dispatch(setClientActiveCallId(null));
        let activeUserDataItem = null;
        if (messageResponseCopy) {
          if (localStorage.getItem("activeUserData") && MainService.isJson(localStorage.getItem("activeUserData"))) {
            activeUserDataItem = JSON.parse(localStorage.getItem("activeUserData"));
          }
          if (activeUserDataItem && messageResponseCopy.supportSessionId === activeUserDataItem.supportSessionId) {
            if (messageResponseCopy.mediaMessage && messageResponseCopy.mediaMessage.endTime && messageResponseCopy.mediaMessage.endTime.includes("Z")) {
              messageResponseCopy.mediaMessage.endTime = messageResponseCopy.mediaMessage.endTime.split("Z")[0];
            }
            dispatch(addMessageToActiveUserMessages(messageResponseCopy));
          } else {
            dispatch(changeCurrentUserLastMessage(clientSessionKey, messageResponseCopy));
          }
        }
      });

      // exceptions
      globalConnection.on('activeCallExc', message => {
        isLog && console.log(message, "exeption message");
        if (!message) { return false; }
        message && AlertService.alert(ERROR_KEY, message);
        dispatch(setClientIsInActiveCallException(true));
      });

      globalConnection.on('actionDeniedExc', message => {
        isLog && console.log(message, "exeption message");
        if (!message) { return false; }
        message && AlertService.alert(ERROR_KEY, message);
        // dispatch(setClientIsInActiveCallException(true));
      });

      globalConnection.on('disabledSupporterException', message => {
        isLog && console.log(message, "exeption message");
        if (!message) { return false; }
        message && AlertService.alert(ERROR_KEY, message);
        // dispatch(setClientIsInActiveCallException(true));
      });

      globalConnection.on('paymentPackageExpiredExc', message => {
        isLog && console.log(message, "exeption message");
        if (!message) { return false; }
        message && AlertService.alert(ERROR_KEY, message);
        // dispatch(setClientIsInActiveCallException(true));
      });

      globalConnection.on('attacheSupporterToSessionExc', message => {
        isLog && console.log(message, "exeption message");
        if (!message) { return false; }
        message && AlertService.alert(ERROR_KEY, message);
        // dispatch(setClientIsInActiveCallException(true));
      });

      globalConnection.on('notFoundExc', message => {
        isLog && console.log(message, "exeption message");
        if (!message) { return false; }
        message && AlertService.alert(ERROR_KEY, message);
        // dispatch(setClientIsInActiveCallException(true));
      });

      globalConnection.on('offlineClientExc', message => {
        isLog && console.log(message, "exeption message");
        if (!message) { return false; }
        message && AlertService.alert(ERROR_KEY, message);
        // dispatch(setClientIsInActiveCallException(true));
      });

      globalConnection.on('globalException', (message, stackTrace, source) => {
        isLog && console.log(`message-> ${message}, stackTrace-> ${stackTrace}, source-> ${source}`);
      });

    }
  }, [globalConnection]);

  useEffect(() => {
    isLog && console.log("useEffect");
    if (isConnectionOk()) {
      globalConnection.on('getHangupCall', (messageResponse, clientSessionKey) => {
        rtcGlobalConnection && rtcGlobalConnection.closeRoom();
      })
    }
  }, [rtcGlobalConnection])

  const connectGroups = async (connection) => {
    if (
      !pathname.includes("/groups") &&
      user &&
      localStorage.getItem("activeGroupsName") && MainService.isJson(localStorage.getItem("activeGroupsName")) && JSON.parse(localStorage.getItem("activeGroupsName")).length &&
      (user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY)
    ) {
      let activeGroupsNameItem = JSON.parse(localStorage.getItem("activeGroupsName"));
      let currentLanguage = localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY;
      activeGroupsNameItem.forEach(group => {
        if (group.isChecked) {
          isLog && console.log("SupporterConnection");
          connection.invoke("SupporterConnection", group.groupName, user.id, group.partnerId, false, currentLanguage).catch((err) => {
            dispatch(setActiveGroupsName(group, true));
          });
        }
      })
    }
  }

  useEffect(() => {
    isLog && console.log("useEffect");
    if (globalConnection && !pathname.includes("/dashboard")) {
      if (localStorage.getItem("typing") && MainService.isJson(localStorage.getItem("typing"))) {
        if (globalConnection.state === 'Connected') {
          globalConnection.invoke("Typing", JSON.parse(localStorage.getItem("typing")).clientSessionKey, false, JSON.parse(localStorage.getItem("typing")).id).then(() => {
            disconnect();
          });
        }
      } else {
        disconnect();
      }
    }
  }, [globalConnection, !pathname.includes("/dashboard")]);

  const disconnect = () => {
    isLog && console.log("The 'disconnect' method was called");
    rtcGlobalConnection && rtcGlobalConnection.closeRoom();
    globalConnection && globalConnection.stop();
    dispatch(changeCallWaitingStatus(false));
    dispatch(setIsCallInProcess(false));
    dispatch(setRtcGlobalConnection(null));
    dispatch(setGlobalConnectionWithRedux(null));
    localStorage.removeItem("callId");
    localStorage.removeItem("activeCallId");
    localStorage.removeItem("isCallInProcess");
    localStorage.removeItem("connectionId");
  }

  const attachToSession = (supportSessionId) => {
    let attachedSessionIds = []
    if (localStorage.getItem("attachedSessionIds") && MainService.isJson(localStorage.getItem("attachedSessionIds"))) {
      attachedSessionIds = JSON.parse(localStorage.getItem("attachedSessionIds"));
    }
    if (!attachedSessionIds.includes(supportSessionId)) {
      attachedSessionIds.push(supportSessionId);
    }
    localStorage.setItem("attachedSessionIds", JSON.stringify(attachedSessionIds));
  }

  const checkPositionIsBottom = () => {
    let messagesBlock = document.getElementById('messages');
    if (messagesBlock) {
      return messagesBlock.scrollHeight - (messagesBlock.scrollTop + messagesBlock.offsetHeight);
    } else {
      return false;
    }
  }

  const getChatBoardPosition = (messageObj, isBottom) => {
    var element = document.getElementById('messages');
    if (isBottom) {
      if (element) {
        setTimeout(() => {
          element.scrollTop = element.scrollHeight;
        }, 300);
      }
    } else {
      messageObj && dispatch(setNewMessageObj(messageObj));
    }
  }

  const redirectToDashboard = () => {
    setIsShowConnectionModal(true);
    setTimeout(() => {
      setIsShowConnectionModal(false);
      props.history.push(`/${language}/profile`)
    }, 2000);
  }

  const audioStart = () => {
    if (
      !localStorage.getItem("isMuted") ||
      (localStorage.getItem("isMuted") && MainService.isJson(localStorage.getItem("isMuted")) && JSON.parse(localStorage.getItem("isMuted")) === false)
    ) {
      var audioElement = document.getElementById("audio");
      audioElement.src = MessageRingtone;
      audioElement.autoplay = true;
    }
  }

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  };

  return translationService ? <Auxiliary>
    <audio id="audio" className="d-none" />
    <audio id="call-waiting-ringtone" className="d-none" />
    {
      connectionUnAvailableMessage.length ?
        <UnAvailableConnectionModal
          message={connectionUnAvailableMessage}
          setConnectionUnAvailableMessage={setConnectionUnAvailableMessage}
        /> :
        null
    }

    {
      showAttacheToSessionNotification ?
        <AttachToSessionModal
          data={showAttacheToSessionNotification}
          history={props.history}
          message={
            translationService.translate("TR_SUPPORTER_TO_SESSION")
              .replace("{0}", user.fullName ? user.fullName : "")
              .replace("{1}", showAttacheToSessionNotification.supporterName)
              .replace("{2}", showAttacheToSessionNotification.sessionName)
          }
          isShowLink={!pathname.includes("my-clients") || (pathname.includes("my-clients") && supporterId) ? true : false}
        />
        : null
    }

    {
      user &&
        (pathname.includes("/inbox") || pathname.includes("/my-clients") || pathname.includes("/all")) &&
        (user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY) &&
        (!activeGroupsName.length || (activeGroupsName.length && !activeGroupsName.find(group => group.groupName === activeGroupName))) ?
        <GlobalNotificationAlert
          path={`/${language}/dashboard/groups`}
          linkName={translationService.translate("TR_GROUPS")}
          text={`${translationService.translate("TR_NOT_CONNECTED_SUPPORTER")}.`}
        />
        : null
    }
    {
      user && user.demoExpDuration ?
        <GlobalNotificationAlert
          path={`/${language}/subscriptions`}
          linkName={translationService.translate("TR_SUBSCRIBE_NOW")}
          text={`${translationService.translate("TR_DEMO_DURATION").replace("{0}", user.demoExpDuration)}`}
        />
        : null
    }
    {
      isShowConnectionModal ?
        <Reconnect />
        : null
    }

  </Auxiliary> : null
}


export default withRouter(Listeners)

