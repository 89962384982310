import React from 'react';
import { useSelector } from 'react-redux';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import OvalButtonSpinner from '../Spinners/OvalButtonSpinner';

const ActionButtonWithOvalSpinner = (props) => {
  const buttonSpinners = useSelector(state => state.spinner.buttonSpinners);
  const currentSpinner = buttonSpinners.find(spinner => spinner === props.spinnerId);
  return <button
    type={props.type}
    style={{ maxWidth: props.maxWidth ? props.maxWidth : "" }}
    disabled={currentSpinner || props.disabled}
    className={`d-flex justify-content-center align-items-center ${props.className}`}
    onClick={props.onClick}
    title={props.title}>
    {
      currentSpinner ?
        <Auxiliary>{'\u00A0'}<OvalButtonSpinner spinner={currentSpinner} spinnerColor={props.spinnerColor ? props.spinnerColor : "#fff"} />{'\u00A0'}</Auxiliary> :
        props.name ? props.name : props.iconClass ? <i className={`${props.iconClass}`} /> : props.children
    }
  </button>
};

export default ActionButtonWithOvalSpinner;  