import { takeLatest, call, put } from 'redux-saga/effects';
import AlertService from "../../Services/AlertService";
import { ERROR_KEY } from "../../Constants/MainKeys";
import { GET_TRANSLATIONS_SUCCESS, GET_TRANSLATIONS_REQUEST } from '../../Constants/ReduxKeys';
import ApiService from './../../Services/ApiService';
import { addSpinner, removeSpinner, setIsShowFlagSpinner } from './../Actions/spinner';
import uuid from 'react-uuid';
const spinnerId = uuid();

export default function* watcher() {
  yield takeLatest(GET_TRANSLATIONS_REQUEST, getTranslations);
}

function* getTranslations(action) {
  const { isShowFlagSpinner } = action.payload;
  try {
    if (isShowFlagSpinner) {
      yield put(setIsShowFlagSpinner(true));
    } else {
      yield put(addSpinner(spinnerId));
    }
    const allTranslations = yield call(ApiService.getAllTranslations);
    if (allTranslations) {
      yield put({
        type: GET_TRANSLATIONS_SUCCESS,
        payload: [...allTranslations.data]
      });
    }
    if (isShowFlagSpinner) {
      yield put(setIsShowFlagSpinner(false));
    } else {
      yield put(removeSpinner(spinnerId));
    }
  } catch (error) {
    if (isShowFlagSpinner) {
      yield put(setIsShowFlagSpinner(false));
    } else {
      yield put(removeSpinner(spinnerId));
    }
    error && AlertService.alert((AlertService.checkMessageType(error?.respcode) || ERROR_KEY), error);
  }
}


