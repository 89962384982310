import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR_KEY } from '../../../Constants/MainKeys'
import AlertService from '../../../Services/AlertService'
import { addButtonSpinner, addSpinner, removeButtonSpinner, removeSpinner } from '../../../Store/Actions/spinner'
import { CirclePicker } from 'react-color'
import uuid from 'react-uuid'
import ApiService from '../../../Services/ApiService'
import TranslationService from '../../../Services/translationService'
import Widget from '../WidgetSettings/Components/Widget'
import { TiArrowSync } from 'react-icons/ti'
import StepFooter from './Components/StepFooter'
import CompanyLogo from '../WidgetSettings/Components/CompanyLogo'

const spinnerId = uuid();
export default function BecomeApartnerSper2(props) {

  const dispatch = useDispatch();
  const { language } = useSelector(state => state.language);
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);
  const [id, setId] = useState(null);
  const [widgetGradientColor, setWidgetGradientColor] = useState(null);
  const [color, setColor] = useState("#00266C");
  const [textColor, setTextColor] = useState("#ffffff");
  const [widgetSize, setWidgetSize] = useState("4em");
  const [isSolidColor, setIsSolidColor] = useState(true);
  const [isGradient, setIsGradient] = useState(false);
  const [form, setForm] = useState({});
  const [isActiveCircularMenu, setIsActiveCircularMenu] = useState(false);
  const [widget_size, set_widget_size] = useState("widget_medium_size");
  const [isShowWidget, setIsShowWidget] = useState(true);
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [welcomeSubMessage, setWelcomeSubMessage] = useState("");
  const [widgetFAQs, setWidgetFAQs] = useState([]);
  const [companyLogoPath, setCompanyLogoPath] = useState(null);

  useEffect(() => {
    getWidgetData();
  }, []);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (color) {
      document.querySelector('.vnd--floating-btn')?.style.setProperty('--primary-color', color);
      document.querySelector('.vnd-local-message')?.style.setProperty('--primary-color', color);
      if (document.querySelectorAll('.vnd--circular-menu')?.length) {
        document.querySelectorAll('.vnd--circular-menu').forEach(element => {
          element.style.setProperty('--primary-color', color);
        })
      }
    }
  }, [color])

  useEffect(() => {
    if (textColor) {
      if (document.querySelectorAll("#vndPlusIcon").length) {
        document.querySelectorAll("#vndPlusIcon").forEach(element => {
          element?.style.setProperty('--white-color', textColor);
        })
      }
      if (document.querySelectorAll('.vnd--circular-menu')?.length) {
        document.querySelectorAll('.vnd--circular-menu').forEach(element => {
          element.style.setProperty('--white-color', textColor)
        })
      }
    }
  }, [textColor])

  useEffect(() => {
    if (document.querySelectorAll('.vnd--circular-menu')?.length) {
      document.querySelectorAll('.vnd--circular-menu').forEach(element => {
        element.style.setProperty('--vnd-4em', widgetSize);
      })
    }
  }, [widgetSize])

  const getWidgetData = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getWidgetDataByPageStepNumber(2).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        setForm(data);
        data.id && setId(data.id);
        data.color && setColor(data.color);
        data.textColor && setTextColor(data.textColor);
        data.companyLogoPath && setCompanyLogoPath(data.companyLogoPath);
        if (data.gradient) {
          setIsGradient(true);
          setWidgetGradientColor(data.gradient);
          setIsSolidColor(false);
        }
        if (data.widgetSize) {
          var size = data.widgetSize === 48 ? "3em" : data.widgetSize === 60 ? "4em" : data.widgetSize === 80 ? "5em" : "4em"
          set_widget_size(size === "3em" ? "widget_small_size" : size === "4em" ? "widget_medium_size" : size === "5em" ? "widget_large_size" : "widget_medium_size");
          setWidgetSize(size);
        }

        if (data.welcomeMessage) { setWelcomeMessage(data.welcomeMessage) }
        if (data.welcomeSubMessage) { setWelcomeSubMessage(data.welcomeSubMessage) }
        if (data.widgetFAQs) { setWidgetFAQs(data.widgetFAQs) };
        setForm(data);
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const interpolateColor = (color1, color2) => {
    if (!color2) { color2 = "#00266C" };
    const r1 = parseInt(color1.slice(1, 3), 16);
    const g1 = parseInt(color1.slice(3, 5), 16);
    const b1 = parseInt(color1.slice(5, 7), 16);
    const r2 = parseInt(color2.slice(1, 3), 16);
    const g2 = parseInt(color2.slice(3, 5), 16);
    const b2 = parseInt(color2.slice(5, 7), 16);
    const r = Math.round((r1 + r2) / 2);
    const g = Math.round((g1 + g2) / 2);
    const b = Math.round((b1 + b2) / 2);
    setWidgetGradientColor(`#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`)
  }

  const onChangeColor = (color) => {
    setColor(color);
    isGradient && interpolateColor(color, widgetGradientColor);
  }

  const changeGradientColors = () => {
    setColor(widgetGradientColor);
    setWidgetGradientColor(color);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    setButtonSpinner(spinnerId);
    if (isGradient) form.gradient = widgetGradientColor;
    form.id = id;
    form.stepNumber = 2;
    form.color = color;
    form.textColor = textColor;
    form.widgetSize = widgetSize === "3em" ? 48 : widgetSize === "4em" ? 64 : widgetSize === "5em" ? 80 : 64;

    ApiService.becomePartner(form).then(() => {
      props.history.push(`/${language}/become-partner/step-3`);
      extractButtonSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId));
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
    spinnerId && extractSpinner(spinnerId);
  };

  return translationService ? <section className="top-section-steps">
    <div className="container">
      <div className='row'>
        <div className='col-12'>
          <div className="vnd--section-title-wrapper">
            <p className="vnd--upp-text-20">{translationService.translate("TR_WIDGET_UI_SETTINGS")}</p>
            <span className="font-size-14">{translationService.translate("TR_COMPANY_DETAILS_TEXT")}</span>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='row'>
            <div className='col-12 my-2'>
              <div className="vnd--m-block vnd-border h-100">
                <div className={`form-group mb-0 ${isGradient ? "disabled" : ""}`}>
                  <label className='font-bold' htmlFor="">{translationService.translate("TR_CHOOSE_WIDGET_COLOR")}</label>
                  <div className='d-flex align-items-center'>
                    <CirclePicker
                      circleSize={45}
                      colors={["#EA2027", "#009432", "#00266C", "#B53471", "#F79F1F"]}
                      onChangeComplete={color => {
                        onChangeColor(color.hex)
                      }}
                    />
                    <div className='vnd--color-picker w-auto'>
                      <div className='color-picker' onClick={() => {
                        document.getElementById('color') && document.getElementById('color').click();
                      }}>
                        <input
                          id="color"
                          type="color"
                          value={color}
                          style={{ border: "1px solid #ffffff" }}
                          onChange={e => {
                            onChangeColor(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 my-2'>
              <div className="vnd--m-block light-brand-background h-100">
                <div className='d-flex justify-content-between flex-wrap'>
                  <div className="form-group">
                    <label className='font-bold'>{translationService.translate("TR_CHOOSE_WIDGET_COLOR_STYLE")} *</label>
                    <div className="d-flex align-items-center">
                      <div className="custom-control custom-radio mx-2">
                        <input
                          id="isSolidColor"
                          name="choose_color_style"
                          type="radio"
                          className="custom-control-input"
                          checked={isSolidColor ? true : false}
                          onChange={() => {
                            setIsGradient(false);
                            setIsSolidColor(true);
                            setWidgetGradientColor(null);
                          }}
                        />
                        <label className="custom-control-label capitalize" htmlFor="isSolidColor">
                          {translationService.translate("TR_SOLID_COLOR")}
                        </label>
                      </div>
                      <div className="custom-control custom-radio mx-2">
                        <input
                          id="isGradient"
                          name="choose_color_style"
                          type="radio"
                          className="custom-control-input"
                          checked={isGradient ? true : false}
                          onChange={() => {
                            setIsGradient(true);
                            setIsSolidColor(false);
                            interpolateColor(color, widgetGradientColor);
                          }}
                        />
                        <label className="custom-control-label capitalize" htmlFor="isGradient">
                          {translationService.translate("TR_GRADIENT")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={`form-group ${!isGradient ? "disabled" : ""}`}>
                    <label className='font-bold' htmlFor="">{translationService.translate("TR_CHOOSE_WIDGET_COLOR")}</label>
                    <div className='d-flex align-items-center'>
                      <CirclePicker
                        circleSize={45}
                        colors={["#00266C", "#F79F1F"]}
                        onChangeComplete={color => {
                          setWidgetGradientColor(color.hex);
                        }}
                      />
                      <div className='vnd--color-picker w-auto'>
                        <div className='color-picker' onClick={() => {
                          document.getElementById('gradient') && document.getElementById('gradient').click();
                        }}>
                          <input
                            id="gradient"
                            type="color"
                            value={widgetGradientColor || ""}
                            style={{ border: "1px solid #ffffff" }}
                            onChange={e => {
                              setWidgetGradientColor(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className='vnd--color-picker w-auto'>
                        <div
                          className='color-picker change-gradient-colors m-0'
                          onClick={changeGradientColors}
                          title={translationService.translate("TR_CHANGE_GRADIENT_COLORS")}
                        >
                          <TiArrowSync fontSize={24} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 my-2'>
              <div className="vnd--m-block vnd-border h-100">
                <div className="form-group mb-0">
                  <label className='font-bold' htmlFor="">{translationService.translate("TR_CHOOSE_WIDGET_TEXT_COLOR")} *</label>
                  <div className='d-flex align-items-center'>
                    <CirclePicker
                      circleSize={45}
                      colors={["#ffffff", "#000000"]}
                      onChangeComplete={color => {
                        setTextColor(color.hex);
                      }}
                    />
                    <div className='vnd--color-picker w-auto'>
                      <div className='color-picker' onClick={() => {
                        document.getElementById('textColor') && document.getElementById('textColor').click();
                      }}>
                        <input
                          id="textColor"
                          type="color"
                          value={color}
                          style={{ border: "1px solid #ffffff" }}
                          onChange={e => {
                            setTextColor(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 my-2'>
              <div className="vnd--m-block vnd-border h-100">
                <div className="form-group mb-0">
                  <div>
                    <label className='font-bold' htmlFor="">{translationService.translate("TR_WIDGET_BUTTON_SIZE")}</label>
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="custom-control custom-radio m-2">
                        <input
                          id="isSmallPlusButton"
                          name="choose_button_size"
                          type="radio"
                          className="custom-control-input"
                          checked={widgetSize === "3em" ? true : false}
                          onChange={() => {
                            setWidgetSize("3em")
                            set_widget_size("widget_small_size");
                          }}
                        />
                        <label className="custom-control-label capitalize"
                          htmlFor="isSmallPlusButton">{translationService.translate("TR_SMALL")}</label>
                      </div>
                      <div className="custom-control custom-radio m-2">
                        <input
                          id="isMediumPlusButton"
                          name="choose_button_size"
                          type="radio"
                          className="custom-control-input"
                          checked={widgetSize === "4em" ? true : false}
                          onChange={() => {
                            setWidgetSize("4em")
                            set_widget_size("widget_medium_size");
                          }}
                        />
                        <label className="custom-control-label capitalize"
                          htmlFor="isMediumPlusButton">{translationService.translate("TR_MEDIUM")}</label>
                      </div>
                      <div className="custom-control custom-radio m-2">
                        <input
                          id="isLargePlusButton"
                          name="choose_button_size"
                          type="radio"
                          className="custom-control-input"
                          checked={widgetSize === "5em" ? true : false}
                          onChange={() => {
                            setWidgetSize("5em")
                            set_widget_size("widget_large_size");
                          }}
                        />
                        <label className="custom-control-label capitalize"
                          htmlFor="isLargePlusButton">{translationService.translate("TR_LARGE")}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div className='vnd--m-block light-brand-background'>
                <CompanyLogo
                  id={id}
                  companyLogoPath={companyLogoPath}
                  setCompanyLogoPath={setCompanyLogoPath}
                  translationService={translationService}
                />
              </div>
            </div>
          </div>
        </div>
        <Widget
          isActiveCircularMenu={isActiveCircularMenu}
          setIsActiveCircularMenu={setIsActiveCircularMenu}
          setIsShowWidget={setIsShowWidget}
          isShowWidget={isShowWidget}
          widget_size={widget_size}
          widgetGradientColor={widgetGradientColor}
          color={color}
          textColor={textColor}
          widgetSize={widgetSize}
          widgetFAQs={widgetFAQs}
          welcomeMessage={welcomeMessage ? welcomeMessage : translationService.translate("TR_WIDGET_WELCOME_TEXT")}
          welcomeSubMessage={welcomeSubMessage ? welcomeSubMessage : translationService.translate("TR_WIDGET_WELCOME_SUB_TEXT")}
          translationService={translationService}
          useWidgetButton={true}
        />
      </div>
    </div>
    <StepFooter
      translationService={translationService}
      setpNumber="2"
      backUrl={`/${language}/become-partner/step-1`}
      actionButtonName={translationService.translate("TR_CONTINUE")}
      spinnerId={spinnerId}
      onSubmit={onSubmit}
    />
  </section> : null
}
