import {
  USER_LOGIN_REQUEST,
  USER_REGISTRATION_REQUEST,
  AUTH_LOGOUT_REQUEST,
  UPDATE_USER_DATA,
  REMOVE_LOGIN_ERROR_MESSAGE_REQUEST,
  SET_USER_DATA,
  SET_TOKEN
} from '../../Constants/ReduxKeys';

export function userLogin(data, spinnerId, history) {
  return {
    type: USER_LOGIN_REQUEST,
    payload: { data, spinnerId, history },
  };
};

export function setUserData(data) {
  return {
    type: SET_USER_DATA,
    payload: data,
  };
};

export function setToken(data) {
  return {
    type: SET_TOKEN,
    payload: data,
  };
};

export function userRegistration(data, spinnerId, history) {
  return {
    type: USER_REGISTRATION_REQUEST,
    payload: { data, spinnerId, history },
  };
};

export function logout() {
  return {
    type: AUTH_LOGOUT_REQUEST,
    payload: {}
  }
};

export function updateUserData(data) {
  return {
    type: UPDATE_USER_DATA,
    payload: data
  }
};

export function removeLoginErrorMessage() {
  return {
    type: REMOVE_LOGIN_ERROR_MESSAGE_REQUEST,
    payload: true
  };
};


