import React from 'react'

export default function CompletedScreenShareSvg(props) {


  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 256 256"
      className={props.className}
    >
      <circle style={{ fill: "#094" }} cx="128" cy="128" r="119.5" />
      <rect style={{ fill: "none" }} width="50" height="50" />
      <path style={{ fill: "#fff" }} d="M171.87,160.91a11,11,0,0,0,11-11V95.09a11,11,0,0,0-11-11H84.13a11,11,0,0,0-11,11v54.85a11,11,0,0,0,11,11H67.67a5.48,5.48,0,0,0,0,11H188.33a5.48,5.48,0,0,0,0-11Zm-38.39-19.37v-12c-15.25,0-25.27,4.65-32.9,14.91,3.06-14.65,11.56-29.22,32.9-32.2V100.58L153.28,119a2.76,2.76,0,0,1,0,4Zm0,0" /></svg>
  )
}
