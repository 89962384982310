import {
  ADD_BUTTON_SPINNER,
  REMOVE_BUTTON_SPINNER,
  SET_FLAG_SPINNER,
  ADD_SPINNER,
  REMOVE_SPINNER,
} from '../../Constants/ReduxKeys';

export function setIsShowFlagSpinner(bool) {
  return {
    type: SET_FLAG_SPINNER,
    payload: bool
  };
};

export function addSpinner(spinnerId) {
  return {
    type: ADD_SPINNER,
    payload: spinnerId
  };
};

export function removeSpinner(spinnerId) {
  return {
    type: REMOVE_SPINNER,
    payload: spinnerId
  };
};

export function addButtonSpinner(spinnerId) {
  return {
    type: ADD_BUTTON_SPINNER,
    payload: spinnerId
  };
};

export function removeButtonSpinner(spinnerId) {
  return {
    type: REMOVE_BUTTON_SPINNER,
    payload: spinnerId
  };
};


