import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Nav, NavItem, UncontrolledTooltip, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTab, setconversationNameInOpenChat } from "./../../../Store/Actions/layout";
import logo from "./../../../assets/logo/logo-white.png"
import { logout } from '../../../Store/Actions/user';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import { LANGUAGE_KEY, PARTNER_USER_TYPE_ID_KEY, SUPERVISOR_USER_TYPE_ID_KEY, SUPPORTER_USER_TYPE_ID_KEY } from '../../../Constants/MainKeys';
import { setActiveGroupName, setPartnerId } from '../../../Store/Actions/main';
import { withRouter } from "react-router-dom"
import { setActiveUserData } from '../../../Store/Actions/chat';
import TranslationService from './../../../Services/translationService';
import { getTranslations } from '../../../Store/Actions/translation';
import { setLanguage } from '../../../Store/Actions/language';
import PuffLoader from "react-spinners/PuffLoader";
import useWindowSize from '../../../Hooks/useWindowSize';
import MainService from '../../../Services/mainService';



function LeftSidebarMenu(props) {
    const { pathname } = useLocation()
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const language = useSelector(state => state.language.language);
    const translations = useSelector(state => state.translation.translations);
    const languages = useSelector(state => state.language.languages);
    const isShowSettings = useSelector(state => state.main.isShowSettings);
    const partnerId = useSelector(state => state.main.partnerId);
    const { subscriptionService } = useSelector(state => state.main);
    const { isShowFlagSpinner } = useSelector(state => state.spinner);

    const { activeUserData } = useSelector(state => state.chat);

    const [translationService, setTranslationService] = useState(null);
    const [dropdownOpen1, setDropdownOpen1] = useState(false);
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const windowSize = useWindowSize();

    const toggle1 = () => setDropdownOpen1(!dropdownOpen1);
    const toggle2 = () => setDropdownOpen2(!dropdownOpen2);

    const changeLanguage = isocode => {
        if (!isocode || isocode === language) { return false; }
        localStorage.setItem(LANGUAGE_KEY, isocode);
        setLanguageHandler(isocode);
        const oldIsoCode = pathname.split("/")[1];
        const newPath = pathname.toString().replace(oldIsoCode, `${isocode}`);
        props.history.push(newPath);
        dispatch(getTranslations(true));
    }

    const setLanguageHandler = useCallback(language => {
        dispatch(setLanguage(language));
    }, []);

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    const logOut = () => {
        dispatch(logout());
        dispatch(setActiveGroupName(null));
        dispatch(setconversationNameInOpenChat("group"));
        dispatch(setPartnerId(null));
        dispatch(setActiveUserData(null));
    }

    if (activeUserData && windowSize.width < 1200){
        return false
    }

    return translationService ? <React.Fragment>

        <div className='side-menu flex-lg-column dashboard-menu-wrapper'>
            <div className="navbar-brand-box">
                <Link to={user && (user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY) ? `/${language}/dashboard/groups` : `/${language}`} className="logo logo-dark">
                    <span className="logo-sm">
                        <img src={logo} alt="logo" height="30" />
                    </span>
                </Link>

                <Link to={user && (user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY) ? `/${language}/dashboard/groups` : `/${language}`} className="logo logo-light">
                    <span className="logo-sm">
                        <img src={logo} alt="logo" height="30" />
                    </span>
                </Link>
            </div>
            <div className="flex-lg-column mb-auto">
                <Nav pills className="side-menu-nav justify-content-center dashboard-menu" role="tablist">
                    {
                        user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY ?
                            <Auxiliary>
                                <NavItem id="Groups" className='dashboard-menu-item'>
                                    <Link
                                        to={`/${language}/dashboard/groups`}
                                        className={`dashboard-menu-link nav-link ${pathname.includes("/dashboard/groups") ? "active" : ""}`}>
                                        <i className="ri-group-line"></i>
                                    </Link>
                                </NavItem>
                                <UncontrolledTooltip target="Groups" className='capitalize' placement={windowSize && windowSize.width > 1199 ? "right" : "top"}>
                                    {translationService.translate("TR_GROUPS")}
                                </UncontrolledTooltip>
                                <NavItem id="Settings" className='dashboard-menu-item'>
                                    <Link
                                        to={`/${language}/dashboard/settings`}
                                        className={`dashboard-menu-link nav-link ${pathname.includes("/dashboard/settings") ? "active" : ""}`}>
                                        <i className="ri-settings-2-line"></i>
                                    </Link>
                                </NavItem>
                                <UncontrolledTooltip target="Settings" className='capitalize' placement={windowSize && windowSize.width > 1199 ? "right" : "top"}>
                                    {translationService.translate("TR_SETTINGS")}
                                </UncontrolledTooltip>
                            </Auxiliary>
                            : null
                    }
                    {
                        user.userTypeId === PARTNER_USER_TYPE_ID_KEY ?
                            <Auxiliary>
                                <NavItem id="WidgetSettings" className='dashboard-menu-item'>
                                    <Link
                                        to={`/${language}/widget-settings`}
                                        className={`dashboard-menu-link nav-link ${pathname.includes("/widget-settings") ? "active" : ""}`}
                                    >
                                        <i className="ri-sound-module-line"></i>
                                    </Link>
                                </NavItem>
                                <UncontrolledTooltip target="WidgetSettings" className='capitalize' placement={windowSize && windowSize.width > 1199 ? "right" : "top"}>
                                    {translationService.translate("TR_WIDGET_SETTINGS")}
                                </UncontrolledTooltip>

                                <NavItem id="Chat" className='dashboard-menu-item'>
                                    <Link
                                        to={`/${language}/dashboard/chats/inbox`}
                                        className={`dashboard-menu-link nav-link ${pathname.includes("/dashboard/chats") && !pathname.includes("/technical-assistant") ? "active" : ""}`}>
                                        <i className="ri-message-3-line"></i>
                                    </Link>
                                </NavItem>
                                <UncontrolledTooltip target="Chat" className='capitalize' placement={windowSize && windowSize.width > 1199 ? "right" : "top"}>
                                    {translationService.translate("TR_CHATBOARD")}
                                </UncontrolledTooltip>

                            </Auxiliary>
                            : null
                    }
                    {
                        user.userTypeId === PARTNER_USER_TYPE_ID_KEY || (partnerId && user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY) ?
                            <Auxiliary>
                                <NavItem id="TechnicalAssistants" className='dashboard-menu-item'>
                                    <Link
                                        to={`/${language}/dashboard/technical-assistants`}
                                        className={`dashboard-menu-link nav-link ${pathname.includes("/dashboard/technical-assistants") ||
                                            pathname.includes("/technical-assistant") ? "active" : ""}`
                                        }
                                    >
                                        <i className="ri-team-line"></i>
                                    </Link>
                                </NavItem>
                                <UncontrolledTooltip target="TechnicalAssistants" className='capitalize' placement={windowSize && windowSize.width > 1199 ? "right" : "top"}>
                                    {translationService.translate("TR_TECHNICAL_ASSISTANTS")}
                                </UncontrolledTooltip>
                                {
                                    MainService.checkServicePermission(subscriptionService, "helping_scripts_service") ?
                                        <>
                                            <NavItem id="Scripts" className='dashboard-menu-item'>
                                                <Link
                                                    to={`/${language}/dashboard/script-groups`}
                                                    className={`dashboard-menu-link nav-link ${pathname.includes("/dashboard/script") ? "active" : ""}`
                                                    }
                                                >
                                                    <i className="ri-chat-new-line"></i>
                                                </Link>
                                            </NavItem>
                                            <UncontrolledTooltip target="Scripts" className='capitalize' placement={windowSize && windowSize.width > 1199 ? "right" : "top"}>
                                                {translationService.translate("TR_SCRIPT_GROUP")}
                                            </UncontrolledTooltip>
                                        </>
                                        : null
                                }
                            </Auxiliary>
                            : null
                    }

                    {
                        // user && user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY ?
                        <Auxiliary>
                            <NavItem id="Help" className='dashboard-menu-item'>
                                <Link
                                    to={`/${language}/dashboard/help`}
                                    className={`dashboard-menu-link nav-link ${pathname.includes("/dashboard/help") ? "active" : ""}`
                                    }
                                >
                                    <i className="ri-question-line"></i>
                                </Link>
                            </NavItem>
                            <UncontrolledTooltip target="Help" className='capitalize' placement={windowSize && windowSize.width > 1199 ? "right" : "top"}>
                                {translationService.translate("TR_HELP")}
                            </UncontrolledTooltip>
                        </Auxiliary>
                        // :null
                    }

                    {/* mobil size */}
                    <NavItem id='_Languages' className="profile-user-dropdown btn-group dropup d-flex align-items-center justify-content-center d-xl-none">
                        <UncontrolledTooltip target="_Languages" className='capitalize' placement={windowSize && windowSize.width > 1199 ? "right" : "top"}>
                            {translationService.translate("TR_LANGUAGES")}
                        </UncontrolledTooltip>
                        <ul className='p-0'>
                            {
                                languages && languages.length ?
                                    <Dropdown nav isOpen={dropdownOpen1} className="btn-group dropup profile-user-dropdown mt-0" toggle={toggle1}>
                                        <DropdownToggle tag="a" className='dashboard-menu-item'>
                                            <div
                                                className={`dashboard-menu-link nav-link`}>
                                                {
                                                    isShowFlagSpinner ?
                                                        <div className='d-flex'>
                                                            <PuffLoader size={30} color={"#6FACE3"} />
                                                        </div>
                                                        : <i className="ri-global-line"></i>
                                                }
                                            </div>
                                        </DropdownToggle>
                                        {
                                            dropdownOpen1 ?
                                                <DropdownMenu className='d-flex flex-column'>
                                                    {
                                                        languages.map(languageItem => {
                                                            return <Link key={languageItem.id} className="py-1" to={"#"} onClick={() => {
                                                                changeLanguage(languageItem.isocode2);
                                                                toggle2()
                                                            }}>
                                                                <img src={languageItem.flagPath} alt="/" className="me-1" height="12" />
                                                                <span className="ms-2 align-middle">{languageItem.name}</span>
                                                            </Link>
                                                        })
                                                    }
                                                </DropdownMenu>
                                                : null
                                        }
                                    </Dropdown>
                                    : null
                            }
                        </ul>
                    </NavItem>
                    <NavItem id='Logout' className="profile-user-dropdown btn-group dropup d-flex align-items-center justify-content-center d-xl-none mt-0">
                        <UncontrolledTooltip target="Logout" className='capitalize' placement={windowSize && windowSize.width > 1199 ? "right" : "top"}>
                            {translationService.translate("TR_SIGNOUT")}
                        </UncontrolledTooltip>
                        <div className='dashboard-menu-item ssss'>

                            <div
                                className={`dashboard-menu-link nav-link`}
                                onClick={logOut}
                            >
                                <i className="ri-logout-box-r-line"></i>
                            </div>
                        </div>
                    </NavItem>
                </Nav>
            </div>
            <div className="flex-lg-column d-none d-lg-block">
                <Nav className="side-menu-nav justify-content-center dashboard-menu">

                    <NavItem id='Languages'>

                        <UncontrolledTooltip target="Languages" className='capitalize' placement={windowSize && windowSize.width > 1199 ? "right" : "top"}>
                            {translationService.translate("TR_LANGUAGES")}
                        </UncontrolledTooltip>
                        <ul className='p-0'>
                            {
                                languages && languages.length ?
                                    <Dropdown nav isOpen={dropdownOpen2} className="btn-group dropup profile-user-dropdown" toggle={toggle2}>
                                        <DropdownToggle tag="a" className='dashboard-menu-item'>
                                            <div
                                                className={`dashboard-menu-link nav-link`}>
                                                {
                                                    isShowFlagSpinner ?
                                                        <div className='d-flex'>
                                                            <PuffLoader size={30} color={"#6FACE3"} />
                                                        </div>
                                                        : <i className="ri-global-line"></i>
                                                }
                                            </div>
                                        </DropdownToggle>
                                        {
                                            dropdownOpen2 ?
                                                <DropdownMenu className='d-flex flex-column'>
                                                    {
                                                        languages.map(languageItem => {
                                                            return <Link key={languageItem.id} className="py-1" to={"#"} onClick={() => {
                                                                changeLanguage(languageItem.isocode2);
                                                                toggle2()
                                                            }}>
                                                                <img src={languageItem.flagPath} alt="/" className="me-1" height="12" />
                                                                <span className="ms-2 align-middle">{languageItem.name}</span>
                                                            </Link>
                                                        })
                                                    }
                                                </DropdownMenu>
                                                : null
                                        }
                                    </Dropdown>
                                    : null
                            }
                        </ul>
                    </NavItem>
                    <NavItem id='Logout'>
                        <UncontrolledTooltip target="Logout" className='capitalize' placement={windowSize && windowSize.width > 1199 ? "right" : "top"}>
                            {translationService.translate("TR_SIGNOUT")}
                        </UncontrolledTooltip>
                        <div className='dashboard-menu-item'>
                            <div
                                className={`dashboard-menu-link nav-link`}
                                onClick={logOut}
                            >
                                <i className="ri-logout-box-r-line"></i>
                            </div>
                        </div>
                    </NavItem>

                </Nav>
            </div>
        </div>

    </React.Fragment> : null
}

export default withRouter(LeftSidebarMenu);
