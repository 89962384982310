import {
  CHAT_USER,
  ACTIVE_USER,
  FULL_USER,
  ADD_LOGGED_USER,
  CREATE_GROUP,
  SET_ACTIVE_USER_DATA,
  SET_USERS,
  ADD_MESSAGES_TO_USER,
  UPDATE_ACTIVE_USER_DATA,
  ADD_MESSAGE_TO_ACTIVE_USER_MESSAGES,
  CHANGE_CURRENT_USER_UNREAD_MESSAGES_COUNT,
  RESET_CURRENT_USER_UNREAD_MESSAGES_COUNT,
  ADD_NEW_USER,
  CHANGE_CURRENT_USER_LAST_MESSAGE,
  CHANGE_CURRENT_USER_DATA,
  CHANGE_USERS_ONLINE_STATUS,
  CHANGE_INBOX_TITLE_NOTIFICATION,
  CHANGE_MY_CLIENTS_TITLE_NOTIFICATION,
  SET_DEFAULT_USERS,
  CHANGE_CURRENT_MESSAGE_RECEIVED_FIELD,
  CHANGE_USER_ONLINE_STATUS,
  CHANGE_CLIENT_ISTYPING,
  CHANGE_CALL_WAITING_STATUS,
  CHANGE_GROUP_UNREAD_MESSAGES_COUNT,
  SET_ACTIVE_SESSION_NAME,
  SET_IS_CALL_IN_PROCESS,
  SET_NEW_MESSAGE_OBJ,
  ADD_FIELDS_TO_ACTIVE_USER_DATA_FROM_SESSION,
  CHANGE_ACTIVE_USER_TICKETS,
  SET_TECHNICAL_ASSISTANTS,
  SET_GROUPS,
  CHANGE_TECHNICAL_ASSISTANT_ONLINE_STATUS,
  SET_DEFAULT_GROUPS,
  SET_DEFAULT_TECHNICAL_ASSISTANTS,
  CHANGE_USER_ISTYPING,
  SET_CLIENT_ACTIVE_CALL_ID,
  REMOVE_CLIENT_FROM_INBOX,
  SET_CLIENT_IS_IN_ACTIVE_CALL_EXCEPTION,
  REMOVE_CLIENT_FROM_CHAT_BOARD,
  ADD_NEW_USER_TO_MY_CLIENTS,
  CHANE_CLIENTS_ONLINE_STATUS,
  CHANGE_CURRENT_USER_NOTE,
  SET_COUNTRIES,
  GET_COUNTRIES,
  SET_SELECTED_COUNTRY,
  SET_SELECTED_GROUP,
  SET_SELECTED_SCRIPT,
  GET_SCRIPTS,
  SET_SCRIPT_GROUPS,
  SET_SCRIPTS,
  SET_IS_DISABLED_SCRIPTS,
  TOGGLE_GROUPS_BY_COUNTRY,
  REMOVE_USER_FIELD,
  SORT_CLIENTS,
  REMOVE_CURRNET_CHAT_ANIMATION,
  GET_SCRIPT_GROUPS
} from './../../Constants/ReduxKeys';

export function removeCurrentChatAnimation(clientSessionKey) {
  return {
    type: REMOVE_CURRNET_CHAT_ANIMATION,
    payload: clientSessionKey,
  };
};

export function removeUserFiled(name) {
  return {
    type: REMOVE_USER_FIELD,
    payload: name,
  };
};

export function toggleGroupsByCountry(bool) {
  return {
    type: TOGGLE_GROUPS_BY_COUNTRY,
    payload: bool,
  };
};
export function setIsDisabledScripts(bool) {
  return {
    type: SET_IS_DISABLED_SCRIPTS,
    payload: bool,
  };
};
export function getScriptGroups(data) {
  return {
    type: GET_SCRIPT_GROUPS,
    payload: data,
  };
};

export function setScripts(data) {
  return {
    type: SET_SCRIPTS,
    payload: data,
  };
};

export function setScriptGroups(data) {
  return {
    type: SET_SCRIPT_GROUPS,
    payload: data,
  };
};

export function getScripts(data) {
  return {
    type: GET_SCRIPTS,
    payload: data,
  };
};

export function getCountries(data) {
  return {
    type: GET_COUNTRIES,
    payload: data,
  };
};

export function setCountries(data) {
  return {
    type: SET_COUNTRIES,
    payload: data,
  };
};

export function setSelectedCountry(data) {
  return {
    type: SET_SELECTED_COUNTRY,
    payload: data,
  };
};

export function setSelectedGroup(data) {
  return {
    type: SET_SELECTED_GROUP,
    payload: data,
  };
};

export function setSelectedScript(data) {
  return {
    type: SET_SELECTED_SCRIPT,
    payload: data,
  };
};

export const chatUser = () => ({
  type: CHAT_USER
});

export const changeCurrentUserNote = (data) => ({
  type: CHANGE_CURRENT_USER_NOTE,
  payload: data
});

export const activeUser = (userId) => ({
  type: ACTIVE_USER,
  payload: userId
});

export function setActiveUserData(userId) {
  return {
    type: SET_ACTIVE_USER_DATA,
    payload: userId,
  };
};


export const updateActiveUserData = (data) => ({
  type: UPDATE_ACTIVE_USER_DATA,
  payload: data
});

export const setFullUser = (fullUser) => ({
  type: FULL_USER,
  payload: fullUser
});

export const addLoggedinUser = (userData) => ({
  type: ADD_LOGGED_USER,
  payload: userData
});

export const createGroup = (groupData) => ({
  type: CREATE_GROUP,
  payload: groupData
})

export function setUsers(data) {
  return {
    type: SET_USERS,
    payload: data
  };
};

export function setDefaultUsers(data) {
  return {
    type: SET_DEFAULT_USERS,
    payload: data
  };
};

export function addMessagesToUser(clientSessionKey, messages) {
  return {
    type: ADD_MESSAGES_TO_USER,
    payload: {
      clientSessionKey,
      messages
    }
  };
};

export function addFieldsToActiveUserDataFromSession(tickets, isSessionAttachedMe, attachedUserId, sessionId, feedbackResponse, note, sessionSource) {
  return {
    type: ADD_FIELDS_TO_ACTIVE_USER_DATA_FROM_SESSION,
    payload: { tickets, isSessionAttachedMe, attachedUserId, sessionId, feedbackResponse, note, sessionSource }
  };
};

export function addMessageToActiveUserMessages(messageResponse) {
  return {
    type: ADD_MESSAGE_TO_ACTIVE_USER_MESSAGES,
    payload: messageResponse,
  };
};

export function changeCurrentMessageReceivedField(timeStampId) {
  return {
    type: CHANGE_CURRENT_MESSAGE_RECEIVED_FIELD,
    payload: timeStampId,
  };
};

export function changeCurrentUserUnReadMessagesCount(clientSessionKey) {
  return {
    type: CHANGE_CURRENT_USER_UNREAD_MESSAGES_COUNT,
    payload: clientSessionKey,
  };
};

export function resetCurrentUserUnReadMessagesCount(clientSessionKey) {
  return {
    type: RESET_CURRENT_USER_UNREAD_MESSAGES_COUNT,
    payload: clientSessionKey,
  };
};

export function addNewUser(userData) {
  return {
    type: ADD_NEW_USER,
    payload: userData,
  };
};

export function addNewUserToMyClients(userData) {
  return {
    type: ADD_NEW_USER_TO_MY_CLIENTS,
    payload: userData,
  };
};

export function changeCurrentUserLastMessage(clientSessionKey, lastMessageResponse) {
  return {
    type: CHANGE_CURRENT_USER_LAST_MESSAGE,
    payload: {
      clientSessionKey,
      lastMessageResponse
    },
  };
};

export function changeCurrentUserData(data) {
  return {
    type: CHANGE_CURRENT_USER_DATA,
    payload: data,
  };
};

export function changeUsersOnlineStatus(userIds) {
  return {
    type: CHANGE_USERS_ONLINE_STATUS,
    payload: userIds,
  };
};

export function changeUserOnlineStatus(clientSessionKey, bool) {
  return {
    type: CHANGE_USER_ONLINE_STATUS,
    payload: {
      clientSessionKey,
      bool
    },
  };
};

export function showOrHideInboxTitleNotification(bool) {
  return {
    type: CHANGE_INBOX_TITLE_NOTIFICATION,
    payload: bool,
  };
};

export function showOrHideMyClientsTitleNotification(bool) {
  return {
    type: CHANGE_MY_CLIENTS_TITLE_NOTIFICATION,
    payload: bool,
  };
};

export function changeClientIsTyping(clientSessionKey, bool) {
  return {
    type: CHANGE_CLIENT_ISTYPING,
    payload: {
      clientSessionKey,
      bool,
    },
  };
};
export function sortClients(clientSessionKey) {
  return {
    type: SORT_CLIENTS,
    payload: clientSessionKey
  };
};

export function changeUserIsTyping(bool, typerUserName) {
  return {
    type: CHANGE_USER_ISTYPING,
    payload: {
      bool,
      typerUserName
    },
  };
};

export function changeCallWaitingStatus(bool) {
  return {
    type: CHANGE_CALL_WAITING_STATUS,
    payload: bool,
  };
};

export function changeGroupUnreadMessagesCount(groupName) {
  return {
    type: CHANGE_GROUP_UNREAD_MESSAGES_COUNT,
    payload: groupName,
  };
};

export function setGroups(groups) {
  return {
    type: SET_GROUPS,
    payload: groups,
  };
};

export function setDefaultGroups(groups) {
  return {
    type: SET_DEFAULT_GROUPS,
    payload: groups,
  };
};

export function setActiveSessionName(sessionName) {
  return {
    type: SET_ACTIVE_SESSION_NAME,
    payload: sessionName,
  };
};

export function setIsCallInProcess(bool) {
  return {
    type: SET_IS_CALL_IN_PROCESS,
    payload: bool,
  };
};

export function setNewMessageObj(newMessageObj) {
  return {
    type: SET_NEW_MESSAGE_OBJ,
    payload: newMessageObj,
  };
};

export function changeActiveUserTickets(ticket) {
  return {
    type: CHANGE_ACTIVE_USER_TICKETS,
    payload: ticket,
  };
};

export function setTechnicalAssistants(technicalAssistants) {
  return {
    type: SET_TECHNICAL_ASSISTANTS,
    payload: technicalAssistants,
  };
};

export function setDefaultTechnicalAssistants(technicalAssistants) {
  return {
    type: SET_DEFAULT_TECHNICAL_ASSISTANTS,
    payload: technicalAssistants,
  };
};

export function changeTechnicalAssistantOnlineStatus(userId, bool) {
  return {
    type: CHANGE_TECHNICAL_ASSISTANT_ONLINE_STATUS,
    payload: {
      userId,
      bool
    },
  };
};

export function setClientActiveCallId(clientSessionKey) {
  return {
    type: SET_CLIENT_ACTIVE_CALL_ID,
    payload: clientSessionKey,
  };
};

export function removeClientFromInbox(clientSessionKey) {
  return {
    type: REMOVE_CLIENT_FROM_INBOX,
    payload: clientSessionKey,
  };
};

export function removeClientFromChatBoard(clientSessionKey) {
  return {
    type: REMOVE_CLIENT_FROM_CHAT_BOARD,
    payload: clientSessionKey,
  };
};

export function setClientIsInActiveCallException(bool) {
  return {
    type: SET_CLIENT_IS_IN_ACTIVE_CALL_EXCEPTION,
    payload: bool,
  };
};

export function changeClientsOnlineStatus() {
  return {
    type: CHANE_CLIENTS_ONLINE_STATUS,
  };
};

