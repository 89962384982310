import React, { useEffect, useState, useCallback } from 'react'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import widgetMobileSvg from "./../../../assets/images/resources/widget-mobile.svg"
import chatboardSvg from "./../../../assets/images/resources/chatboard-2.svg"
import TranslationService from '../../../Services/translationService'
import { addSpinner, removeSpinner } from '../../../Store/Actions/spinner'
import AlertService from '../../../Services/AlertService'
import { ERROR_KEY } from '../../../Constants/MainKeys'
import uuid from 'react-uuid'
import ApiService from './../../../Services/ApiService'
import Parser from 'html-react-parser'
import { AiOutlineCheck } from 'react-icons/ai'
import Carousel from "react-multi-carousel"
import { RxCross2 } from "react-icons/rx"

import star from '../../../assets/images/3d-images/star.png'
import rocket from '../../../assets/images/3d-images/rocket.png'
import message from '../../../assets/images/3d-images/massage-emojies.png'
import serviceList from '../../../assets/images/3d-images/list.png'
import feedback from '../../../assets/images/3d-images/feedback.png'

import cryllex from '../../../assets/icons/site/partners/crillex.svg'
import frantixmedia from '../../../assets/icons/site/partners/frantixmedia.svg'
import mindalay from '../../../assets/icons/site/partners/mindalay.svg'

import userAvatar from '../../../assets/images/user-avatar.jpg'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const fullScreen = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 0 },
    items: 1
  },
};

const Home = (props) => {

  const dispatch = useDispatch();
  const { activeSection } = useSelector(state => state.main);
  const { language } = useSelector(state => state.language);
  const { translations } = useSelector(state => state.translation);

  const [translationService, setTranslationService] = useState(null);
  const [paymentPackages, setPaymentPackages] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      scrollToCurrentPosition(activeSection);
    }, 200);
  }, [activeSection]);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getPaymentPackages();
  }, [])

  const getPaymentPackages = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getPaymentPackages().then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setPaymentPackages(data)
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const scrollToCurrentPosition = (activeSection) => {
    const currentSection = document.getElementById(activeSection);
    currentSection && window.scrollTo({
      top: currentSection?.getBoundingClientRect().top + document.documentElement.scrollTop - 100,
      behavior: "smooth"
    });
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId, cb) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractSpinner(spinnerId);
  };

  return translationService ? <Auxiliary>
    <section id="home" style={{ marginTop: "100px" }}>
      <div className='container position-relative'>
        <div className='row'>
          <div className='col-12'>
            <div className="light-brand-opacity-background vnd-block">
              <Carousel
                showDots={true}
                arrows={false}
                autoPlay={true}
                responsive={fullScreen}
                infinite={true}
                transitionDuration={4000}
                className='vnd--custom-carousel dots-end'
              >
                <div className='row'>
                  <div className='col-lg-10'>
                    <h1 className="vnd--text-50">
                      {Parser(translationService.translate("TR_COVER_FIRST_TITLE"))}
                    </h1>
                    <p>{translationService.translate("TR_COVER_FIRST_TEXT")}</p>
                    <button
                      className="vnd-btn vnd-primary-btn my-4"
                      onClick={() => {
                        props.history.push(`/${language}/sign-up`)
                      }}
                    >
                      {translationService.translate("TR_LETS_ROCK")}
                    </button>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-10'>
                    <h1 className="vnd--text-50">
                      {Parser(translationService.translate("TR_COVER_SECOND_TITLE"))}
                    </h1>
                    <p>{translationService.translate("TR_COVER_SECOND_TEXT")}</p>
                    <button
                      className="vnd-btn vnd-primary-btn my-4"
                      onClick={() => {
                        props.history.push(`/${language}/sign-up`)
                      }}
                    >
                      {translationService.translate("TR_LETS_ROCK")}
                    </button>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-10'>
                    <h1 className="vnd--text-50">
                      {Parser(translationService.translate("TR_COVER_THIRD_TITLE"))}
                    </h1>
                    <p>{translationService.translate("TR_COVER_THIRD_TEXT")}</p>

                    <button
                      className="vnd-btn vnd-primary-btn my-4"
                      onClick={() => {
                        props.history.push(`/${language}/sign-up`)
                      }}
                    >
                      {translationService.translate("TR_LETS_ROCK")}
                    </button>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
          <div className='col-12'>
            <ul className="vnd-cover-list flex-wrap">
              <li className='font-size-14'><AiOutlineCheck
                className='vnd--text-secondary m-1'
              /> {translationService.translate("TR_FREE_TRIAL")}</li>
              <li className='font-size-14'><AiOutlineCheck
                className='vnd--text-secondary mr-1'
              /> {translationService.translate("TR_MONEY_BACK_GUARANTEE")}</li>
              <li className='font-size-14'><AiOutlineCheck
                className='vnd--text-secondary mr-1'
              /> {translationService.translate("TR_WIDGET_CUSTOMIZATION")}</li>
            </ul>
          </div>
        </div>
        <img src={star} className="vnd-decoreation vnd-decoration-right vnd-star" alt="planet-blue" />
      </div>
    </section>
    <div className='container'>
      <hr />
    </div>
    <section id="relamy" className='section'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='vnd-block'>
              <h2 className="vnd--text-50">{translationService.translate("TR_WHAT_IS_RELAMY")}</h2>
              <h3 className="vnd--upp-text-20 vnd--opacity-50">{translationService.translate("TR_WHAT_IS_RELAMY_ANSW")}</h3>
              <div className='my-3'>
                <p>{translationService.translate("TR_WHAT_IS_RELAMY_DESC")}</p>
                <button
                  className="vnd-btn vnd-primary-btn my-4"
                  onClick={() => {
                    props.history.push(`/${language}/sign-up`)
                  }}
                >
                  {translationService.translate("TR_LETS_ROCK")}
                </button>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='vnd--m-block light-brand-background h-100 position-relative'>
              <ul className="list-style-none p-0 position-relative z-index-10">
                <li className='mb-3 font-size-20 font-bold vnd--text-primary'><AiOutlineCheck
                  className='vnd--text-secondary font-size-20 mr-2'
                /> {translationService.translate("TR_FREE_TRIAL")}</li>
                <li className='mb-3 font-size-20 font-bold vnd--text-primary'><AiOutlineCheck
                  className='vnd--text-secondary font-size-20 mr-2'
                /> {translationService.translate("TR_MONEY_BACK_GUARANTEE")}</li>
                <li className='font-size-20 font-bold vnd--text-primary'><AiOutlineCheck
                  className='vnd--text-secondary font-size-20 mr-2'
                /> {translationService.translate("TR_WIDGET_CUSTOMIZATION")}</li>
              </ul>
              <img src={rocket} className="vnd-decoreation vnd-decoration-right vnd-home-rocket" alt="planet-blue" />
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <div className='container'>
      <hr />
    </div> */}
    {/* <section className='section'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-5'>
            <div className='vnd-block brand-background mb-3'>
              <h4 className='vnd--upp-text-20 text-white'>
                Freelance Support Specialists
                Unite on Relamy!
              </h4>
              <p className='text-white my-3'>
                Join our platform to offer your expertise to global partners. Work with multiple companies worldwide and seize new opportunities
              </p>
              <button
                className="vnd-btn vnd-secondary-btn mt-5"
                onClick={() => {
                  props.history.push(`/${language}/sign-up`)
                }}
              >
                {translationService.translate("TR_LEARN_MORE")}
              </button>
            </div>
            <div className='vnd-block brand-background'>
              <h4 className='vnd--upp-text-20 text-white'>
                Elevate Your Support Services
              </h4>
              <p className='text-white my-3'>
                Our platform connects you with a pool of skilled support specialists, allowing you to deliver
                top-notch assistance to your customers. Collaborate
                with the best and take your support offerings to the next level.
              </p>
              <button
                className="vnd-btn vnd-secondary-btn mt-5"
                onClick={() => {
                  props.history.push(`/${language}/sign-up`)
                }}
              >
                {translationService.translate("TR_LEARN_MORE")}
              </button>
            </div>
          </div>
          <div className='col-lg-7 mt-lg-0 mt-3'>
            <div className='vnd-block light-brand-background h-100 position-relative overflow-hidden'>
              <div className='d-flex flex-column justify-content-between h-100 position-relative z-index-10'>
                <div className='d-block'>
                  <h2 className='vnd--text-50'>{translationService.translate("WORK_ON_RELAMY")}</h2>
                  <h3 className='vnd--upp-text-20 vnd--opacity-50'>{translationService.translate("TR_BECOME_SUPPORT_ENGINEER_TITLE")}</h3>
                  <p className='my-3'>Work on Relamy: Become a Freelance Support Specialist for Our Partners

                    At Relamy, we offer an exciting opportunity for skilled support
                    specialists to thrive in the freelance world. As part of our platform,
                    you can showcase your expertise, and Relamy's trusted partners can easily
                    discover and hire you for their support needs. Join our community of professionals
                    and unlock new career possibilities today.</p>
                </div>
                <div className='d-block'>
                  <button
                    className="vnd-btn vnd-primary-btn mt-5"
                    onClick={() => {
                      props.history.push(`/${language}/sign-up`)
                    }}
                  >
                    {translationService.translate("TR_LEARN_MORE")}
                  </button>
                </div>
              </div>
              <img src={hiring} className="vnd-decoreation vnd-hiring-top-left" alt="vnd-hiring-top-left" />
              <img src={hiring} className="vnd-decoreation vnd-hiring-bottom-right" alt="vnd-hiring-bottom-right" />
            </div>
          </div>
        </div>
      </div>
    </section> */}
    <div className='container'>
      <hr />
    </div>
    <section id='service' className='section'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 mt-lg-0 mt-3'>
            <div className='vnd--m-block light-brand-background position-relative'>
              <h2 className="vnd--text-50">{translationService.translate("TR_USE_ALL_IN_ONE_TITLE")}</h2>
              <p className='my-3'>{translationService.translate("TR_USE_ALL_IN_ONE_TEXT")}</p>
              <img className="w-50" src={widgetMobileSvg} alt="widget-mobile" />
              <img src={message} className="vnd-decoreation vnd-group-chat-services" alt="vnd-group-chat" />
            </div>
          </div>
          <div className='col-lg-6 mt-lg-0 mt-3'>
            <div className='vnd--m-block light-gray-background position-relative overflow-hidden h-100'>
              <div className='d-flex flex-column justify-content-between h-100 position-relative z-index-10'>
                <div className='d-block'>
                  <h2 className="vnd--text-50 vnd--text-primary">{Parser(translationService.translate("TR_MEET_OUR_SERVICES"))}</h2>
                  <ul className="list-style-none p-0 position-relative z-index-10 my-3">
                    {
                      paymentPackages.map((paymentPackage, index) => {
                        if (index === 3) {
                          return paymentPackage.paymentPackageAllServices && paymentPackage.paymentPackageAllServices.length ?
                            paymentPackage.paymentPackageAllServices.map((paymentPackageAllService, index) => {
                              return <li key={index} className='mb-3 font-size-20 font-bold vnd--text-dark'><AiOutlineCheck
                                className='vnd--text-success font-size-20 mr-2'
                              />
                                {translationService.translate(paymentPackageAllService.displayName)}
                              </li>
                            })
                            : null
                        }
                      })
                    }
                  </ul>
                </div>
                <div className='d-block'>
                  <button
                    className="vnd-btn vnd-primary-btn mt-4"
                    onClick={() => {
                      props.history.push(`/${language}/sign-up`)
                    }}
                  >
                    {translationService.translate("TR_LEARN_MORE")}
                  </button>
                </div>
              </div>
              <img src={serviceList} className="vnd-decoreation vnd-sevices-list" alt="vnd-group-chat" />
            </div>
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-lg-6'>
            <div className='vnd-block'>
              <h2 className="vnd--text-50">{translationService.translate("TR_ALL_IN_ONE")}</h2>
              <h3 className="vnd--upp-text-20 vnd--opacity-50">{translationService.translate("TR_CLIENT_INTEGRATION")}</h3>
              <div className='my-3'>
                <p>{translationService.translate("TR_CLIENT_INTEGRATION_TEXT")}</p>
                <button
                  className="vnd-btn vnd-primary-btn my-4"
                  onClick={() => {
                    props.history.push(`/${language}/sign-up`)
                  }}
                >
                  {translationService.translate("TR_LEARN_MORE")}
                </button>
              </div>
            </div>
          </div>
          <div className='col-lg-6 mt-lg-0 mt-3'>
            <div className="vnd-side-image-wrapper">
              <img src={chatboardSvg} alt="chatboard" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className='container'>
      <hr />
    </div>
    <section id="pricing" className='section'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='vnd-block vnd--borderd'>
              <h2 className="vnd--text-50">{Parser(translationService.translate("TR_CHOOSE_YOUR_PP"))}</h2>
              <p className="my-3">{translationService.translate("TR_PAYMENT_PACKAGE_TEXT")}</p>
            </div>
          </div>
        </div>
        <div className='row'>
          {
            paymentPackages.map((paymentPackage, index) => { // vnd-good-price
              return <div key={index} className={'col-xl-3 col-md-6 col-12 my-3 mb-lg-0'}>
                <div className={`price-card-wrapper  ${index === 2 ? "vnd-good-price" : "light-gray-background"}`}>
                  <p className="price-card-name">{translationService.translate(`${paymentPackage.displayName}`)}</p>
                  {
                    paymentPackage.cost === 0 ?
                      <span className="price-card-price d-block inline-text">{translationService.translate("TR_FREE")}</span>
                      : <span className="price-card-price">${paymentPackage.cost}<span>/ {translationService.translate("TR_MONTH")}</span></span>
                  }
                  <hr />
                  <ul className="order-list list-unstyled text-small text-start">
                    {
                      paymentPackage.paymentPackageAllServices && paymentPackage.paymentPackageAllServices.length ?
                        paymentPackage.paymentPackageAllServices.map(paymentPackageAllService => {
                          if (paymentPackageAllService.isAvailable) {
                            return <li key={paymentPackageAllService.id} className="mb-3 ml-0 capitalize">
                              <AiOutlineCheck
                                className='vnd--text-success font-size-20 mr-2'
                              />
                              {translationService.translate(paymentPackageAllService.displayName)}
                            </li>
                          } else {
                            return <li key={paymentPackageAllService.id} className="mb-3 ml-0 text-muted capitalize">
                              {
                                index === 2 ? <RxCross2 className='vnd--text-white vnd--opacity-50 font-size-20 mr-2' />
                                  : <RxCross2
                                    className='vnd--text-dark vnd--opacity-50 font-size-20 mr-2'
                                  />
                              }
                              <del>{translationService.translate(paymentPackageAllService.displayName)}</del>
                            </li>
                          }
                        })
                        : null
                    }
                  </ul>
                  {
                    <Link
                      to={!paymentPackage.cost ? "#" : `/${language}/subscriptions/${paymentPackage.id}`}
                      className={`link-button ${!paymentPackage.cost ? "disabled" : ""} ${index === 2 ? "vnd-btn vnd-secondary-btn" : "vnd-btn vnd-dark-btn"}`}
                    >
                      {translationService.translate("TR_SUBSCRIBE_NOW")}
                    </Link>
                  }
                </div>
              </div>
            })
          }
        </div>
      </div>
    </section>
    <div className='container'>
      <hr />
    </div>
    <section id="journey" className='section'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='vnd-block'>
              <h2 className="vnd--text-50">{Parser(translationService.translate("TR_WE_WORK_WITH_THE_BEST_TITLE"))}</h2>
              <h3 className="vnd--upp-text-20 vnd--opacity-50">{translationService.translate("TR_WE_ARE_TRUSTED")}</h3>
              <p className="my-3">{Parser(translationService.translate("TR_WE_WORK_WITH_THE_BEST_TEXT"))}</p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 mt-3'>
            <div className='vnd--m-block vnd--borderd my-2'>
              <Carousel
                showDots={true}
                arrows={false}
                autoPlay={true}
                responsive={responsive}
                infinite={true}
                className='vnd--custom-carousel dots-center vnd--carousel-wrapper'
              >
                <div className="vnd--carousel-partner-logo">
                  <a href="https://cryllex.biz/" target='_blank'>
                    <img src={cryllex} alt="" />
                  </a>
                </div>
                <div className="vnd--carousel-partner-logo">
                  <a href="https://frantixmedia.com/" target='_blank'>
                    <img src={frantixmedia} alt="" />
                  </a>
                </div>
                <div className="vnd--carousel-partner-logo" target='_blank'>
                  <a href="https://mindalay.com/">
                    <img src={mindalay} alt="" />
                  </a>
                </div>

              </Carousel>
            </div>
            {/* <hr className='section' /> */}
          </div>
        </div>
        {/* <div className='row'>
          <div className='col-lg-6'>
            <div className='vnd-block light-gray-background h-100'>
              <h2 className="vnd--text-50">{Parser(translationService.translate("TR_WHAT_PEOPLE_THINK_ABOUT"))}</h2>
              <h3 className="vnd--upp-text-20 vnd--opacity-50">{translationService.translate("TR_PARTNERS_JOURNEY")}</h3>
              <p className="my-3">{Parser(translationService.translate("TR_FEEDBACK_TEXT"))}</p>
            </div>
          </div>
          <div className='col-lg-6 mt-lg-0 mt-3'>
            <div className='vnd-block light-brand-background position-relative h-100'>
              <div className='position-relative z-index-10'>
                <Carousel
                  responsive={fullScreen}
                  showDots={true}
                  autoPlay={true}
                  infinite={true}
                  arrows={false}
                  className='vnd--custom-carousel dots-center vnd--carousel-wrapper'
                >
                  <div className="vnd-partners-feedback-wrapper">
                    <div className="vnd-partner-wrapper">
                      <div className="vnd-partner-image-wrapper">
                        <div className="vnd-partner-image" style={{ backgroundImage: `url(${userAvatar})` }}></div>
                      </div>
                      <div className="vnd-partner-info`">
                        <h5>Mindalay</h5>
                        <h6>Miles Harrington</h6>
                      </div>
                    </div>
                    <p>
                      I just wanted to take a moment to say thank you for your assistance. Your help was greatly appreciated and made a
                      big difference in my experience. Your professionalism, knowledge, and patience were outstanding,
                      and I feel fortunate to have had your support.
                    </p>
                  </div>
                  <div className="vnd-partners-feedback-wrapper">
                    <div className="vnd-partner-wrapper">
                      <div className="vnd-partner-image-wrapper">
                        <div className="vnd-partner-image" style={{ backgroundImage: `url(${userAvatar})` }}></div>
                      </div>
                      <div className="vnd-partner-info`">
                        <h5>Frantixmedia</h5>
                        <h6>Harper Carrington</h6>
                      </div>
                    </div>
                    <p>I am writing to express my gratitude for the outstanding service I
                      received from you and your team. Your level of expertise and dedication was
                      truly impressive, and I was thoroughly impressed with the way you handled my issue.
                    </p>
                  </div>
                  <div className="vnd-partners-feedback-wrapper">
                    <div className="vnd-partner-wrapper">
                      <div className="vnd-partner-image-wrapper">
                        <div className="vnd-partner-image" style={{ backgroundImage: `url(${userAvatar})` }}></div>
                      </div>
                      <div className="vnd-partner-info`">
                        <h5>Get me pet</h5>
                        <h6>Avery Townsend</h6>
                      </div>
                    </div>
                    <p>I recently started using the supporter system platform and I
                      am blown away by its capabilities. The user interface is easy
                      to navigate, and I was able to quickly set up and manage my
                      organization's supporter database.
                    </p>
                  </div>
                </Carousel>
              </div>
              <img src={feedback} className="vnd-decoreation vnd-feedback" alt="vnd-feedback" />
            </div>
          </div>
        </div> */}
      </div>
    </section>
  </Auxiliary>
    : null
}

export default withRouter(Home)
