import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileDataBlock from './ProfileComponents/ProfileDataBlock';
import Security from './ProfileComponents/Security';
import { setActiveSection } from '../../../Store/Actions/main';
import { ERROR_KEY, SUCCESS_KEY, VALID_IMAGE_TYPES_KEY, WARNING_KEY } from '../../../Constants/MainKeys';
import AlertService from '../../../Services/AlertService';
import MainService from '../../../Services/mainService';
import RemoveSvg from "./../../../assets/icons/remove.svg";
import ActionButton from '../../../Components/Buttons/ActionButton';
import uuid from 'react-uuid';
import { addButtonSpinner, addSpinner, removeButtonSpinner, removeSpinner } from '../../../Store/Actions/spinner';
import ApiService from './../../../Services/ApiService';
import { updateUserData } from '../../../Store/Actions/user';
import * as moment from "moment";
import TranslationService from './../../../Services/translationService';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { AiOutlineEdit, AiOutlineLeft } from "react-icons/ai";
import useWindowSize from '../../../Hooks/useWindowSize';
import { AiOutlineUser } from "react-icons/ai";


const buttonSpinnerId = uuid();
export default function Profile(props) {

  const maxFilesSize = 3; //MB
  const mainService = new MainService();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const { language } = useSelector(state => state.language);
  const { user } = useSelector(state => state.user);
  const { translations } = useSelector(state => state.translation);
  const [key, setKey] = useState(1);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [userData, setUserData] = useState(null);
  const [translationService, setTranslationService] = useState(null);
  const [isShowTabs, setIsShowTabs] = useState(false);

  useEffect(() => {
    setActiveSectionName("");
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getProfileData();
  }, []);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const getProfileData = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId)
    ApiService.getPartnerProfileData().then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        setUserData(data);
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const setActiveSectionName = (name) => {
    dispatch(setActiveSection(name))
  }

  const removeImage = () => {
    if (uploadedFile) {
      setUploadedFile(null);
      setFile(null);
    }
    if (user.avatarPath && !uploadedFile) {
      AlertService.alertConfirm(
        `${translationService.translate("TR_ARE_YOU_SURE")} ?`,
        "",
        translationService.translate("TR_YES"),
        translationService.translate("TR_NO")
      ).then(() => {
        ApiService.removeUserAvatar().then(() => {
          const data = { avatarPath: null };
          dispatch(updateUserData(data));
          AlertService.alert(SUCCESS_KEY, `${translationService.translate("TR_DATA")} ${translationService.translate("TR_SAVED")}`)
        }).catch(error => getFail(error, buttonSpinnerId))
      })
    }
  }

  const updateUserImage = (event) => {
    event.preventDefault();
    setButtonSpinner(buttonSpinnerId);
    const formData = new FormData();
    formData.append("formFile", file);
    ApiService.updateUserAvatar(formData).then(response => {
      if (response && response.data) {
        const data = {
          avatarPath: response.data,
        }
        dispatch(updateUserData(data));
      }
      setUploadedFile(null);
      setFile(null);
      AlertService.alert(SUCCESS_KEY, `${translationService.translate("TR_DATA")} ${translationService.translate("TR_SAVED")}`)
      extractButtonSpinner(buttonSpinnerId);
    }).catch(error => {
      getFail(error, buttonSpinnerId);
      setUploadedFile(null);
    })
  }

  const uploadFile = event => {
    if (!event.target.files[0]) { return; }
    const file = event.target.files[0];
    if (file) {
      if (file.size >= maxFilesSize * 1024 * 1024) {
        AlertService.alert(WARNING_KEY, translationService.translate("TR_WIDGET_IMAGE_SIZE_VALIDATION"));
        return false;
      }
      mainService.readFile(file, VALID_IMAGE_TYPES_KEY).then(uploadedImage => {
        setUploadedFile(uploadedImage);
        setFile(file);
      }).catch(error => {
        error && AlertService.alert(ERROR_KEY, translationService.translate("TR_INVALID_FILE_EXC"));
        setUploadedFile(null);
      });
    };
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
    spinnerId && extractSpinner(spinnerId);
  };

  return translationService && userData ?
    <aside className='right-section'>
      <div className='d-flex flex-column justify-content-end'>
        <section className="top-section dashboard-body customer-profile">
          <div className="container-fluid">
            <div className='d-flex justify-content-between align-items-center'>
              <div className="vnd--aside-title-block">
                <div className="backspace">
                  <span
                    className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                    onClick={() => window.history.back()}
                  >
                    <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                  </span>
                </div>
                <span className="slash">|</span>
                <p className="vnd--upp-text-22 m-0 text-nowrap">{translationService.translate("TR_PROFILE")}</p>
              </div>
              <div className='vnd--icon-wrapper cursor-pointer d-lg-none d-block' onClick={() => setIsShowTabs(!isShowTabs)}>
                <AiOutlineEdit fontSize={20} />
              </div>
            </div>
            <Row className='border-top'>
              <Col lg={3}>
                <Row>
                  <Col md={4} lg={12} className='border-md-right border-md-bottom'>
                    <div className="vnd-customer-edit-avatar">
                      {
                        !user.avatarPath && !uploadedFile ?
                          <div className="d-flex justify-content-center p-3">
                            <div className="avatar-lg">
                              <span style={{ fontSize: "30px" }} className="avatar-title rounded-circle bg-soft-primary vnd--text-primary">
                                <i className="ri-user-3-line"></i>
                              </span>
                            </div>
                          </div>
                          : null
                      }
                      {
                        uploadedFile || user.avatarPath ?
                          <div
                            className="vnd-bkg-cover-img rounded-circle my-3 position-relative avatar-xl m-auto"
                            style={{ backgroundImage: `url(${uploadedFile ? uploadedFile : user.avatarPath ? user.avatarPath : null})` }}
                          >
                            <div className="vnd-edit-tols">
                              <img src={RemoveSvg} alt="/" onClick={removeImage} />
                            </div>
                          </div>
                          : null
                      }
                    </div>
                    <div className='d-flex flex-column align-items-center'>
                      {user.fullName ? <p className="vnd--upp-text-20 font-size-16">{user.fullName}</p> : null}
                      {user.email ? <small className='font-size-12'>{user.email}</small> : null}
                    </div>
                    {
                      uploadedFile ?
                        <ActionButton
                          type="button"
                          name={translationService.translate("TR_SAVE")}
                          className="vnd-primary-btn vnd-btn vnd-small-btn m-auto mt-3 mb-4"
                          spinnerId={buttonSpinnerId}
                          onClick={updateUserImage}
                        />
                        : <div className="vnd--upload-image-btn my-4">
                          <button className="vnd-primary-btn vnd-btn vnd-small-btn m-auto mt-3" onClick={MainService.triggerUploadClick}>
                            {translationService.translate("TR_UPLOAD_IMAGE")}
                          </button>
                          <input type="file" id='avatarImage' className="d-none" hidden onChange={uploadFile} />
                        </div>
                    }

                    <hr className='d-lg-block d-none m-0' />
                  </Col>
                  <Col md={8} lg={12} className='border-md-bottom'>
                    <div className="vnd--user-info-block p-3">
                      <p className="vnd--upp-text-16">{translationService.translate("TR_PROFILE")}</p>
                      <ul className="vnd--list-info m-0">
                        <li className="vnd--list-info-item-wrapper">
                          <span className="vnd--user-info-item capitalize">{translationService.translate("TR_STATUS")}</span>
                          <span className="vnd--user-info-item capitalize">
                            {userData.isActive ? `${translationService.translate("TR_ACTIVE")}` : `${translationService.translate("TR_INACTIVE")}`}
                          </span>
                        </li>
                        <li className="vnd--list-info-item-wrapper">
                          <span className="vnd--user-info-item capitalize">{translationService.translate("TR_TYPE")}</span>
                          <span className="vnd--user-info-item capitalize">
                            {translationService.translate(userData.userTypeName) ? translationService.translate(userData.userTypeName) : userData.userTypeName}
                          </span>
                        </li>
                        <li className="vnd--list-info-item-wrapper">
                          <span className="vnd--user-info-item capitalize">{translationService.translate("TR_JOINED")}</span>
                          <span className="vnd--user-info-item capitalize">
                            {moment(MainService.convertUTCDateToLocalDate(new Date(userData?.createDate))).format("ll")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <hr className='m-0' />
                    <div className="vnd--user-info-block p-3">
                      <p className="vnd--upp-text-16">{translationService.translate("TR_TECHNICAL_ASSISTANTS")}</p>
                      <ul className="vnd--list-info m-0">
                        <li className="vnd--list-info-item-wrapper">
                          <span className="vnd--user-info-item capitalize">
                            {translationService.translate("TR_BY_SUBSCRIPTION")}:&nbsp;
                          </span>
                          <span className="vnd--user-info-item capitalize">
                            {
                              userData?.partnerSupportersCount?.supportersCount ? userData?.partnerSupportersCount?.supportersCount : 0
                            }
                          </span>
                        </li>
                        <li className="vnd--list-info-item-wrapper">
                          <span className="vnd--user-info-item capitalize">{translationService.translate("TR_REGISTRED")}:</span>
                          <span className="vnd--user-info-item capitalize">
                            {
                              userData?.partnerSupportersCount?.activeSupportersCount ? userData.partnerSupportersCount?.activeSupportersCount : 0
                            }
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} className={` ${windowSize && windowSize.width < 992 && !isShowTabs ? "d-none d-lg-block" : ""} p-0 border-left border-right border-md-bottom`}>
                <Tabs
                  activeKey={key}
                  onSelect={(key) => { setKey(key) }}
                  className='mt-2'
                >
                  <Tab eventKey={1} title={translationService.translate("TR_PERSONAL")}>
                    <ProfileDataBlock
                      user={userData}
                      isShowCancelButton={windowSize && windowSize.width < 992}
                      setIsShowTabs={setIsShowTabs}
                    />
                  </Tab>
                  <Tab eventKey={2} title={translationService.translate("TR_SECURITY")} >
                    <Security
                      isShowCancelButton={windowSize && windowSize.width < 992}
                      setIsShowTabs={setIsShowTabs}
                    />
                  </Tab>
                </Tabs>
              </Col>
              <Col lg={3} className='mt-lg-0 mt-3'>
                <Row>
                  {
                    userData?.partnerSubscription && !(userData?.partnerSubscription.name === "Demo" || userData?.partnerSubscription.name === "Free") ?
                      <Auxiliary>
                        <Col md={6} lg={12} className='mb-3 mb-md-0'>
                          <div className={`${windowSize && windowSize.width < 992 ? "vnd--m-block light-brand-background" : ""} p-lg-3`}>
                            <p className="vnd--upp-text-16">{translationService.translate("TR_SUBSCRIPTION")}</p>
                            <ul className="vnd--list-info m-0">
                              {
                                userData?.partnerSubscription?.name ?
                                  <li className="vnd--list-info-item-wrapper">
                                    <span className="vnd--user-info-item capitalize">{translationService.translate("TR_SUBSCRIPTION")}</span>
                                    <span className="vnd--user-info-item capitalize">
                                      {userData?.partnerSubscription?.name}
                                    </span>
                                  </li>
                                  : null
                              }
                              {
                                userData?.partnerSubscription?.statusText ?
                                  <li className="vnd--list-info-item-wrapper">
                                    <span className="vnd--user-info-item capitalize">{translationService.translate("TR_STATUS")}</span>
                                    <span className="vnd--user-info-item capitalize">
                                      {translationService.translate(userData.partnerSubscription.statusText)}
                                    </span>
                                  </li>
                                  : null
                              }
                              {
                                userData?.partnerSubscription?.createDate ?
                                  <Auxiliary>
                                    <li className="vnd--list-info-item-wrapper">
                                      <span className="vnd--user-info-item capitalize">{translationService.translate("TR_CREATE_DATE")}</span>
                                      <span className="vnd--user-info-item capitalize">
                                        {moment(MainService.convertUTCDateToLocalDate(new Date(userData?.partnerSubscription?.createDate))).format("LL")}
                                      </span>
                                    </li>
                                    <li className="vnd--list-info-item-wrapper">
                                      <span className="vnd--user-info-item capitalize">{translationService.translate("TR_EXPIRE_DATE")}</span>
                                      <span className="vnd--user-info-item capitalize">
                                        {moment(MainService.convertUTCDateToLocalDate(new Date(userData?.partnerSubscription?.expireDate))).format("LL")}
                                      </span>
                                    </li>
                                  </Auxiliary>
                                  : null
                              }

                            </ul>
                          </div>
                        </Col>
                        <Col lg={12} className='d-none d-lg-block'>
                          <hr className='m-0' />
                        </Col>
                      </Auxiliary> : <Auxiliary>
                        <Col md={6} lg={12} className='mb-3 mb-md-0'>
                          <div className={`${windowSize && windowSize.width < 992 ? "vnd--m-block light-brand-background" : ""} p-lg-3`}>
                            <p className="vnd--upp-text-16">{translationService.translate("TR_SUBSCRIPTION")}</p>
                            <p>{translationService.translate("TR_UNSUBSCRIBE_TEXT")}</p>
                            <button
                              className='vnd-gradient-btn vnd-btn vnd-small-btn m-auto mt-3 w-100'
                              onClick={() => props.history.push(`/${language}/subscriptions`)}
                            >
                              {translationService.translate("TR_UPGRADE")}
                            </button>
                          </div>
                        </Col>
                        <Col lg={12} className='d-none d-lg-block'>
                          <hr className='m-0' />
                        </Col>
                      </Auxiliary>
                  }
                  <Col md={6} lg={12} className='mb-3 mb-md-0'>
                    <div className={`${windowSize && windowSize.width < 992 ? "vnd--m-block light-brand-background" : ""} p-lg-3`}>
                      <p className="vnd--upp-text-16">{translationService.translate("TR_TECHNICAL_ASSISTANT")} {translationService.translate("TR_SESSIONS")}</p>
                      <ul className="vnd--list-info m-0">
                        <li className="vnd--list-info-item-wrapper">
                          <span className="vnd--user-info-item capitalize">{translationService.translate("TR_ALL")}</span>
                          <span className="vnd--user-info-item capitalize">
                            {
                              userData?.partnerSessions?.allSessions ?
                                userData?.partnerSessions?.allSessions
                                : 0
                            }
                          </span>
                        </li>
                        <li className="vnd--list-info-item-wrapper">
                          <span className="vnd--user-info-item capitalize">{translationService.translate("TR_TODAY")}</span>
                          <span className="vnd--user-info-item capitalize">
                            {
                              userData?.partnerSessions?.todaysSessions ?
                                userData?.partnerSessions?.todaysSessions
                                : 0
                            }
                          </span>
                        </li>
                        <li className="vnd--list-info-item-wrapper">
                          <span className="vnd--user-info-item capitalize">{translationService.translate("TR_ACTIVE")}</span>
                          <span className="vnd--user-info-item capitalize">
                            {
                              userData?.partnerSessions?.activeSessions ?
                                userData?.partnerSessions?.activeSessions
                                : 0
                            }
                          </span>
                        </li>
                        <li className="vnd--list-info-item-wrapper">
                          <span className="vnd--user-info-item capitalize">{translationService.translate("TR_LAST")} {translationService.translate("TR_WEEK")}</span>
                          <span className="vnd--user-info-item capitalize">
                            {
                              userData?.partnerSessions?.lastWeekSessions ?
                                userData?.partnerSessions?.lastWeekSessions
                                : 0
                            }
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </aside >
    : null

}
