import { takeLatest, call, put } from 'redux-saga/effects';
import ApiService from '../../Services/ApiService';
import AlertService from "../../Services/AlertService";
import { IdentityService } from '../../Services/IdentityService.js';
import { addButtonSpinner, removeButtonSpinner, } from '../Actions/spinner';
import { ACTIVE_SECTION_KEY, DEFAULT_LANGUAGE_KEY, ERROR_KEY, INVALID_USERNAME_OR_PASSWORD, LANGUAGE_KEY, SEMI_PARTNER_TYPE_ID_KEY, SUCCESS_KEY, SUPPORTER_USER_TYPE_ID_KEY } from "../../Constants/MainKeys";
import {
  AUTH_SUCCESS,
  CURRENT_USER_SUCCESS,
  AUTH_FAIL,
  CURRENT_USER_UPDATE_SUCCESS,
  REGISTRATION_FAIL,
  USER_LOGIN_REQUEST,
  USER_REGISTRATION_REQUEST,
  CURRENT_USER_UPDATE_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_REQUEST
} from '../../Constants/ReduxKeys';
import ResponseService from '../../Services/responseService';
import { setActiveGroupName } from '../Actions/main';
import { SUPERVISOR_USER_TYPE_ID_KEY, PARTNER_USER_TYPE_ID_KEY } from './../../Constants/MainKeys';

export default function* watcher() {
  yield takeLatest(USER_LOGIN_REQUEST, userLogin);
  yield takeLatest(USER_REGISTRATION_REQUEST, userRegistration);
  yield takeLatest(CURRENT_USER_UPDATE_REQUEST, userUpdate);
  yield takeLatest(AUTH_LOGOUT_REQUEST, logout);
};

function* userLogin(action) {
  const { spinnerId, history } = action.payload;
  try {
    const formData = { ...action.payload.data };
    const token = yield call(IdentityService.login, formData);
    const response = yield call(ResponseService.responseCheckIdentity, token);
    yield put({
      type: AUTH_SUCCESS,
      payload: {
        token,
        refreshToken: response?.refreshToken
      }
    });
    const user = yield call(ApiService.currentUser);

    if (user && user.data) {
      yield put({
        type: CURRENT_USER_SUCCESS,
        payload: user.data,
      });
    } else {
      logout();
    }

    var language = localStorage.getItem(LANGUAGE_KEY);
    if (user && user.data) {
      if (user.data.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.data.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY) {
        localStorage.removeItem(ACTIVE_SECTION_KEY);
        if (language && language !== null && language !== "null") {
          history.push(`/${language}/dashboard/groups`);
        } else {
          history.push(`/${DEFAULT_LANGUAGE_KEY}/dashboard/groups`);
        }
      } else if (user.data.userTypeId === PARTNER_USER_TYPE_ID_KEY) {
        if (localStorage.getItem("returnUrl")) {
          if (language && language !== null && language !== "null") {
            history.push(`/${language}/${localStorage.getItem("returnUrl")}`);
          } else {
            history.push(`/${DEFAULT_LANGUAGE_KEY}/${localStorage.getItem("returnUrl")}`);
          }
          localStorage.removeItem("returnUrl")
        } else {
          if (language && language !== null && language !== "null") {
            history.push(`/${language}/widget-settings`);
          } else {
            history.push(`/${DEFAULT_LANGUAGE_KEY}/widget-settings`);
          }
        }
      }
    }

    if (user && user.data && user.data.groupName) {
      yield put(setActiveGroupName(user.data.groupName));
    }

    if (user && user.data && !user.data.partnerId && user.data.userTypeId === SEMI_PARTNER_TYPE_ID_KEY && user.data.stepNumber) {
      if (language && language !== null && language !== "null") {
        history.push(`/${language}/become-partner/step-${user.data.stepNumber}`);
      } else {
        history.push(`/${DEFAULT_LANGUAGE_KEY}/become-partner/step-${user.data.stepNumber}`);
      }
    }

    yield put(removeButtonSpinner(spinnerId));

  } catch (error) {
    if (error === INVALID_USERNAME_OR_PASSWORD) {
      yield put({
        type: AUTH_FAIL,
        payload: error,
      });
    } else {
      error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    }
    yield put(removeButtonSpinner(spinnerId));
  };
};

function* userRegistration(action) {
  const { spinnerId, history } = action.payload;
  try {
    yield put(addButtonSpinner(spinnerId));
    const data = { ...action.payload.data };
    data.password = btoa(data.password);
    data.confirmPassword = btoa(data.confirmPassword);

    yield call(ApiService.registration, data);
    yield userLogin({
      type: USER_LOGIN_REQUEST,
      payload: {
        spinnerId,
        history,
        data: {
          username: data.email,
          password: window.atob(data.password)
        }
      }
    });
    yield put(removeButtonSpinner(spinnerId));
  } catch (error) {
    yield put(removeButtonSpinner(spinnerId));
    AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    yield put({
      type: REGISTRATION_FAIL,
      payload: error
    });
  };
};

function* userUpdate(action) {
  try {
    const user = { ...action.payload.data };
    const savedUserData = { ...user };
    delete user.avatarPath;
    const formData = new FormData();
    for (let i in user) {
      if (!user[i]) {
        delete user[i];
      } else {
        formData.append(`${i}`, user[i]);
      }
    }
    yield call(ApiService.userUpdate, formData);
    AlertService.alert(SUCCESS_KEY, action.payload.translationSuccessMessage);
    yield put({
      type: CURRENT_USER_UPDATE_SUCCESS,
      payload: savedUserData
    });
  }
  catch (error) {
    AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }
}

function* logout() {
  try {
    // yield call(ApiService.logout);
    yield put({
      type: AUTH_LOGOUT_SUCCESS,
      payload: {}
    });
  }
  catch (error) {
    AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }
}
