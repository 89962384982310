import {
  ADD_BUTTON_SPINNER,
  REMOVE_BUTTON_SPINNER,
  SET_FLAG_SPINNER,
  REMOVE_SPINNER,
  ADD_SPINNER
} from "../../Constants/ReduxKeys";

const initialState = {
  buttonSpinners: [],
  isShowFlagSpinner: false,
  spinners: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SPINNER:
      return {
        ...state,
        spinners: [...state.spinners, action.payload]
      };
    case REMOVE_SPINNER:
      return {
        ...state,
        spinners: state.spinners.filter(data => data !== action.payload)
      };
    case ADD_BUTTON_SPINNER:
      return {
        ...state,
        buttonSpinners: [...state.buttonSpinners, action.payload]
      };
    case REMOVE_BUTTON_SPINNER:
      return {
        ...state,
        buttonSpinners: state.buttonSpinners.filter(data => data !== action.payload)
      };
    case SET_FLAG_SPINNER:
      return {
        ...state,
        isShowFlagSpinner: action.payload
      };

    default:
      break;
  }
  return state;
};

export default reducer;