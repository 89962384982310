import React from 'react';
import Auxiliary from '../../hoc/auxiliary/auxiliary';

export default function CheckBoxInput(props) {
  return (
    <Auxiliary>
      <div className={`custom-control custom-checkbox ${props.blockClassName ? props.blockClassName : ""}`}>
        <input
          id={props.id}
          type="checkbox"
          name={props.name}
          disabled={props.disabled}
          value={props.value}
          checked={props.checked}
          defaultChecked={props.defaultChecked}
          className={`custom-control-input ${props.className ? props.className : props.inputClassName ? props.inputClassName : ""}`}
          onChange={props.onChange}
        />
        <label
          className={`custom-control-label capitalize ${props.labelClassName ? props.labelClassName : ""}`}
          htmlFor={props.htmlFor ? props.htmlFor : ""}
        >
          {props.labelValue ? props.labelValue : ""}
        </label>
      </div>
    </Auxiliary>
  )
}
