import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TranslationService from "../../../Services/translationService";
import ContactUs from "../../AuthUserPages/Subscriptions/Components/ContactUs";

const ContactUsUnAuth = () => {

  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  return translationService ? <>
    <section className='h-section section'>
      <div className='container'>
        <div className="row">
          <div className="col-12">
            <h5 className="vnd--text-50 mb-4">{translationService.translate("TR_CONTACT_US")}</h5>
          </div>
          <div className="col-lg-8">
            <div className='vnd-block vnd--borderd white-background'>
              <ContactUs />
            </div>
          </div>
          <div className="col-lg-4 contact-card">
            <div className='vnd-block light-brand-background'>
              <div>
                <h5 className="vnd--upp-text-16">{translationService.translate("TR_CONTACT_INFORMATION")}</h5>

                <ul className="list-unstyled">
                  <li className='mt-3'><strong>TEL: </strong><a href="tel:+1(302)894-8885" className="d-inline">+1(302)894-8885</a></li>
                  <li className='mt-3'><strong>{translationService.translate("TR_EMAIL")}:</strong> <span className='text-initial'>info@relmay.com</span></li>
                </ul>
                <hr />
                <h5 className="vnd--upp-text-16">{translationService.translate("TR_COMMUNITY")}</h5>
                <ul className="contact-card-item list-unstyled">
                  <li className="d-flex align-items-center">
                    <a href="https://fb.com" target="_blank"><i className="ri-facebook-box-fill"></i></a>
                    <a href="https://linkedin.com" target="_blank"><i className="ri-linkedin-box-fill"></i></a>
                    <a href="https://youtube.com" target="_blank"><i className="ri-youtube-fill"></i></a>
                    <a href="https://twitter.com" target="_blank"><i className="ri-twitter-fill"></i></a>
                  </li>
                  <li className="mt-2"><small>{translationService.translate("TR_FIND_US_IN_NETWORK")}</small></li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </> : null
}

export default ContactUsUnAuth;