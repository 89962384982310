import React, { useEffect, useRef, useState } from 'react';
import lottieJson from "./../../assets/videos/71oNicFXe2.json"
import Lottie from 'react-lottie-player'
import { useSelector } from 'react-redux';
import TranslationService from '../../Services/translationService';

export default function NoData(props) {

  const { width, height } = props;
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  return translationService ? <div>
    <Lottie
      play
      loop
      animationData={lottieJson}
      style={{ width: width ? width : 300, height: height ? height : 300, margin: "0 auto" }}
    />
    <p className={`d-block text-center font-weight-bold capitalize ${props.textClassName}`}>{translationService.translate("TR_NO_DATA")}</p>
  </div> : null
}
