
import { takeLatest, call, put } from 'redux-saga/effects';
import ApiService from '../../Services/ApiService';
import AlertService from "../../Services/AlertService";
import { ERROR_KEY } from "../../Constants/MainKeys";
import { setIsDisabledScripts } from '../Actions/chat';
import {
  SET_COUNTRIES,
  GET_COUNTRIES,
  GET_SCRIPTS,
  SET_SCRIPT_GROUPS,
  SET_SCRIPTS,
  SET_SCRIPT_GROUPS_PAGINATION,
  ADD_SCRIPT_GROUPS,
  SET_SCRIPTS_PAGINATION,
  ADD_SCRIPTS,
  GET_SCRIPT_GROUPS,
} from "../../Constants/ReduxKeys";

export default function* watcher() {
  yield takeLatest(GET_COUNTRIES, _getCountries);
  yield takeLatest(GET_SCRIPTS, _getScriptsByGroupId);
  yield takeLatest(GET_SCRIPT_GROUPS, _getScriptGroups);
};

function* _getCountries(action) {
  const partnerId = action.payload;
  try {
    yield put(setIsDisabledScripts(true));
    const response = yield call(ApiService.getExistingCountries, partnerId);

    if (response && response.data) {
      yield put({
        type: SET_COUNTRIES,
        payload: response.data.length ? response.data : [],
      });
    }
    yield put(setIsDisabledScripts(false));
  } catch (e) {
    AlertService.alert(e, ERROR_KEY);
    yield put(setIsDisabledScripts(false));
  };
};

function* _getScriptGroups(action) {
  const { partnerId, countryId, prefix, pageNumber, pageSize, isAddData } = action.payload;
  try {
    yield put(setIsDisabledScripts(true));
    let data = {
      partnerId,
      countryId: countryId ? countryId : null,
      prefix,
      pageNumber,
      pageSize
    }
    const response = yield call(ApiService.getScriptGroups, data);

    if (response && response.data) {
      const data = { ...response.data };
      if (data.pagination) {
        yield put({
          type: SET_SCRIPT_GROUPS_PAGINATION,
          payload: data.pagination
        })
      }
      if (isAddData) {
        yield put({
          type: ADD_SCRIPT_GROUPS,
          payload: data?.pagedData,
        });
      } else {
        yield put({
          type: SET_SCRIPT_GROUPS,
          payload: data?.pagedData,
        });
      }
    }
    yield put(setIsDisabledScripts(false));
  } catch (e) {
    AlertService.alert(e, ERROR_KEY);
    yield put(setIsDisabledScripts(false));
  };
};

function* _getScriptsByGroupId(action) {
  const { groupId, partnerId, prefix, pageNumber, pageSize, isAddData } = action.payload;
  try {
    yield put(setIsDisabledScripts(true));
    let data = {
      scriptGroupId: groupId,
      partnerId,
      prefix,
      pageNumber,
      pageSize
    }
    const response = yield call(ApiService.getScriptsByGroupId, data);
    if (response && response.data) {
      const data = { ...response.data };
      if (data.pagination) {
        yield put({
          type: SET_SCRIPTS_PAGINATION,
          payload: data.pagination
        })
      }
      if (isAddData) {
        yield put({
          type: ADD_SCRIPTS,
          payload: data?.pagedData,
        });
      } else {
        yield put({
          type: SET_SCRIPTS,
          payload: data?.pagedData,
        });
      }
    }
    yield put(setIsDisabledScripts(false));
  } catch (e) {
    AlertService.alert(e, ERROR_KEY);
    yield put(setIsDisabledScripts(false));
  };
};


