import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TranslationService from '../../Services/translationService';

const CookiesModal = (props) => {

  let acceptedCookies = localStorage.getItem("acceptedCookies");
  const [isShowCookiesModal, setIsShowCookiesModal] = useState(false);
  const { language } = useSelector(state => state.language);
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem("acceptedCookies")) {
      setIsShowCookiesModal(true);
    } else {
      setIsShowCookiesModal(false);
    }
  }, [acceptedCookies]);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const hideModal = () => {
    setIsShowCookiesModal(false);
    localStorage.setItem("acceptedCookies", true);
  }

  return (isShowCookiesModal && translationService && !window.location.pathname.includes("cookies-policy") && !window.location.pathname.includes("privacy-policy") ?
    <div className='cookie-modal'>
      <div className='cookie-modal-block d-flex p-4 align-items-center justify-content-between flex-wrap'>
        <p className='m-0'>{translationService.translate("TR_COOKIE_INFO")}</p>
        <div className='d-flex mt-2 mt-md-0'>
          <button
            className="vnd-btn vnd-primary-btn me-2 nowrap"
            onClick={hideModal}
          >
            {translationService.translate("TR_ACCEPT")}
          </button>
          <button
            className="vnd-primary-outline-btn vnd-outline-btn ms-2 nowrap"
            onClick={() => props.history.push(`/${language}/cookies-policy`)}
          >
            {translationService.translate("TR_LEARN_MORE")}
          </button>
        </div>
      </div>
    </div>
    : null
  )
}

export default withRouter(CookiesModal)
