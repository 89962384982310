import React from "react";
import PartnerRouting from "../../Routing/PartnerRouting/PartnerRouting";
import PartnerLeftMenu from "../LayoutSections/PartnerLeftMenu/PartnerLeftMenu";
import PartnerHeader from "../LayoutSections/Headers/PartnerHeader";
import "../../sass/styles.css";
import PartnerFooter from "../LayoutSections/Footers/PartnerFooter";
import { useSelector } from 'react-redux';

const PartnerLayout = () => {

  const { spinners } = useSelector(state => state.spinner);

  return (
    <div id="main">
      <PartnerHeader />
      <PartnerLeftMenu />
      <div className={`${spinners.length ? "disabled" : ""}`}>
        <PartnerRouting />
        <PartnerFooter />
      </div>
    </div>
  );
}

export default PartnerLayout;

