import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TranslationService from '../../../../Services/translationService';
import ActionButton from '../../../../Components/Buttons/ActionButton';

export default function PaymentProcess(props) {

  const [translationService, setTranslationService] = useState(null);
  const translations = useSelector(state => state.translation.translations);

  const { paymentSystems, setPaymentSystemId, paymentSystemId, buttonSpinnerId, confirmPayment } = props;

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  return translationService ? <div className="payment-process-wrapper d-lg-flex">
    <div className="payment-process-types">
      <div className='mb-3'>
        <p className="vnd--upp-text-20">{translationService.translate("TR_PAYMENT_METHODS")}</p>
        <span className='font-size-14'>{translationService.translate("TR_PAYMENT_METHODS_TEXT")}</span>
      </div>
      <hr />
      <div className='d-flex align-items-center flex-wrap'>
        {
          paymentSystems && paymentSystems.length ? paymentSystems.map(paymentSystem => {
            return <div
              key={paymentSystem.id}
              className={`vnd--payment-method-wrapper ${paymentSystemId === paymentSystem.id ? "active" : ""}`}
              onClick={() => setPaymentSystemId(paymentSystem.id)}
              title={translationService.translate(paymentSystem.name) ? translationService.translate(paymentSystem.name) : paymentSystem.name}
            >
              <img src={paymentSystem.logoPath} alt="/" />

            </div>
          }) : null
        }
      </div>
      <ActionButton
        type="button"
        name={translationService.translate("TR_CONFIRM")}
        className="vnd-btn vnd-primary-btn w-100 mt-4"
        spinnerId={buttonSpinnerId}
        onClick={confirmPayment}
      />
    </div>
  </div> : null
}
