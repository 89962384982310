import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import uuid from 'react-uuid'
import ActionButton from '../../../Components/Buttons/ActionButton'
import { ERROR_KEY, fieldLengths } from '../../../Constants/MainKeys'
import AlertService from '../../../Services/AlertService'
import { addButtonSpinner, addSpinner, removeButtonSpinner, removeSpinner } from '../../../Store/Actions/spinner'
import CloseSvg from "./../../../assets/icons/close-blue-01.svg"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import Auxiliary from '../../../hoc/auxiliary/auxiliary'
import Textarea from '../../../Components/Inputs/Textarea'
import { Link } from 'react-router-dom'
import ApiService from '../../../Services/ApiService'
import { useSelector } from 'react-redux'
import TranslationService from '../../../Services/translationService'
import { Picker } from 'emoji-mart'
import { UncontrolledTooltip, ButtonDropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import Widget from '../WidgetSettings/Components/Widget'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import StepFooter from './Components/StepFooter'

const spinnerId = uuid();
export default function BecomeApartnerStep3(props) {

  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);

  const [translationService, setTranslationService] = useState(null);
  const [id, setId] = useState(null);
  const [widgetFAQs, setWidgetFAQs] = useState([]);
  const [currentFAQ, setCurrentFAQ] = useState("");
  const [key, setKey] = useState(1);
  const [welcomeSubMessageLibDataId, setWelcomeSubMessageLibDataId] = useState(2);
  const [welcomeMessageLibDataId, setWelcomeMessageLibDataId] = useState(5);
  const [welcomeSubMessage, setWelcomeSubMessage] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [form, setForm] = useState({});
  const [welcomeSubMessageTitleLibrary, setWelcomeSubMessageTitleLibrary] = useState([]);
  const [welcomeSubMessageTitle, setWelcomeSubMessageTitle] = useState("");
  const [welcomeMessageTitleLibrary, setWelcomeMessageTitleLibrary] = useState([]);
  const [welcomeMessageTitle, setWelcomeMessageTitle] = useState("");
  const [isShowWelcomeSubMessageTextarea, setIsShowWelcomeSubMessageTextarea] = useState(false);
  const [isShowWelcomeMessageTextarea, setIsShowWelcomeMessageTextarea] = useState(false);
  const [color, setColor] = useState("#00266c");
  const [textColor, setTextColor] = useState("#ffffff");
  const [gradient, setGradient] = useState(null);
  const [widgetSize, setWidgetSize] = useState(null);
  const [widget_size, set_widget_size] = useState("widget_medium_size");
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isOpenWelcomeMessageEmojies, setIsOpenWelcomeMessageEmojies] = useState(false);
  const [isOpenWelcomeSubMessageEmojies, setIsOpenWelcomeSubMessageEmojies] = useState(false);
  const [isActiveCircularMenu, setIsActiveCircularMenu] = useState(false);
  const [isShowWidget, setIsShowWidget] = useState(true);

  useEffect(() => {
    getWidgetData();
  }, []);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const toggleWelcomeMessageEmojies = () => {
    setIsOpenWelcomeMessageEmojies(!isOpenWelcomeMessageEmojies)
  }
  const toggleWelcomeSubMessageEmojies = () => {
    setIsOpenWelcomeSubMessageEmojies(!isOpenWelcomeSubMessageEmojies)
  }

  const addEmoji = (e, cb, text, maxLength = null) => {
    if (maxLength && (maxLength <= text.length || maxLength <= text.length + 1)) { return; }
    let emoji = e.native;
    cb(`${text}${emoji}`);
  }

  const removeFAQ = (faq) => {
    setWidgetFAQs(widgetFAQs.filter(faqItem => faqItem !== faq))
  }

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
  }

  const addCurrentFAQinarray = () => {
    if (currentFAQ.length && currentFAQ.trim().length) {
      widgetFAQs.push(currentFAQ);
      setWidgetFAQs(widgetFAQs);
      setCurrentFAQ("");
    }
  }

  const getWidgetData = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getWidgetDataByPageStepNumber(3).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        setForm(data);
        data.id && setId(data.id);
        data.widgetFAQs && setWidgetFAQs(data.widgetFAQs);
        data.gradient && setGradient(data.gradient);
        data.welcomeConfirmedSubMessageId && setWelcomeSubMessageLibDataId(data.welcomeConfirmedSubMessageId);
        data.welcomeConfirmedMessageId && setWelcomeMessageLibDataId(data.welcomeConfirmedMessageId);

        if (data.widgetSize) {
          var widget_size = data.widgetSize === 48 ? "3em" : data.widgetSize === 64 ? "4em" : data.widgetSize === 80 ? "5em" : 64
          document.querySelector('.vnd--circular-menu').style.setProperty('--vnd-4em', widget_size);
          set_widget_size(
            widget_size === "3em" ?
              "widget_small_size" :
              widget_size === "4em" ?
                "widget_medium_size" :
                widget_size === "5em" ?
                  "widget_large_size" :
                  "widget_medium_size"
          );
          setWidgetSize(widget_size);
        }

        if (data.color) {
          setColor(data.color);
          document.querySelector(".vnd-local-message").style.setProperty('--primary-color', data.color);
          document.querySelector('.vnd--circular-menu').style.setProperty('--primary-color', data.color);
        }

        if (data.textColor) {
          setTextColor(data.textColor);
          document.querySelector('.vnd--circular-menu').style.setProperty('--white-color', data.textColor);
        }

        if (data.welcomeSubMessageTitle) {
          if (data.welcomeSubMessageTitle.widgetLibraryDatas && data.welcomeSubMessageTitle.widgetLibraryDatas.length) {
            setWelcomeSubMessageTitleLibrary(data.welcomeSubMessageTitle.widgetLibraryDatas);
          }
          setWelcomeSubMessageTitle(data.welcomeSubMessageTitle.displayString);
        }

        if (data.welcomeMessageTitle) {
          if (data.welcomeMessageTitle.widgetLibraryDatas && data.welcomeMessageTitle.widgetLibraryDatas.length) {
            setWelcomeMessageTitleLibrary(data.welcomeMessageTitle.widgetLibraryDatas);
          }
          setWelcomeMessageTitle(data.welcomeMessageTitle.displayString);
        }

        if (data.welcomeSubMessage) {
          setWelcomeSubMessage(data.welcomeSubMessage);
          setIsShowWelcomeSubMessageTextarea(true);
        }

        if (data.welcomeMessage) {
          setWelcomeMessage(data.welcomeMessage);
          setIsShowWelcomeMessageTextarea(true);
        }

      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const onSubmit = (event) => {
    event.preventDefault();

    var invalidSubmit = false;
    welcomeSubMessageTitleLibrary.forEach(subLibraryItem => {
      if (subLibraryItem.name === "WELCOME_SUB_CUSTOM" && subLibraryItem.id === welcomeSubMessageLibDataId && !welcomeSubMessage.trim().length) {
        invalidSubmit = true;
        setIsInvalidSubmit(true);
      }
    });

    welcomeMessageTitleLibrary.forEach(libraryItem => {
      if (libraryItem.name === "WELCOME_CUSTOM" && libraryItem.id === welcomeMessageLibDataId && !welcomeMessage.trim().length) {
        invalidSubmit = true;
        setIsInvalidSubmit(true);
      }
    });

    if (invalidSubmit) {
      return;
    } else {
      setButtonSpinner(spinnerId);
      form.id = id;
      form.stepNumber = 3;
      form.welcomeSubMessage = welcomeSubMessage.trim();
      form.welcomeMessage = welcomeMessage.trim();
      form.widgetFAQs = widgetFAQs;
      form.welcomeSubMessageLibDataId = welcomeSubMessageLibDataId;
      form.welcomeMessageLibDataId = welcomeMessageLibDataId;
      form.welcomeSubMessageLibId = form.welcomeSubMessageTitle.id;
      form.welcomeMessageLibId = form.welcomeMessageTitle.id;

      ApiService.becomePartner(form).then(() => {
        props.history.push(`/${language}/become-partner/step-4`);
        extractButtonSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId));
    }
  };

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
    spinnerId && extractSpinner(spinnerId);
  };


  return translationService ? <section className="top-section-steps">
    <div className="container">
      <div className="row">
        <div className='col-12'>
          <div className="vnd--section-title-wrapper">
            <p className="vnd--upp-text-20">{translationService.translate("TR_WM_AND_FAQ")}</p>
            <span className="font-size-14">{translationService.translate("TR_WELCOME_MESSAGE_INFO")}:</span>
          </div>
        </div>
      </div>
      <div className='row' style={{ minHeight: "500px" }}>
        <div className='col-lg-6'>
          <div className='row'>
            <div className='col-12 my-2'>
              <div className="vnd--m-block light-brand-background h-100">
                <Tabs
                  activeKey={key}
                  onSelect={(key) => { setKey(key) }}
                >
                  <Tab eventKey={1} title={`${welcomeMessageTitle}`}>
                    {
                      welcomeMessageTitleLibrary && welcomeMessageTitleLibrary.length ?
                        welcomeMessageTitleLibrary.map((libraryItem, index) => {
                          return <div key={index} className="custom-control custom-radio form-group my-2">
                            <input
                              id={libraryItem.id}
                              type="radio"
                              name="welcome_message"
                              className="custom-control-input"
                              checked={welcomeMessageLibDataId === libraryItem.id ? true : false}
                              onChange={() => {
                                setWelcomeMessageLibDataId(libraryItem.id);
                                setWelcomeMessage("");
                                setIsInvalidSubmit(false);
                                if (libraryItem.name === 'WELCOME_CUSTOM') setIsShowWelcomeMessageTextarea(true);
                                else setIsShowWelcomeMessageTextarea(false);
                              }}
                            />
                            <label className="custom-control-label capitalize" htmlFor={libraryItem.id}>{libraryItem.displayName}</label>
                          </div>
                        })
                        : null
                    }
                    {
                      isShowWelcomeMessageTextarea ?
                        <div className='position-relative'>
                          <div className='emoji-block'>
                            <ButtonDropdown
                              className="emoji-dropdown"
                              direction="up"
                              isOpen={isOpenWelcomeMessageEmojies}
                              toggle={toggleWelcomeMessageEmojies}
                            >
                              <DropdownToggle id="emoji" color="link" className="text-decoration-none font-size-16 btn-lg waves-effect">
                                <i className="ri-emotion-happy-line"></i>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <Picker onSelect={(e) => addEmoji(e, setWelcomeMessage, welcomeMessage, fieldLengths.maxLength_30)} />
                              </DropdownMenu>
                            </ButtonDropdown>
                            <UncontrolledTooltip target="emoji" placement="top">
                              {translationService.translate("TR_EMOJI")}
                            </UncontrolledTooltip>
                          </div>
                          <Textarea
                            id="welcome_text"
                            htmlFor="welcome_text"
                            value={welcomeMessage}
                            labelValue={translationService.translate("TR_MESSAGE")}
                            placeholder={`${translationService.translate("TR_MESSAGE_PLACEHOLDER")}...`}
                            rows="3"
                            className="resize-none"
                            isInvalidField={welcomeMessageTitleLibrary.find(libraryItem => isInvalidSubmit && libraryItem.name === "WELCOME_CUSTOM" && libraryItem.id === welcomeMessageLibDataId && !welcomeMessage.trim().length)}
                            onChange={(event) => onChange(event, setWelcomeMessage, fieldLengths.maxLength_50)}
                          />
                        </div>

                        : null
                    }
                  </Tab>
                  <Tab eventKey={2} title={`${welcomeSubMessageTitle}`}>
                    <Auxiliary>
                      {
                        welcomeSubMessageTitleLibrary && welcomeSubMessageTitleLibrary.length ?
                          welcomeSubMessageTitleLibrary.map((libraryItem, index) => {
                            return <div key={index} className="custom-control custom-radio form-group my-2">
                              <input
                                id={libraryItem.id}
                                type="radio"
                                name="welcome_sub_message"
                                className="custom-control-input"
                                checked={welcomeSubMessageLibDataId === libraryItem.id ? true : false}
                                onChange={() => {
                                  setWelcomeSubMessageLibDataId(libraryItem.id);
                                  setWelcomeSubMessage("");
                                  setIsInvalidSubmit(false);
                                  if (libraryItem.name === 'WELCOME_SUB_CUSTOM') setIsShowWelcomeSubMessageTextarea(true);
                                  else setIsShowWelcomeSubMessageTextarea(false);
                                }}
                              />
                              <label className="custom-control-label capitalize" htmlFor={libraryItem.id}>{libraryItem.displayName}</label>
                            </div>
                          })
                          : null
                      }
                      {
                        isShowWelcomeSubMessageTextarea ?
                          <div className='position-relative'>
                            <div className='emoji-block'>
                              <ButtonDropdown
                                className="emoji-dropdown"
                                direction="up"
                                isOpen={isOpenWelcomeSubMessageEmojies}
                                toggle={toggleWelcomeSubMessageEmojies}
                              >
                                <DropdownToggle id="emoji" color="link" className="text-decoration-none font-size-16 btn-lg waves-effect">
                                  <i className="ri-emotion-happy-line"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <Picker onSelect={(e) => addEmoji(e, setWelcomeSubMessage, welcomeSubMessage, fieldLengths.maxLength_30)} />
                                </DropdownMenu>
                              </ButtonDropdown>
                              <UncontrolledTooltip target="emoji" placement="top">
                                {translationService.translate("TR_EMOJI")}
                              </UncontrolledTooltip>
                            </div>
                            <Textarea
                              id="welcome_sub_text"
                              htmlFor="welcome_sub_text"
                              value={welcomeSubMessage}
                              labelValue={translationService.translate("TR_MESSAGE")}
                              placeholder={`${translationService.translate("TR_MESSAGE_PLACEHOLDER")}...`}
                              rows="3"
                              className="resize-none"
                              isInvalidField={welcomeSubMessageTitleLibrary.find(subLibraryItem => isInvalidSubmit && subLibraryItem.name === "WELCOME_SUB_CUSTOM" && subLibraryItem.id === welcomeSubMessageLibDataId && !welcomeSubMessage.trim().length)}
                              onChange={(event) => onChange(event, setWelcomeSubMessage, fieldLengths.maxLength_100)}
                            />
                          </div>
                          : null
                      }
                    </Auxiliary>
                  </Tab>

                </Tabs>
              </div>
            </div>
            <div className='col-12 my-2'>
              <div className="vnd--m-block light-gray-background h-100">
                <div className="form-group mb-0">
                  <label htmlFor="currentFAQ" className='capitalize cursor-pointer'>{translationService.translate("TR_CREATE")} {translationService.translate("TR_FAQ")}</label>
                  <div className="vnd-complexe-input d-flex">
                    <input
                      id='currentFAQ'
                      name="currentFAQ"
                      type="text"
                      placeholder={`${translationService.translate("TR_CREATE")} ${translationService.translate("TR_FAQ")}`}
                      value={currentFAQ}
                      onChange={event => onChange(event, setCurrentFAQ, fieldLengths.maxLength_120)}
                      onKeyDown={event => {
                        if (event.key === "Enter") {
                          addCurrentFAQinarray();
                        }
                      }}
                    />
                    <div className="vnd-complexe-btn" onClick={addCurrentFAQinarray}>
                      <i className="ri-add-line text-white font-size-20"></i>
                    </div>
                  </div>
                  <div className="vnd-added-item-container">
                    {
                      widgetFAQs && widgetFAQs.length ?
                        widgetFAQs.map((FAQitem, index) => <div key={index} className="vnd-added-item-wrapper word-break-break-word">
                          <span>{FAQitem}</span>
                          <img src={CloseSvg} alt="" onClick={() => removeFAQ(FAQitem)} />
                        </div>)
                        : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Widget
          isActiveCircularMenu={isActiveCircularMenu}
          setIsActiveCircularMenu={setIsActiveCircularMenu}
          setIsShowWidget={setIsShowWidget}
          isShowWidget={isShowWidget}
          widget_size={widget_size}
          widgetGradientColor={gradient ? gradient : color}
          welcomeSubMessageTitleLibrary={welcomeSubMessageTitleLibrary}
          color={color}
          textColor={textColor}
          widgetSize={widgetSize}
          widgetFAQs={widgetFAQs}
          welcomeMessage={welcomeMessage}
          welcomeSubMessage={welcomeSubMessage}
          welcomeMessageTitleLibrary={welcomeMessageTitleLibrary}
          welcomeMessageLibDataId={welcomeMessageLibDataId}
          welcomeSubMessageLibDataId={welcomeSubMessageLibDataId}
          translationService={translationService}
          useWidgetButton={true}
        />
      </div>
    </div>
    <StepFooter
      translationService={translationService}
      setpNumber="3"
      backUrl={`/${language}/become-partner/step-2`}
      actionButtonName={translationService.translate("TR_CONTINUE")}
      spinnerId={spinnerId}
      onSubmit={onSubmit}
    />
  </section> : null
}
