import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import facebook from '../../../../assets/images/auth-services/facebook.svg';
import uuid from "react-uuid";

const spinnerId = uuid();

const FacebookAuth = () => {
    const dispatch = useDispatch();
    const signUp = () => {
        window.FB.login(function (response) {
            if (response.authResponse) {
                // console.log('Welcome!  Fetching your information.... ');
                window.FB.api('/me', { fields: 'first_name, last_name, picture, email' }, function (response) {
                    // console.log(response);
                    if (response) {
                        // const data = {
                        //     email: res.data.email,
                        //     firstName: res.data.given_name,
                        //     lastName: res.data.family_name,
                        //     userRegistrationType: 0
                        // }
                        // dispatch(userRegistration(data, spinnerId, props.history))
                    }
                });
            } else {

                // console.log('User cancelled login or did not fully authorize.');
            }
        }, { scope: 'email', return_scopes: true });
        // window.FB.login(response => {
        //     if (response.authResponse) {
        //         // console.log('Welcome!  Fetching your information.... ');
        //         window.FB.api('/me', { fields: 'first_name, last_name, picture, email' }, function (response) {
        //             // console.log(response);
        //         });
        //     } else {
        //         // console.log('User cancelled login or did not fully authorize.');
        //     }
        //     // // console.log(response, window.FB);
        //     // window.FB.api(
        //     //     "/me",
        //     //     "GET",
        //     //     {
        //     //         headers: {
        //     //             access_token: response.authResponse.accessToken,
        //     //         },
        //     //     },
        //     //     { fields: "id,last_name, first_name,email,picture" },
        //     //     function (response) {
        //     //         // console.log(response);
        //     //         setTimeout(() => {
        //     //             window.FB.logout(response => {
        //     //                 // console.log(response);
        //     //             })
        //     //         }, 5000);
        //     //         // Insert your code here
        //     //         // const data = {
        //     //         //     email: res.data.email,
        //     //         //     firstName: res.data.given_name,
        //     //         //     lastName: res.data.family_name,
        //     //         //     userRegistrationType: 0
        //     //         // }

        //     //         // dispatch(userRegistration(data, spinnerId, props.history))
        //     //     },
        //     // );
        // }, { scope: 'email' });

        // setTimeout(() => {
        //     window.FB.logout(function (response) {
        //         // console.log(response);
        //         // Person is now logged out
        //     });
        // }, 5000);
    };

    useEffect(() => {
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: "684734950299096",
                autoLogAppEvents: true,
                xfbml: true,
                version: "v18.0",
            });
        };
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    }, []);
    return <div className='vnd--alternative-auth-item' onClick={() => { signUp() }}>
        <img src={facebook} alt="" />
        <span>facebook</span>
    </div>
};
export default withRouter(FacebookAuth);