import React from "react";
import PuffLoader from "react-spinners/PuffLoader";

function OvalButtonSpinner(props) {

  return (
    props.spinner && props.spinner.length ?
      <div className="sweet-loading d-flex">
        <PuffLoader size={25} color={props.spinnerColor} />
      </div>
      : null
  );
}

export default OvalButtonSpinner;