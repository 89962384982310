import React, { useEffect, useState } from 'react'
import Auxiliary from '../../hoc/auxiliary/auxiliary';

export default function Reconnect() {

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Auxiliary>
      <div className='connecting-loader'>
        <div className='layer' />
        <div className='connecting-loader-block'>
          <p className='title'>Connection lost</p>
          {
            seconds < 30 ? <p className='text'>
              Trying to reconnect
              <span className="animate-typing">
                <span className="dot ms-1"></span>
                <span className="dot ms-1"></span>
                <span className="dot ms-1"></span>
              </span>
            </p> : null
          }
          {
            seconds >= 30 ?
              <button
                type='button'
                className="vnd-btn vnd-small-btn vnd-primary-btn mt-3"
                onClick={() => {
                  window.location.reload()
                }}
              >
                Reload page
              </button>
              : null
          }
        </div>
      </div>
    </Auxiliary >

  )
}
