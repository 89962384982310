import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { ERROR_KEY, SUCCESS_KEY, fieldLengths } from '../../Constants/MainKeys';
import AlertService from '../../Services/AlertService';
import { addButtonSpinner, removeButtonSpinner } from '../../Store/Actions/spinner';
import ActionButton from '../Buttons/ActionButton';
import Input from '../Inputs/Input';
import ApiService from './../../Services/ApiService';
import { logout } from './../../Store/Actions/user';
import TranslationService from './../../Services/translationService';

const buttonSpinnerId = uuid()
export default function CloseAccountModal(props) {

  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);

  const [translationService, setTranslationService] = useState(null);
  const [password, setPassword] = useState("");
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
  }

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!password.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    } else {
      setButtonSpinner(buttonSpinnerId);
      const data = { password };
      ApiService.closeAccount(data).then(() => {
        extractButtonSpinner(buttonSpinnerId)
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_ACCOUNT_CLOSED"));
        dispatch(logout());
      }).catch(error => getFail(error, buttonSpinnerId))
    }
  }

  const closeModal = () => {
    setIsInvalidSubmit(false);
    setPassword("");
    extractButtonSpinner(buttonSpinnerId);
    props.closeModal()
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  return (translationService ? <div className="vnd-modal-window">
    <div className="vnd-modal-window-wrapper">
      <div className="vnd-modal">
        <p className="vnd--upp-text-20">{translationService.translate("TR_CLOSE_ACCOUNT")}</p>
        <hr />
        <p>{translationService.translate("TR_CLOSE_ACCOUNT_INFO")}</p>
        <form onSubmit={onSubmit}>
          <Input
            id="password"
            htmlFor="password"
            type="password"
            name="password"
            labelValue={`${translationService.translate("TR_PASSWORD")} *`}
            placeholder={`${translationService.translate("TR_PASSWORD")}`}
            value={password}
            autoComplete="new-password"
            inputClassName={`${(isInvalidSubmit && !password.trim()) ? "error-border" : ""}`}
            onChange={(event) => {
              onChange(event, setPassword, fieldLengths.maxLength_100);
              setIsInvalidSubmit(false);
            }}
          />
          <div className='d-flex justify-content-end mb-3 mt-4'>
            <ActionButton
              name={translationService.translate("TR_CLOSE_ACCOUNT")}
              className="vnd-btn vnd-danger-btn mx-1"
              spinnerId={buttonSpinnerId}
            />
            <button className='vnd-btn vnd-primary-btn mx-1' type='button' onClick={closeModal}>
              {translationService.translate("TR_CANCEL")}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div> : null
  )
}
