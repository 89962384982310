import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { ERROR_KEY, PARTNER_USER_TYPE_ID_KEY } from '../../../Constants/MainKeys';
import UseScroll from "./../../../Hooks/useScroll"
import uuid from 'react-uuid';
import ApiService from '../../../Services/ApiService';
import Parser from "html-react-parser"
import { useState } from 'react';
import { addSpinner, removeSpinner } from '../../../Store/Actions/spinner';
import AlertService from '../../../Services/AlertService';
import TranslationService from '../../../Services/translationService';
import moment from 'moment';
import MainService from '../../../Services/mainService';



const SetPageData = ({ pagecontent, translationService }) => {

  const scrollToCurrentPosition = (activeSection) => {
    const currentSection = document.getElementById(activeSection);
    currentSection && window.scrollTo({
      top: currentSection?.getBoundingClientRect().top + document.documentElement.scrollTop - 100,
      behavior: "smooth"
    });
  }


  const setHeight = () => {
    let scrollY = UseScroll();
    if (document.body.querySelector("footer")) {
      return scrollY > 170 && scrollY + 500 + document.body.querySelector("footer").offsetHeight < document.body.offsetHeight
    } else {
      return scrollY > 200
    }
  };

  return (<div className="container">
    <div className='row'>
      <div className='col-12'>
        <h1 className="vnd--upp-text-22 mb-2">
          {
            pagecontent && pagecontent.firstContent && pagecontent.firstContent.title ?
              pagecontent.firstContent.title
              : null
          }
        </h1>
        <div className='vnd--m-block light-brand-background'>
          <small className="d-block">Relamy Inc.</small>
          <small className="d-block">{translationService.translate("TR_UPDATED")} :
            {
              moment(MainService.convertUTCDateToLocalDate(new Date(pagecontent && pagecontent.firstContent && pagecontent.firstContent.updateDate ? pagecontent.firstContent.updateDate : null))).format(' LL HH:mm')
            }
          </small>
        </div>
        <hr />
      </div>
    </div>
    <div className='row'>
      {
        pagecontent && pagecontent.firstContent && pagecontent.firstContent.fullContent ?
          Parser(pagecontent.firstContent.fullContent)
          : null
      }
      <div className='col-md-4'>
        <div className={`mt-4 ${setHeight() ? "list-fixed" : ""}`}>
          <h2 className="vnd--upp-text-20 mb-3">{translationService.translate("TR_TABLE_OF_CONTENTS")}</h2>
          <ol className='vnd--fixed-list-menu list-unstyled mt-2'>
            <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("overview")}>
              <Link to="#" className='text-underline text-uppercase'>{translationService.translate("TR_OVERVIEW")}</Link>
            </li>
            <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("whatAreCookies")}>
              <Link to="#" className='text-underline text-uppercase'>{translationService.translate("TR_WHAT_ARE_COOKIES")}</Link>
            </li>
            <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("cookiesWeUse")}>
              <Link to="#" className='text-underline text-uppercase'>{translationService.translate("TR_COOKIES_WE_USE")}</Link>
            </li>
            <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("webBeacins")}>
              <Link to="#" className='text-underline text-uppercase'>{translationService.translate("TR_WEB_BEACONS")}</Link>
            </li>
            <li className='cursor-pointer' onClick={() => scrollToCurrentPosition("howToManageCookies")}>
              <Link to="#" className='text-underline text-uppercase'>{translationService.translate("TR_HOW_MANAGE_COOKIES")}</Link>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  )
}

const CookiesPolicy = (props) => {
  const dispatch = useDispatch();
  const { language } = useSelector(state => state.language);
  const { translations } = useSelector(state => state.translation);
  const { user } = useSelector(state => state.user);
  const [pagecontent, setPageContent] = useState(null);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [language]);

  useEffect(() => {
    getPageContent();
  }, [language])

  const setPage = () => {
    if (user) {
      if (user.userTypeId === PARTNER_USER_TYPE_ID_KEY) {
        return <aside className="right-section bg-white">
          <section className="dashboard top-section ">
            {/* {setPageData(pagecontent, translationService)} */}
            <SetPageData
              pagecontent={pagecontent}
              translationService={translationService}
            />
          </section>
        </aside>
      }
    } else {
      return (
        // <section className="h-section bg-white">{setPageData(pagecontent, translationService)}</section>
        <section className="h-section bg-white">
          <SetPageData
            pagecontent={pagecontent}
            translationService={translationService}
          />
        </section>
      )
    }
  }

  const getPageContent = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getPageByName("cookie")
      .then((response) => {
        if (response && response.data) {
          const data = { ...response.data };
          setPageContent(data);
        }
        extractSpinner(spinnerId);
      })
      .catch((error) => { getFail(error, spinnerId) });
  };


  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractSpinner(spinnerId);
  };

  return translationService ? <>{setPage()}</> : null

}

export default withRouter(CookiesPolicy)





