import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ERROR_KEY, SUCCESS_KEY, fieldLengths } from '../../../Constants/MainKeys';
import AlertService from '../../../Services/AlertService';
import uuid from 'react-uuid';
import ApiService from '../../../Services/ApiService';
import { addButtonSpinner, removeButtonSpinner } from '../../../Store/Actions/spinner';
import Input from '../../../Components/Inputs/Input';
import ActionButton from '../../../Components/Buttons/ActionButton';
import MainService from '../../../Services/mainService';
import TranslationService from './../../../Services/translationService';
import Parser from 'html-react-parser';
import { AiOutlineLeft } from "react-icons/ai";


const buttonSpinnerId = uuid();
const TechnicalAssistantForm = (props) => {

  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);
  const translations = useSelector(state => state.translation.translations);

  const [translationService, setTranslationService] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [isInvalidConfirmPassword, setIsInvalidConfirmPassword] = useState(false);
  const [mismatchPasswords, setMismatchPasswords] = useState(false);
  const [isInvalidFirstName, setIsInvalidFirstName] = useState(false);
  const [isInvalidLastName, setIsInvalidLastName] = useState(false);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
    setMismatchPasswords(false);
  }

  const checkFieldsValidation = (field, fieldName) => {
    var isValid = MainService.isValidField(field, fieldName);
    if (fieldName === "password") {
      changeIsinvalidFieldName(isValid, setIsInvalidPassword);
    }
    if (fieldName === "confirmPassword") {
      changeIsinvalidFieldName(isValid, setIsInvalidConfirmPassword);
    }
    if (fieldName === "password" || fieldName === "confirmPassword") {
      if (password.trim().length && confirmPassword.trim().length && (password !== confirmPassword)) {
        changeIsinvalidFieldName(false, setMismatchPasswords);
      } else {
        changeIsinvalidFieldName(true, setMismatchPasswords);
      }
    }
    switch (fieldName) {
      case "firstName":
        changeIsinvalidFieldName(isValid, setIsInvalidFirstName)
        break;
      case "lastName":
        changeIsinvalidFieldName(isValid, setIsInvalidLastName)
        break;
      case "email":
        changeIsinvalidFieldName(isValid, setIsInvalidEmail)
        break;
      default:
        break;
    }
  }

  const changeIsinvalidFieldName = (isValid, cb) => {
    if (!isValid) cb(true);
    else cb(false);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (
      !firstName.trim().length ||
      !lastName.trim().length ||
      !email.trim().length ||
      isInvalidFirstName ||
      isInvalidLastName ||
      isInvalidEmail
    ) {
      setIsInvalidSubmit(true);
      return false;
    } else {
      setButtonSpinner(buttonSpinnerId);
      const form = {
        firstName,
        lastName,
        email
      }
      ApiService.createSupporter(form).then(() => {
        AlertService.alert(SUCCESS_KEY,
          ` ${translationService.translate("TR_TECHNICAL_ASSISTANT")}
            ${" "}
            ${translationService.translate("TR_CREATED_SUCCESSFULLY")}
          `);
        extractButtonSpinner(buttonSpinnerId);
        props.history.push(`/${language}/technical-assistants`);
      }).catch(error => getFail(error, buttonSpinnerId))
    }
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  return user && translationService ?
    <aside className='right-section'>
      <section className="dashboard top-section customer-profile">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="vnd--aside-title-block">
                <div className="backspace">
                  <span
                    className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                    onClick={() => {
                      window.history.back();
                    }}
                  >
                    <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                  </span>
                </div>
                <span className="slash">|</span>
                <div className="">
                  <p className="vnd--upp-text-22">{translationService.translate("TR_CREATE")} {translationService.translate("TR_TECHNICAL_ASSISTANT")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='vnd--m-block vnd--borderd white-background'>
                <form>
                  <Input
                    id="firstName"
                    htmlFor="firstName"
                    type="text"
                    name="firstName"
                    labelValue={`${translationService.translate("TR_FIRST_NAME")} *`}
                    placeholder={`${translationService.translate("TR_FIRST_NAME")} *`}
                    value={firstName}
                    inputClassName={`${(isInvalidSubmit && !firstName.trim()) || isInvalidFirstName ? "error-border" : ""}`}
                    onChange={(event) => {
                      onChange(event, setFirstName, fieldLengths.maxLength_100);
                      setIsInvalidSubmit(false);
                    }}
                    onBlur={() => checkFieldsValidation(firstName, "firstName")}
                  />
                  <Input
                    id="lastName"
                    htmlFor="lastName"
                    type="text"
                    name="lastName"
                    labelValue={`${translationService.translate("TR_LAST_NAME")} *`}
                    placeholder={`${translationService.translate("TR_LAST_NAME")} *`}
                    value={lastName}
                    inputClassName={`${(isInvalidSubmit && !lastName.trim()) || isInvalidLastName ? "error-border" : ""}`}
                    onChange={(event) => {
                      onChange(event, setLastName, fieldLengths.maxLength_100);
                      setIsInvalidSubmit(false);
                    }}
                    onBlur={() => checkFieldsValidation(lastName, "lastName")}
                  />
                  <Input
                    id="email"
                    htmlFor="email"
                    type="email"
                    name="email"
                    value={email}
                    labelValue={`${translationService.translate("TR_EMAIL")} *`}
                    placeholder={`${translationService.translate("TR_EMAIL")} *`}
                    required={true}
                    inputClassName={`${(isInvalidSubmit && !email.trim()) || isInvalidEmail ? "error-border" : ""}`}
                    onChange={(event) => onChange(event, setEmail, fieldLengths.maxLength_100)}
                    onBlur={() => checkFieldsValidation(email, "email")}
                  />
                  {
                    mismatchPasswords ?
                      <div className='my-4'><small className="red-color">{translationService.translate("TR_PASSWORD_MISMATCH")}</small></div>
                      : null
                  }
                  {
                    isInvalidConfirmPassword ?
                      <small className="red-color">{translationService.translate("TR_PASSWORD_LENGTH_VAL").replace('{0}', "6").replace('{1}', "100")}</small>
                      : null
                  }
                  <div className='d-flex justify-content-end w-100'>
                    <ActionButton
                      type="submit"
                      disabled={isInvalidFirstName || isInvalidLastName || isInvalidEmail}
                      className="vnd-btn vnd-primary-btn mt-4"
                      name={translationService.translate("TR_CREATE")}
                      spinnerId={buttonSpinnerId}
                      onClick={onSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className='col-md-6 mt-3 mt-md-0'>
              <div className='vnd--m-block light-brand-background'>
                <p className=''>
                  {Parser(translationService.translate("TR_CREATE_SUPPORTER_INFO"))}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </aside >
    : null
}

export default withRouter(TechnicalAssistantForm)