import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TranslationService from '../../Services/translationService';

export default function UnAvailableConnectionModal(props) {

  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const closeModal = () => {
    props.setConnectionUnAvailableMessage("");
  }

  return (translationService ? <div className="vnd-modal-window">
    <div className="vnd-modal-window-wrapper">
      <div className="vnd-modal">
        <p className="vnd--upp-text-20">{translationService.translate("TR_CONNECTION_UNAVAILABLE")}</p>
        <hr />
        <p>{translationService.translate(props.message) ? translationService.translate(props.message) : props.message}</p>
        <div className='d-flex justify-content-end mb-3 mt-4'>
          <button className='vnd-btn vnd-danger-btn  vnd-small-btn mx-1' type='button' onClick={closeModal}>
            {translationService.translate("TR_CLOSE")}
          </button>
        </div>
      </div>
    </div>
  </div> : null
  )
}
