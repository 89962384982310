import { combineReducers } from "redux";
import user from "./user";
import spinner from "./spinner";
import language from "./language";
import translation from "./translation";
import main from "./main";
import chat from "./chat";
import layout from "./layout";
import signalR from "./signalR";
import pageContent from "./pageContent";

export default combineReducers({
  user,
  spinner,
  language,
  translation,
  main,
  chat,
  layout,
  signalR,
  pageContent,
});