import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { ERROR_KEY, SUCCESS_KEY, fieldLengths } from '../../Constants/MainKeys';
import AlertService from '../../Services/AlertService';
import { addButtonSpinner, removeButtonSpinner } from '../../Store/Actions/spinner';
import ActionButton from '../Buttons/ActionButton';
import ApiService from '../../Services/ApiService';
import TranslationService from '../../Services/translationService';
import ReactSelectOption from '../SelectOption/ReactSelectOption';
import CloseSvg from "./../../assets/icons/close.svg"
import { changeActiveUserTickets } from '../../Store/Actions/chat';

const buttonSpinnerId = uuid();
export default function TicketModal(props) {

  const { ticketUrgentResponse, ticketStatusResponse, currentTicket } = props;
  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const activeUserData = useSelector(state => state.chat.activeUserData);
  const [translationService, setTranslationService] = useState(null);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [statusKeyName, setStatusKeyName] = useState(null);
  const [urgentKeyName, setUrgentKeyName] = useState(null);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
  }

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (!currentTicket) {
      if (ticketStatusResponse) {
        if (ticketStatusResponse.activeStatusKeyName) {
          setStatusKeyName(ticketStatusResponse.activeStatusKeyName);
        } else {
          if (ticketStatusResponse.statuses && ticketStatusResponse.statuses.length) {
            setStatusKeyName(ticketStatusResponse.statuses[0].name);
          }
        }
      }
      if (ticketUrgentResponse) {
        if (ticketUrgentResponse.activeUrgentKeyName) {
          setUrgentKeyName(ticketUrgentResponse.activeUrgentKeyName)
        } else {
          if (ticketUrgentResponse.urgents && ticketUrgentResponse.urgents.length) {
            setUrgentKeyName(ticketUrgentResponse.urgents[0].name)
          }
        }
      }
    } else {
      currentTicket.statusKeyName && setStatusKeyName(currentTicket.statusKeyName);
      currentTicket.urgentKeyName && setUrgentKeyName(currentTicket.urgentKeyName)
    }
  }, [])

  useEffect(() => {
    if (currentTicket) {
      currentTicket.title && setName(currentTicket.title);
      currentTicket.description && setDescription(currentTicket.description);
      currentTicket.statusKeyName && setStatusKeyName(currentTicket.statusKeyName);
      currentTicket.urgentKeyName && setUrgentKeyName(currentTicket.urgentKeyName);
    }
  }, [currentTicket])

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!name.trim().length || !description.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    } else {
      setButtonSpinner(buttonSpinnerId);
      const form = {
        id: currentTicket ? currentTicket.id : null,
        title: name,
        description,
        urgentKeyName,
        statusKeyName,
        supportSessionId: activeUserData?.supportSessionId,
      };
      (currentTicket ? ApiService.updateTicket(form) : ApiService.createTicket(form)).then((response) => {
        if (currentTicket) {
          translationService && AlertService.alert(SUCCESS_KEY, translationService.translate("TR_TICKET_UPDATED"));
        } else {
          translationService && AlertService.alert(SUCCESS_KEY, translationService.translate("TR_TICKET_CREATED"));
        }
        if (response && response.data) {
          const data = { ...response.data };
          dispatch(changeActiveUserTickets(data));
        } else {
          form.createDate = currentTicket.createDate;
          form.creatorUserId = currentTicket.creatorUserId
          dispatch(changeActiveUserTickets(form));
        }
        extractButtonSpinner(buttonSpinnerId)
        cancel();
      }).catch(error => getFail(error, buttonSpinnerId));
    }
  }

  const cancel = () => {
    props.cancel();
    setIsInvalidSubmit(false);
    setName("");
    setDescription("");
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  return translationService ?
    <div className='vnd--ticket-form'>
      <form onSubmit={onSubmit} className="border p-2 mt-2 rounded">
        <div className='ticket-modal-title-block p-2'>
          <span className='vnd--upp-text-16'>{currentTicket ? translationService.translate("TR_UPDATE_TICKET") : translationService.translate("TR_CREATE_TICKET")}</span>
          <div className='modal-close-block'>
            <img
              src={CloseSvg}
              alt="/"
              onClick={cancel} />
          </div>
        </div>
        <hr />
        <div className="form-group col-12">
          <label className="form-control-label capitalize cursor-pointer font-weight-bold">{translationService.translate("TR_TITLE")} *</label>
          <input
            type="text"
            value={name}
            placeholder={translationService.translate("TR_NAME")}
            className={`${isInvalidSubmit && !name.trim().length ? "error-border" : ""}`}
            onChange={(event) => onChange(event, setName, fieldLengths.maxLength_50)}
          />
        </div>
        <div className="form-group col-12">
          <label className="form-control-label capitalize cursor-pointer font-weight-bold">{translationService.translate("TR_DESCRIPTION")} *</label>
          <textarea
            rows="3"
            autoComplete="new-password"
            placeholder={translationService.translate("TR_DESCRIPTION")}
            className={`resize-none ${isInvalidSubmit && !description.trim().length ? "error-border" : ""}`}
            value={description}
            onChange={(event) => onChange(event, setDescription, fieldLengths.maxLength_1000)}
          />
        </div>
        <div className='col-12'>
          <div className='row'>
            {
              <div className="form-group col-12 col-md-6">
                <label className="form-control-label capitalize cursor-pointer font-weight-bold">{ticketStatusResponse.displayName} *</label>
                <div className='px-0'>
                  <ReactSelectOption
                    value={statusKeyName}
                    selectedValue={(() => {
                      const selectedValue = { ...ticketStatusResponse.statuses.find(data => data.name === statusKeyName) };
                      if (selectedValue) {
                        selectedValue.label = selectedValue.displayName;
                        selectedValue.value = selectedValue.name;
                      }
                      return selectedValue;
                    })()}
                    items={ticketStatusResponse.statuses.map(data => ({ label: data.displayName, value: data.name }))}
                    onChange={(item) => setStatusKeyName(item.value)}
                  />
                </div>
              </div>
            }
            {
              <div className="form-group col-12 col-md-6">
                <label className="form-control-label capitalize cursor-pointer font-weight-bold">{ticketUrgentResponse.displayName} *</label>
                <div className='px-0'>
                  <ReactSelectOption
                    value={urgentKeyName}
                    selectedValue={(() => {
                      const selectedValue = { ...ticketUrgentResponse.urgents.find(data => data.name === urgentKeyName) };
                      if (selectedValue) {
                        selectedValue.label = selectedValue.displayName;
                        selectedValue.value = selectedValue.name;
                      }
                      return selectedValue;
                    })()}
                    items={ticketUrgentResponse.urgents.map(data => ({ label: data.displayName, value: data.name }))}
                    onChange={(item) => setUrgentKeyName(item.value)}
                  />
                </div>
              </div>
            }
          </div>
        </div>
        <div className="d-sm-flex d-block justify-content-end">
          <ActionButton
            type="submit"
            name={!currentTicket ? translationService.translate("TR_CREATE") : translationService.translate("TR_UPDATE")}
            className="vnd-btn flex-1 vnd-primary-btn vnd-small-btn m-1"
            spinnerId={buttonSpinnerId}
            onClick={onSubmit}
          />
          <button
            type="button"
            className="vnd-btn flex-1 vnd-danger-btn vnd-small-btn m-1"
            onClick={cancel}
          >
            {translationService.translate("TR_CANCEL")}
          </button>
        </div>
      </form>
    </div>
    : null
}
